import { useEffect, useState } from 'react';

import './switch.css';

export default function Switch({ disable, onClick, name, id, checked, value }) {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked])

  return (
    <label id="switch" className={(disable && !isChecked) ? 'disable' : undefined}>
      <input 
        onClick={onClick} 
        onChange={event => setIsChecked(event.target.checked)} 
        type="checkbox" 
        name={name} 
        id={id} 
        disabled={isChecked ? false : disable} 
        checked={checked}
        value={value}
      />
      <span className="slider"></span>
    </label>
  )
}