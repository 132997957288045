
import Button from '../../../../../../components/button/button';
import Modal from '../../../../../../components/modal/modal';
import './deleteEventModal.css';

export default function DeleteEventModal({ isLoadingDel, onClickCancel, onClickSubmit, eventInfo }) {
  return (
    <Modal showModal={onClickCancel} close={true}>
      <div className="content-modal">
        <p className="title">Você quer excluir esse registo de {eventInfo?.Mês_do_registro}?</p>
        <p className="text">Ele será excluido permanentemente.</p>

        <div className="modal-buttons-wrapper">
          <div />
          <Button color="white" onClick={onClickCancel}>Cancelar</Button>
          <Button color="blue" onClick={() => onClickSubmit()} isLoading={isLoadingDel}>Excluir evento</Button>
        </div>
      </div>
    </Modal>
  );
}