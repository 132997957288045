import { useState } from "react";
import Lottie from "react-lottie-player";
import { useHistory } from "react-router-dom";
import XLSX from "xlsx";

import "./modalImport.css";

import { useFarmContext } from "../../../../contexts/farmContext";
import useSpreadsheet from "../../../../hooks/useSpreadsheet";

import Button from "../../../../components/button/button";
import Modal from "../../../../components/modal/modal";
import Table from "../../../../components/table/table";

import loading_gif from "../../../../assets/gifs/loading_2.json";
import big_arrow from "../../../../assets/svg/events/big-arrow-up.svg";

export default function ModalImport({ event, showModal }) {
  const history = useHistory();

  const [uploadTried, setUploadTried] = useState(false);

  const {
    farmSelected,
    animalsId,
    animals: farmAnimals,
    getAnimals,
    lots,
  } = useFarmContext();
  const {
    animals,
    invalidAnimals,
    columns,
    isLoading,
    isProcessingData,
    handleData,
    saveSpreadsheet,
  } = useSpreadsheet(event, farmSelected, animalsId, farmAnimals, lots);

  async function handleSaveSpreadsheet() {
    await saveSpreadsheet();
    await getAnimals(farmSelected.farm_id);
    showModal();
    history.push("/add-event");
  }

  async function handleLoadSpreadsheet(e) {
    const file = await e.target.files[0].arrayBuffer();
    const workbook = XLSX.read(file);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const data = XLSX.utils
      .sheet_to_json(worksheet, { header: 1 })
      .filter((arr) => arr.length > 0);

    setUploadTried(true);
    handleData(data);
  }

  function returnContent() {
    if (uploadTried) {
      if (isProcessingData) {
        return (
          <div className="loading-data">
            <Lottie loop play animationData={loading_gif} />
          </div>
        );
      }

      if (animals.length === 0) {
        return (
          <p className="no-data-message">
            Por favor selecione uma planilha com dados válidos para esse evento
          </p>
        );
      }

      return (
        <div className="table_wrapper">
          <div
            className="table_wrapper_container"
            style={{ minWidth: columns.length * 180 + "px" }}
          >
            <Table
              animals={animals}
              columns={columns}
              onChangeAnimal={() => {}}
            />
            {invalidAnimals.length > 0 && (
              <p className="invalid-animals">
                {invalidAnimals.length > 1
                  ? "Os eventos dos animais "
                  : "O evento do animal "}
                {invalidAnimals.map((a, i) =>
                  i < invalidAnimals.length - 1 ? a + ", " : a
                )}{" "}
                {invalidAnimals.length > 1 ? "possuem " : "possui "} informações
                inválidas, por isso não{" "}
                {invalidAnimals.length > 1
                  ? "foram listados. "
                  : "foi listado. "}
                Altere os valores na planilha e faça o upload novamente!
              </p>
            )}
          </div>
        </div>
      );
    }

    return <img src={big_arrow} alt="Upload" className="upload-arrow" />;
  }

  return (
    <Modal showModal={showModal} close={true}>
      <div className="import-modal-content">
        <p className="title">Importar planilha preenchida</p>

        <p className="text">
          Selecione o arquivo da planilha que você preencheu e exporte ele como{" "}
          <b>.xlsx</b>, para adicionar o evento de <b>{event}</b>. Confira se as
          informações estão corretas e salve a planilha.
        </p>

        {returnContent()}

        <div className="modal-div-buttons">
          <div />
          {
            animals.length === 0 ? (
              <Button color="blue" className="upload-button" onClick={() => {}}>
                <label htmlFor="upload">
                  Selecionar arquivo
                  <input
                    type="file"
                    name="upload"
                    id="upload"
                    accept=".xlsx"
                    onChange={handleLoadSpreadsheet}
                  />
                </label>
              </Button>
            ) : (
              <div />
            )
            // : (
            //   <Button color="white" onClick={() => {}}>
            //     Corrigir arquivo
            //   </Button>
            // )
          }
          <Button
            disabled={animals.length === 0}
            onClick={handleSaveSpreadsheet}
            isLoading={isLoading}
          >
            Salvar planilha
          </Button>
        </div>
      </div>
    </Modal>
  );
}
