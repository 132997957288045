
import { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie-player";
import { YearSelector } from "../../../bases/bases";
import loading_gif from "../../../../../../assets/gifs/loading_2.json";
import { meses } from "../../../../../../utils/meses";
import AreaChart from "../../../../../../components/baseChart/areaChart/areaChart";

import "./AnualAreaChart.css";

export default function AnualAreaChart({allData, maxDate, isLoading, label} ){

  const [sizeYearChart, setSizeYearChart] = useState({});

  const chartRef = useRef(null);
    // CCS in tank Comparison
  const [CCSComparisonData, setCCSComparisonData] = useState([]);
  const [CCSComparisonDataSecond, setCCSComparisonDataSecond] = useState([]);

  const aux_date = new Date(maxDate * 1000);
  const [date, setDate] = useState(aux_date.getFullYear());
  const [maxYear, setMaxYear] = useState(aux_date.getFullYear());

  useEffect(() => {
    const date = new Date(maxDate * 1000);
    setMaxYear(date.getFullYear())
    setDate(date.getFullYear())
  }, [maxDate])

  useEffect(() => {
    // Comparação de CCS no tanque
    var chartDataCCS = [];
    var chartDataCCS2 = [];
    var yearData = allData[date - 1];
    var yearData2 = allData[date];
    for (let i = 0; i < 12; i++) {
    var value = 0
    if (yearData && yearData[i]) {
        value = yearData[i];
    };
    var value2 = 0
    if (yearData2 && yearData2[i]) {
        value2 = yearData2[i];
    };
    chartDataCCS.push({
        label: meses[i],
        value,
        tooltipContent: `<b><p class="tooltip-text">${value}/<span>${value2}</span></p><b>`,
    });
    chartDataCCS2.push({
        label: meses[i],
        value: value2,
        tooltipContent: `<b><p class="tooltip-text">${value}/<span>${value2}</span></p><b>`,
    });
    }

    setCCSComparisonData(chartDataCCS);
    setCCSComparisonDataSecond(chartDataCCS2);
  }, [allData, date])

  useEffect(() => {
    var aux = {};
    aux.height = 180;

    aux.width = chartRef.current.offsetWidth - 80;
    setSizeYearChart(aux);
  }, []);

  return (
    <>
    <YearSelector
      doubleYear
      year={date}
      onChange={(value) => {
        setDate(value);
      }}
      maxYear={maxYear}
    />
    <div className="monthly-graph-wrapper" ref={chartRef}>
      {isLoading ? (
        <div className="no-data">
          <Lottie loop play animationData={loading_gif} />
        </div>
      ) : (
        <AreaChart
          svgProps={{
            margin: { top: 50, bottom: 40, left: 40, right: 40 },
            width: sizeYearChart.width,
            height: sizeYearChart.height,
          }}
          axisProps={{
            xLabel: "",
            yLabel: label,
            drawXGridlines: true,
          }}
          data={CCSComparisonData}
          data2={CCSComparisonDataSecond}
          strokeWidth={3}
        />
      )}
    </div>
    
    </>)
}