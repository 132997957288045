import { newApi } from "../api";

export async function deleteAnimal(animalId, farmId, userId) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    await newApi.delete(`/animal/delete`, {
      ...config,
      data: { animalId, farmId, userId },
    });
  } catch (err) {
    throw new Error(err.response.data);
  }
}
