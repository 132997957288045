import { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useForm from "../../hooks/useForm";
import subscribeUser from "../../services/userForm/subscribeUser";

import logo from "../../assets/images/logo-small-1.png";

import Button from "../../components/button/button";
import Input from "../../components/input/input";

import "./payment.css";

function PaymentPage() {
  const history = useHistory();
  const { search } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const { values, errors, handleChange, handleSubmit, handleSetErrors } =
    useForm();

  const [isLoading, setIsLoading] = useState(false);

  if (!query.get("id")) {
    return history.push("/");
  }

  async function checkValues() {
    const error = {};

    if (!values.card_number) {
      error.card_number = "Este campo é obrigatório";
    } else if (errors.card_number) {
      delete error.card_number;
    }

    const current_year = new Date().getFullYear();
    if (!values.expiration) {
      error.expiration = "Este campo é obrigatório";
    } else if (
      parseInt(values.expiration.substring(0, 2)) === 0 ||
      parseInt(values.expiration.substring(0, 2)) > 12
    ) {
      error.expiration = "Insira uma data válida";
    } else if (
      parseInt(values.expiration.substring(3)) < current_year ||
      parseInt(values.expiration.substring(3)) > current_year + 20
    ) {
      error.expiration = "Insira uma data válida";
    } else if (errors.expiration) {
      delete error.expiration;
    }

    if (!values.safety_code) {
      error.safety_code = "Este campo é obrigatório";
    } else if (errors.safety_code) {
      delete error.safety_code;
    }

    if (!values.cardholder_name) {
      error.cardholder_name = "Este campo é obrigatório";
    } else if (errors.cardholder_name) {
      delete error.cardholder_name;
    }

    return error;
  }

  async function valuesAreCorrect() {
    setIsLoading(true);

    // eslint-disable-next-line no-undef
    const card = PagSeguro.encryptCard({
      publicKey: process.env.REACT_APP_PAGSEGURO_PUBLICK_KEY,
      holder: values.cardholder_name,
      number: values.card_number.replace(/\D/g, ""),
      expMonth: values.expiration.split("/")[0],
      expYear: values.expiration.split("/")[1],
      securityCode: values.safety_code,
    });

    if (card.hasErrors) {
      console.error(card.errors);
      handleSetErrors({
        api: "Ocorreu um erro ao criptografar o seu cartão. Tente novamente",
      });
      return;
    }

    await subscribeUser({
      id: query.get("id"),
      encryptedCard: card.encryptedCard,
      cardSecurtyCode: Number(values.safety_code),
    });

    history.push("/");
  }

  return (
    <div className="payment">
      <div className="white-part">
        <div>
          <h1 className="title">Informação de Pagamento</h1>
          <form
            onSubmit={handleSubmit(checkValues, valuesAreCorrect, isLoading)}
          >
            <Input
              id="card_number"
              label="Número do cartão"
              placeholder="Insira o número do seu cartão"
              className="payment-input"
              type="text"
              error={errors.card_number}
              value={values.card_number}
              onChange={handleChange}
              mask="9999 9999 9999 9999"
            />
            <div className="input-row">
              <Input
                id="expiration"
                label="Vencimento"
                placeholder="MM/AAAA"
                className="payment-input"
                type="text"
                errorAlignColumn
                error={errors.expiration}
                value={values.expiration}
                onChange={handleChange}
                mask="99/9999"
              />
              <Input
                id="safety_code"
                label="Código de segurança"
                placeholder="3 dígitos"
                className="payment-input"
                type="text"
                errorAlignColumn
                error={errors.safety_code}
                value={values.safety_code}
                onChange={handleChange}
                mask="999"
              />
            </div>
            <Input
              id="cardholder_name"
              label="Nome do titular do cartão"
              placeholder="Insira o nome do titular do cartão"
              className="payment-input"
              type="text"
              error={errors.cardholder_name}
              value={values.cardholder_name}
              onChange={handleChange}
            />

            {errors.api && <p className="api-error">{errors.api}</p>}

            <div className="button-row">
              <div />
              <Button color="blue" type="submit" isLoading={isLoading}>
                Concluir
              </Button>
            </div>
          </form>
        </div>
      </div>

      <div className="blue-part">
        <div className="mask">
          <div>
            <img className="logo" src={logo} alt="logo" />
            <p className="minor-text">
              Organize e gerencie os eventos dos seus animais
            </p>
            <p className="minor-text">
              Ficou mais fácil organizar e gerenciar os eventos dos animais da
              fazenda
            </p>
            <p className="minor-text">
              Eficiência para organizar e gerenciar os eventos dos seus animais
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentPage;
