import { format } from "date-fns/esm";

export function getLastPartosRealizados(data) {
  var today = new Date();
  var stringDates = [];
  data.forEach(function (element) {
    var auxDate = new Date(element.eventDate.slice(0, -1));
    if (auxDate.getTime() < today.getTime()) return stringDates.push(auxDate);
  });

  stringDates.sort(function (a, b) {
    // var auxA = new Date(a)
    // var auxB = new Date(b)
    return b.getTime() - a.getTime();
  });

  stringDates = stringDates.map(function (element) {
    return format(element, "dd/MM/yyyy");
  });

  var objDates = [];
  stringDates.forEach((element) => {
    objDates.push({ Data: element });
  });

  return objDates;
}
