// import React, { forwardRef, useEffect } from 'react';
import * as d3 from 'd3';

function drawAxis(config) {
  const {
    margin,
    width,
    height,
    drawXGridlines,
    drawYGridlines,
    xLabel,
    yLabel,
    // axisClass,
    // gridClass,
    // data,
    svgRef,
    xScale,
    yScale,
  } = config;


  const svg = d3.select(svgRef.current).select('g');

  // var drawXGridlines = true

  if (drawYGridlines)
    svg
      .append('g')
      .attr('class', 'base__gridlines gridlines__y')
      .attr('transform', `translate(0,${height})`)
      .call(
        d3.axisBottom(xScale)
          .tickSize(-height,0,0)
          .tickFormat(''),
      );

  if (drawXGridlines)
    svg
      .append('g')
      .attr('class', 'base__gridlines gridlines__x')
      .call(
        d3.axisLeft(yScale)
          .tickSize(-width)
          .tickFormat(''),
      );
      

  svg
    .append('g')
    .attr('class', 'base__axis axis__x')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(xScale).ticks().tickSizeInner(0).tickSizeOuter(0).tickPadding(8))
    .selectAll("text").style("fill", "var(--dark-gray)");
    // .tickValues([]);
    

  svg
    .append('g')
    .attr('class', 'base__axis axis__y')
    .call(d3.axisLeft(yScale).ticks().tickSizeInner(0).tickSizeOuter(0).tickPadding(8))
    .selectAll("text").style("fill", "var(--dark-gray)"); 
    // .ticks(5)

  if (xLabel)
    svg
      .append('text')
      .attr('class', 'base__axis-label axis__x-label')
      .attr('text-anchor', 'middle')
      .attr('x', width - margin.left)
      .attr('y', height + margin.top)
      .text(xLabel);

  if (yLabel)
    svg
      .append('text')
      .attr('class', 'base__axis-label axis__y-label')
      .attr('text-anchor', 'middle')
      .attr('x', 20)
      .attr('y', -15)
      .attr('transform', 'rotate(0)')
      .text(yLabel);

};

export default drawAxis;