import Button from "../../../../components/button/button";
import Input from "../../../../components/input/input";
import Modal from "../../../../components/modal/modal";
import useForm from "../../../../hooks/useForm";

import "./modalAccount.css";

export default function ModalAccount({
  onClickCancel,
  onClickSubmit,
  isLoading,
}) {
  const { values, errors, handleChange, handleSubmit } = useForm();

  async function checkValues() {
    const error = {};

    if (!values.email) {
      error.email = "Este campo é obrigatório";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      error.email = "Endereço de e-mail invalido";
    } else if (errors.email) {
      delete error.email;
    }

    if (!values.password) {
      error.password = "Este campo é obrigatório";
    } else if (values.password.length < 8) {
      error.password = "A senha deve ter mais de 8 caracteres";
    } else if (!/[A-Z]/.test(values.password)) {
      error.password = "A senha deve ter letras maiúsculas";
    } else if (!/[0-9]/.test(values.password)) {
      error.password = "A senha deve ter numerais";
    } else if (errors.password) {
      delete error.password;
    }

    return error;
  }

  function valuesAreCorrect() {
    onClickSubmit();
  }

  return (
    <Modal showModal={onClickCancel} close={true}>
      <div className="content-account">
        <p className="title">Você deseja excluir a conta?</p>
        <p className="text">
          Ao excluir a sua conta, você não terá mais acesso aos seus dados. Para
          sua segurança, confirme os seus dados para excluir sua conta.
        </p>

        <form onSubmit={handleSubmit(checkValues, valuesAreCorrect, isLoading)}>
          <Input
            className="px400-input"
            id="email"
            label="E-mail"
            type="text"
            error={errors.email}
            value={values.email}
            onChange={handleChange}
            placeholder="Insira o e-mail"
          />
          <Input
            className="px400-input"
            id="password"
            label="Senha"
            type="password"
            error={errors.password}
            value={values.password}
            onChange={handleChange}
            placeholder="Insira a senha"
          />

          <div className="modal-buttons-wrapper">
            <div />
            <Button color="white" onClick={onClickCancel}>
              Não excluir
            </Button>
            <Button color="blue" type="submit" isLoading={isLoading}>
              Excluir conta
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
