import { newApi } from "../api";

export async function updateCarenciaMastitis(body) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.put(`mastitis/treatment`, body, config);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}
