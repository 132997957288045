import { newApi } from "../api";

export async function updateAnimal(data) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.put(`/animal`, data, config);
    if (response.data.lenght > 0) throw new Error();
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}

export default updateAnimal;
