import Lottie from "react-lottie-player";
import loading_gif from "../../../../../assets/gifs/loading_2.json";
import BarChart from "../../../../../components/baseChart/barChart/barChart";
import { Box, TextInfo } from "../../bases/bases";

const columns = ["Identificação", "Lote", "Volume_diário"];

export default function MilkPerAnimal({
  isLoading,
  milkPerAnimal,
  animalsMiklPerAnimal,
  titleDescription,
}) {
  return (
    <Box
      id="box5"
      title="Leite produzido por animal"
      titleDescription={titleDescription}
      viewMore={milkPerAnimal.length > 0}
      isViewMoreFull={milkPerAnimal.length >= 11} // Se tiver mais que 12 animais aparece uma mensagem na tela
      className="bigger"
      viewMoreTitle={"Leite produzido por animal"}
      animals={animalsMiklPerAnimal}
      columns={columns}
    >
      {isLoading ? (
        <div className="no-data">
          <Lottie loop play animationData={loading_gif} />
        </div>
      ) : milkPerAnimal.length > 0 ? (
        <BarChart data_={milkPerAnimal} unit="litros" min={10} max={30} />
      ) : (
        <TextInfo
          first="Sem dados"
          latter="Cadastre um evento de produção de leite"
        />
      )}
    </Box>
  );
}
