import { useHistory } from 'react-router-dom';

import './pageContainer.css';

import { FarmContextProvider } from '../../contexts/farmContext';
import { useUserContext } from '../../contexts/userContext';

import Navbar from '../navbar/navbar';
import PageRoutes from '../../router/pageRoutes';

function PageContainer() {
  const history = useHistory();
  const { isAuthenticated } = useUserContext();

  if (!isAuthenticated()) {
    history.push('/');
  }

  return (
    <FarmContextProvider>
      <div id="page-container">
        <Navbar />

        <div className="page-body">
          <PageRoutes />
        </div>
      </div>
    </FarmContextProvider>
  )
}

export default PageContainer;