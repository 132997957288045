import { useCallback, useState } from "react";

function useForm() {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  const cleanErrors = () => {
    setErrors({});
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.currentTarget.name]: event.currentTarget.value,
    });
    setErrors({ ...errors, [event.currentTarget.name]: undefined });
  };

  const handleSelectChange = useCallback((name, value) => {
    if (value && value.label) {
      setValues((v) => {
        return { ...v, [name]: value.label };
      });
    } else {
      setValues((v) => {
        return { ...v, [name]: value };
      });
    }

    setErrors((e) => {
      return { ...e, [name]: undefined };
    });
  }, []);

  const handleSelectStateChange = useCallback((value) => {
    const stringValue = String(value);
    setValues((v) => {
      return { ...v, city: "-1", state: stringValue };
    });
  }, []);

  const updateValues = (value) => {
    setValues(value);
  };

  const handleSubmit =
    (checkValues, valuesAreCorrect, isLoading) => async (event) => {
      event.preventDefault();

      const error = await checkValues();

      setErrors(error);

      if (Object.keys(error).length === 0 && !isLoading) {
        valuesAreCorrect();
      }
    };

  const handleSetErrors = (error) => {
    setErrors({ ...errors, ...error });
  };

  const handleTankChange = (event, tank) => {
    setValues({
      ...values,
      [tank]: {
        ...values[tank],
        [event.currentTarget.name]: event.currentTarget.value,
      },
    });
    setErrors({
      ...errors,
      [tank]: { ...errors[tank], [event.currentTarget.name]: undefined },
    });
  };

  const reset = () => {
    setValues({});
    setErrors({});
  };

  return {
    values,
    errors,
    handleSetErrors,
    handleChange,
    handleSubmit,
    handleSelectChange,
    handleSelectStateChange,
    cleanErrors,
    handleTankChange,
    updateValues,
    reset,
  };
}

export default useForm;
