import { useCallback, useState } from "react";

import { useAlertContext } from "../../../../../contexts/alertContext";

import "./ModalDrying.css";

import Button from "../../../../../components/button/button";
import Modal from "../../../../../components/modal/modal";
import Table from "../../../../../components/table/table";

const columns = ["Identificação", "Lote", "Data_prevista"];

export default function ModalDrying() {
  const {
    modalProps,
    handleModalShow,
    modalRememberLater,
    modalUnderstand,
    // handleModalProps,
  } = useAlertContext();
  const { animals } = modalProps;

  const [checkOn, setCheckOn] = useState(false);
  const [selectedAnimals, setSelectedAnimals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function handleClickUnderstand() {
    setIsLoading(true);
    try {
      await modalUnderstand("drying");
    } catch (e) {
      setIsLoading(false);
      console.log("Error");
    }
  }

  // function handleNewDate() {
  //   if (selectedAnimals.length > 0) {
  //     handleModalProps({
  //       animals: selectedAnimals,
  //       type: "secagem",
  //       allAnimals: animals,
  //     });
  //     modalUnderstand("newDate");
  //   }
  // }

  const onChangeAnimal = useCallback((value) => {
    setSelectedAnimals(value);
  }, []);

  return (
    <Modal showModal={handleModalShow} close={true}>
      <div className="drying-modal-content">
        <p className="title">Data(s) de secagem</p>
        <p className="text">
          Esses são os animais e suas previsões de secagem para os próximos
          dias.
        </p>

        <div className="table_wrapper">
          <div
            className="table_wrapper_container"
            style={{ minWidth: columns.length * 180 + "px" }}
          >
            <Table
              multi
              animals={animals}
              columns={columns}
              check={checkOn}
              alert
              onChangeAnimal={onChangeAnimal}
            />
          </div>
        </div>

        {checkOn ? (
          <div className="modal-div-buttons checkOn">
            <div />
            <Button
              color="white"
              className="modal-button"
              onClick={() => setCheckOn(false)}
            >
              Cancelar
            </Button>
            {/* <Button
              color="blue"
              className="modal-button"
              onClick={handleNewDate}
            >
              Alterar Data(s)
            </Button> */}
          </div>
        ) : (
          <div className="modal-div-buttons checkOff">
            <div />
            <Button
              color="white"
              className="modal-button"
              onClick={modalRememberLater}
            >
              Lembrar-me Depois
            </Button>
            {/* <Button
              color="blue"
              className="modal-button"
              onClick={() => setCheckOn(true)}
            >
              Alterar Data(s)
            </Button> */}
            <Button
              color="blue"
              className="modal-button"
              isLoading={isLoading}
              onClick={handleClickUnderstand}
            >
              Apagar alerta
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
}
