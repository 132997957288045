import "./noTank.css";

import Button from '../../../../components/button/button';

import no_tank from '../../../../assets/svg/milk-quality/no-tank.svg';

export default function NoTank({ onClick }) {
  return (
    <div className="no-tank-wrapper">
      <div className="no-tank">
        <img src={no_tank} alt="Sem tanque" />
        <p className="text">Nenhum tanque por aqui ainda. Comece adicionando um tanque.</p>
        <Button onClick={onClick}>Adicionar Tanque</Button>
      </div>
    </div>
  )
}