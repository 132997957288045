import PageTitle from "../../components/PageTitle/PageTitle"

import FormAccount from "./components/FormAccount/formAccount";

import './accountConfig.css'
import UploadFile from "../../components/uploadFile/uploadFile";


function AccountConfig() {
    return (
        <div className="property-config-body">
            <PageTitle
                path="Configurações"
                backButton
            />

            <div className="form-wrapper">
                <div className="account-config">
                    <h2 className="config-title">Configurações da Conta</h2>
                    <FormAccount />
                </div>

            </div>

            <UploadFile></UploadFile>
            
            {/* <Dropzone></Dropzone> */}
        </div>
        
    )
}

export default AccountConfig;