import { newApi } from "../api";

async function signIn(data) {
  try {
    const response = await newApi.post("/user/login", data);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export default signIn;
