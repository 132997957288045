import { Box, TextInfo } from "../../bases/bases";

const columns = ["Identificação", "Lote", "Inseminação", "Parto_Previsto"];

export default function GestationHerd({ gestationNumber, gestationAnimals }) {
  return (
    <Box
      className="small"
      viewMore={gestationNumber > 0}
      viewMoreTitle="Situação de rebanho em gestação"
      animals={gestationAnimals}
      columns={columns}
    >
      <TextInfo
        first="Situação de rebanho"
        number={gestationNumber}
        latter="em gestação"
      />
    </Box>
  );
}
