import { useHistory } from "react-router-dom";
import "./milkQualitySave.css";

import Button from "../../../../components/button/button";

import checkCircle from '../../../../assets/svg/events/checkcircle.svg';

export default function MilkQualitySave({ value, backButton }) {
  const history = useHistory();

  return (
    <div className="milk-quality-save-wrapper">
      <div className="milk-quality-save">
        <img src={checkCircle} alt="Check" />
        <p className="text">Que ótimo, a qualidade do leite foi registrada.</p>

        <div className="box">
          {value ? (
            <>
              <p className="text">Valor pago pelo leite</p>
              <p className="value">R$ {value.toFixed(2)}</p>
            </>
          ) : null
        }

          <p className="details">Para ver mais detalhes sobre a sua qualidade, clique para ver o relatório.</p>
          <div className="buttons-milk-quality">
            <Button onClick={() => history.push('/farm-report#milk-quality')} >Ver relatório</Button>
            <Button color={"white"} onClick={backButton}>Voltar</Button>
          </div>
        </div>
      </div>
    </div>
  )
}