import { newApi } from "../api";

async function addtionalSignUp(data) {
  try {
    const response = await newApi.put("/user/register", data);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data.err.code);
  }
}

export default addtionalSignUp;
