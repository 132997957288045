import { addDays, format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { createContext, useCallback, useContext, useState } from "react";

import { listAlerts } from "../services/alerts/listAlerts";
import { updateActiveBirth } from "../services/alerts/updateActiveBirth";
import { updateActiveDrying } from "../services/alerts/updateActiveDrying";
import { updateActiveHightCcs } from "../services/alerts/updateActiveHightCCS";
import { updateActiveNumberMastitis } from "../services/alerts/updateActiveNumberMastitis";

import { useFarmContext } from "./farmContext";

import ModalBirth from "../pages/alerts/components/modals/modalBirth/ModalBirth";
import ModalDrying from "../pages/alerts/components/modals/modalDrying/ModalDrying";
import ModalEndShortage from "../pages/alerts/components/modals/modalEndShortage/ModalEndShortage";
import ModalHightCCS from "../pages/alerts/components/modals/modalHightCCS/ModalHightCCS";
import ModalMastitis from "../pages/alerts/components/modals/modalMastitis/ModalMastitis";
import ModalNewAlertGenerated from "../pages/alerts/components/modals/modalNewAlertGenerated/ModalNewAlertGenerated";
import ModalNewDate from "../pages/alerts/components/modals/modalNewDate/ModalNewDate";
import ModalNewDateAlert from "../pages/alerts/components/modals/modalNewDateAlert/ModalNewDateAlert";
import ModalNumberMastitis from "../pages/alerts/components/modals/modalNumberMastitis/ModalNumberMastitis";
import ModalSimpleAlert from "../pages/alerts/components/modals/modalSimpleAlert/ModalSimpleAlert";

export const AlertContext = createContext({});

export function AlertContextProvider({ children }) {
  const { farmSelected, getAnimalById } = useFarmContext();

  const [alerts, setAlerts] = useState([]);
  const [alertsToShow, setAlertsToShow] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [modalsControl, setModalsControl] = useState();
  const [modalProps, setModalProps] = useState({});

  const [loadingAlerts, setLoadingAlerts] = useState(true);

  const isAlertCritical = useCallback((number, totalAnimals) => {
    const percentage = (number * 100) / totalAnimals;
    return percentage > 15 ? true : false;
  }, []);

  const getAlerts = useCallback(async () => {
    try {
      setLoadingAlerts(true);
      const alerts_response = await listAlerts(farmSelected.farm_id);

      const alerts_aux = [];
      if (alerts_response.mastiteSubclinica.length > 0) {
        alerts_aux.push({
          id: "alert-1",
          name: "Mastite",
          modal_name: "animalsNumberMastitis",
          isCritical: isAlertCritical(
            alerts_response.mastiteSubclinica.length,
            alerts_response.totalAnimalsMastiteSubclinica
          ),
          animals_number: alerts_response.mastiteSubclinica.length,
          animals_ids: alerts_response.mastiteSubclinica.map((alert) => {
            return {
              animal_id: alert.animalId,
              id: alert.id,
              type: alert.type === "CMT" ? "cmt" : "ccs",
            };
          }),
          filter_by: ["mastitis"],
        });
      }
      if (alerts_response.ccsAlto.length > 0) {
        alerts_aux.push({
          id: "alert-2",
          name: "Valor CCS alto",
          modal_name: "hightCCS",
          isCritical: isAlertCritical(
            alerts_response.ccsAlto.length,
            alerts_response.totalAnimalsCCS
          ),
          animals_number: alerts_response.ccsAlto.length,
          animals: alerts_response.ccsAlto
            .filter((alert) => getAnimalById(alert.animalId).length > 0)
            .map((alert) => {
              const animal_array = getAnimalById(alert.animalId);
              return {
                Identificação: animal_array[0].identification,
                Nascimento: animal_array[0].Nascimento,
                Lote: animal_array[0].Lote,
                Raça: animal_array[0].Raça,
                Valor_de_CCS: alert.ccs + " cel/ml",
              };
            }),
          animals_ids: alerts_response.ccsAlto.map((alert) => {
            return {
              animalId: alert.animalId,
              ccsId: alert.id,
              farmId: farmSelected.farm_id,
            };
          }),
          filter_by: ["high_css"],
        });
      }
      if (alerts_response.secagemProx.length > 0) {
        alerts_aux.push({
          id: "alert-3",
          name: "Secagem prevista",
          modal_name: "drying",
          isCritical: false,
          animals_number: alerts_response.secagemProx.length,
          animals: alerts_response.secagemProx
            .filter((alert) => getAnimalById(alert.animalId).length > 0)
            .map((alert) => {
              const animal_array = getAnimalById(alert.animalId);
              const data_prevista = format(
                new Date(alert.expectedDate.slice(0, -1)),
                "dd/MM/yyyy"
              );
              return {
                id: animal_array[0].id,
                alert_id: alert.id,
                Identificação: animal_array[0].identification,
                Nascimento: animal_array[0].Nascimento,
                Lote: animal_array[0].Lote,
                Raça: animal_array[0].Raça,
                Data_prevista: data_prevista,
              };
            }),
          animals_ids: alerts_response.secagemProx.map((alert) => {
            return {
              animalId: alert.animalId,
              dryingId: alert.id,
              farmId: farmSelected.farm_id,
            };
          }),
          filter_by: [],
        });
      }
      if (alerts_response.partoProx.length > 0) {
        alerts_aux.push({
          id: "alert-4",
          name: "Parto previsto",
          modal_name: "birth",
          isCritical: false,
          animals_number: alerts_response.partoProx.length,
          animals: alerts_response.partoProx
            .filter((alert) => getAnimalById(alert.animalId).length > 0)
            .map((alert) => {
              const animal_array = getAnimalById(alert.animalId);
              const data_prevista = format(
                new Date(alert.expectedBirthdayDate.slice(0, -1)),
                "dd/MM/yyyy",
                { locale: ptBR }
              );
              return {
                id: animal_array[0].id,
                alert_id: alert.id,
                Identificação: animal_array[0].identification,
                Nascimento: animal_array[0].Nascimento,
                Espécie: animal_array[0].Categoria,
                Raça: animal_array[0].Raça,
                Data_prevista: data_prevista,
              };
            }),
          animals_ids: alerts_response.partoProx.map((alert) => {
            return {
              animalId: alert.animalId,
              reproductionId: alert.id,
              farmId: farmSelected.farm_id,
            };
          }),
          filter_by: ["expected_birth"],
        });
      }
      if (alerts_response.vacasEmTratamento.length > 0) {
        alerts_aux.push({
          id: "alert-5",
          name: "Tratamento de Mastite",
          modal_name: "mastitis",
          isCritical: false,
          animals_number: alerts_response.vacasEmTratamento.length,
          animals: alerts_response.vacasEmTratamento
            .filter((alert) => getAnimalById(alert.animalId).length > 0)
            .map((alert) => {
              const animal_array = getAnimalById(alert.animalId);
              let data_prevista = undefined;
              if (alert.treatmentDate) {
                data_prevista = format(
                  addDays(
                    new Date(alert.treatmentDate.slice(0, -1)),
                    alert.lack ?? 0
                  ),
                  "dd/MM/yyyy"
                );
              }
              return {
                id: animal_array[0].id,
                alert_id: alert.id,
                Identificação: animal_array[0].identification,
                Nascimento: animal_array[0].Nascimento,
                Lote: animal_array[0].Lote,
                Raça: animal_array[0].Raça,
                Data_prevista: data_prevista,
              };
            }),
          animals_ids: alerts_response.vacasEmTratamento.map((alert) => {
            return {
              animalId: alert.animalId,
              mastitisId: alert.id,
              farmId: farmSelected.farm_id,
            };
          }),
          filter_by: ["mastitis"],
        });
      }
      if (alerts_response.secagemMedicamento.length > 0) {
        alerts_aux.push({
          id: "alert-6",
          name: "Final da carência de medicamento",
          modal_name: "endShortage",
          isCritical: false,
          animals_number: alerts_response.secagemMedicamento.length,
          animals: alerts_response.secagemMedicamento
            .filter((alert) => getAnimalById(alert.animalId).length > 0)
            .map((alert) => {
              const animal_array = getAnimalById(alert.animalId);
              const data_prevista = format(
                new Date(alert.expectedDate.slice(0, -1)),
                "dd/MM/yyyy"
              );
              return {
                Identificação: animal_array[0].identification,
                Nascimento: animal_array[0].Nascimento,
                Lote: animal_array[0].Lote,
                Raça: animal_array[0].Raça,
                Data_prevista: data_prevista,
              };
            }),
          animals_ids: alerts_response.secagemMedicamento.map((alert) => {
            return {
              animalId: alert.animalId,
              dryingId: alert.id,
              farmId: farmSelected.farm_id,
            };
          }),
          filter_by: ["lack"],
        });
      }
      if (alerts_response.secagemHoje.length > 0) {
        alerts_response.secagemHoje.forEach((alert) => {
          const animal_array = getAnimalById(alert.animalId);
          if (animal_array.length > 0) {
            alerts_aux.push({
              id: "alert-7-" + alert.id,
              name: "Agendar CMT ou CCS individual",
              modal_name: "simpleAlert",
              isCritical: false,
              animal_id: animal_array[0].identification, // Identificação
              id_animal: alert.animalId, // Id
              filter_by: ["individual"],
            });
          }
        });
      }
      if (alerts_response.carenciaLiberado.length > 0) {
        alerts_response.carenciaLiberado.forEach((alert) => {
          const animal_array = getAnimalById(alert.animalId);
          if (animal_array.length > 0) {
            alerts_aux.push({
              id: "alert-8-" + alert.id,
              name: "Leite liberado da carência",
              modal_name: "simpleAlert",
              isCritical: false,
              animal_id: animal_array[0].identification,
              id_animal: alert.animalId, // Id
              filter_by: ["milk"],
            });
          }
        });
      }
      if (alerts_response.parto7.length > 0) {
        alerts_response.parto7.forEach((alert) => {
          const animal_array = getAnimalById(alert.animalId);
          if (animal_array.length > 0) {
            alerts_aux.push({
              id: "alert-9-" + alert.id,
              name: "Parto próximo",
              modal_name: "simpleAlert",
              isCritical: false,
              animal_id: animal_array[0].identification,
              id_animal: alert.animalId, // Id
              filter_by: ["expected_birth"],
            });
          }
        });
      }

      setAlerts(alerts_aux);
      setAlertsToShow(alerts_aux);
      setLoadingAlerts(false);
      return alerts_aux;
    } catch (err) {
      console.log("Error fetching alerts: " + err);
      setLoadingAlerts(false);
    }
  }, [farmSelected, getAnimalById, isAlertCritical]);

  function addFilter(filter) {
    if (!selectedFilters.includes(filter)) {
      setSelectedFilters([...selectedFilters, filter]);

      let presentAlertsAux = [];
      selectedFilters.length !== 0 && (presentAlertsAux = alertsToShow);

      alerts.forEach(
        (alert) =>
          alert.filter_by.includes(filter.value) &&
          (presentAlertsAux = [...presentAlertsAux, alert])
      );
      setAlertsToShow(presentAlertsAux);
    }
  }

  function removeFilter(filter, index) {
    if (selectedFilters.length === 1) setAlertsToShow(alerts);
    else {
      const presentAlertsAux = alertsToShow.filter((elem) =>
        elem.filter_by.includes(filter.value) ? false : true
      );
      setAlertsToShow(presentAlertsAux);
    }

    let selectedFiltersAux = selectedFilters;
    selectedFiltersAux.splice(index, 1);
    setSelectedFilters(selectedFiltersAux);
  }

  function handleModals(alert) {
    if (!alert) {
      setModalsControl();
      return;
    }
    const {
      modal_name,
      name,
      animal_id,
      animals: alert_animals,
      animals_number,
      id_animal,
    } = alert;
    setModalsControl(modal_name);

    if (modal_name === "animalsNumberMastitis") {
      setModalProps({ number: animals_number });
    } else if (
      modal_name === "hightCCS" ||
      modal_name === "endShortage" ||
      modal_name === "birth" ||
      modal_name === "drying" ||
      modal_name === "mastitis"
    ) {
      setModalProps({ animals: alert_animals });
    } else if (modal_name === "simpleAlert") {
      if (name === "Parto próximo") {
        setModalProps({
          title: "Parto próximo",
          text:
            "A vaca " +
            animal_id +
            " vai entrar em trabalho de parto nos próximos 7 dias.",
          animal_id: id_animal,
        });
      } else if (name === "Leite liberado da carência") {
        setModalProps({
          title: "Leite liberado do período de carência",
          text:
            "O animal " +
            animal_id +
            " está com o leite liberado do período de carência do medicamento.",
          animal_id: id_animal,
        });
      } else if (name === "Agendar CMT ou CCS individual") {
        setModalProps({
          title: "Incluir CMT ou CCS individual",
          text:
            "A Vaca " +
            animal_id +
            " irá secar hoje, inclua o CMT ou o CCS individual para o animal.",
          animal_id: id_animal,
        });
      }
    }
  }

  function handleModalShow(boolean) {
    if (!boolean) {
      handleModals();
    }
  }

  function modalRememberLater() {
    setModalsControl();
  }

  async function modalUnderstand(modalName, simpleAlertAnimalID, update) {
    try {
      if (modalName === "animalsNumberMastitis") {
        // const mastitis_alert = alerts.filter(
        //   (alert) => alert.modal_name === "animalsNumberMastitis"
        // );
        // await updateActiveNumberSubclinicalMastitis(
        //   mastitis_alert[0].animals_ids
        // );
      } else if (modalName === "hightCCS") {
        const ccs_alert = alerts.filter(
          (alert) => alert.modal_name === "hightCCS"
        );
        await updateActiveHightCcs(ccs_alert[0].animals_ids);
      } else if (modalName === "drying") {
        const drying_alert = alerts.filter(
          (alert) => alert.modal_name === "drying"
        );
        await updateActiveDrying(drying_alert[0].animals_ids);
      } else if (modalName === "birth") {
        const birth_alert = alerts.filter(
          (alert) => alert.modal_name === "birth"
        );
        await updateActiveBirth(birth_alert[0].animals_ids);
      } else if (modalName === "mastitis") {
        const mastitis_alert = alerts.filter(
          (alert) => alert.modal_name === "mastitis"
        );
        await updateActiveNumberMastitis(mastitis_alert[0].animals_ids);
      } else if (modalName === "endShortage") {
        const shortage_alert = alerts.filter(
          (alert) => alert.modal_name === "endShortage"
        );
        await updateActiveDrying(shortage_alert[0].animals_ids);
      } else if (modalName === "simpleAlert") {
        const alert = alerts.filter((a) => a.id_animal === simpleAlertAnimalID);
        if (alert[0].id.includes("alert-7")) {
          await updateActiveDrying([
            {
              animalId: alert[0].id_animal,
              dryingId: alert[0].id.substring(8),
              farmId: farmSelected.farm_id,
            },
          ]);
        } else if (alert[0].id.includes("alert-8")) {
          await updateActiveNumberMastitis([
            {
              animalId: alert[0].id_animal,
              mastitisId: alert[0].id.substring(8),
              farmId: farmSelected.farm_id,
            },
          ]);
        } else if (alert[0].id.includes("alert-9")) {
          await updateActiveBirth([
            {
              animalId: alert[0].id_animal,
              reproductionId: alert[0].id.substring(8),
              farmId: farmSelected.farm_id,
            },
          ]);
        }
      } else if (modalName === "updateDateBirth") {
        // await updateDateBirth(update);
        modalUnderstand("newDateAlert");
      } else if (modalName === "updateDateDrying") {
        // await updateDateDrying(update);
        modalUnderstand("newDateAlert");
      } else if (modalName === "endTreatment") {
        // await updateCarenciaMastitis(update);
        modalUnderstand("newAlertGenerated");
      }

      if (
        ["newDateAlert", "newDate", "newAlertGenerated"].includes(modalName)
      ) {
        setModalsControl(modalName);
      } else if (
        !["updateDateBirth", "endTreatment", "updateDateDrying"].includes(
          modalName
        )
      ) {
        getAlerts();
        setModalsControl();
      }
    } catch (err) {
      throw new Error(err);
    }
  }

  function handleModalProps(props) {
    setModalProps(props);
  }

  return (
    <AlertContext.Provider
      value={{
        alerts,
        alertsToShow,
        selectedFilters,
        modalProps,
        loadingAlerts,
        getAlerts,
        addFilter,
        removeFilter,
        handleModals,
        handleModalShow,
        modalRememberLater,
        modalUnderstand,
        handleModalProps,
      }}
    >
      {children}

      {modalsControl === "animalsNumberMastitis" && <ModalNumberMastitis />}
      {modalsControl === "hightCCS" && <ModalHightCCS />}
      {modalsControl === "simpleAlert" && <ModalSimpleAlert />}
      {modalsControl === "endShortage" && <ModalEndShortage />}
      {modalsControl === "birth" && <ModalBirth />}
      {modalsControl === "drying" && <ModalDrying />}
      {modalsControl === "newDate" && <ModalNewDate />}
      {modalsControl === "newDateAlert" && <ModalNewDateAlert />}
      {modalsControl === "mastitis" && <ModalMastitis />}
      {modalsControl === "newAlertGenerated" && <ModalNewAlertGenerated />}
    </AlertContext.Provider>
  );
}

export const useAlertContext = () => {
  return useContext(AlertContext);
};
