import { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import { useHistory } from "react-router-dom";

import "./milkQuality.css";

import useForm from "../../hooks/useForm";

import { useFarmContext } from "../../contexts/farmContext";

import Button from "../../components/button/button";
import Input from "../../components/input/input";
import PageTitle from "../../components/PageTitle/PageTitle";

import loading_gif from "../../assets/gifs/loading_2.json";
import MilkQualitySave from "./components/milkQualitySave/milkQualitySave";
import ModalDeleteTank from "./components/modalDeleteTank/modalDeleteTank";
import ModalHistoryTank from "./components/modalHistoryTank/modalHistoryTank";
import NoTank from "./components/noTank/noTank";

import trash_icon from "../../assets/svg/your-properties/trash.svg";

import { addTanque } from "../../services/milkQuality/addTanque";
import { getTanque } from "../../services/milkQuality/getTanks";

import { isDateValid, isPastDate } from "../../utils/isDateValid";

export default function MilkQuality() {
  const history = useHistory();

  const { farmSelected } = useFarmContext();

  const { values, errors, handleTankChange, handleSubmit } = useForm();

  const [tanks, setTanks] = useState([]);
  const [loadingTanks, setLoadingTanks] = useState(true);
  const [tankSelected, setTankSelected] = useState();
  const [tanksCount, setTanksCount] = useState(1);
  const [isDeleteTankOpen, setIsDeleteTankOpen] = useState(false);
  const [isQualitySave, setIsQualitySave] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isHistoryTankOpen, setIsHistoryTankOpen] = useState(false);

  useEffect(() => {
    async function fetchTanks() {
      try {
        const response = await getTanque(farmSelected.farm_id);

        const tanksAux = response.tanks.map((tank) => ({
          id: tank.id,
          name: parseInt(tank.tankName),
        }));

        tanksAux.sort((a, b) => a.name - b.name);
        setTanks(tanksAux);
        setTanksCount(tanksAux.at(-1).name + 1);
        setLoadingTanks(false);
      } catch (err) {
        setLoadingTanks(false);
        console.log("Erro ao buscar tanques");
      }
    }
    fetchTanks();
  }, [farmSelected]);

  useEffect(() => {
    if (tanks.length === 1 || !tanks.includes(tankSelected)) {
      setTankSelected(tanks[0]);
    }
  }, [tanks, isDeleteTankOpen]);

  function onAddTankClick() {
    setTanks([...tanks, { name: tanksCount }]);
    setTanksCount(tanksCount + 1);
  }

  function editPrice(price) {
    if (!price) return price;
    var aux = price;
    aux = aux.replace("R$ ", "");
    aux = aux.replace(",", ".");
    return parseFloat(aux);
  }

  function literValueCalculo() {
    var value = 0;
    if (values[tankSelected.name]?.liter_value)
      value += editPrice(values[tankSelected.name].liter_value);
    if (values[tankSelected.name]?.cpp_value)
      value += editPrice(values[tankSelected.name].cpp_value);
    if (values[tankSelected.name]?.protein_value)
      value += editPrice(values[tankSelected.name].protein_value);
    if (values[tankSelected.name]?.ccs_value)
      value += editPrice(values[tankSelected.name].ccs_value);
    if (values[tankSelected.name]?.fat_value)
      value += editPrice(values[tankSelected.name].fat_value);
    return value;
  }

  async function checkValues() {
    const error = {};

    if (!values[tankSelected.name] || !values[tankSelected.name].data) {
      error.data = "Este campo é obrigatório";
    } else if (!isDateValid("01/" + values[tankSelected.name].data)) {
      error.data = "Insira uma data válida";
    } else if (!isPastDate("01/" + values[tankSelected.name].data)) {
      error.data = "Insira um mês passado ou o atual";
    } else if (errors[tankSelected.name].data) {
      delete error.data;
    }

    if (!values[tankSelected.name] || !values[tankSelected.name].ccs) {
      error.ccs = "Este campo é obrigatório";
    } else if (parseInt(values[tankSelected.name].ccs) === 0) {
      error.ccs = "Preencha um valor maior que zero";
    } else if (errors[tankSelected.name].ccs) {
      delete error.ccs;
    }

    if (!values[tankSelected.name] || !values[tankSelected.name].cpp) {
      error.cpp = "Este campo é obrigatório";
    } else if (parseInt(values[tankSelected.name].cpp) === 0) {
      error.cpp = "Preencha um valor maior que zero";
    } else if (errors[tankSelected.name].cpp) {
      delete error.cpp;
    }

    if (Object.keys(error).length === 0) {
      return {};
    } else {
      return { [tankSelected.name]: error };
    }
  }

  async function valuesAreCorrect() {
    try {
      setIsLoading(true);
      // const date = new Date(
      //   values[tankSelected.name].data.substring(3),
      //   parseInt(values[tankSelected.name].data.substring(0, 2)) - 1,
      //   6
      // );
      const monthSplit = values[tankSelected.name].data.split("/");

      const data = {
        farmId: farmSelected.farm_id,
        tankName: String(tankSelected.name),
        ccs: parseInt(values[tankSelected.name].ccs),
        cpp: parseInt(values[tankSelected.name].cpp),
        month: monthSplit[1] + "-" + monthSplit[0] + "-01", // yyyy-mm-dd
      };
      if (tankSelected.id) data.tankId = tankSelected.id;
      if (values[tankSelected.name].fat)
        data.milkFat = parseFloat(values[tankSelected.name].fat);
      if (values[tankSelected.name].protein)
        data.milkProtein = parseFloat(values[tankSelected.name].protein);
      if (values[tankSelected.name].liter_value)
        data.priceLiter = editPrice(values[tankSelected.name].liter_value);
      if (values[tankSelected.name].cpp_value)
        data.priceCpp = editPrice(values[tankSelected.name].cpp_value);
      if (values[tankSelected.name].protein_value)
        data.priceProtein = editPrice(values[tankSelected.name].protein_value);
      if (values[tankSelected.name].ccs_value)
        data.priceCcs = editPrice(values[tankSelected.name].ccs_value);
      if (values[tankSelected.name].fat_value)
        data.priceFat = editPrice(values[tankSelected.name].fat_value);

      const response = await addTanque(data);
      setTankSelected({ id: response.tank.id, name: response.tank.tankName });

      values[tankSelected.name] = null;
      setIsLoading(false);
      setIsQualitySave(true);
    } catch (err) {
      setIsLoading(false);
      console.log("Error saving tanque");
    }
  }

  return (
    <div className="milk-quality-page">
      <PageTitle path="Qualidade do Tanque" backButton>
        {tanks.length > 0 && (
          <Button
            color="white"
            background="white"
            onClick={() => setIsDeleteTankOpen(true)}
          >
            Excluir
            <img src={trash_icon} alt="Excluir" />
          </Button>
        )}
      </PageTitle>

      {loadingTanks ? (
        <div className="no-tanks">
          <Lottie loop play animationData={loading_gif} />
        </div>
      ) : (
        <div className="body-wrapper">
          <div className="tanks">
            <div>
              {tanks.map((tank) => {
                return (
                  <p
                    key={tank.name}
                    className={
                      "tank " + (tankSelected?.name === tank.name && "selected")
                    }
                    onClick={() => {
                      setTankSelected(tank);
                      setIsQualitySave(false);
                    }}
                  >
                    Tanque {tank.name}
                  </p>
                );
              })}
            </div>
            {/* <p className="add-tank" onClick={onAddTankClick}>
              Adicionar tanque
            </p> */}
          </div>
          <div className="body-content">
            <div className="historico-title">
              <p className="title">
                Qualidade do leite no Tanque{" " + (tankSelected?.name || "")}
              </p>
              <div className="historico-button">
                <Button
                  color="white"
                  onClick={() => setIsHistoryTankOpen(true)}
                >
                  Histórico
                </Button>
              </div>
            </div>
            {tanks.length === 0 && <NoTank onClick={onAddTankClick} />}
            {tanks.length > 0 && isQualitySave && (
              <MilkQualitySave
                value={literValueCalculo()}
                backButton={() => setIsQualitySave(false)}
              />
            )}
            {tanks.length > 0 && !isQualitySave && tankSelected && (
              <form
                onSubmit={handleSubmit(
                  checkValues,
                  valuesAreCorrect,
                  isLoading
                )}
              >
                <div className="content">
                  <div>
                    <div className="inputs-wrapper">
                      <Input
                        id="data"
                        label="Mês do registro"
                        className="milk-quality-input"
                        errorAlignColumn
                        error={
                          errors[tankSelected.name]?.data
                            ? errors[tankSelected.name].data
                            : ""
                        }
                        value={
                          values[tankSelected.name]?.data
                            ? values[tankSelected.name].data
                            : ""
                        }
                        type="text"
                        placeholder="mm/aaaa"
                        mask="99/9999"
                        onChange={(event) =>
                          handleTankChange(event, tankSelected.name)
                        }
                      />
                      <Input
                        label="Valor de CCS"
                        id="ccs"
                        className="milk-quality-input"
                        error={
                          errors[tankSelected.name]?.ccs
                            ? errors[tankSelected.name].ccs
                            : ""
                        }
                        value={
                          values[tankSelected.name]?.ccs
                            ? values[tankSelected.name].ccs
                            : ""
                        }
                        type="text"
                        errorAlignColumn
                        placeholder="Insira o valor de CCS em cel/ml"
                        mask="9999 mil cel/ml"
                        maskChar=" "
                        onChange={(event) =>
                          handleTankChange(event, tankSelected.name)
                        }
                      />
                      <Input
                        label="Valor de CPP"
                        id="cpp"
                        className="milk-quality-input"
                        error={
                          errors[tankSelected.name]?.cpp
                            ? errors[tankSelected.name].cpp
                            : ""
                        }
                        value={
                          values[tankSelected.name]?.cpp
                            ? values[tankSelected.name].cpp
                            : ""
                        }
                        type="text"
                        errorAlignColumn
                        placeholder="Insira o valor de CPP em ufc/ml"
                        mask="9999 mil ufc/ml"
                        maskChar=" "
                        onChange={(event) =>
                          handleTankChange(event, tankSelected.name)
                        }
                      />
                      <Input
                        label="Porcentagem de gordura"
                        id="fat"
                        className="milk-quality-input"
                        error={
                          errors[tankSelected.name]?.fat
                            ? errors[tankSelected.name].fat
                            : ""
                        }
                        value={
                          values[tankSelected.name]?.fat
                            ? values[tankSelected.name].fat
                            : ""
                        }
                        type="text"
                        errorAlignColumn
                        placeholder="Insira o valor em porcentagem"
                        mask="9.99 %"
                        maskChar=" "
                        onChange={(event) =>
                          handleTankChange(event, tankSelected.name)
                        }
                      />
                      <Input
                        label="Porcentagem de proteína"
                        id="protein"
                        className="milk-quality-input"
                        error={
                          errors[tankSelected.name]?.protein
                            ? errors[tankSelected.name].protein
                            : ""
                        }
                        value={
                          values[tankSelected.name]?.protein
                            ? values[tankSelected.name].protein
                            : ""
                        }
                        type="text"
                        errorAlignColumn
                        placeholder="Insira o valor em porcentagem"
                        mask="9.99 %"
                        maskChar=" "
                        onChange={(event) =>
                          handleTankChange(event, tankSelected.name)
                        }
                      />
                    </div>
                  </div>

                  <div className="pay-for-quality open">
                    <div className="pay-inputs-wrapper">
                      <Input
                        label="Valor pago pelo litro de leite"
                        id="liter_value"
                        className="milk-quality-input"
                        error={
                          errors[tankSelected.name]?.liter_value
                            ? errors[tankSelected.name].liter_value
                            : ""
                        }
                        value={
                          values[tankSelected.name]?.liter_value
                            ? values[tankSelected.name].liter_value
                            : ""
                        }
                        type="text"
                        errorAlignColumn
                        placeholder="Insira o valor por litro de leite"
                        mask="R$ 9,99"
                        maskChar=" "
                        onChange={(event) =>
                          handleTankChange(event, tankSelected.name)
                        }
                      />
                      <div className="input-row">
                        <Input
                          label="Valor pago pela CPP"
                          id="cpp_value"
                          className="milk-quality-input"
                          error={
                            errors[tankSelected.name]?.cpp_value
                              ? errors[tankSelected.name]?.cpp_value
                              : ""
                          }
                          value={
                            values[tankSelected.name]?.cpp_value
                              ? values[tankSelected.name]?.cpp_value
                              : ""
                          }
                          type="text"
                          errorAlignColumn
                          placeholder="Insira o valor por CPP"
                          mask="R$ 9,99"
                          maskChar=" "
                          onChange={(event) =>
                            handleTankChange(event, tankSelected.name)
                          }
                        />
                        <Input
                          label="Valor pago pela CCS"
                          id="ccs_value"
                          className="milk-quality-input"
                          error={
                            errors[tankSelected.name]?.ccs_value
                              ? errors[tankSelected.name].ccs_value
                              : ""
                          }
                          value={
                            values[tankSelected.name]?.ccs_value
                              ? values[tankSelected.name].ccs_value
                              : ""
                          }
                          type="text"
                          errorAlignColumn
                          placeholder="Insira o valor por CSS"
                          mask="R$ 9,99"
                          maskChar=" "
                          onChange={(event) =>
                            handleTankChange(event, tankSelected.name)
                          }
                        />
                      </div>
                      <div className="input-row">
                        <Input
                          label="Valor pago pela proteína"
                          id="protein_value"
                          className="milk-quality-input"
                          error={
                            errors[tankSelected.name]?.protein_value
                              ? errors[tankSelected.name]?.protein_value
                              : ""
                          }
                          value={
                            values[tankSelected.name]?.protein_value
                              ? values[tankSelected.name]?.protein_value
                              : ""
                          }
                          type="text"
                          errorAlignColumn
                          placeholder="Insira o valor pago por proteína"
                          mask="R$ 9,99"
                          maskChar=" "
                          onChange={(event) =>
                            handleTankChange(event, tankSelected.name)
                          }
                        />
                        <Input
                          label="Valor pago pela gordura"
                          id="fat_value"
                          className="milk-quality-input"
                          error={
                            errors[tankSelected.name]?.fat_value
                              ? errors[tankSelected.name]?.fat_value
                              : ""
                          }
                          value={
                            values[tankSelected.name]?.fat_value
                              ? values[tankSelected.name]?.fat_value
                              : ""
                          }
                          type="text"
                          errorAlignColumn
                          placeholder="Insira o valor pago por gordura"
                          mask="R$ 9,99"
                          maskChar=" "
                          onChange={(event) =>
                            handleTankChange(event, tankSelected.name)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="buttons">
                  <div />
                  <Button
                    color="white"
                    onClick={() => history.push("/property-painel")}
                  >
                    Cancelar
                  </Button>
                  <Button type="submit" isLoading={isLoading}>
                    Salvar
                  </Button>
                </div>
              </form>
            )}
          </div>
        </div>
      )}

      {isDeleteTankOpen && (
        <ModalDeleteTank
          showModal={(boolean) => setIsDeleteTankOpen(boolean)}
          onClickCancel={() => setIsDeleteTankOpen(false)}
          onClickDelete={(tanksSelected) => {
            tanksSelected.forEach((tank) => {
              tanks.splice(tanks.indexOf(tank), 1);
            });
            setIsDeleteTankOpen(false);
          }}
          tanks={tanks}
          farm_id={farmSelected.farm_id}
        />
      )}

      {isHistoryTankOpen && (
        <ModalHistoryTank
          showModal={(boolean) => setIsHistoryTankOpen(boolean)}
          onClickCancel={() => setIsHistoryTankOpen(false)}
          tankSelected={tankSelected}
          farm_id={farmSelected.farm_id}
        />
      )}
    </div>
  );
}
