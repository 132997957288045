import "./modalDownload.css";

import Button from "../../../../components/button/button";
import Modal from "../../../../components/modal/modal";

import { useFarmContext } from "../../../../contexts/farmContext";
import useSpreadsheet from "../../../../hooks/useSpreadsheet";

export default function ModalDownload({ event, onClickCancel, showModal }) {
  const { farmSelected, animalsId, getCorrectEventAnimalsSpreadsheet, lots } =
    useFarmContext();
  const eventAnimals = getCorrectEventAnimalsSpreadsheet();
  const { isLoading, handleCreateSpreadsheet } = useSpreadsheet(
    event,
    farmSelected,
    animalsId,
    eventAnimals,
    lots
  );

  return (
    <Modal showModal={showModal} close={true}>
      <div className="download-modal-content">
        <p className="title">Baixar planilha para o evento?</p>

        <p className="text">
          Ao baixar a planilha você consegue trabalhar offline. Leve a planilha
          com você e preencha-a com atenção. Quando conseguir acesso à internet,
          importe a sua planilha com os dados preenchidos e o evento será criado
          automaticamente.
        </p>

        <div className="modal-div-buttons">
          <div />
          <Button
            color="white"
            className="modal-button"
            onClick={onClickCancel}
          >
            Cancelar
          </Button>
          <Button
            color="blue"
            className="modal-button"
            onClick={handleCreateSpreadsheet}
            disabled={isLoading}
            isLoading={isLoading}
          >
            Baixar planilha
          </Button>
        </div>
      </div>
    </Modal>
  );
}
