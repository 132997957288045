import { format } from "date-fns/esm";

export function getMastiteData(data) {
  data.sort(function (a, b) {
    return (
      new Date(b.eventDate.slice(0, -1)).getTime() -
      new Date(a.eventDate.slice(0, -1)).getTime()
    );
  });

  return data.map((item) => {
    return {
      Diagnóstico: format(new Date(item.eventDate.slice(0, -1)), "dd/MM/yyyy"),
      "Tipo Tratamento": item.treatmentType ?? "--",
      "Início Tratamento": item.treatmentDate
        ? format(new Date(item.treatmentDate.slice(0, -1)), "dd/MM/yyyy")
        : "--",
      "Fim Tratamento": item.endTreatmentDate
        ? format(new Date(item.endTreatmentDate.slice(0, -1)), "dd/MM/yyyy")
        : "--",
      "Fim Carência": item.lack ? item.lack + " dias" : "--",
      Medicamento: item.medicine ?? "--",
    };
  });
}

// export function getMastiteData(data) {
//   var mastiteData = [];

//   data.forEach((element) => {
//     var mastiteObj = {};

//     if (element.tetos) {
//       if (parseInt(element.tetos.all)) {
//         {
//           mastiteObj.leftFront = {
//             degree: parseInt(element.tetos.all)
//               ? element.tetos.all
//               : "Sem grau",
//             status: "Clínica",
//             // element.tipo === "Mastite subclínica" ? "Subclínica" : "Clínica",
//           };
//           mastiteObj.rightFront = {
//             degree: parseInt(element.tetos.all)
//               ? element.tetos.all
//               : "Sem grau",
//             status: "Clínica",
//             // element.tipo === "Mastite subclínica" ? "Subclínica" : "Clínica",
//           };

//           mastiteObj.leftBack = {
//             degree: parseInt(element.tetos.all)
//               ? element.tetos.all
//               : "Sem grau",
//             status: "Clínica",
//             // element.tipo === "Mastite subclínica" ? "Subclínica" : "Clínica",
//           };

//           mastiteObj.rightBack = {
//             degree: parseInt(element.tetos.all)
//               ? element.tetos.all
//               : "Sem grau",
//             status: "Clínica",
//             // element.tipo === "Mastite subclínica" ? "Subclínica" : "Clínica",
//           };
//         }
//       } else {
//         if (element.tetos.leftFront) {
//           mastiteObj.leftFront = {
//             degree:
//               element.tetos.leftFront !== "0"
//                 ? element.tetos.leftFront
//                 : "Sem grau",
//             status: "Clínica",
//             // element.tipo === "Mastite subclínica" ? "Subclínica" : "Clínica",
//           };
//         }
//         if (element.tetos.rightFront) {
//           mastiteObj.rightFront = {
//             degree:
//               element.tetos.rightFront !== "0"
//                 ? element.tetos.rightFront
//                 : "Sem grau",
//             status: "Clínica",
//             // element.tipo === "Mastite subclínica" ? "Subclínica" : "Clínica",
//           };
//         }
//         if (element.tetos.leftBack) {
//           mastiteObj.leftBack = {
//             degree:
//               element.tetos.leftBack !== "0"
//                 ? element.tetos.leftBack
//                 : "Sem grau",
//             status: "Clínica",
//             // element.tipo === "Mastite subclínica" ? "Subclínica" : "Clínica",
//           };
//         }
//         if (element.tetos.rightBack) {
//           mastiteObj.rightBack = {
//             degree:
//               element.tetos.rightBack !== "0"
//                 ? element.tetos.rightBack
//                 : "Sem grau",
//             status: "Clínica",
//             //element.tipo === "Mastite subclínica" ? "Subclínica" : "Clínica",
//           };
//         }
//       }
//     }

//     // if (element.tipo === "Mastite subclínica") {
//     //   mastiteObj.data_inicio = element.data_teste;
//     // }
//     // if (element.tipo === "Mastite clínica") {
//     mastiteObj.data_inicio = new Date(element.eventDate.slice(0, -1));
//     // }

//     mastiteData.push(mastiteObj);
//   });

//   mastiteData.sort(function (a, b) {
//     return b.data_inicio.getTime() - a.data_inicio.getTime();
//   });

//   mastiteData.forEach(function (element) {
//     element.data_inicio = format(element.data_inicio, "dd/MM/yyyy");
//   });

//   return mastiteData;
// }
