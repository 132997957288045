import { useHistory } from "react-router-dom";

import PageTitle from "../../components/PageTitle/PageTitle";
import ActionCard from "./components/actionCard/actionCard";
import ActionCardflat from "./components/actionCardFlat/actionCardflat";

import alert from "../../assets/svg/property-painel/alert 1.svg";
import cow from "../../assets/svg/property-painel/cow.svg";
import graph from "../../assets/svg/property-painel/graph.svg";
import icon_l from "../../assets/svg/property-painel/icon_l.svg";
import plus_calendar from "../../assets/svg/property-painel/plus_calendar.svg";
import settings from "../../assets/svg/property-painel/settings 3.svg";

import "./propertyPainel.css";

function PropertyPainel() {
  const history = useHistory();

  return (
    <div className="property-painel-body">
      <PageTitle backButton />

      <div className="actions-wrapper">
        <ActionCard
          name="Adicionar Evento"
          gridarea="card1"
          image_path={plus_calendar}
          image_alt="Ícone de calendário"
          onClick={() => history.push("/add-event")}
        />
        <ActionCard
          name="Animais da Propriedade"
          gridarea="card2"
          image_path={cow}
          image_alt="Ícone de gado"
          onClick={() => history.push("/animals")}
        />
        <ActionCard
          name="Relatório da Fazenda"
          gridarea="card3"
          image_path={graph}
          image_alt="Gráfico"
          onClick={() => history.push("/farm-report")}
        />
        <ActionCard
          name="Qualidade do Tanque"
          gridarea="card4"
          image_path={icon_l}
          image_alt="Qualidade do Leite"
          onClick={() => history.push("/milk-quality")}
        />
        <ActionCardflat
          name="Alertas"
          id="alert"
          gridarea="cardflat1"
          image_path={alert}
          image_alt="Alerta"
          onClick={() => history.push("/alerts")}
        />
        <ActionCardflat
          name="Configurações"
          id="conf"
          gridarea="cardflat2"
          image_path={settings}
          image_alt="Configuração"
          onClick={() => history.push("/property-config")}
        />
      </div>
    </div>
  );
}

export default PropertyPainel;
