import { useHistory } from 'react-router-dom';

import './eventCard.css';

import { useEventContext } from '../../../contexts/eventContext';

import plus_icon from '../../../assets/svg/plus.svg';

function EventCard({ name }) {
  const history = useHistory();

  const { handleSetEventName } = useEventContext();

  function handleClick() {
    handleSetEventName(name);
    history.push('/create-events')
  }

  return (
    <div className="event-card" onClick={handleClick}>
      <div className="event-body">
        <div className="event-info">
          <p className="event-name">{name}</p>
        </div>
        <div className="plus-button">
          <div className="button">
            <img src={plus_icon} alt="Plus"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventCard;
