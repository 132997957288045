import { useEffect, useState } from "react";

import "./table.css";

import checkIcon from "../../assets/svg/alerts/check.svg";
import search_icon from "../../assets/svg/search-icon.svg";
import arrow_white from "../../assets/svg/your-properties/arrow-white.svg";
import useForm from "../../hooks/useForm";
import { dateToTimestamp } from "../../utils/dateToTimestamp";
import Input from "../input/input";

function Table({
  onChangeAnimal,
  check,
  multi,
  animals,
  columns,
  alert,
  ccs,
  event,
  search = true,
  top,
}) {
  const { values, handleChange } = useForm();
  const [animalsToShow, setAnimalsToShow] = useState(animals);
  const [animalsSelected, setAnimalsSelected] = useState([]);
  const [reload, setReload] = useState(false);
  const [sortColumn, setSortColumn] = useState("Identificação");
  const [sortDesc, setSortDesc] = useState(true);

  useEffect(() => {
    setAnimalsSelected([]);
    onChangeAnimal([]);
  }, [check, onChangeAnimal]);

  useEffect(() => {
    setAnimalsToShow(animals);
  }, [animals]);

  function addAnimal(animal) {
    if (check) {
      let animalsAux = animalsSelected;
      if (multi) {
        if (animalsSelected.includes(animal)) {
          animalsAux.splice(animalsSelected.indexOf(animal), 1);
        } else {
          animalsAux.push(animal);
        }
      } else {
        if (animalsSelected.includes(animal)) {
          animalsAux = [];
        } else {
          animalsAux = [animal];
        }
      }
      setAnimalsSelected(animalsAux);
      setReload(!reload);
      onChangeAnimal(animalsAux);
    }
  }

  function addAllAnimals() {
    if (check && multi) {
      if (animalsSelected.length === animalsToShow.length) {
        setAnimalsSelected([]);
        onChangeAnimal([]);
      } else {
        let animalsAux = [];
        animalsToShow.forEach((animal) => animalsAux.push(animal));
        setAnimalsSelected(animalsAux);
        onChangeAnimal(animalsAux);
      }
    }
  }

  function sortedAnimals(animalsAux, sortColumnAux, desc) {
    const array = animalsAux;
    const date_columns = [
      "Data",
      "Última_Produção",
      "Data_Prevista",
      "Data_do_Parto",
      "Data_Realizada",
      "Data_do_Tratamento",
      "Data_do_Diagnóstico",
      "Fim_do_Tratamento",
      "Data_Anterior",
      "Inseminação",
      "Parto_Previsto",
      "Nascimento",
      "Mês_do_registro",
    ];
    if (date_columns.includes(sortColumnAux)) {
      return array.sort((a, b) => {
        if (
          dateToTimestamp(a[sortColumnAux]) > dateToTimestamp(b[sortColumnAux])
        ) {
          return desc ? -1 : 1;
        } else if (
          dateToTimestamp(b[sortColumnAux]) > dateToTimestamp(a[sortColumnAux])
        ) {
          return desc ? 1 : -1;
        } else {
          return 0;
        }
      });
    }

    return array.sort((a, b) => {
      if ((a[sortColumnAux] || "") > (b[sortColumnAux] || "")) {
        return desc ? -1 : 1;
      } else if ((b[sortColumnAux] || "") > (a[sortColumnAux] || "")) {
        return desc ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  if (animals[0]) {
    var new_columns = columns;
  }

  if (!window.matchMedia("(min-width:800px)").matches) {
    if (alert) new_columns = ["Identificação", "Data_prevista"];
    else if (ccs) new_columns = ["Identificação", "Valor_de_CCS"];
    else if (event) new_columns = ["Identificação", "Raça", "Lote"];
  }

  function handleSearchChange(event) {
    const subStringAtual = event.currentTarget.value;
    if (
      subStringAtual &&
      values.search &&
      subStringAtual.length >= values.search.length
    ) {
      // Adding a letter only filter in current values
      setAnimalsToShow(
        animalsToShow.filter((option) =>
          columns.some((key) =>
            option[key]
              ? option[key]
                  .toString()
                  .toLowerCase()
                  .indexOf(event.currentTarget.value.toLowerCase()) !== -1
              : false
          )
        )
      );
    } else {
      // Remove a letter filter in all original values
      setAnimalsToShow(
        animals.filter((option) =>
          columns.some((key) =>
            option[key]
              ? option[key]
                  .toString()
                  .toLowerCase()
                  .indexOf(event.currentTarget.value.toLowerCase()) !== -1
              : false
          )
        )
      );
    }
    handleChange(event);
  }

  return (
    <div className="table-component">
      {search && (
        <div className="input-wrapper-search">
          <Input
            // autoFocus
            id="search"
            className="search-word-input"
            type="text"
            placeholder={
              "Insira " +
              columns[0]?.toLowerCase().replaceAll("_", " ") +
              ", " +
              columns[1]?.toLowerCase().replaceAll("_", " ") +
              "..."
            }
            onChange={handleSearchChange}
            disableAutocomplete
          />
          <img src={search_icon} alt="Lupa" />
        </div>
      )}
      {animalsToShow.length > 0 ? (
        <div className={"table-div "}>
          <div className="columns-wrapper">
            <div className="columns-none" />
            {new_columns &&
              new_columns.map((_, index) => {
                return <div key={index} className="columns"></div>;
              })}
          </div>
          <div className={"row-card-master " + (check && "check")}>
            {check && multi && (
              <div
                className={
                  "check " +
                  (animalsSelected.length === animalsToShow.length && "icon")
                }
                onClick={addAllAnimals}
              >
                {animalsSelected.length === animalsToShow.length && (
                  <img src={checkIcon} alt="Check Icon" />
                )}
              </div>
            )}
            {new_columns &&
              new_columns.map((label, index) => {
                return (
                  <div key={label + index} className="row-element">
                    {(() => {
                      switch (label) {
                        case "Valor_de_CCS":
                          return "Valor de CCS (mil cel/ml)";
                        case "Valor_de_CPP":
                          return "Valor de CPP (mil ucf/ml)";
                        case "Volume_diário":
                          return "Volume diário (litros)";

                        default:
                          return label.replaceAll("_", " ");
                      }
                    })()}
                    <div
                      className="sort-button"
                      onClick={() => {
                        if (sortColumn === label) setSortDesc(!sortDesc);
                        else {
                          setSortColumn(label);
                          setSortDesc(true);
                        }
                      }}
                    >
                      <img
                        src={arrow_white}
                        alt="Arrow"
                        className={
                          "arrow " +
                          (sortColumn === label && sortDesc ? "down" : "up")
                        }
                      />
                    </div>
                  </div>
                );
              })}
          </div>

          {sortedAnimals(animalsToShow, sortColumn, sortDesc)
            .slice(0, top ?? 999999)
            .map((animal, index) => {
              return (
                <div
                  key={index}
                  className={"row-card " + (check && "check")}
                  onClick={() => addAnimal(animal)}
                >
                  {check && (
                    <div
                      className={
                        "check " + (animalsSelected.includes(animal) && "icon")
                      }
                    >
                      {animalsSelected.includes(animal) && (
                        <img src={checkIcon} alt="Check Icon" />
                      )}
                    </div>
                  )}
                  {new_columns.map((label, i) => {
                    return (
                      <div key={label + i} className="row-element">
                        <p
                          className={
                            "text-row " + (animal[label] === "-" && "warning")
                          }
                          dangerouslySetInnerHTML={{
                            __html: animal[label],
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      ) : (
        <div className="text-not-found">
          <p>Não encontrado</p>
        </div>
      )}
    </div>
  );
}

export default Table;
