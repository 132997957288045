import { format } from "date-fns";
import { useEffect, useState } from "react";

import { useEventContext } from "../../../../contexts/eventContext";
import { useFarmContext } from "../../../../contexts/farmContext";
import useForm from "../../../../hooks/useForm";

import { lastDrying, registerDrying } from "../../../../services/events/drying";

import Input from "../../../../components/input/input";
import Select from "../../../../components/select/select";
import CheckBoxTeto from "../../components/checkBoxTeto/checkBoxTeto";
import EventContent from "../../components/eventContent/eventContent";

import "./drying.css";

// import { getPartoById } from "../../../../services/events/birth";
import { dateToApiDate } from "../../../../utils/dateToApiDate";
import { isDateValid, isPastDate } from "../../../../utils/isDateValid";
import { isNumber } from "../../../../utils/isNumber";
import { handlePredictedShortageData } from "../../../../utils/predictData";

const procedures = [
  { label: "CCS individual" },
  { label: "CMT" },
  { label: "CMT e CCS" },
];

const drugs = [
  { label: "AMOCLOX S", period: 60 },
  { label: "ANAMASTIT S", period: 30 },
  { label: "BIOMAST VS", period: 45 },
  { label: "CEPRAVIN", period: 60 },
  { label: "CIPROLAC VACA SECA", period: 59 },
  { label: "CURACLOX VACA SECA", period: 49 },
  // { label: "EMEMAST VS", period: 32 },
  { label: "GENTATEC VACA SECA", period: 40 },
  { label: "MAMYZIM", period: 37 },
  { label: "MASTIFIN VACA SECA", period: 30 },
  { label: "MASTIJET VACA SECA", period: 35, alert: 1 },
  { label: "MASTIZONE VS", period: 60 },
  { label: "NEOMASTIC", period: 30, alert: 3 },
  { label: "NOROCLOX VACA SECA", period: 35, alert: 4 },
  { label: "ORBENIN EXTRA DRY COW", period: 42 },
  { label: "RILEXINA 500", period: 60 },
  { label: "VASECLOX VACA SECA", period: 30, alert: 3 },
  { label: "VETIMAST PLUS VS", period: 60 },
];

export default function Drying() {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleSelectChange,
    handleSetErrors,
    reset,
  } = useForm();
  const { selectedAnimals, handleModalShow, handleModalProps } =
    useEventContext();
  const { farmSelected } = useFarmContext();

  const [isLoading, setIsLoading] = useState(false);
  const [shortageDate, setShortageDate] = useState();
  const [period, setPeriod] = useState();
  const [alertDrug, setAlertDrug] = useState();
  const [infoValue, setInfoValue] = useState("-");
  // const [lastBirt, setLastBirt] = useState("");

  useEffect(() => {
    async function fetchLastRegister() {
      try {
        const register_response = await lastDrying(
          selectedAnimals[0].id,
          selectedAnimals[0].farmId
        );
        const date = new Date(register_response.drying.eventDate.slice(0, -1));
        const value = format(date, "dd/MM/yyyy");
        setInfoValue(value);
      } catch (err) {}
    }
    if (selectedAnimals.length === 1) {
      fetchLastRegister();
    }
  }, [selectedAnimals]);

  // useEffect(() => {
  //   async function fetchLastRegister() {
  //     try {
  //       if (selectedAnimals[0].parto_id) {
  //         const register_response = await getPartoById(
  //           selectedAnimals[0].parto_id
  //         );
  //         setLastBirt(register_response.data_parto);
  //       }
  //     } catch (err) {}
  //   }
  //   if (selectedAnimals.length === 1) {
  //     fetchLastRegister();
  //   }
  // }, [selectedAnimals]);

  async function checkValues() {
    const error = {};

    if (!values.date) {
      error.date = "Este campo é obrigatório";
    } else if (!isDateValid(values.date)) {
      error.date = "Insira uma data válida";
    } else if (!isPastDate(values.date)) {
      error.date = "Insira uma data no passado ou o dia corrente";
      // } else if (isPastDate(values.date, lastBirt)) {
      //   error.date =
      //     "Insira uma data posterior ao ultimo parto do animal (" +
      //     format(lastBirt * 1000, "dd/MM/yyyy") +
      //     ")";
    } else if (errors.date) {
      delete error.date;
    }

    if (!values.drug) {
      error.drug = "Selecione um medicamento";
    } else if (errors.drug) {
      delete error.drug;
    }

    // if (!values.procedure) {
    //   error.procedure = "Selecione um procedimento";
    // } else if (errors.procedure) {
    //   delete error.procedure;
    // }

    // if (!values.procedure) return error;

    let count = 0;
    for (let teto in values.tetos) {
      if (values.tetos[teto]) count++;
      else delete values.tetos[teto];
    }

    if (values.procedure === "CCS individual") {
      if (!values.valueCCS) {
        error.valueCCS = "Este campo é obrigatório";
      } else if (!isNumber(parseInt(values.valueCCS))) {
        error.valueCCS = "Preencha só com números";
      } else if (parseInt(values.valueCCS) === 0) {
        error.valueCCS = "Preencha um valor maior que zero";
      } else if (errors.valueCCS) {
        delete error.valueCCS;
      }
    } else if (values.procedure === "CMT") {
      if (!values.tetos || count === 0) {
        error.tetos = "Selecione um teto ou mais";
      } else if (errors.tetos) {
        delete error.tetos;
      }
    } else if (values.procedure === "CMT e CCS") {
      if (!values.valueCCS) {
        error.valueCCS = "Este campo é obrigatório";
      } else if (!isNumber(parseInt(values.valueCCS))) {
        error.valueCCS = "Preencha só com números";
      } else if (parseInt(values.valueCCS) === 0) {
        error.valueCCS = "Preencha um valor maior que zero";
      } else if (errors.valueCCS) {
        delete error.valueCCS;
      }

      if (!values.tetos || count === 0) {
        error.tetos = "Selecione um teto ou mais";
      } else if (errors.tetos) {
        delete error.tetos;
      }
    }

    return error;
  }

  async function valuesAreCorrect() {
    try {
      setIsLoading(true);
      const data = {
        animalId: selectedAnimals[0].id,
        farmId: farmSelected.farm_id,
        eventDate: dateToApiDate(values.date),
        expectedDate: dateToApiDate(values.date),
        medicine: values.drug,
        medicineDate: dateToApiDate(shortageDate),
        // procedimento: values.procedure,
        // parto_id: selectedAnimals[0].parto_id,
      };
      if (values.procedure === "CCS individual") {
        data["ccs"] = parseInt(values.valueCCS);
      } else if (values.procedure === "CMT") {
        data["tetos"] = values.tetos;
      } else if (values.procedure === "CMT e CCS") {
        data["ccs"] = parseInt(values.valueCCS);
        data["tetos"] = values.tetos;
      }
      if (alertDrug) data["medicineAlert"] = alertDrug;

      await registerDrying([data]);

      setIsLoading(false);
      reset();
      const boxValue = values.date;
      const boxSecondaryInfo = {
        label: "Data do medicamento:",
        value: values.date,
      };
      handleModalProps({
        boxValue: boxValue,
        drug: values.drug,
        procedure: values.procedure,
        boxSecondary: boxSecondaryInfo,
      });
      handleModalShow(true);
    } catch (err) {
      setIsLoading(false);
      handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
    }
  }

  function handleDrugsChange(value) {
    handleSelectChange("drug", value.label);

    if (values.date && value.period) {
      const aux_shortage = handlePredictedShortageData(
        values.date,
        value.period
      );
      setShortageDate(aux_shortage);
      setPeriod(value.period);
    }
    if (value.alert) setAlertDrug(value.alert);
  }

  function handleDateChange(event) {
    handleChange(event);

    const value = event.target.value;
    if (!value.includes("_") && values.drug) {
      const aux_shortage = handlePredictedShortageData(value, period);
      setShortageDate(aux_shortage);
    }
  }

  return (
    <EventContent
      animals={selectedAnimals}
      className="drying"
      eventInfoValue={infoValue}
      onClickSave={handleSubmit(checkValues, valuesAreCorrect, isLoading)}
      isLoading={isLoading}
      apiError={errors.api}
    >
      <div className="drug-line">
        <div className="px300-input">
          <Input
            id="date"
            label="Data da última ordenha"
            errorAlignColumn
            error={errors.date}
            value={values.date}
            type="text"
            onChange={handleDateChange}
            mask="99/99/9999"
            placeholder="dd/mm/aaaa"
          />
        </div>
        {values.date && !values.date.includes("_") && (
          <div className="shortage-date">
            <p className="shortage-label">
              Data de aplicação do medicamento vaca seca:
            </p>
            <p className="shortage-text">{values.date}</p>
          </div>
        )}
      </div>
      <div className="drug-line">
        <div className="px400-input">
          <Select
            id="drug"
            errorAlignColumn
            label="Medicamento usado para secar a vaca"
            placeholder="Selecione o medicamento"
            error={errors.drug}
            value={values.drug}
            onChange={handleDrugsChange}
            options={drugs}
          />
        </div>
        {values.drug && values.date && !values.date.includes("_") && (
          <div className="shortage-date">
            <p className="shortage-label">Data para o fim carência:</p>
            <p className="shortage-text">{shortageDate}</p>
          </div>
        )}
      </div>
      <div className="px400-input margin-bottom">
        <Select
          id="procedure"
          errorAlignColumn
          label="Análise da mastite subclínica"
          placeholder="Selecione o procedimento"
          error={errors.procedure}
          value={values.procedure}
          onChange={(value) => handleSelectChange("procedure", value.label)}
          options={procedures}
        />
      </div>

      {values.procedure && values.procedure === "CCS individual" && (
        <div className="px400-input">
          <Input
            id="valueCCS"
            label="Valor"
            placeholder="Inserir valor do CCS em cel/ml"
            errorAlignColumn
            error={errors.valueCCS}
            value={values.valueCCS}
            type="text"
            mask="9999 mil cel/ml"
            maskChar=" "
            onChange={handleChange}
          />
        </div>
      )}
      {values.procedure && values.procedure === "CMT" && (
        <CheckBoxTeto
          id="tetos"
          cow
          label="Selecione o(s) teto(s)"
          error={errors.tetos}
          onChange={(v) => handleSelectChange("tetos", v)}
          cmt
        />
      )}
      {values.procedure && values.procedure === "CMT e CCS" && (
        <div className="CMT-CCS">
          <div className="CMT-input">
            <p className="labels">CMT:</p>
            <CheckBoxTeto
              id="tetos"
              label="Selecione o(s) teto(s)"
              errorAlignColumn
              error={errors.tetos}
              onChange={(v) => handleSelectChange("tetos", v)}
              cmt
            />
          </div>
          <div className="CCS-input">
            <p className="labels">CCS individual:</p>
            <div className="px300-input fixed">
              <Input
                id="valueCCS"
                label="Valor"
                errorAlignColumn
                error={errors.valueCCS}
                value={values.valueCCS}
                type="text"
                placeholder="Inserir valor do CCS em cel/ml"
                mask="9999 mil cel/ml"
                maskChar=" "
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      )}
    </EventContent>
  );
}
