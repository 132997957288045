import AlertCard from "../alertCard/alertCard";

export default function ShowAlerts({ alerts, onClick }) {
  return (
    <div className="alerts-wrapper">
      {alerts.map((alert) => {
        return (
          <AlertCard 
            key={alert.id}
            name={alert.name}
            animals_number={alert.animals_number}
            animal_id={alert.animal_id}
            isCritical={alert.isCritical}
            onClick={() => onClick(alert)}
          />
        )}
      )}
    </div>
  )
}