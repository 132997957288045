import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { useFarmContext } from "../../contexts/farmContext";

import Button from "../../components/button/button";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "../../components/table/table";

import plus_icon from "../../assets/svg/plus.svg";

import "./farmAnimals.css";

// const options = [
//   { label: "Animais arquivados", value: "Animais arquivados" },
//   { label: "Próximos partos", value: "Próximos partos" },
//   { label: "Próximas secagens", value: "Próximas secagens" },
//   { label: "Animais com ccs alto", value: "Animais com ccs alto" },
//   {
//     label: "Animais com mastite subclínica",
//     value: "Animais com mastite subclínica",
//   },
//   {
//     label: "Animais com mastite clínica",
//     value: "Animais com mastite clínica",
//   },
//   { label: "Animais em lactação", value: "Animais em lactação" },
//   { label: "Rebanhos", value: "Rebanhos" },
//   { label: "Lote", value: "Lote" },
// ];

const columns = ["Identificação", "Nascimento", "Categoria", "Raça", "Lote"];

function FarmAnimals() {
  const history = useHistory();

  const { animals, animalsSelected, animalBigImg, selectAnimals } =
    useFarmContext();

  // const [selectedFilters, setSelectedFilters] = useState([]);
  // const [animalsToShow, setAnimalsToShow] = useState([]);

  // function addFilter(filter) {
  //   if (!selectedFilters.includes(filter)) {
  //     setSelectedFilters([...selectedFilters, filter]);

  //     let animalsToShowAux = [];
  //     selectedFilters.length !== 0 && (animalsToShowAux = animalsToShow);

  //     // animals.forEach(animal => animal.filter_by.includes(filter.value) && (animalsToShowAux = [...animalsToShowAux, animal]))
  //     setAnimalsToShow(animalsToShowAux);
  //   }
  // }

  // function removeFilter(filter, index) {
  //   if (selectedFilters.length === 1) setAnimalsToShow(animals);
  //   else {
  //     const animalsToShowAux = animalsToShow.filter((elem) =>
  //       elem.filter_by.includes(filter.value) ? false : true
  //     );
  //     setAnimalsToShow(animalsToShowAux);
  //   }

  //   const selectedFiltersAux = selectedFilters;
  //   selectedFiltersAux.splice(index, 1);
  //   setSelectedFilters(selectedFiltersAux);
  // }

  const onChangeAnimal = useCallback(
    (value) => {
      selectAnimals(value);
    },
    [selectAnimals]
  );

  return (
    <div className="farm-animals-body">
      <PageTitle path="Animais da fazenda" backButton>
        <Button color="blue" onClick={() => history.push("animal-register")}>
          Adicionar animal
          <img src={plus_icon} alt="Cadastrar" />
        </Button>
      </PageTitle>

      {animals.length > 0 ? (
        <div className="body-wrapper">
          <p className="second-title">Animais da fazenda</p>
          <p className="first-text">
            Selecione o animal para abrir a respectiva ficha.
          </p>

          <div className="input-button-div">
            {/* <div className="input-wrapper">
              <Input
                id="search-word"
                label="Busca individual"
                className="search-word-input"
                type="text"
                placeholder="Insira a identificação, o nascimento, ..."
                onChange={() => {}}
              />
              <img src={search_icon} alt="Lupa" />
            </div> */}

            {/* <Select
              id="filter-select"
              className="filter-select"
              placeholder={
                <div className="filter-placeholder">
                  <img src={filter_icon} alt="Filtro" />
                  Filtrar
                </div>
              }
              onChange={addFilter}
              options={options}
              filter
            /> */}
          </div>

          {/* <div className="filters">
            {selectedFilters.map((filter, index) => {
              return (
                <Filter
                  key={filter.label + index}
                  label={filter.label}
                  onClickRemove={() => removeFilter(filter, index)}
                />
              );
            })}
          </div> */}

          <div className="table_wrapper">
            <div
              className="table_wrapper_container"
              style={{ minWidth: columns.length * 180 + "px" }}
            >
              <Table
                animals={animals}
                columns={columns}
                check
                event
                onChangeAnimal={onChangeAnimal}
              />
            </div>
          </div>

          <div className="continue-button">
            <Button
              disabled={animalsSelected?.length === 0}
              onClick={() => history.push("/animal-report")}
            >
              Continuar
            </Button>
          </div>
        </div>
      ) : (
        <div className="no-animals">
          <img src={animalBigImg()} alt="Vaca" />
          <p className="text">
            Nenhum animal por aqui ainda. Comece adicionando um animal.
          </p>
          <Button color="blue" onClick={() => history.push("animal-register")}>
            Adicionar Animal
            <img src={plus_icon} alt="Cadastrar" />
          </Button>
        </div>
      )}
    </div>
  );
}

export default FarmAnimals;
