// import React, { useState } from "react";
import * as d3 from "d3";
import { useColorIndication } from "./useColorIndication";

import './circularChart.css';

function ProgressArc(props) {
  const { svgWidth, arcWidth, progressPercentage, colorIndicator, notShowPercentage } = props;
  const svgHeight = svgWidth;
  const arcOuterRadius = svgWidth / 2;
  const arcInnerRadius = svgWidth / 2 - arcWidth;
  const arcGenerator = d3
    .arc()
    .innerRadius(arcInnerRadius)
    .outerRadius(arcOuterRadius)
    .startAngle(0)
    .cornerRadius(5);
  const progressArc = value =>
    arcGenerator({
      endAngle: 2 * Math.PI * value
    });

  return (
    
    <div className="circular-chart">
      <svg height={svgHeight} width={svgWidth}>
        <g transform={`translate(${svgWidth / 2}, ${svgHeight / 2})`}>
          <path d={progressArc(1)} opacity="0.3" fill="gray" />
        </g>
        <g transform={`translate(${svgWidth / 2}, ${svgHeight / 2})`}>
          <path
            d={progressArc(progressPercentage / 100)}
            fill={colorIndicator}
          />
          {notShowPercentage ? 
          <text x="-15" y="5">
            {`${progressPercentage}%`}
          </text>
          : null}
        </g>
      </svg>
      {props.label ? 
        <div className="circular-chart-label">
          <p>
              {props.label}
          </p> 
        </div>
      : null}
    </div>
  );
}

export function CircularChart({progressPercentage, width, label, arcWidth, notShowPercentage}) {
  const svgWidth = width;
  arcWidth ? arcWidth=arcWidth : arcWidth=20;
  notShowPercentage ? notShowPercentage=false : notShowPercentage=true;
  // const [progressPercentage, setProgressPercentage] = useState(50);
  const colorIndicator = useColorIndication(progressPercentage);

  return (
    <ProgressArc
      svgWidth={svgWidth}
      arcWidth={arcWidth}
      progressPercentage={progressPercentage}
      colorIndicator={colorIndicator}
      label={label}
      notShowPercentage={notShowPercentage}
    />
  );
}