import "./modal.css";

function Modal({ showModal, close, fullsize, children }) {
  window.onclick = function (event) {
    const modal = document.getElementById("myModal");
    if (event.target === modal) {
      showModal(false);
    }
  };

  return (
    <div id="myModal" className="modal">
      <div className={"modal-content " + (fullsize && "fullsize")}>
        {close && (
          <span className="modal-close" onClick={() => showModal(false)}>
            &times;
          </span>
        )}

        {children}
      </div>
    </div>
  );
}

export default Modal;
