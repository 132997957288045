import { getUnixTime } from "date-fns";
import pt from "date-fns/locale/pt";
import { forwardRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "../../utils/datepicker.css";
import "./farmReport.css";

import download from "../../assets/svg/animal-report/download.svg";

import PageTitle from "../../components/PageTitle/PageTitle";
import Button from "../../components/button/button";
import Select from "../../components/select/select";

import { useState } from "react";
import { Column, Row } from "./components/bases/bases";
import { Birth } from "./components/birth/birth";
import { DryCowTherapy } from "./components/dryCowTherapy/dryCowTherapy";
import { MastitisClinical } from "./components/mastitisClinical/mastitisClinical";
import { Mastitis } from "./components/mastitisSubClinical/mastitisSubClinical";
import { MilkProduction } from "./components/milkProduction/milkProduction";
import { MilkQuality } from "./components/milkQuality/milkQuality";
import { PrevalenceOfMicroorganisms } from "./components/prevalenceOfMicroorganisms/prevalenceOfMicroorganisms";
// import MastitisTreatment from "./components/mastitisTreatment/mastitisTreatment";

import filter_icon from "../../assets/svg/filter.svg";
import ShowFilters from "../../components/ShowFilters/showFilters";

const filters = [
  { label: "Leite produzido", value: "leite_produzido" },
  { label: "Parto e Secagem", value: "parto_e_secagem" },
  { label: "Mastite Clínica", value: "mastite_clinica" },
  { label: "Mastite Subclínica", value: "mastite_subclinica" },
  {
    label: "Prevalência de microrganismos",
    value: "prevalencia_de_microrganismos",
  },
  { label: "Qualidade do leite", value: "qualidade_do_leite" },
];

export default function FarmReport() {
  registerLocale("pt", pt);
  const [date, setDate] = useState(new Date());
  const [selectedFilters, setSelectedFilters] = useState([]);

  function addFilter(filter) {
    if (!selectedFilters.includes(filter.value)) {
      setSelectedFilters([...selectedFilters, filter]);
    }
  }

  function removeFilter(filter, index) {
    if (selectedFilters.length === 1) setSelectedFilters([]);
    else {
      const aux = selectedFilters.filter((elem) =>
        elem === filter ? false : true
      );
      setSelectedFilters(aux);
    }
  }

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button className="example-custom-Button" onClick={onClick} ref={ref}>
      {value}
    </Button>
  ));

  return (
    <div className="farm-report-page">
      <PageTitle path="Relatório de rebanho" backButton />

      <div className="body-wrapper">
        <div className="top-row">
          <div className="first-part">
            <p className="title">Relatórios de rebanho</p>
            {/* <Select id="filter-select" // FAZER FILTROS
              className="filter-select"
              placeholder={<div className="filter-placeholder"><img src={filter_icon} alt="Filtro" />Filtrar</div>}
              onChange={() => {}} 
              options={options}
              filter
            /> */}
          </div>
          <Select
            id="filters-select"
            className="filters-wrapper"
            placeholder={
              <div className="filter-placeholder">
                <img src={filter_icon} alt="Filtro" />
                Selecione
              </div>
            }
            onChange={addFilter}
            options={filters}
            filter={true}
          />
          <div className="button-wrapper">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={date}
              onChange={(date) => setDate(date)}
              maxDate={new Date()}
              placeholderText="Selecione o mês"
              customInput={<ExampleCustomInput />}
              withPortal
              portalClassName="datepicker-portal"
              showYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={15}
              scrollableYearDropdown
            />
            <Button
              color="white"
              onClick={() => {
                window.print();
              }}
            >
              Baixar
              <img src={download} alt="Download" />
            </Button>
          </div>
        </div>
        <div>
          <ShowFilters
            filters={selectedFilters}
            onClickRemoveFilter={removeFilter}
          />
        </div>

        {(selectedFilters.includes(filters[0]) ||
          selectedFilters.length === 0) && (
          <MilkProduction date={getUnixTime(date)} />
        )}

        {(selectedFilters.includes(filters[1]) ||
          selectedFilters.length === 0) && (
          <Row className="birth-dry-therapy">
            <Column>
              <Birth date={getUnixTime(date)} />
            </Column>
            <Column>
              <DryCowTherapy date={getUnixTime(date)} />
            </Column>
          </Row>
        )}

        {(selectedFilters.includes(filters[2]) ||
          selectedFilters.length === 0) && (
          <MastitisClinical date={getUnixTime(date)} />
        )}

        {/* <MastitisTreatment /> */}

        {(selectedFilters.includes(filters[3]) ||
          selectedFilters.length === 0) && (
          <Mastitis date={getUnixTime(date)} />
        )}

        {(selectedFilters.includes(filters[4]) ||
          selectedFilters.length === 0) && (
          <PrevalenceOfMicroorganisms date={getUnixTime(date)} />
        )}

        {(selectedFilters.includes(filters[5]) ||
          selectedFilters.length === 0) && (
          <MilkQuality date={getUnixTime(date)} />
        )}
      </div>
    </div>
  );
}
