import { newApi } from "../api";

async function forgotPassword(email) {
  try {
    const response = await newApi.get("/user/password/" + email);
    return response.data;
  } catch (err) {
    throw new Error(err.response);
  }
}

export default forgotPassword;
