import * as d3 from "d3";
import BaseChart from "../baseChart";

import "./scatterPlot.css";

function dotColorClass(x, y) {
  if (x <= 4 && y <= 4) return "scatter-plot__point_blue";
  if (x >= 4 && y <= 4) return "scatter-plot__point_orange";
  if (x <= 4 && y >= 4) return "scatter-plot__point_green";
  if (x >= 4 && y >= 4) return "scatter-plot__point_red";
}

function drawScatterPlot(props) {
  const {
    svgRef,
    data,
    xScale,
    yScale,
    width,
    height,
    // margin,
    // pointClass,
    pointWidth,
  } = props;

  const svg = d3.select(svgRef.current).select("g");

  svg
    .append("g")
    .selectAll("dot")
    .data(data)
    .enter()
    .append("circle")
    .attr("cx", (d) => xScale(d.value))
    .attr("cy", (d) => yScale(d.label))
    .attr("r", pointWidth)
    .attr("class", (d) => dotColorClass(d.value, d.label));

  // x line
  svg
    .append("line")
    .style("stroke", "grey")
    .style("fill", "none")
    .style("stroke-width", 2)
    .style("stroke-opacity", 0.4)
    .style("stroke-linecap", "round")
    .attr("x1", 0)
    .attr("y1", yScale(4))
    .attr("x2", width)
    .attr("y2", yScale(4));

  // y line
  svg
    .append("line")
    .style("stroke", "grey")
    .style("stroke-width", 2)
    .style("stroke-opacity", 0.4)
    .style("stroke-linecap", "round")
    .attr("x1", xScale(4))
    .attr("y1", 0)
    .attr("x2", xScale(4))
    .attr("y2", height);

  // Curadas
  d3.select(svgRef.current)
    .append("text")
    .attr("font-size", "0.8em")
    .attr("x", width / 5)
    .attr("y", (5 * height) / 10)
    .text("Curadas");

  // Cronicas
  d3.select(svgRef.current)
    .append("text")
    .attr("font-size", "0.8em")
    .attr("x", (7 * width) / 10)
    .attr("y", (5 * height) / 10)
    .text("Crônicas");

  // Sadias
  d3.select(svgRef.current)
    .append("text")
    .attr("font-size", "0.8em")
    .attr("x", width / 5)
    .attr("y", (10.5 * height) / 10)
    .text("Sadias");

  // Novas
  d3.select(svgRef.current)
    .append("text")
    .attr("font-size", "0.8em")
    .attr("x", (7 * width) / 10)
    .attr("y", (10.5 * height) / 10)
    .text("Novas");
}

const extraProps = {
  useScaleBands: { x: false, y: false },
  // drawXGridlines: true,
  // drawYGridlines: true,
};

export default BaseChart(drawScatterPlot, extraProps);
