
export function getOthersAndTop5(DATA) {

    var top5 = DATA.sort(function(a,b) {
        return a.value < b.value ? 1 : a.value > b.value ? -1 : 0;
    });

    var sum = 0
    top5.forEach(function(element){
        sum += element.value
    });

    var others = {value: 0, name: 'Outros'}

    top5.forEach(function(element, index){
        if(index < 5){
        element.value = element.value / sum * 100
        }
        else{
            others.value += element.value
        }
    });

    others.value = (others.value / sum * 100)
    
	if (top5.length > 5){
        
        top5 = top5.slice(0, 5)
        top5.push(others)
        
        top5.sort(function(a,b) {
            return a.value < b.value ? 1 : a.value > b.value ? -1 : 0;
        });

	}
    
    top5.forEach(function(element){
        element.value = Math.round(element.value)
    });


    return top5
}