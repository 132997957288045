import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import './navbar.css';

import logo from "../../assets/images/logo-small-1.png";

import { useFarmContext } from '../../contexts/farmContext';
import { useUserContext } from '../../contexts/userContext';

function Options(){
  const { haveFarmSelected } = useFarmContext();
  const history = useHistory();
  const { logOut } = useUserContext();
  return (
  <>
    <div>
      {haveFarmSelected() ? 
        <>
        <p className="option" onClick={() =>  history.push('/farm-report')}>Relatório de rebanho</p>
        <p className="option" onClick={() =>  history.push('/alerts')}>Alertas</p>
        <p className="option" onClick={() =>  null}>FAQ</p>
        </>
        :
        null
      }
      <p className="option">Aprender</p>
      {/* <p className="option" onClick={() =>  history.push('/account-config')}>Conta</p> */}
      
    </div>
    <p className="option" onClick={() => {logOut(); history.push('/')}}>Sair</p>
  </>
  )
}


function Navbar() {
  const history = useHistory();
  const [isSidenavOpen, setIsSidenavOpen] = useState(false);

  return (
    <>
      <nav className="navbar">
        <img src={logo} alt="Logo" onClick={() => history.push('/your-properties')}/>
        <Options/>
        <div className="sandwich-icon" onClick={() => setIsSidenavOpen(true)}>
          <div/>
          <div/>
          <div/>
        </div>
      </nav>

      <div className={"sidenav " + (isSidenavOpen ? "open" : "close")}>
        <div className="close" onClick={() => setIsSidenavOpen(false)}>&times;</div>

        <Options/>
      </div>
    </>
  )
}

export default Navbar;