import "./filter.css";

function Filter(props) {
  return (
    <div className="filter-wrapper" >
      {props.icon && <img src={props.icon} alt="Ícone" className="img-filter" />}
          
      <p className="filter-title">
        {props.label}
      </p>

      <span className="remove-filter" onClick={props.onClickRemove}>&times;</span>
    </div>
  );
}

export default Filter;
