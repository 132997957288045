import { useHistory } from 'react-router-dom';

import './warningBanner.css';

import Button from "../../../../components/button/button"

import noAlerts from '../../../../assets/svg/alerts/noalerts.svg';

function WarningBanner() {
    const history = useHistory();

    return (
        <div className="warning">
            <img
                src={noAlerts}
                alt="Ícone sem Alertas"
                className="noalerts-icon"
            />
            <h3 className="noalerts-description">
                Nenhum alerta por aqui ainda. Crie eventos para os animais e os começarão a aparecer.
            </h3>
            
            <Button className="new-event-button-wrapper" color="blue" children="Criar Eventos" onClick={() => history.push("/add-event")} />
        </div>
    );
}
export default WarningBanner;