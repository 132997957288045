import { format } from "date-fns";

function fillTetosValues(data) {
  var valor = "";
  var teto_name = "";
  var grau = "";
  for (var teto in data["tetos"]) {
    if (teto === "rightFront") teto_name = "frontal direito:";
    else if (teto === "leftFront") teto_name = "frontal esquerdo:";
    else if (teto === "rightBack") teto_name = "traseiro direito:";
    else if (teto === "leftBack") teto_name = "traseiro esquerdo:";
    grau = data["tetos"][teto];
    teto_name += " " + grau + ".  ";
    valor += teto_name;
  }
  return valor;
}

export function getAllEvents(data) {
  var events = [];
  var date = null;
  var name_categoria = null;
  var valor = "";
  var id = "";

  for (const categoria in data) {
    for (var evento in data[categoria]) {
      if (categoria === "mastitis") {
        name_categoria = "mastite";
        date = format(
          new Date(data[categoria][evento]["eventDate"].slice(0, -1)),
          "dd/MM/yyyy"
        );
        valor = fillTetosValues(data[categoria][evento]);
      } else if (categoria === "reproduction") {
        name_categoria = "reprodução";
        date = format(
          new Date(data[categoria][evento]["eventDate"].slice(0, -1)),
          "dd/MM/yyyy"
        );
        valor =
          "Data prevista de parto: " +
          format(
            new Date(
              data[categoria][evento]["expectedBirthdayDate"].slice(0, -1)
            ),
            "dd/MM/yyyy"
          );
      } else if (categoria === "culture") {
        name_categoria = "cultura microbiológica";
        date = format(
          new Date(data[categoria][evento]["eventDate"].slice(0, -1)),
          "dd/MM/yyyy"
        );
        valor = "bactéria: " + data[categoria][evento]["bacterium"];
      } else if (categoria === "milkProduction") {
        name_categoria = "produção de leite";
        date = format(
          new Date(data[categoria][evento]["eventDate"].slice(0, -1)),
          "dd/MM/yyyy"
        );
        valor = data[categoria][evento]["dayVolume"] * 30 + " litros";
      } else if (categoria === "birth") {
        name_categoria = "parto";
        date = format(
          new Date(data[categoria][evento]["eventDate"].slice(0, -1)),
          "dd/MM/yyyy"
        );
        valor = "";
      } else if (categoria === "drying") {
        name_categoria = "secagem";
        date = format(
          new Date(data[categoria][evento]["eventDate"].slice(0, -1)),
          "dd/MM/yyyy"
        );
        valor = "medicamento: " + data[categoria][evento]["medicine"];
      } else if (categoria === "cmt") {
        name_categoria = "teste CMT";
        // var tetos = data[categoria][evento]["tetos"]
        date = format(
          new Date(data[categoria][evento]["eventDate"].slice(0, -1)),
          "dd/MM/yyyy"
        );
        valor = fillTetosValues(data[categoria][evento]);
      } else if (categoria === "ccs") {
        name_categoria = "CCS individual";
        date = format(
          new Date(data[categoria][evento]["eventDate"].slice(0, -1)),
          "dd/MM/yyyy"
        );
        valor = data[categoria][evento]["ccs"] + " mil cel/ml";
      }
      id = data[categoria][evento]["id"];
      events.push({
        Categoria: name_categoria,
        Data: date,
        Valor: valor,
        id: id,
      });
    }
  }

  return events;
}
