import React, { useState } from "react";

import "./checkBoxTeto.css";

import Button from "../../../../components/button/button";
import CheckBox from "../../../../components/checkBox/checkBox";
import SmallSelect from "./smallSelect/smallSelect";

import bodyCow from "../../../../assets/svg/events/bodyCow.svg";

import { useFarmContext } from "../../../../contexts/farmContext";
import { calcAge } from "../../../../utils/calcAge";

const optionsType = [{ label: "1" }, { label: "2" }, { label: "3" }];

const optionsCMT = [
  { label: "1" },
  { label: "2" },
  { label: "3" },
  { label: "4" },
  { label: "x" },
  { label: "m" },
];

export default function CheckBoxTeto(props) {
  const { animalImg } = useFarmContext();

  const [degreeSelected, setDegreeSelected] = useState(false);
  const [tetosSelected, setTetosSelected] = useState({});

  function handleOptionClick(values, checked) {
    var aux;
    if (values === "all" && checked) {
      aux = {};
    } else {
      aux = tetosSelected;
    }

    checked
      ? (aux = { ...aux, [values]: "0" })
      : (aux = { ...aux, [values]: undefined });

    setTetosSelected(aux);
    props.onChange(aux);
  }

  const handleSelectChange = (name, value) => {
    setTetosSelected({ ...tetosSelected, [name]: value });
    props.onChange({ ...tetosSelected, [name]: value });
  };

  function handleSetDegreeSelected() {
    setDegreeSelected(!degreeSelected);
    // To remove degree ("1,2,3" to "0")
    for (var teto in tetosSelected) {
      if (tetosSelected[teto]) {
        tetosSelected[teto] = "0";
      }
    }
  }

  return (
    <div className="checkBoxTeto">
      {props.animal && (
        <div className="animal-name">
          <p>
            <b>{props.animal.Identificação}</b>
            {isNaN(calcAge(props.animal.Nascimento))
              ? ""
              : "(" + calcAge(props.animal.Nascimento) + " anos)"}
          </p>
        </div>
      )}

      <div
        className={
          "check-label-teto " +
          (props.errorAlignColumn ? "errorColumn" : "errorRow")
        }
      >
        {props.label && <p className="label">{props.label}: </p>}
        {props.error && <p className="erro">{props.error}</p>}
      </div>

      <div className="checkBoxTeto-content">
        <div className="checkBoxTeto-checks">
          <div className="checkbox-options" id="options-tetos">
            <div className="option">
              <CheckBox
                name="teto"
                checked={tetosSelected.all}
                onChange={(value, checked) => handleOptionClick(value, checked)}
                language="Todos os tetos"
                value="all"
              />
              {!props.noDegree && tetosSelected.all && degreeSelected && (
                <SmallSelect
                  onChange={(value) => handleSelectChange("all", value)}
                  options={props.cmt ? optionsCMT : optionsType}
                />
              )}
            </div>
            <div className="option">
              <CheckBox
                name="teto"
                checked={tetosSelected.leftFront || tetosSelected.all}
                onChange={(value, checked) => handleOptionClick(value, checked)}
                language="Teto frontal esquerdo"
                value="leftFront"
              />
              {!props.noDegree &&
                (tetosSelected.leftFront || tetosSelected.all) &&
                degreeSelected && (
                  <SmallSelect
                    onChange={(value) => handleSelectChange("leftFront", value)}
                    options={props.cmt ? optionsCMT : optionsType}
                  />
                )}
            </div>
            <div className="option">
              <CheckBox
                name="teto"
                checked={tetosSelected.rightFront || tetosSelected.all}
                onChange={(value, checked) => handleOptionClick(value, checked)}
                language="Teto frontal direito"
                value="rightFront"
              />
              {!props.noDegree &&
                (tetosSelected.rightFront || tetosSelected.all) &&
                degreeSelected && (
                  <SmallSelect
                    onChange={(value) =>
                      handleSelectChange("rightFront", value)
                    }
                    options={props.cmt ? optionsCMT : optionsType}
                  />
                )}
            </div>
            <div className="option">
              <CheckBox
                name="teto"
                checked={tetosSelected.leftBack || tetosSelected.all}
                onChange={(value, checked) => handleOptionClick(value, checked)}
                language="Teto traseiro esquerdo"
                value="leftBack"
              />
              {!props.noDegree &&
                (tetosSelected.leftBack || tetosSelected.all) &&
                degreeSelected && (
                  <SmallSelect
                    onChange={(value) => handleSelectChange("leftBack", value)}
                    options={props.cmt ? optionsCMT : optionsType}
                  />
                )}
            </div>
            <div className="option">
              <CheckBox
                name="teto"
                checked={tetosSelected.rightBack || tetosSelected.all}
                onChange={(value, checked) => handleOptionClick(value, checked)}
                language="Teto traseiro direito"
                value="rightBack"
              />
              {!props.noDegree &&
                (tetosSelected.rightBack || tetosSelected.all) &&
                degreeSelected && (
                  <SmallSelect
                    onChange={(value) => handleSelectChange("rightBack", value)}
                    options={props.cmt ? optionsCMT : optionsType}
                  />
                )}
            </div>
          </div>
        </div>
        {props.cow === true && (
          <div className="checkBoxTeto-cow">
            <div className="cow-body">
              <img
                src={bodyCow}
                alt="Ícone de animal"
                className="cow-body-icon"
              />
              <div className="tetos">
                <div className="tetos-column">
                  <span
                    className={
                      "teto " +
                      (tetosSelected.leftBack || tetosSelected.all
                        ? "selected"
                        : null)
                    }
                  />
                  <span
                    className={
                      "teto " +
                      (tetosSelected.rightBack || tetosSelected.all
                        ? "selected"
                        : null)
                    }
                  />
                </div>
                <div className="tetos-column">
                  <span
                    className={
                      "teto " +
                      (tetosSelected.leftFront || tetosSelected.all
                        ? "selected"
                        : null)
                    }
                  />
                  <span
                    className={
                      "teto " +
                      (tetosSelected.rightFront || tetosSelected.all
                        ? "selected"
                        : null)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="cow-header">
              <img
                src={animalImg()}
                alt="Ícone de animal"
                className="cow-header-icon"
              />
            </div>
          </div>
        )}
      </div>
      <div className="add-degree">
        {!props.noDegree &&
          (!degreeSelected ? (
            <Button
              color="white"
              onClick={() => setDegreeSelected(!degreeSelected)}
            >
              Adicionar grau
            </Button>
          ) : (
            <Button color="white" onClick={handleSetDegreeSelected}>
              Remover grau
            </Button>
          ))}
      </div>
    </div>
  );
}
