import axios from "axios";

export const api = axios.create({
  baseURL: "https://sdn6fibuzg.execute-api.us-east-2.amazonaws.com/dev",
});

export const newApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // baseURL: "http://localhost:3000/dev",
});
