
export function getMinMaxByProperty(arr, prop) {
  let min = parseFloat(arr[0][prop]);
  let max = parseFloat(arr[0][prop]);
  for (let i = 1; i < arr.length; i++) {
    if (parseFloat(arr[i][prop]) < min) {
      min = parseFloat(arr[i][prop]);
    }
    if (parseFloat(arr[i][prop]) > max) {
      max = parseFloat(arr[i][prop]);
    }
  }
  return { min, max };
}