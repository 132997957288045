import { useState } from "react";

import "./smallSelect.css";

function SmallSelect(props){
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [optionSelected, setOptionSelected] = useState("");

  function handleOptionClick(value){
    setOptionSelected(value);
    props.onChange(value);
  }

  return (
    <div className={"small-select-div " + props.className}>
      <div className="custom-select-wrapper" onClick={() => setIsSelectOpen(!isSelectOpen)}>
        <div className={"custom-select " + (isSelectOpen && "open")}>
          <div className="custom-select__trigger">
            <span>{ optionSelected === "" ? "-" : optionSelected }</span>
            <div className="arrow"></div>
          </div>

          { isSelectOpen && (
            <div className="custom-options">
              { props.options.map((option,index) => {
                  return(
                    <div key={index} className="option-wrapper" onClick={() => handleOptionClick(option.label)}>
                      <div className="border">
                        <p className="custom-option">{option.label}</p>
                      </div>
                    </div>
                  );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SmallSelect;