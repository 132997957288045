import { format } from "date-fns";

export function getSecagemData(data) {
  data.sort(function (a, b) {
    return (
      new Date(b.eventDate.slice(0, -1)).getTime() -
      new Date(a.eventDate.slice(0, -1)).getTime()
    );
  });

  return data.map((item) => {
    return {
      Ordenha: format(new Date(item.eventDate.slice(0, -1)), "dd/MM/yyyy"),
      Medicamento: item.medicine ?? "-",
      Aplicação: item.expectedDate
        ? format(new Date(item.expectedDate.slice(0, -1)), "dd/MM/yyyy")
        : "-",
      Carência: item.medicineDate
        ? format(new Date(item.medicineDate.slice(0, -1)), "dd/MM/yyyy")
        : "-",
      CMT: item.tetos ? "Positivo" : "Negativo",
      CCS: item.ccs ? item.ccs + " cel/ml" : "-",
    };
  });
}
