import { useState } from "react";
import { useHistory } from "react-router-dom";

import { useUserContext } from "../../contexts/userContext";
import useForm from "../../hooks/useForm";

import logo from "../../assets/images/logo-small-1.png";

import Button from "../../components/button/button";
import Input from "../../components/input/input";

import "./emailVerification.css";

import confirmRegister from "../../services/userForm/confirmRegister";
import resendConfirmationCode from "../../services/userForm/resendConfirmationCode";

function EmailVerificationPage() {
  const history = useHistory();

  const { values, errors, handleChange, handleSubmit, handleSetErrors } =
    useForm();
  const { user } = useUserContext();

  const [isLoading, setIsLoading] = useState(false);
  const [resendIsLoading, setResendIsLoading] = useState(false);

  async function checkValues() {
    const error = {};

    if (!values.code) {
      error.code = "Este campo é obrigatório";
    } else if (errors.code) {
      delete error.code;
    }

    return error;
  }

  async function valuesAreCorrect() {
    try {
      setIsLoading(true);
      const data = {
        email: user.email,
        code: values.code,
      };
      await confirmRegister(data);

      if (user.id) history.push("/register/additional?id=" + user.id);
      else history.push("/");
    } catch (err) {
      setIsLoading(false);
      handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
    }
  }

  async function resendCode() {
    setResendIsLoading(true);
    await resendConfirmationCode({ email: user.email });
    setResendIsLoading(false);
  }

  return (
    <div className="email-verification">
      <div className="white-part">
        <div>
          <h1 className="title">Verificação</h1>
          <p className="text">
            Você receberá um e-mail com o código de verificação
          </p>
          <form
            onSubmit={handleSubmit(checkValues, valuesAreCorrect, isLoading)}
          >
            <Input
              id="code"
              label="Código"
              placeholder="Insira seu código"
              className="email-verification-input"
              type="text"
              error={errors.code}
              value={values.code}
              onChange={handleChange}
              mask="999999"
            />

            {errors.api && <p className="api-error">{errors.api}</p>}

            <div className="button-row">
              <Button
                color="white"
                onClick={resendCode}
                isLoading={resendIsLoading}
              >
                Enviar novamente
              </Button>
              <Button color="blue" type="submit" isLoading={isLoading}>
                Continuar
              </Button>
            </div>
          </form>
        </div>
      </div>

      <div className="blue-part">
        <div className="mask">
          <div>
            <img className="logo" src={logo} alt="logo" />
            <p className="minor-text">
              Organize e gerencie os eventos dos seus animais
            </p>
            <p className="minor-text">
              Ficou mais fácil organizar e gerenciar os eventos dos animais da
              fazenda
            </p>
            <p className="minor-text">
              Eficiência para organizar e gerenciar os eventos dos seus animais
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerificationPage;
