import { format, fromUnixTime } from "date-fns";

export function dateToApiDate(date) {
  // date = dd/mm/yyyy
  const date_split = date.split("/");
  return date_split[2] + "-" + date_split[1] + "-" + date_split[0]; // yyyy-mm-dd
}

export function timestampToApiDate(timestamp) {
  // date = dd/mm/yyyy
  const date = format(fromUnixTime(timestamp), "dd/MM/yyyy");
  const date_split = date.split("/");
  return date_split[2] + "-" + date_split[1] + "-" + date_split[0]; // yyyy-mm-dd
}

export function apiDateToFormatedDate(date) {
  return format(new Date(date.slice(0, -1)), "dd/MM/yyyy");
}
