import { useState } from "react";

import { useAlertContext } from "../../../../../contexts/alertContext";

import "./ModalHightCCS.css";

import Button from "../../../../../components/button/button";
import Modal from "../../../../../components/modal/modal";
import Table from "../../../../../components/table/table";

const columns = ["Identificação", "Lote", "Valor_de_CCS"];

// modalName = hightCCS
export default function ModalHightCCS() {
  const { modalProps, handleModalShow, modalRememberLater, modalUnderstand } =
    useAlertContext();
  const { animals } = modalProps;

  const [isLoading, setIsLoading] = useState(false);

  async function handleClickUnderstand() {
    setIsLoading(true);
    try {
      await modalUnderstand("hightCCS");
    } catch (e) {
      setIsLoading(false);
      console.log("Error");
    }
  }

  return (
    <Modal showModal={handleModalShow} close={true}>
      <div className="ccs-modal-content">
        <p className="title">Animal com valor de CCS alta</p>
        <p className="text">
          Esses são os animais e os seus respectivos valores de CCS.
        </p>

        <div className="table_wrapper">
          <div
            className="table_wrapper_container"
            style={{ minWidth: columns.length * 180 + "px" }}
          >
            <Table
              animals={animals}
              columns={columns}
              ccs
              onChangeAnimal={() => {}}
            />
          </div>
        </div>

        <div className="modal-div-buttons">
          <div />
          <Button
            color="white"
            className="modal-button"
            onClick={modalRememberLater}
          >
            Lembrar-me Depois
          </Button>
          <Button
            color="blue"
            className="modal-button"
            isLoading={isLoading}
            onClick={handleClickUnderstand}
          >
            Apagar alerta
          </Button>
        </div>
      </div>
    </Modal>
  );
}
