import { addDays, format } from "date-fns";
import xl from "excel4node";
import { useState } from "react";

import {
  dateToTimestamp,
  excelDateToString,
  rewriteDate,
} from "../utils/dateToTimestamp";
import { isDateValid, isPastDate } from "../utils/isDateValid";
import { handlePredictedShortageData } from "../utils/predictData";

import { registerAnimal } from "../services/animals/registerAnimal";
import { registerBirth } from "../services/events/birth";
import { registerDrying } from "../services/events/drying";
import { registerCCS } from "../services/events/individualCCS";
import { updateAnimalLot } from "../services/events/lot";
import { registerMastitis } from "../services/events/mastitis";
import { registerCulture } from "../services/events/microbiologicalCulture";
import { registerMilkProduction } from "../services/events/milkproduction";
import { registerReproduction } from "../services/events/reproduction";
import { registerCMT } from "../services/events/testCMT";
import { registerLot } from "../services/lot/registerLot";
import { dateToApiDate } from "../utils/dateToApiDate";
import { separar } from "../utils/separarArray";

const bacteria_dictionary = [
  { "Escherichia coli": "<i>Escherichia coli</i>" },
  { "Klebsiella spp": "<i>Klebsiella</i> spp" },
  { "Enterobacter spp": "<i>Enterobacter</i> spp" },
  { "Pseudomonas spp": "<i>Pseudomonas</i> spp" },
  { "Prototheca spp": "<i>Prototheca</i> spp" },
  { Levedura: "Levedura" },
  { "Streptococcus dysgalactiae": "<i>Streptococcus dysgalactiae</i>" },
  { "Streptococcus agalactiae": "<i>Streptococcus agalactiae</i>" },
  { "Streptococcus uberis": "<i>Streptococcus uberis</i>" },
  { "Enterococcus spp": "<i>Enterococcus</i> spp" },
  { "Lactococcus spp": "<i>Lactococcus</i> spp" },
  { "Aerococcus viridans": "<i>Aerococcus viridans</i>" },
  { "Staphylococcus haemoliyticus": "<i>Staphylococcus haemoliyticus</i>" },
  {
    "Staphylococcus não aureus (SCN – Staphylococcus coagulase negativa)":
      "<i>Staphylococcus</i> não <i>aureus</i> (SCN – <i>Staphylococcus</i> coagulase negativa)",
  },
  { "Outras Gram-positivas": "Outras Gram-positivas" },
];
const bacteria_list = [
  "Escherichia coli",
  "Klebsiella spp",
  "Enterobacter spp",
  "Pseudomonas spp",
  "Prototheca spp",
  "Levedura",
  "Streptococcus dysgalactiae",
  "Streptococcus agalactiae",
  "Streptococcus uberis",
  "Enterococcus spp",
  "Lactococcus spp",
  "Aerococcus viridans",
  "Staphylococcus haemoliyticus",
  "Staphylococcus não aureus (SCN – Staphylococcus coagulase negativa)",
  "Outras Gram-positivas",
];

const secagemDrugs = [
  { label: "AMOCLOX S", period: 60 },
  { label: "ANAMASTIT S", period: 30 },
  { label: "BIOMAST VS", period: 45 },
  { label: "CEPRAVIN", period: 60 },
  { label: "CIPROLAC VACA SECA", period: 59 },
  { label: "CURACLOX VACA SECA", period: 49 },
  // { label: "EMEMAST VS", period: 32 },
  { label: "GENTATEC VACA SECA", period: 40 },
  { label: "MAMYZIM", period: 37 },
  { label: "MASTIFIN VACA SECA", period: 30 },
  { label: "MASTIJET VACA SECA", period: 35, alert: 1 },
  { label: "MASTIZONE VS", period: 60 },
  { label: "NEOMASTIC", period: 30, alert: 3 },
  { label: "NOROCLOX VACA SECA", period: 35, alert: 4 },
  { label: "ORBENIN EXTRA DRY COW", period: 42 },
  { label: "RILEXINA 500", period: 60 },
  { label: "VASECLOX VACA SECA", period: 30, alert: 3 },
  { label: "VETIMAST PLUS VS", period: 60 },
];
const secagemDrugsOptions = secagemDrugs.map((drug) => drug.label);

const intramamario_drugs = [
  { label: "AGROMASTIT", period: 4 },
  { label: "BIOMAST", period: 3.5 },
  { label: "BOVIGAN L", period: 3 },
  { label: "CEFAVET", period: 3.5 },
  { label: "CIPROLAC", period: 2 },
  { label: "COBACTAN VL", period: 2.5 },
  { label: "GENTATEC", period: 4 },
  { label: "INTRAMAST", period: 3 },
  { label: "MASTCLIN", period: 2 },
  { label: "MASTIBEST L", period: 4 },
  { label: "MASTICAL", period: 3 },
  { label: "MASTICEL", period: 3.5 },
  { label: "MASTICINE L", period: 4 },
  { label: "MASTICLOX L", period: 4 },
  { label: "MASTIFIN", period: 4 },
  { label: "MASTIJET FORTE", period: 9 },
  { label: "MASTIPLAN LC", period: 5 },
  { label: "MASTIPLUS", period: 9 },
  { label: "MASTITE CLINICA VL", period: 2.5 },
  { label: "MASTIZONE", period: 3.5 },
  { label: "MASTIZONE PLUS LACTAÇÃO", period: 4 },
  { label: "NEWMAST", period: 6 },
  { label: "PROMASTIC", period: 3 },
  { label: "QUALLYXINE", period: 3.5 },
  { label: "RILEXINE", period: 4 },
  { label: "SILMAST", period: 3 },
  { label: "SPECTRAMAST LC", period: 3 },
  { label: "SUPRONAL", period: 1.5 },
  { label: "TOPMAST", period: 4 },
  { label: "UBERLAC", period: 3.5 },
  { label: "UBRECILIN", period: 3 },
  { label: "UBROLEXIN", period: 5 },
  { label: "VASECLOX MA", period: 4.5 },
  { label: "VETIMAST", period: 4 },
];
const intramamarioDrugsOptions = intramamario_drugs.map((drug) => drug.label);

const sistemico_drugs = [
  { label: "AGROVET PLUS", period: 3 },
  { label: "BORGAL", period: 2 },
  { label: "CALBIÓTICO", period: 5 },
  { label: "CLAMOXYL", period: 4 },
  { label: "CLAVACILIN", period: 1 },
  { label: "DIASTIN", period: 7 },
  { label: "DIAZIL", period: 13 },
  { label: "ENRO FLEX INJETÁVEL", period: 3 },
  { label: "ENROFLOXACINA 10%", period: 3 },
  { label: "ESTREPTOMAX", period: 1 },
  { label: "ESTREPTOMICINA BIOFARM", period: 3 },
  { label: "FLOBIOTIC", period: 3 },
  { label: "FLOXICLIN", period: 3 },
  { label: "FLUMAST", period: 4 },
  { label: "FORCYL", period: 2 },
  { label: "FORTECILIN PLUS", period: 5 },
  { label: "FORTGAL PLUS", period: 1 },
  { label: "GENTAMOX", period: 3 },
  { label: "GENTATEC", period: 4 },
  { label: "GENTOMICIN", period: 4 },
  { label: "IBATRIM", period: 7 },
  { label: "KINETOMAX", period: 5 },
  { label: "MEGACILIN PPU PLUS", period: 5 },
  { label: "MEGACILIN SUPER PLUS", period: 5 },
  { label: "MOGIPEN", period: 5 },
  { label: "NORODINE 24", period: 4 },
  { label: "OUROTETRA PLUS LA", period: 7 },
  { label: "PANGRAM 10%", period: 1.5 },
  { label: "PENCIMAX", period: 5 },
  { label: "PENFORT PPU", period: 18 },
  { label: "PRONTO PEN", period: 3 },
  { label: "PROPEN", period: 3 },
  { label: "PULMODAZIN PLUS", period: 3 },
  { label: "PULMODAZIN REFORÇADO", period: 3 },
  { label: "SEPTIPEN", period: 5 },
  { label: "SULFATRIM", period: 3 },
  { label: "SUPRA PEN", period: 4 },
  { label: "TERRAMAX 20 LA", period: 4 },
  { label: "TERRAMICINA LA", period: 4 },
  { label: "TETRABAC LA", period: 8 },
  { label: "TRIAZOCLIN", period: 3 },
  { label: "TRIBRISSEN", period: 3 },
  { label: "TRISSULFIN", period: 5 },
  { label: "TRISSULMAX", period: 11 },
  { label: "TYLADEN", period: 4 },
  { label: "TYLAN 200", period: 3 },
  { label: "VETSULFA", period: 5 },
  { label: "ZELOTRIL 10%", period: 3 },
  { label: "ZELOTRIL PLUS", period: 3 },
];
const sistemicoDrugsOptions = sistemico_drugs.map((drug) => drug.label);

const maxNumberOfLines = 400;

function useSpreadsheet(
  eventName,
  farmSelected,
  animalsId,
  farmAnimals,
  lots,
  races,
  categories
) {
  const [animals, setAnimals] = useState([]);
  const [invalidAnimals, setInvalidAnimals] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessingData, setIsProcessingData] = useState(false);

  async function saveSpreadsheet() {
    setIsLoading(true);
    try {
      if (eventName === "Cadastro") {
        const data = animals.map((animal) => {
          const birthday_array = animal.data_nascimento.split("/");
          const auxData = {
            farmId: animal.fazenda_id,
            category: animal.categoria,
            identification: animal.numero_animal,
            breed: animal.raca ?? null,
            birthday:
              birthday_array[2] +
              "-" +
              birthday_array[1] +
              "-" +
              birthday_array[0], // yyyy-mm-dd
          };
          return auxData;
        });
        await Promise.all(
          separar(data, maxNumberOfLines).map(async (arr) => {
            await registerAnimal(arr);
          })
        );
      } else if (eventName === "Reprodução") {
        const data = animals.map((animal) => {
          return {
            animalId: animal.animal_id,
            farmId: animal.fazenda_id,
            eventDate: dateToApiDate(animal.data_cobricao),
            specieName: farmSelected.apiSpecie,
            // data_nascimento: animal.data_nascimento,
          };
        });
        await Promise.all(
          separar(data, maxNumberOfLines).map(async (arr) => {
            await registerReproduction(arr);
          })
        );
      } else if (eventName === "Parto") {
        const data = animals.map((animal) => {
          return {
            animalId: animal.animal_id,
            farmId: animal.fazenda_id,
            eventDate: dateToApiDate(animal.data_birth),
            specieName: farmSelected.apiSpecie,
          };
        });
        await Promise.all(
          separar(data, maxNumberOfLines).map(async (arr) => {
            await registerBirth(arr);
          })
        );
      } else if (eventName === "Produção de Leite") {
        const data = animals.map((animal) => {
          return {
            animalId: animal.animal_id,
            farmId: animal.fazenda_id,
            eventDate: dateToApiDate(animal.register_data),
            dayVolume: animal.volume_dia,
            // volume_mes: animal.volume_mes,
          };
        });
        await Promise.all(
          separar(data, maxNumberOfLines).map(async (arr) => {
            await registerMilkProduction(arr);
          })
        );
      } else if (eventName === "Teste CMT") {
        const data = animals.map((animal) => {
          return {
            animalId: animal.animal_id,
            farmId: animal.fazenda_id,
            eventDate: dateToApiDate(animal.data_teste),
            tetos: animal.tetos,
          };
        });
        await Promise.all(
          separar(data, maxNumberOfLines).map(async (arr) => {
            await registerCMT(arr);
          })
        );
      } else if (eventName === "CCS Individual") {
        const data = animals.map((animal) => {
          return {
            animalId: animal.animal_id,
            farmId: animal.fazenda_id,
            ccs: animal.ccs,
            eventDate: dateToApiDate(animal.data_register),
            specieName: farmSelected.apiSpecie,
          };
        });
        await Promise.all(
          separar(data, maxNumberOfLines).map(async (arr) => {
            await registerCCS(arr);
          })
        );
      } else if (eventName === "Mastite Clínica") {
        const data = animals.map((animal) => {
          const aux = {
            animalId: animal.animal_id,
            farmId: animal.fazenda_id,
            // tipo: animal.tipo,
            tetos: animal.tetos,
            eventDate: dateToApiDate(animal.Data),
          };
          // if (animal.periodo_ordenha)
          //   aux["periodo_ordenha"] = animal.periodo_ordenha;
          if (animal.data_tratamento) {
            aux["treatmentDate"] = dateToApiDate(animal.data_tratamento_date);
            aux["treatmentType"] = animal.tipo_tratamento;
            aux["medicine"] = animal.medicamento;
            aux["lack"] = animal.carencia;
          }

          return aux;
        });
        await Promise.all(
          separar(data, maxNumberOfLines).map(async (arr) => {
            await registerMastitis(arr);
          })
        );
      } else if (eventName === "Secagem") {
        const data = animals.map((animal) => {
          const aux = {
            animalId: animal.animal_id,
            farmId: animal.fazenda_id,
            eventDate: dateToApiDate(animal.last_date),
            expectedDate: dateToApiDate(animal.last_date),
            medicine: animal.medicamento,
            medicineDate: dateToApiDate(animal.shortageDate),
            // procedimento: animal.procedimento,
          };
          if (animal.ccs) aux["ccs"] = animal.ccs;
          if (animal.tetos) aux["tetos"] = animal.tetos;
          if (animal.alert) aux["medicineAlert"] = animal.alert;

          return aux;
        });
        await Promise.all(
          separar(data, maxNumberOfLines).map(async (arr) => {
            await registerDrying(arr);
          })
        );
      } else if (eventName === "Cultura Microbiológica") {
        const data = animals.map((animal) => {
          const aux = {
            animalId: animal.animal_id,
            farmId: animal.fazenda_id,
            eventDate: dateToApiDate(animal.data_teste),
            tetos: animal.tetos,
          };
          if (animal.bacteria) aux["bacterium"] = animal.bacteria;
          return aux;
        });
        await Promise.all(
          separar(data, maxNumberOfLines).map(async (arr) => {
            await registerCulture(arr);
          })
        );
      } else if (eventName === "Lote") {
        const new_lots = animals
          .filter((animal) => !animal.lote_id)
          .map((animal) => animal.lote_num_letras);
        const unique_new_lots = new_lots.filter(
          (este, i) => new_lots.indexOf(este) === i
        );
        // const new_registered_lots = await Promise.all(
        //   unique_new_lots.map(async (lot) => {
        //     const lot_data = {
        //       codLote: lot,
        //       fazenda_id: farmSelected.farm_id,
        //     };
        //     const result = await registerLot(lot_data);
        //     return {
        //       lote_id: result.id,
        //       lote_num_letras: lot,
        //     };
        //   })
        // );

        const resgiterLotResponse = await registerLot(
          unique_new_lots,
          farmSelected.farm_id
        );
        const new_registered_lots = resgiterLotResponse.lots;
        const data = animals.map((animal) => {
          if (!animal.lote_id) {
            animal.lote_id = new_registered_lots.filter(
              (lot) => lot.code === animal.lote_num_letras
            )[0].id;
          }
          return {
            farmId: farmSelected.farm_id,
            animalId: animal.animal_id,
            lotId: animal.lote_id,
            lotCode: animal.lote_num_letras,
          };
        });
        await Promise.all(
          separar(data, maxNumberOfLines).map(async (arr) => {
            await updateAnimalLot(arr);
          })
        );
      }
    } catch (e) {
      setIsLoading(false);
    }
  }

  async function handleCreateSpreadsheet() {
    setIsLoading(true);
    const wb = new xl.Workbook({
      defaultFont: {
        size: 10,
        name: "Roboto",
        color: "#09202d",
      },
      author: "Milk Care",
    });

    const ws = wb.addWorksheet(eventName);
    if (
      ["Secagem", "Mastite Clínica", "Cultura Microbiológica", "Lote"].includes(
        eventName
      )
    ) {
      var lists = wb.addWorksheet("Listas");
    }

    const styleDefaultHeading = wb.createStyle({
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      font: {
        size: 16,
        bold: true,
        name: "Nunito",
        color: "#ffffff",
      },
      fill: {
        type: "pattern",
        patternType: "solid",
        bgColor: "#05a6fc",
        fgColor: "#05a6fc",
      },
    });

    const styleHeading = wb.createStyle({
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
      font: {
        size: 14,
        bold: true,
        name: "Nunito",
      },
      border: {
        bottom: {
          style: "medium",
          color: "#05a6fc",
        },
      },
      fill: {
        type: "pattern",
        patternType: "solid",
        bgColor: "#dedede",
        fgColor: "#dedede",
      },
    });

    const styleCell = wb.createStyle({
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
    });

    const styleCellPreenchida = wb.createStyle({
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
      font: {
        color: "#FFFFFF",
      },
      border: {
        left: {
          style: "thin",
          color: "#FFFFFF",
        },
        right: {
          style: "thin",
          color: "#FFFFFF",
        },
        bottom: {
          style: "thin",
          color: "#FFFFFF",
        },
      },
      fill: {
        type: "pattern",
        patternType: "solid",
        bgColor: "#165578",
        fgColor: "#165578",
      },
    });

    // Default MU HEADING
    ws.row(1).setHeight(50).freeze();
    ws.cell(1, 1, 1, 20, true)
      .string(
        "ATENÇÃO! Nenhuma linha ou coluna deve ser excluida ou inserida, nem nenhuma informação presente nas células com o fundo marcado deve ser alterada."
      )
      .style(styleDefaultHeading);

    let generateAux = {
      headingColumns: [],
      headingRowIndex: 0,
      animalsColumns: [],
    };
    if (eventName === "Cadastro") {
      generateAux = generateRegisterSheet(ws, styleCell);
    } else if (eventName === "Reprodução") {
      generateAux = generateReproductionSheet(ws, styleCell);
    } else if (eventName === "Parto") {
      generateAux = generateBirthSheet(ws, styleCell);
    } else if (eventName === "Produção de Leite") {
      generateAux = generateMilkProductionSheet(ws, styleCell);
    } else if (eventName === "Teste CMT") {
      generateAux = generateTestCMTSheet(ws, styleCell, styleHeading);
    } else if (eventName === "CCS Individual") {
      generateAux = generateIndividualCCSSheet(ws, styleCell);
    } else if (eventName === "Mastite Clínica") {
      generateAux = generateMastitisSheet(ws, lists, styleCell, styleHeading);
    } else if (eventName === "Secagem") {
      generateAux = dryingSheet(ws, lists, styleCell, styleHeading);
    } else if (eventName === "Cultura Microbiológica") {
      generateAux = generateMicrobiologicalCultureSheet(
        ws,
        lists,
        styleCell,
        styleHeading
      );
    } else if (eventName === "Lote") {
      generateAux = generateLotSheet(ws, lists, styleCell, styleHeading);
    } else return;

    let headingIndex = 1;
    generateAux.headingColumns.forEach((heading) => {
      ws.cell(generateAux.headingRowIndex, headingIndex++)
        .string(heading)
        .style(styleHeading);
    });

    if (eventName !== "Cadastro") {
      let rowIndex = generateAux.headingRowIndex + 1;
      farmAnimals.forEach((record) => {
        let columnIndex = 1;
        generateAux.animalsColumns.forEach((columnName) => {
          ws.cell(rowIndex, columnIndex++)
            .string(record[columnName]?.toString())
            .style(styleCellPreenchida);
        });
        rowIndex++;
      });
    }

    // if (eventName === "Parto") {
    //   let rowIndex = generateAux.headingRowIndex + 1;
    //   await Promise.all(
    //     farmAnimals.map(async (record) => {
    //       if (record.reproducao_id) {
    //         const value = await dataPrevista(record.reproducao_id);
    //         const data_nascimento = format(
    //           fromUnixTime(value.data_nascimento),
    //           "dd/MM/yyyy"
    //         );
    //         ws.cell(rowIndex, 5)
    //           .string(data_nascimento)
    //           .style(styleCellPreenchida);
    //       } else {
    //         ws.cell(rowIndex, 5).string("-").style(styleCellPreenchida);
    //       }
    //       rowIndex++;
    //     })
    //   );
    // }

    const uint8Array = await wb.writeToBuffer();
    const buffer = uint8Array.buffer.slice(
      uint8Array.byteOffset,
      uint8Array.byteLength + uint8Array.byteOffset
    );

    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = eventName;
    link.click();
    setIsLoading(false);
  }

  async function handleData(result) {
    try {
      setIsProcessingData(true);
      const sheet_data = await processData(result.data || result);
      setAnimals(sheet_data.animals);
      setInvalidAnimals(sheet_data.invalid);
      setIsProcessingData(false);
    } catch (e) {
      setIsProcessingData(false);
    }
  }

  async function processData(result) {
    if (eventName === "Cadastro") {
      setColumns(["Identificação", "Nascimento", "Categoria", "Raça"]);
      const data = await handleDataPattern(result, handleRegisterData, 1, true);
      return { animals: data.animalsData, invalid: data.invalidAnimals };
    } else if (eventName === "Reprodução") {
      setColumns([
        "Identificação",
        "Lote",
        "Data_inseminação",
        "Data_prevista_do_parto",
      ]);
      const data = await handleDataPattern(result, handleReproductionData);
      return { animals: data.animalsData, invalid: data.invalidAnimals };
    } else if (eventName === "Parto") {
      setColumns(["Identificação", "Raça", "Lote", "Data_do_parto"]);
      const data = await handleDataPattern(result, handleBirthData);
      return { animals: data.animalsData, invalid: data.invalidAnimals };
    } else if (eventName === "Produção de Leite") {
      setColumns([
        "Identificação",
        "Lote",
        "Dia",
        "Volume_total_no_dia",
        "Volume_estimado_no_mês",
      ]);
      const data = await handleDataPattern(result, handleMilkProductionData); //, 2
      return { animals: data.animalsData, invalid: data.invalidAnimals };
    } else if (eventName === "Teste CMT") {
      const { animalsData, titsColumns, invalidAnimals } =
        await handleDataPattern(result, handleTestCMTData, 3);
      setColumns(["Identificação", "Lote", "Data_do_teste", ...titsColumns]);
      return { animals: animalsData, invalid: invalidAnimals };
    } else if (eventName === "CCS Individual") {
      setColumns(["Identificação", "Raça", "Lote", "Data", "Resultado"]);
      const data = await handleDataPattern(result, handleIndividualCCSData);
      return { animals: data.animalsData, invalid: data.invalidAnimals };
    } else if (eventName === "Mastite Clínica") {
      setColumns(["Identificação", "Lote", "Tipo_da_mastite", "Data"]);
      const data = await handleDataPattern(result, handleMastiteData, 3);
      return { animals: data.animalsData, invalid: data.invalidAnimals };
    } else if (eventName === "Secagem") {
      setColumns([
        "Identificação",
        "Lote",
        "Data_última_ordenha",
        "Data_carência",
        "Análise_da_mastite",
      ]);
      const data = await handleDataPattern(result, handleDryingData, 4);
      return { animals: data.animalsData, invalid: data.invalidAnimals };
    } else if (eventName === "Cultura Microbiológica") {
      const { animalsData, titsColumns, invalidAnimals } =
        await handleDataPattern(result, handleMicrobiologicalCutureData, 3);
      setColumns(["Identificação", "Lote", "Data_do_teste", ...titsColumns]);
      return { animals: animalsData, invalid: invalidAnimals };
    } else if (eventName === "Lote") {
      setColumns(["Identificação", "Novo_lote"]);
      const data = await handleDataPattern(result, handleLotData, 3);
      return { animals: data.animalsData, invalid: data.invalidAnimals };
    }
  }

  const handleDataPattern = async (
    data,
    handleSpecificData,
    ignoreFirstRows = 2,
    isRegister = false
  ) => {
    const animalsData = [],
      titsColumnsAux = [],
      invalidAnimals = [];
    await Promise.all(
      data.map(async (row, index) => {
        if (
          index < ignoreFirstRows &&
          (isRegister || !animalsId.includes(row[0]))
        )
          return;

        const { hasAllData, specificData, validData, titsColumns } =
          await handleSpecificData(row);

        if (!hasAllData) return;
        if (!validData) {
          invalidAnimals.push(row[1]);
          return;
        }

        if (titsColumns) {
          titsColumns.forEach(
            (column) =>
              !titsColumnsAux.includes(column) && titsColumnsAux.push(column)
          );
        }

        const auxData = isRegister
          ? specificData
          : {
              // Valores fixos [Id, Nome/Numero, Raça, Lote, ...]
              fazenda_id: farmSelected.farm_id,
              animal_id: row[0],
              Identificação: row[1],
              Raça: row[2],
              Lote: row[3],
              ...specificData,
            };
        animalsData.push(auxData);
      })
    );
    return { animalsData, titsColumns: titsColumnsAux, invalidAnimals };
  };

  // Cadastro de animal
  const handleRegisterData = async (row) => {
    const numero_animal = row[0];
    const categoria = row[2];
    const raca = row[3];

    if (!numero_animal || !categoria || !raca) return { hasAllData: false };

    const date_aux = rewriteDate(row[1]);

    if (
      !isDateValid(date_aux) ||
      !isPastDate(date_aux) ||
      !races.includes(raca) ||
      !categories.includes(categoria)
    )
      return { hasAllData: true, validData: false };

    const specificData = {
      fazenda_id: farmSelected.farm_id,
      categoria: categoria,
      Categoria: categoria,
      data_nascimento: isDateValid(date_aux) ? date_aux : undefined,
      Nascimento: date_aux,
      numero_animal: numero_animal.toString(),
      Identificação: numero_animal.toString(),
      raca: raca,
      Raça: raca,
    };

    return { hasAllData: true, specificData, validData: true };
  };
  const generateRegisterSheet = (ws, styleCell) => {
    const numberOfRows = 10000;
    const headingRowIndex = 2;
    const headingColumns = [
      "Nome/Número do animal",
      "Data de nascimento (dd/mm/aaaa)",
      "Categoria",
      "Raça",
    ];

    ws.row(2).freeze();
    ws.column(1).setWidth(40);
    ws.column(2).setWidth(50);
    ws.column(3).setWidth(20);
    ws.column(4).setWidth(20);

    ws.addDataValidation({
      type: "list",
      allowBlank: false,
      sqref: "C3:C" + numberOfRows,
      showDropDown: true,
      error: "Valor inválido, selecione uma das opções",
      formulas: [categories],
    });
    ws.addDataValidation({
      type: "list",
      allowBlank: false,
      sqref: "D3:D" + numberOfRows,
      showDropDown: true,
      error: "Valor inválido, selecione uma das opções",
      formulas: [races],
    });

    for (let rowIndex = 3; rowIndex < numberOfRows; rowIndex++) {
      [1, 2, 3, 4].forEach((number) => {
        ws.cell(rowIndex, number).style(styleCell);
      });
    }

    return { headingColumns, headingRowIndex };
  };

  // Parto
  const handleBirthData = async (row) => {
    const data_birth = rewriteDate(row[4]);

    if (!data_birth) return { hasAllData: false };

    // const reproducao_id = farmAnimals.filter((a) => a.id === row[0])[0]
    //   .reproducao_id;

    if (!isDateValid(data_birth) || !isPastDate(data_birth))
      return { hasAllData: true, validData: false };

    const specificData = {
      Data_do_parto: data_birth,
      data_parto: dateToTimestamp(data_birth),
      data_birth,
      // reproducao_id: reproducao_id,
    };

    return { hasAllData: true, specificData, validData: true };
  };
  const generateBirthSheet = (ws, styleCell) => {
    const headingRowIndex = 2;
    const headingColumns = [
      "Id",
      "Nome/Número do animal",
      "Raça",
      "Lote",
      "Data do parto (dd/mm/aaaa)",
    ];
    const animalsColumns = ["id", "Identificação", "Raça", "Lote"];

    ws.column(1).hide();
    ws.row(2).freeze();
    ws.column(2).setWidth(40);
    ws.column(3).setWidth(20);
    ws.column(4).setWidth(20);
    ws.column(5).setWidth(40);
    let rowIndex = headingRowIndex + 1;
    farmAnimals.forEach(() => {
      ws.cell(rowIndex, 5).string("").style(styleCell);
      rowIndex++;
    });

    return { headingColumns, animalsColumns, headingRowIndex };
  };

  // Secagem
  const handleDryingData = async (row) => {
    const last_date = rewriteDate(row[4]);

    if (!last_date) return { hasAllData: false };

    // const parto_id = farmAnimals.filter((a) => a.id === row[0])[0].parto_id;
    // const response = await getPartoById(parto_id);

    if (
      !isDateValid(last_date) ||
      !isPastDate(last_date)
      // || isPastDate(last_date, response.data_parto)
    )
      return { hasAllData: true, validData: false };

    const used_drug = row[5];
    const analise = row[6];

    const levels = ["1", "2", "3", "4", "x", "m"];
    const tfe = row[8] ? row[8].toString() : undefined;
    const tfd = row[9] ? row[9].toString() : undefined;
    const tte = row[10] ? row[10].toString() : undefined;
    const ttd = row[11] ? row[11].toString() : undefined;
    const tetos = {};
    if (levels.includes(tfe)) tetos["leftFront"] = tfe;
    if (levels.includes(tfd)) tetos["rightFront"] = tfd;
    if (levels.includes(tte)) tetos["leftBack"] = tte;
    if (levels.includes(ttd)) tetos["rightBack"] = ttd;

    const analiseCMTeCCS =
      analise === "CMT e CCS" && row[7] && Object.keys(tetos).length > 0;
    const analiseCMT =
      analise === "CMT" ? Object.keys(tetos).length > 0 : analiseCMTeCCS;
    const analiseCSS = analise === "CCS individual" ? !!row[7] : analiseCMT;

    if (
      !(used_drug && secagemDrugsOptions.includes(used_drug)) ||
      // !(analise && ["CCS individual", "CMT", "CMT e CCS"].includes(analise)) ||
      (analise && !(analiseCMTeCCS || analiseCMT || analiseCSS))
    )
      return { hasAllData: false };

    if (
      ["CCS individual", "CMT e CCS"].includes(analise) &&
      isNaN(parseInt(row[7]))
    )
      return { hasAllData: true, validData: false };

    const drug = secagemDrugs.find((d) => d.label === used_drug);
    const shortageDate = handlePredictedShortageData(last_date, drug.period);

    const specificData = {
      Data_última_ordenha: last_date,
      Data_carência: shortageDate,
      Análise_da_mastite: analise,
      data: dateToTimestamp(last_date),
      last_date: last_date,
      data_prevista: dateToTimestamp(last_date),
      medicamento: used_drug,
      data_medicamento: dateToTimestamp(shortageDate),
      shortageDate: shortageDate,
      procedimento: analise,
    };
    if (analise === "CCS individual") {
      specificData["ccs"] = parseInt(row[7]);
    } else if (analise === "CMT") {
      specificData["tetos"] = tetos;
    } else if (analise === "CMT e CCS") {
      specificData["ccs"] = parseInt(row[7]);
      specificData["tetos"] = tetos;
    }
    if (drug.alert) specificData["alert"] = drug.alert;

    return { hasAllData: true, specificData, validData: true };
  };
  const dryingSheet = (ws, lists, styleCell, styleHeading) => {
    const headingRowIndex = 4;
    const headingColumns = [
      "Id",
      "Nome/Número do animal",
      "Raça",
      "Lote",
      "Data da última ordenha",
      "Medicamento usado",
      "Análise da mastite subclínica",
      "Valor CCS",
      "Teto frontal esquerdo",
      "Teto frontal direito",
      "Teto traseiro esquerdo",
      "Teto traseiro direito",
    ];
    const animalsColumns = ["id", "Identificação", "Raça", "Lote"];

    ws.cell(2, 8).string("CCS individual").style(styleHeading);
    ws.cell(2, 9, 2, 12, true).string("CMT").style(styleHeading);
    ws.cell(3, 9, 3, 12, true)
      .string("Tetos (Grau: 1, 2, 3, 4, x, m)")
      .style(styleHeading);
    ws.cell(2, 1).string("#");
    ws.cell(3, 1).string("#");
    ws.row(2).freeze();
    ws.row(3).freeze();
    ws.row(4).freeze();
    ws.column(1).hide();
    ws.column(2).setWidth(40);
    ws.column(3).setWidth(20);
    ws.column(4).setWidth(20);
    ws.column(5).setWidth(40);
    ws.column(6).setWidth(40);
    ws.column(7).setWidth(40);
    ws.column(8).setWidth(40);
    ws.column(9).setWidth(40);
    ws.column(10).setWidth(40);
    ws.column(11).setWidth(40);
    ws.column(12).setWidth(40);

    const countLines = farmAnimals.length;
    ws.addDataValidation({
      type: "list",
      allowBlank: false,
      sqref: `G5:G${countLines + headingRowIndex}`,
      showDropDown: true,
      error: "Valor inválido, selecione uma das opções",
      formulas: ["CCS individual, CMT, CMT e CCS"],
    });
    ws.addDataValidation({
      type: "list",
      allowBlank: false,
      sqref: `F5:F${countLines + headingRowIndex}`,
      showDropDown: true,
      error: "Valor inválido, selecione uma das opções",
      formulas: ["=Listas!$A$1:$A$17"],
    });
    ws.addDataValidation({
      type: "list",
      allowBlank: false,
      sqref: `I5:L${countLines + headingRowIndex}`,
      showDropDown: true,
      error: "Valor inválido, selecione uma das opções",
      formulas: ["1, 2, 3, 4, x, m"],
    });

    let rowIndex = headingRowIndex + 1;
    let columnsIndex = [5, 6, 7, 8, 9, 10, 11, 12];
    farmAnimals.forEach(() => {
      columnsIndex.forEach((index) => {
        ws.cell(rowIndex, index).string("").style(styleCell);
      });
      rowIndex++;
    });

    lists.column(1).hide();
    secagemDrugsOptions.forEach((drug, index) => {
      lists
        .cell(index + 1, 1)
        .string(drug)
        .style(styleCell);
    });

    return { headingColumns, animalsColumns, headingRowIndex };
  };

  // CCS Individual
  const handleIndividualCCSData = async (row) => {
    const data_register = rewriteDate(row[4]);
    const exam_result = parseInt(row[5]);

    if (!data_register || !exam_result) return { hasAllData: false };

    // const parto_id = farmAnimals.filter((a) => a.id === row[0])[0].parto_id;
    // const response = await getPartoById(parto_id);

    if (
      !isDateValid(data_register) ||
      !isPastDate(data_register) ||
      // isPastDate(data_register, response.data_parto) ||
      isNaN(exam_result)
    )
      return { hasAllData: true, validData: false };

    const specificData = {
      Data: data_register,
      Resultado: exam_result,
      data: dateToTimestamp(data_register),
      data_register: data_register,
      ccs: exam_result,
      hightCCS: isCCSHigh(exam_result),
    };

    return { hasAllData: true, specificData, validData: true };
  };
  const generateIndividualCCSSheet = (ws, styleCell) => {
    const headingRowIndex = 2;
    const headingColumns = [
      "Id",
      "Nome/Número do animal",
      "Raça",
      "Lote",
      "Data do registro (dd/mm/aaaa)",
      "Resultado do exame (mil cel/ml)",
    ];
    const animalsColumns = ["id", "Identificação", "Raça", "Lote"];

    ws.column(1).hide();
    ws.row(2).freeze();
    ws.column(2).setWidth(40);
    ws.column(3).setWidth(20);
    ws.column(4).setWidth(20);
    ws.column(5).setWidth(50);
    ws.column(6).setWidth(50);
    let rowIndex = headingRowIndex + 1;
    farmAnimals.forEach(() => {
      [5, 6].forEach((index) => {
        ws.cell(rowIndex, index).string("").style(styleCell);
      });
      rowIndex++;
    });

    return { headingColumns, animalsColumns, headingRowIndex };
  };

  // Mastite Clínica
  const handleMastiteData = async (row) => {
    const data_diagnostico = rewriteDate(row[4]);
    const tfe = row[6].toString();
    const tfd = row[7].toString();
    const tte = row[8].toString();
    const ttd = row[9].toString();

    if (!data_diagnostico || !(tfe || tfd || tte || ttd))
      return { hasAllData: false };

    if (
      !isDateValid(data_diagnostico) ||
      !isPastDate(data_diagnostico) ||
      !["Manhã", "Tarde", "Noite"].includes(row[5])
    )
      return { hasAllData: true, validData: false };

    const levels = ["1", "2", "3"];
    const tetos = {};
    if (levels.includes(tfe)) tetos["leftFront"] = tfe;
    if (levels.includes(tfd)) tetos["rightFront"] = tfd;
    if (levels.includes(tte)) tetos["leftBack"] = tte;
    if (levels.includes(ttd)) tetos["rightBack"] = ttd;

    const specificData = {
      Tipo_da_mastite: "Mastite clínica",
      tipo: "Mastite clínica",
      Data: data_diagnostico,
      data_diagnostico: dateToTimestamp(data_diagnostico),
      tetos: tetos,
      periodo_ordenha: row[5],
    };
    const intramamarioDrug = intramamario_drugs.find(
      (drug) => drug.label === row[12]
    );
    const sistemicoDrug = sistemico_drugs.find(
      (drug) => drug.label === row[13]
    );

    const data_tratamento = rewriteDate(row[10]);

    const tipoTratamentoSistemico = row[11] === "Sistêmico" && !!row[13];
    const tipoTratamentoIntramamario =
      row[11] === "Intramamário" ? !!row[12] : tipoTratamentoSistemico;
    if (
      data_tratamento &&
      isDateValid(data_tratamento) &&
      row[11] &&
      (row[11] === "Intramamário e Sistêmico"
        ? row[12] && row[13]
        : tipoTratamentoIntramamario)
    ) {
      specificData["data_tratamento"] = dateToTimestamp(data_tratamento);
      specificData["data_tratamento_date"] = data_tratamento;
      specificData["tipo_tratamento"] = row[11];

      const medicamentoIntramamario =
        row[11] === "Intramamário" ? row[12] : row[13];
      specificData["medicamento"] =
        row[11] === "Intramamário e Sistêmico"
          ? row[12] + " e " + row[13]
          : medicamentoIntramamario;

      const carenciaIntramamario =
        row[11] === "Intramamário"
          ? intramamarioDrug.period
          : sistemicoDrug.period;
      specificData["carencia"] =
        row[11] === "Intramamário e Sistêmico"
          ? (intramamarioDrug && intramamarioDrug.period) +
            (sistemicoDrug && sistemicoDrug.period)
          : carenciaIntramamario;
    }

    return { hasAllData: true, specificData, validData: true };
  };
  const generateMastitisSheet = (ws, lists, styleCell, styleHeading) => {
    const headingRowIndex = 3;
    const headingColumns = [
      "Id",
      "Nome/Número do animal",
      "Raça",
      "Lote",
      "Data do diagnóstico (dd/mm/aaaa)",
      "Turno da ordenha",
      "Teto frontal esquerdo",
      "Teto frontal direito",
      "Teto traseiro esquerdo",
      "Teto traseiro direito",
      "Data do início do tratamento",
      "Tipo de tratamento",
      "Medicamento intramamário",
      "Medicamento sistêmico",
    ];
    const animalsColumns = ["id", "Identificação", "Raça", "Lote"];

    ws.cell(2, 11, 2, 14, true)
      .string("Incluir tratamento")
      .style(styleHeading);
    ws.cell(2, 7, 2, 10, true)
      .string("Tetos (Grau: 1, 2, 3)")
      .style(styleHeading);

    ws.cell(2, 1).string("#");

    ws.row(2).freeze();
    ws.row(3).freeze();
    ws.column(1).hide();

    ws.column(2).setWidth(40);
    ws.column(3).setWidth(20);
    ws.column(4).setWidth(20);
    ws.column(5).setWidth(50);
    ws.column(6).setWidth(30);
    ws.column(7).setWidth(30);
    ws.column(8).setWidth(30);
    ws.column(9).setWidth(30);
    ws.column(10).setWidth(30);
    ws.column(11).setWidth(40);
    ws.column(12).setWidth(30);
    ws.column(13).setWidth(40);
    ws.column(14).setWidth(40);

    ws.addDataValidation({
      type: "list",
      allowBlank: false,
      sqref: `F4:F${farmAnimals.length + headingRowIndex}`,
      showDropDown: true,
      error: "Valor inválido, selecione uma das opções",
      formulas: ["Manhã, Tarde, Noite"],
    });
    ws.addDataValidation({
      type: "list",
      allowBlank: false,
      sqref: `G4:J${farmAnimals.length + headingRowIndex}`,
      showDropDown: true,
      error: "Valor inválido, selecione uma das opções",
      formulas: ["1, 2, 3"],
    });
    ws.addDataValidation({
      type: "list",
      allowBlank: false,
      sqref: `L4:L${farmAnimals.length + headingRowIndex}`,
      showDropDown: true,
      error: "Valor inválido, selecione uma das opções",
      formulas: ["Intramamário, Sistêmico, Intramamário e Sistêmico"],
    });
    ws.addDataValidation({
      type: "list",
      allowBlank: false,
      sqref: `M4:M${farmAnimals.length + headingRowIndex}`,
      showDropDown: true,
      error: "Valor inválido, selecione uma das opções",
      formulas: ["=Listas!$A$1:$A$34"],
    });
    ws.addDataValidation({
      type: "list",
      allowBlank: false,
      sqref: `N4:N${farmAnimals.length + headingRowIndex}`,
      showDropDown: true,
      error: "Valor inválido, selecione uma das opções",
      formulas: ["=Listas!$C$1:$C$49"],
    });

    let rowIndex = headingRowIndex + 1;
    let columnsIndex = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    farmAnimals.forEach(() => {
      columnsIndex.forEach((index) => {
        ws.cell(rowIndex, index).string("").style(styleCell);
      });
      rowIndex++;
    });

    lists.column(1).hide();
    lists.column(3).hide();
    intramamarioDrugsOptions.forEach((drug, index) => {
      lists
        .cell(index + 1, 1)
        .string(drug)
        .style(styleCell);
    });
    sistemicoDrugsOptions.forEach((drug, index) => {
      lists
        .cell(index + 1, 3)
        .string(drug)
        .style(styleCell);
    });

    return { headingColumns, animalsColumns, headingRowIndex };
  };

  // Cultura Microbiológica
  const handleMicrobiologicalCutureData = async (row) => {
    const data_teste = rewriteDate(row[4]);
    const tfe = row[6];
    const tfd = row[7];
    const tte = row[8];
    const ttd = row[9];

    if (!data_teste || !(tfe || tfd || tte || ttd))
      return { hasAllData: false };

    // const parto_id = farmAnimals.filter((a) => a.id === row[0])[0].parto_id;
    // const response = await getPartoById(parto_id);

    if (
      !isDateValid(data_teste) ||
      !isPastDate(data_teste)
      // || isPastDate(data_teste, response.data_parto)
    )
      return { hasAllData: true, validData: false };

    const titsColumnsAux = [];
    const levels = ["sim"];
    const tetos = {};

    if (levels.includes(tfe)) {
      tetos["leftFront"] = true;
      titsColumnsAux.push("Teto_frontal_esquerdo");
    }
    if (levels.includes(tfd)) {
      tetos["rightFront"] = true;
      titsColumnsAux.push("Teto_frontal_direito");
    }
    if (levels.includes(tte)) {
      tetos["leftBack"] = true;
      titsColumnsAux.push("Teto_traseiro_esquerdo");
    }
    if (levels.includes(ttd)) {
      tetos["rightBack"] = true;
      titsColumnsAux.push("Teto_traseiro_direito");
    }
    const specificData = {
      Data_do_teste: data_teste,
      Teto_frontal_esquerdo: tfe,
      Teto_frontal_direito: tfd,
      Teto_traseiro_esquerdo: tte,
      Teto_traseiro_direito: ttd,
      data: dateToTimestamp(data_teste),
      data_teste: data_teste,
      tetos: tetos,
    };

    const use_bacteria_list = bacteria_list[0].split(", ");
    if (use_bacteria_list.includes(row[5])) {
      specificData["bacteria"] = bacteria_dictionary[row[5]];
    }

    return {
      hasAllData: true,
      specificData,
      validData: true,
      titsColumns: titsColumnsAux,
    };
  };
  const generateMicrobiologicalCultureSheet = (
    ws,
    lists,
    styleCell,
    styleHeading
  ) => {
    const headingRowIndex = 3;
    const headingColumns = [
      "Id",
      "Nome/Número do animal",
      "Raça",
      "Lote",
      "Data do teste (dd/mm/aaaa)",
      "Bactéria encontrada",
      "Teto frontal esquerdo",
      "Teto frontal direito",
      "Teto traseiro esquerdo",
      "Teto traseiro direito",
    ];
    const animalsColumns = ["id", "Identificação", "Raça", "Lote"];

    ws.cell(2, 7, 2, 10, true).string("Tetos").style(styleHeading);
    ws.cell(2, 1).string("#");
    ws.row(2).freeze();
    ws.row(3).freeze();
    ws.column(1).hide();
    ws.column(2).setWidth(40);
    ws.column(3).setWidth(20);
    ws.column(4).setWidth(20);
    ws.column(5).setWidth(40);
    ws.column(6).setWidth(40);
    ws.column(7).setWidth(40);
    ws.column(8).setWidth(40);
    ws.column(9).setWidth(40);
    ws.column(10).setWidth(40);

    ws.addDataValidation({
      type: "list",
      allowBlank: false,
      sqref: `F4:F${farmAnimals.length + headingRowIndex}`,
      showDropDown: true,
      error: "Valor inválido, selecione uma das opções",
      formulas: ["=Listas!$A$1:$A$14"],
    });
    ws.addDataValidation({
      type: "list",
      allowBlank: false,
      sqref: `G4:J${farmAnimals.length + headingRowIndex}`,
      showDropDown: true,
      error: "Valor inválido, selecione uma das opções",
      formulas: ["sim"],
    });

    let rowIndex = headingRowIndex + 1;
    let columnsIndex = [5, 6, 7, 8, 9, 10];
    farmAnimals.forEach(() => {
      columnsIndex.forEach((index) => {
        ws.cell(rowIndex, index).string("").style(styleCell);
      });
      rowIndex++;
    });

    lists.column(1).hide();
    bacteria_list.forEach((bacteria, index) => {
      lists
        .cell(index + 1, 1)
        .string(bacteria)
        .style(styleCell);
    });

    return { headingColumns, animalsColumns, headingRowIndex };
  };

  // Produção de Leite
  const handleMilkProductionData = async (row) => {
    const register_data = excelDateToString(row[4]);
    const day_volume = parseFloat(parseFloat(row[5]).toFixed(2));

    if (!register_data || !day_volume) return { hasAllData: false };

    if (
      !isDateValid(register_data) ||
      !isPastDate(register_data) ||
      isNaN(day_volume)
    )
      return { hasAllData: true, validData: false };

    const specificData = {
      Dia: register_data,
      Volume_total_no_dia: day_volume,
      Volume_estimado_no_mês: Math.floor(day_volume * 30),
      mes: dateToTimestamp(register_data),
      register_data: register_data,
      volume_dia: day_volume,
      volume_mes: Math.floor(day_volume * 30),
    };

    return { hasAllData: true, specificData, validData: true };
  };
  const generateMilkProductionSheet = (ws, styleCell) => {
    const headingRowIndex = 2;
    const headingColumns = [
      "Id",
      "Nome/Número do animal",
      "Raça",
      "Lote",
      "Dia do registro (dd/mm/aaaa)",
      "Volume total no dia",
    ];
    const animalsColumns = ["id", "Identificação", "Raça", "Lote"];

    ws.cell(2, 1).string("#");
    ws.row(2).freeze();
    ws.column(1).hide();
    ws.column(2).setWidth(40);
    ws.column(3).setWidth(20);
    ws.column(4).setWidth(20);
    ws.column(5).setWidth(40);
    ws.column(6).setWidth(40);

    let rowIndex = headingRowIndex + 1;
    let columnsIndex = [5, 6];
    farmAnimals.forEach(() => {
      columnsIndex.forEach((index) => {
        ws.cell(rowIndex, index).string("").style(styleCell);
      });
      rowIndex++;
    });

    return { headingColumns, animalsColumns, headingRowIndex };
  };

  // Reprodução
  const handleReproductionData = async (row) => {
    const data_cobricao = rewriteDate(row[4]);

    if (!data_cobricao) return { hasAllData: false };
    if (!isDateValid(data_cobricao) || !isPastDate(data_cobricao))
      return { hasAllData: true, validData: false };

    const date_aux = handlePredictedBirthData(data_cobricao);
    const specificData = {
      Data_inseminação: data_cobricao,
      Data_prevista_do_parto: date_aux,
      data_inseminacao: dateToTimestamp(data_cobricao),
      data_nascimento: dateToTimestamp(date_aux),
      data_cobricao: data_cobricao,
    };

    return { hasAllData: true, specificData, validData: true };
  };
  const generateReproductionSheet = (ws, styleCell) => {
    const headingRowIndex = 2;
    const headingColumns = [
      "Id",
      "Nome/Número do animal",
      "Raça",
      "Lote",
      "Data da cobrição/inseminação (dd/mm/aaaa)",
    ];
    const animalsColumns = ["id", "Identificação", "Raça", "Lote"];

    ws.row(2).freeze();
    ws.column(1).hide();
    ws.column(2).setWidth(40);
    ws.column(3).setWidth(20);
    ws.column(4).setWidth(20);
    ws.column(5).setWidth(60);
    let rowIndex = headingRowIndex + 1;
    farmAnimals.forEach(() => {
      ws.cell(rowIndex, 5).string("").style(styleCell);
      rowIndex++;
    });

    return { headingColumns, animalsColumns, headingRowIndex };
  };

  // Teste CMT
  const handleTestCMTData = async (row) => {
    const data_teste = rewriteDate(row[4]);
    const tfe = row[5].toString();
    const tfd = row[6].toString();
    const tte = row[7].toString();
    const ttd = row[8].toString();

    if (!data_teste || !(tfe || tfd || tte || ttd))
      return { hasAllData: false };

    // const parto_id = farmAnimals.filter((a) => a.id === row[0])[0].parto_id;
    // const response = await getPartoById(parto_id);

    if (
      !isDateValid(data_teste) ||
      !isPastDate(data_teste)
      // || isPastDate(data_teste, response.data_parto)
    )
      return { hasAllData: true, validData: false };

    const titsColumnsAux = [];
    const levels = ["1", "2", "3", "4", "x", "m"];
    const tetos = {};

    if (levels.includes(tfe)) {
      tetos["leftFront"] = tfe;
      titsColumnsAux.push("Teto_frontal_esquerdo");
    }
    if (levels.includes(tfd)) {
      tetos["rightFront"] = tfd;
      titsColumnsAux.push("Teto_frontal_direito");
    }
    if (levels.includes(tte)) {
      tetos["leftBack"] = tte;
      titsColumnsAux.push("Teto_traseiro_esquerdo");
    }
    if (levels.includes(ttd)) {
      tetos["rightBack"] = ttd;
      titsColumnsAux.push("Teto_traseiro_direito");
    }
    const specificData = {
      Teto_frontal_esquerdo: tfe,
      Teto_frontal_direito: tfd,
      Teto_traseiro_esquerdo: tte,
      Teto_traseiro_direito: ttd,
      Data_do_teste: data_teste,
      data: dateToTimestamp(data_teste),
      data_teste: data_teste,
      tetos: tetos,
    };

    return {
      hasAllData: true,
      specificData,
      validData: true,
      titsColumns: titsColumnsAux,
    };
  };
  const generateTestCMTSheet = (ws, styleCell, styleHeading) => {
    const headingRowIndex = 3;
    const headingColumns = [
      "Id",
      "Nome/Número do animal",
      "Raça",
      "Lote",
      "Data do teste (dd/mm/aaaa)",
      "Teto frontal esquerdo",
      "Teto frontal direito",
      "Teto traseiro esquerdo",
      "Teto traseiro direito",
    ];
    const animalsColumns = ["id", "Identificação", "Raça", "Lote"];

    ws.cell(2, 6, 2, 9, true)
      .string("Tetos (Grau: 1, 2, 3, 4, x, m)")
      .style(styleHeading);
    ws.cell(2, 1).string("#");
    ws.row(2).freeze();
    ws.row(3).freeze();
    ws.column(1).hide();
    ws.column(2).setWidth(40);
    ws.column(3).setWidth(20);
    ws.column(4).setWidth(20);
    ws.column(5).setWidth(40);
    ws.column(6).setWidth(40);
    ws.column(7).setWidth(40);
    ws.column(8).setWidth(40);
    ws.column(9).setWidth(40);

    ws.addDataValidation({
      type: "list",
      allowBlank: false,
      sqref: `F4:I${farmAnimals.length + headingRowIndex}`,
      showDropDown: true,
      error: "Valor inválido, selecione uma das opções",
      formulas: ["1, 2, 3, 4, x, m"],
    });

    let rowIndex = headingRowIndex + 1;
    let columnsIndex = [5, 6, 7, 8, 9];
    farmAnimals.forEach(() => {
      columnsIndex.forEach((index) => {
        ws.cell(rowIndex, index).string("").style(styleCell);
      });
      rowIndex++;
    });

    return { headingColumns, animalsColumns, headingRowIndex };
  };

  // Lote
  const handleLotData = async (row) => {
    const lotName = row[4] ? row[4] : row[5];

    if (!lotName) return { hasAllData: false };

    const lotId =
      lots.filter((lot) => lot.codLote === lotName).length > 0
        ? lots.filter((lot) => lot.codLote === lotName)[0].id
        : undefined;

    const specificData = {
      lote_id: lotId,
      lote_num_letras: lotName.toString(),
      Novo_lote: lotName.toString(),
    };

    return {
      hasAllData: true,
      specificData,
      validData: true,
    };
  };
  const generateLotSheet = (ws, lists, styleCell, styleHeading) => {
    const headingRowIndex = 3;
    const headingColumns = [
      "Id",
      "Nome/Número do animal",
      "Raça",
      "Lote atual",
      "Selecione um lote",
      "Crie um novo lote (nome-números-letras)",
    ];
    const animalsColumns = ["id", "Identificação", "Raça", "Lote"];

    ws.cell(2, 5, 2, 6, true)
      .string("Selecione ou crie um novo lote")
      .style(styleHeading);
    ws.cell(2, 1).string("#");
    ws.row(2).freeze();
    ws.row(3).freeze();
    ws.column(1).hide();
    ws.column(2).setWidth(40);
    ws.column(3).setWidth(20);
    ws.column(4).setWidth(40);
    ws.column(5).setWidth(40);
    ws.column(6).setWidth(70);

    ws.addDataValidation({
      type: "list",
      allowBlank: false,
      sqref: `E4:E${farmAnimals.length + headingRowIndex}`,
      showDropDown: true,
      error: "Valor inválido, selecione uma das opções",
      formulas: ["=Listas!$A$1:$A1000"],
    });

    let rowIndex = headingRowIndex + 1;
    let columnsIndex = [5, 6];
    farmAnimals.forEach(() => {
      columnsIndex.forEach((index) => {
        ws.cell(rowIndex, index).string("").style(styleCell);
      });
      rowIndex++;
    });

    lists.column(1).hide();
    lots.forEach((item, index) => {
      lists
        .cell(index + 1, 1)
        .string(item.codLote?.toString())
        .style(styleCell);
    });

    return { headingColumns, animalsColumns, headingRowIndex };
  };

  function handlePredictedBirthData(value) {
    const dictDays = {
      Vacas: 283,
      Ovelhas: 152,
      Búfalas: 310,
      Cabras: 150,
    };
    const month = parseInt(value.substring(3, 5)) - 1;
    const date = new Date(value.substring(6), month, value.substring(0, 2));
    return format(addDays(date, dictDays[farmSelected.specie]), "dd/MM/yyyy");
  }

  function isCCSHigh(value) {
    const ccs_value = parseInt(value);
    if (["Vacas", "Búfalas"].includes(farmSelected.specie)) {
      if (ccs_value > 200) return true;
    } else if (["Ovelhas", "Cabras"].includes(farmSelected.specie)) {
      if (ccs_value > 1000) return true;
    }
    return false;
  }

  return {
    animals,
    invalidAnimals,
    columns,
    isLoading,
    isProcessingData,
    handleData,
    handleCreateSpreadsheet,
    saveSpreadsheet,
  };
}

export default useSpreadsheet;
