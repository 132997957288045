import { useState } from "react";
import AnimalModal from "./animalModal/animalModal";
import "./bases.css";

export function SectionHeader({ id, title }) {
  return (
    <div className="section-header-wrapper">
      <div id={id} className="section-header">
        <p className="section-title">{title}</p>
      </div>
    </div>
  );
}

export function Row({ children, className }) {
  return <div className={"row " + className}>{children}</div>;
}

export function Column({ children, className }) {
  return <div className={"column " + className}>{children}</div>;
}

export function Box({
  children,
  className,
  id,
  title,
  titleDescription,
  viewMore,
  isViewMoreFull,
  viewMoreTitle,
  animals,
  columns,
}) {
  const [showModalAnimal, setShowModalAnimal] = useState(false);

  const animalsOrLotes = title === "Média diária por lote" ? "lotes" : "animais";

  return (
    <>
      <div className={"box " + className} id={id}>
        {title && (
          <div className="chart-title">
            <p>{title}</p>
            {titleDescription && (
              <p className="chart-title-description">{titleDescription}</p>
            )}
          </div>
        )}
        {children}
        {viewMore && (
          <div
            className={"showMore " + (isViewMoreFull && "full")}
            onClick={() => setShowModalAnimal(true)}
          >
            <p>
              {isViewMoreFull
                ? "Veja a lista completa de " + animalsOrLotes
                : "Ver mais"}
            </p>
          </div>
        )}
      </div>
      {viewMore && showModalAnimal && (
        <AnimalModal
          title={viewMoreTitle}
          onClickCancel={() => setShowModalAnimal(false)}
          animals={animals}
          columns={columns}
        />
      )}
    </>
  );
}

export function TextInfo({ children, className, id, first, number, latter }) {
  return (
    <div className={"textInfo " + className} id={id}>
      <div className="first-title">
        <p>{first}</p>
      </div>
      <div className="number-title">
        <p>{number}</p>
      </div>
      <div className="latter-title">
        <p>{latter}</p>
      </div>
      {children}
    </div>
  );
}

export function MonthSelector({ date, onChange }) {
  const Months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  var { month, year } = date;

  var currentDate = new Date();
  var currentMonth = currentDate.getMonth();
  var currentYear = currentDate.getFullYear();
  const [maxDate, setMaxDate] = useState(true);

  function lastMonth() {
    if (month === 0) year--;

    setMaxDate(false);
    month = (((month - 1) % 12) + 12) % 12;
    onChange({ month, year });
  }

  function nextMonth() {
    if (
      year === currentYear &&
      (((month + 1) % 12) + 12) % 12 === currentMonth
    ) {
      setMaxDate(true);
    }

    if (year === currentYear && month === currentMonth) {
      return;
    }
    if (month === 11) year++;
    month = (((month + 1) % 12) + 12) % 12;
    onChange({ month, year });
  }

  return (
    <div className="month-selector">
      <div className="back-arrow-wrapper" onClick={lastMonth}>
        <div className="back-month-arrow"></div>
      </div>
      {Months[month] + " / " + year}
      <div
        className={
          "next-arrow-wrapper " + (maxDate ? "deactivate" : "activate")
        }
        onClick={nextMonth}
      >
        <div
          className={
            "next-month-arrow " + (maxDate ? "deactivate" : "activate")
          }
        ></div>
      </div>
    </div>
  );
}

export function YearSelector({ year, onChange, doubleYear, maxYear }) {
  const [isMaxYear, setIsMaxYear] = useState(true);

  function lastMonth() {
    setIsMaxYear(false);
    onChange(year - 1);
  }

  function nextMonth() {
    if (year + 1 === maxYear) {
      setIsMaxYear(true);
      onChange(year + 1);
      return;
    } else if (year < maxYear) {
      onChange(year + 1);
    }
  }

  return (
    <div className="month-selector">
      <div className="back-arrow-wrapper" onClick={lastMonth}>
        <div className="back-month-arrow"></div>
      </div>
      {doubleYear ? (
        <p>
          {" "}
          {year - 1} / <span> {year} </span>{" "}
        </p>
      ) : (
        <p> {year} </p>
      )}
      <div
        className={
          "next-arrow-wrapper " + (isMaxYear ? "deactivate" : "activate")
        }
        onClick={nextMonth}
      >
        <div
          className={
            "next-month-arrow " + (isMaxYear ? "deactivate" : "activate")
          }
        ></div>
      </div>
    </div>
  );
}
