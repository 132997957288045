import Button from "../../../../components/button/button";
import Modal from "../../../../components/modal/modal";

import './deleteAnimalModal.css';

export default function DeleteAnimalModal({ isLoading, onClickCancel, onClickSubmit }) {
  return (
    <Modal showModal={onClickCancel} close={true}>
      <div className="content-modal">
        <p className="title">Você quer excluir esse animal?</p>
        <p className="text">Caso exclua esse animal, você não poderá mais acessá-lo depois.</p>

        <div className="modal-buttons-wrapper">
          <div />
          <Button color="white" onClick={onClickCancel}>Cancelar</Button>
          <Button color="blue" onClick={onClickSubmit} isLoading={isLoading}>Excluir animal</Button>
        </div>
      </div>
    </Modal>
  );
}