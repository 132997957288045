import Routes from "./router/routes";

import { UserContextProvider } from "./contexts/userContext";

function App() {
  return (
    <UserContextProvider>
      <Routes />
    </UserContextProvider>
  );
}

export default App;
