import Filter from "../filter/filter";

import "./showFilters.css";

export default function ShowFilters({ filters, onClickRemoveFilter }) {
  return (
    <div className="show-filters">
      { filters.map((filter, index) => { 
        return (
          <Filter key={filter.label + index} 
            icon={filter.icon} 
            label={filter.label} 
            onClickRemove={() => onClickRemoveFilter(filter, index)} 
          />
        )}
      )}
    </div>
  )
}