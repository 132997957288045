import { format, fromUnixTime } from "date-fns";

export function timestampToDate(UNIX_timestamp) {
  if (UNIX_timestamp)
    var result = format(fromUnixTime(UNIX_timestamp), "dd/MM/yyyy");

  return result;
}

export function timestampToMonth(UNIX_timestamp) {
  if (UNIX_timestamp)
    var result = format(fromUnixTime(UNIX_timestamp), "MM/yyyy");

  return result;
}
