import BaseChart from '../baseChart';
import * as d3 from 'd3';

import './areaChart.css';

function drawAreaChart(props) {
  const {
    svgRef,
    data,
    data2,
    xScale,
    yScale,
    // width,
    // height,
    // margin,
    // areaClass,
    strokeWidth,
  } = props;

  const svg = d3.select(svgRef.current).select('g');

  svg
    .append('path')
    .datum(data)
    .attr('stroke-width', strokeWidth)
    .attr('class', 'area-chart__area')
    .attr('d', d3.area()
      .curve(d3.curveBumpX)
      .x((d) => xScale(d.label))
      .y0(yScale(0))
      .y1((d) => yScale(d.value)));
  
  if (data2){
    svg
      .append('path')
      .datum(data2)
      .attr('stroke-width', strokeWidth)
      .attr('class', 'area-chart__area2')
      .attr('d', d3.area()
        .curve(d3.curveBumpX)
        .x((d2) => xScale(d2.label))
        .y0(yScale(0))
        .y1((d2) => yScale(d2.value)));
  }

}

const extraProps = {
  useScaleBands: { x: true, y: false },
  paddingInner: 1,
  isAreaChart: true
};

export default BaseChart(drawAreaChart, extraProps);
