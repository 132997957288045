import './actionCardflat.css';

import arrow_white from '../../../../assets/svg/your-properties/arrow-white.svg';

function ActionCardflat({ name, gridarea, id, image_path, image_alt, onClick }) {
    return (
        <div className="action-card-flat" style={{ gridArea: gridarea }} id={id} onClick={onClick}>

            <div className="action-body">
                <div className="action-info">
                    <img src={image_path} alt={image_alt} className="farm-icon" />
                    <p className="action-name">{name}</p>
                </div>

                <div className="arrow-button">
                    <div className="button">
                        <img src={arrow_white} alt="Arrow" />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ActionCardflat;