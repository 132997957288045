import { useAlertContext } from "../../../../../contexts/alertContext";

import "./ModalNewDateAlert.css";

import Button from "../../../../../components/button/button";
import Modal from "../../../../../components/modal/modal";

// modalName = newDateAlert
export default function ModalNewAlertDate(){
    const { modalProps, handleModalShow, modalUnderstand } = useAlertContext();
    const { animals, type } = modalProps;
    
    function printNameAnimals(animals){
        if (animals.length === 1){
            return [<b>{animals[0].Identificação}</b>, " para o dia ", <b>{animals[0].Data_prevista}</b>]
        } else {
            return (animals.map((animal, index) => { 
                const stringtext = ", "
                const last = " e da "
                if (index < animals.length-2) {
                    return (
                        [<b key={animal.Identificação + index}>{animal.Identificação}</b>, " para o dia ", <b key={animal.Identificação + 1}>{animal.Data_prevista}</b>, stringtext]
                    )
                } else if (index < animals.length - 1){
                    return [<b key={animal.Identificação + index}>{animal.Identificação}</b> , " para o dia ",  <b key={animal.Identificação + 1}>{animal.Data_prevista}</b>]
                } else {
                    return [last , <b key={animal.Identificação + index}>{animal.Identificação}</b> , " para o dia ",  <b key={animal.Identificação + 1}>{animal.Data_prevista}</b>]
                }
            }))
        }
    }

    return(
        <Modal showModal={handleModalShow} close={true}>
            <div className="newdate-alert-modal-content">
                <p className="title">Novo alerta de {type} gerado</p>
                <p className="text">
                    {"Você alterou a data de " + type + " da "}
                    { printNameAnimals(animals) }
                    {". Um novo alerta foi gerado para lembrar você desse evento."}      
                </p>

                <div className="modal-div-buttons">
                    <div />
                    <Button color="white" className="modal-button" onClick={() => modalUnderstand('birth')}>Voltar</Button>
                    <Button color="blue" className="modal-button" onClick={() => modalUnderstand()}>Apagar alerta</Button>
                </div>
            </div>
        </Modal>
    );  
}