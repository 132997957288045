import { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import loading_gif from "../../../../assets/gifs/loading_2.json";
import ProgressLine from "../../../../components/baseChart/progressLine/progressLine";
import { useFarmContext } from "../../../../contexts/farmContext";
import { getSubclinicMastitisInfo } from "../../../../services/report/getSubclinicMastitisInfo";
import { apiDateToFormatedDate } from "../../../../utils/dateToApiDate";
import { Box, Row, SectionHeader } from "../bases/bases";
import "./prevalenceOfMicroorganisms.css";

export function PrevalenceOfMicroorganisms(props) {
  const { farmSelected } = useFarmContext();

  const [prevalenceOfMicrorganisms, setPrevalenceOfMicrorganisms] = useState(
    []
  );
  const [animalsPrevalence, setAnimalsPrevalence] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const aux_data = await getSubclinicMastitisInfo(
          farmSelected.farm_id,
          props.date
        );
        setPrevalenceOfMicrorganisms([]);
        // Prevalência de micro organismos
        const chartData = [];
        for (let microorganismos in aux_data["microorganismsPrevalence"]) {
          chartData.push({
            value: aux_data["microorganismsPrevalence"][microorganismos],
            name: microorganismos,
          });
        }
        setPrevalenceOfMicrorganisms(chartData);
        const aux_animais = aux_data.animals_microorganismsPrevalence.map(
          (item, index) => {
            var date = "-";
            if (item.eventDate) {
              date = apiDateToFormatedDate(item.eventDate);
            }
            return {
              id: "d" + index,
              Identificação: item.identification,
              Data: date,
              Bactéria: item.bacterium,
              Lote: item.lot,
            };
          }
        );
        setAnimalsPrevalence(aux_animais);

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.log("Error fetching data Prevalence Of Microorganisms: " + e);
      }
    }
    fetchData();
  }, [farmSelected, props.date]);

  return (
    <div className="prevalenceOfMicroorganisms-wrapper">
      <SectionHeader title="Prevalência de microrganismos" />
      <Row className="mastitis fifth">
        <Box
          viewMore={animalsPrevalence.length > 0}
          viewMoreTitle="Prevalência de microrganismos"
          animals={animalsPrevalence}
          columns={["Identificação", "Lote", "Data", "Bactéria"]}
        >
          {isLoading ? (
            <div className="no-data">
              <Lottie loop play animationData={loading_gif} />
            </div>
          ) : (
            // prevalenceOfMicroorganisms.length ?
            <ProgressLine
              data_={prevalenceOfMicrorganisms}
              unit="%"
              default_text="Não existem culturas cadastradas"
            />
            // :
            // <TextInfo
            // latter="Não existem culturas cadastradas"
            // />
          )}
        </Box>
      </Row>
    </div>
  );
}
