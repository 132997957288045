import { useState } from "react";
import { useHistory } from "react-router-dom";

import { useUserContext } from "../../contexts/userContext";
import useForm from "../../hooks/useForm";

import Button from "../../components/button/button";
import Input from "../../components/input/input";

import ModalAnswer from "./components/modalAnswer";
import ModalInformEmail from "./components/modalInformEmail";
import ModalInformNewEmail from "./components/modalInformNewEmail";
import ModalVerifyEmail from "./components/modalVerifyEmail";
import ModalVerifyNewEmail from "./components/modalVerifyNewEmail";

import logo from "../../assets/images/logo-large.png";

import signIn from "../../services/userForm/signIn";

import "./login.css";

function LoginPage() {
  const history = useHistory();

  const { isAuthenticated, handleToken, handleUser } = useUserContext();
  const { values, errors, handleChange, handleSubmit, handleSetErrors } =
    useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [showModalInformEmail, setShowModalInformEmail] = useState(false);
  const [showModalVerifyEmail, setShowModalVerifyEmail] = useState(false);
  const [showModalAnswer, setShowModalAnswer] = useState(false);
  const [showModalInformNewEmail, setShowModalInformNewEmail] = useState(false);
  const [showModalVerifyNewEmail, setShowModalVerifyNewEmail] = useState(false);

  async function checkValues() {
    const error = {};

    if (!values.email) {
      error.email = "Este campo é obrigatório";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      error.email = "Endereço de e-mail invalido";
    } else if (errors.email) {
      delete error.email;
    }

    if (!values.password) {
      error.password = "Este campo é obrigatório";
    } else if (values.password.length < 8) {
      error.password = "A senha deve ter mais de 8 caracteres";
    } else if (!/[A-Z]/.test(values.password)) {
      error.password = "A senha deve ter letras maiúsculas";
    } else if (!/[0-9]/.test(values.password)) {
      error.password = "A senha deve ter numerais";
    } else if (errors.password) {
      delete error.password;
    }

    return error;
  }

  async function valuesAreCorrect() {
    try {
      setIsLoading(true);
      const data = {
        email: values.email,
        password: values.password,
      };
      const signIn_response = await signIn(data);
      handleToken(signIn_response.accessToken);
    } catch (err) {
      setIsLoading(false);
      if (err.message === "User does not exist.") {
        handleSetErrors({ api: "E-mail não cadastrado" });
      } else if (err.message === "Incorrect username or password.") {
        handleSetErrors({ api: "Senha incorreta" });
      } else if (err.message === "User is not confirmed.") {
        handleUser({ email: values.email });
        history.push("/verification");
      } else if (err.message === "Usuário com cadastro incompleto") {
        history.push("/register/additional?id=" + err.body.id);
      } else if (err.message === "Usuário sem plano selecionado") {
        history.push("/plans?id=" + err.body.id);
      } else if (err.message === "Usuário com pagamento pendente") {
        if (err.body.id) {
          history.push("/payment?id=" + err.body.id);
        } else {
          handleSetErrors({ api: "Usuário com pagamento pendente" });
        }
      } else {
        handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
      }
    }
  }

  if (isAuthenticated()) {
    history.push("/your-properties");
  }

  return (
    <div className="login">
      <div className="white-part">
        <form onSubmit={handleSubmit(checkValues, valuesAreCorrect, isLoading)}>
          <h1 className="login-text">Login</h1>
          <Input
            id="email"
            label="E-mail"
            placeholder="Insira o e-mail"
            className="email-input"
            type="text"
            error={errors.email}
            value={values.email}
            onChange={handleChange}
          />
          <Input
            id="password"
            label="Senha"
            placeholder="Insira a senha"
            className="password-input"
            type="password"
            error={errors.password}
            value={values.password}
            onChange={handleChange}
          />

          <div className="div-forgot">
            <p
              className="p-forgot"
              onClick={() => setShowModalInformEmail(true)}
            >
              Esqueci minha senha
            </p>
          </div>

          {errors.api && <p className="api-error">{errors.api}</p>}

          <div className="div-button">
            <Button color="white" onClick={() => history.push("/register")}>
              Novo Cadastro
            </Button>
            <Button color="blue" type="submit" isLoading={isLoading}>
              Fazer Login
            </Button>
          </div>
        </form>
      </div>

      <div className="blue-part">
        <div className="mask">
          <div>
            <img src={logo} alt="logo" />
            {/* <p>Juntos pela qualidade</p> */}
          </div>
        </div>
      </div>

      {showModalInformEmail && (
        <ModalInformEmail
          showModal={(boolean) => setShowModalInformEmail(boolean)}
          onClickForgot={() => {
            setShowModalInformEmail(false);
            setShowModalAnswer(true);
          }}
          onClickDone={() => {
            setShowModalInformEmail(false);
            setShowModalVerifyEmail(true);
          }}
        />
      )}
      {showModalVerifyEmail && (
        <ModalVerifyEmail
          showModal={(boolean) => setShowModalVerifyEmail(boolean)}
          // onClickNotReceive={() => setShowModalVerifyEmail(false)}
          onClickUnderstand={() => setShowModalVerifyEmail(false)}
        />
      )}
      {showModalAnswer && (
        <ModalAnswer
          showModal={(boolean) => setShowModalAnswer(boolean)}
          onClickCancel={() => setShowModalAnswer(false)}
          onClickContinue={() => {
            setShowModalAnswer(false);
            setShowModalInformNewEmail(true);
          }}
        />
      )}
      {showModalInformNewEmail && (
        <ModalInformNewEmail
          showModal={(boolean) => setShowModalInformNewEmail(boolean)}
          onClickCancel={() => setShowModalInformNewEmail(false)}
          onClickRegister={() => {
            setShowModalInformNewEmail(false);
            setShowModalVerifyNewEmail(true);
          }}
        />
      )}
      {showModalVerifyNewEmail && (
        <ModalVerifyNewEmail
          showModal={(boolean) => setShowModalVerifyNewEmail(boolean)}
          onClickNotReceive={() => setShowModalVerifyNewEmail(false)}
          onClickUnderstand={() => setShowModalVerifyNewEmail(false)}
        />
      )}
    </div>
  );
}

export default LoginPage;
