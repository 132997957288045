import Button from "../../../components/button/button";
import Modal from "../../../components/modal/modal";

import "./loginModals.css";

export default function ModalVerifyEmail({ showModal, onClickUnderstand }){
  return(
    <Modal showModal={showModal} close={true}>
      <div className="content verifyEmail">
        {/* <p className="title">Agora, verifique o e-mail informado</p>
        <p className="text">Enviamos para você os próximos passos para criar uma nova senha.</p> */}
        <p className="title">Sua nova senha foi salva</p>
        <p className="text">Faça o login e aproveite nossa plataforma.</p>

        <div className="modal-button-wrapper">
          <div />
          <div />
          {/* <Button color="white" onClick={onClickNotReceive}>Não recebi</Button> */}
          <Button color="blue" onClick={onClickUnderstand}>Continuar</Button>
        </div>
      </div>
    </Modal>
  );
}
