import { format } from "date-fns";
import { useEffect, useState } from "react";

import { useEventContext } from "../../../../contexts/eventContext";
import { useFarmContext } from "../../../../contexts/farmContext";
import useForm from "../../../../hooks/useForm";

import {
  dataPrevista,
  getPartoById,
  registerBirth,
} from "../../../../services/events/birth";

import Input from "../../../../components/input/input";
import EventContent from "../../components/eventContent/eventContent";

import { calcAge } from "../../../../utils/calcAge";
import { dateToApiDate } from "../../../../utils/dateToApiDate";
import { isDateValid, isPastDate } from "../../../../utils/isDateValid";
import { separar } from "../../../../utils/separarArray";

import "./birth.css";

export default function Birth() {
  const { errors, values, handleSubmit, handleChange, handleSetErrors, reset } =
    useForm();
  const { selectedAnimals, handleModalShow, handleModalProps } =
    useEventContext();
  const { farmSelected } = useFarmContext();

  const [isLoading, setIsLoading] = useState(false);
  const [infoValue, setInfoValue] = useState("-");
  const [expectedDates, setExpectedDates] = useState([]);

  useEffect(() => {
    async function fetchLastRegister() {
      try {
        const register_response = await getPartoById(
          selectedAnimals[0].farmId,
          selectedAnimals[0].id
        );
        const date = new Date(register_response.birth.eventDate.slice(0, -1));
        const value = format(date, "dd/MM/yyyy");
        setInfoValue(value);
      } catch (err) {}
    }
    if (selectedAnimals.length === 1) {
      fetchLastRegister();
    }
  }, [selectedAnimals]);

  useEffect(() => {
    if (selectedAnimals.length > 0) {
      selectedAnimals.forEach(async (animal) => {
        const value = await getDataPrevista(
          selectedAnimals[0].farmId,
          animal.id
        );
        setExpectedDates((arr) => [
          ...arr,
          {
            id: animal.id,
            date: value.date_nascimento,
          },
        ]);
      });
    }
  }, [selectedAnimals]);

  async function getDataPrevista(farm_id, animal_id) {
    let date_nascimento = "-";
    try {
      const expected_response = await dataPrevista(farm_id, animal_id);
      const data_nascimento = new Date(
        expected_response.reproduction.expectedBirthdayDate.slice(0, -1)
      );
      date_nascimento = format(data_nascimento, "dd/MM/yyyy");
    } catch (err) {}
    return { date_nascimento };
  }

  async function checkValues() {
    const error = {};

    selectedAnimals.forEach((animal) => {
      if (!values[animal.id]) {
        error[animal.id] = "Este campo é obrigatório";
      } else if (!isDateValid(values[animal.id])) {
        error[animal.id] = "Insira uma data válida";
      } else if (!isPastDate(values[animal.id])) {
        error[animal.id] = "Insira uma data no passado ou dia corrente";
        // } else if (
        //   isPastDate(
        //     values[animal.id],
        //     expectedDates.filter((item) => item.id === animal.id)[0]
        //       .data_inseminacao
        //   )
        // ) {
        //   error[animal.id] = "Insira uma data posterior a data de reprodução";
      } else if (errors[animal.id]) {
        delete error[animal.id];
      }
    });

    return error;
  }

  async function valuesAreCorrect() {
    let boxValue = "";
    if (selectedAnimals.length > 1) {
      boxValue = undefined;
    } else {
      for (let value in values) {
        boxValue = values[value];
      }
    }

    try {
      setIsLoading(true);
      const data = [];
      for (let value in values) {
        data.push({
          animalId: value,
          farmId: farmSelected.farm_id,
          eventDate: dateToApiDate(values[value]),
          specieName: farmSelected.apiSpecie,
        });
      }
      await Promise.all(
        separar(data, 400).map(async (arr) => {
          await registerBirth(arr);
        })
      );

      setIsLoading(false);
      reset();
      handleModalProps({ boxValue: boxValue });
      handleModalShow(true);
    } catch (err) {
      setIsLoading(false);
      handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
    }
  }

  return (
    <EventContent
      animals={selectedAnimals}
      className="birth"
      eventInfoValue={infoValue}
      onClickSave={handleSubmit(checkValues, valuesAreCorrect, isLoading)}
      isLoading={isLoading}
      apiError={errors.api}
    >
      {selectedAnimals.map((animal) => {
        const value = expectedDates.filter((item) => item.id === animal.id);
        return (
          <div key={animal.id} className="input-wrapper">
            {selectedAnimals.length > 1 && (
              <div className="animal-name">
                <p id="reproduction-animal-name">
                  <b>{animal.Identificação}</b>
                  {isNaN(calcAge(animal.Nascimento))
                    ? ""
                    : "(" + calcAge(animal.Nascimento) + " anos)"}
                </p>
              </div>
            )}
            <Input
              id={animal.id}
              label="Data do parto"
              errorAlignColumn
              error={errors[animal.id]}
              value={values[animal.id]}
              type="text"
              onChange={handleChange}
              mask="99/99/9999"
              placeholder="dd/mm/aaaa"
            />
            <p className="expected_date">
              Data prevista: {value.length > 0 ? value[0].date : "-"}
            </p>
          </div>
        );
      })}
    </EventContent>
  );
}
