import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useUserContext } from "./userContext";

import { listAnimals } from "../services/animals/getAnimals";
import { getAnimalsInTreatment } from "../services/events/mastitis";
import { listFarms } from "../services/farm/listFarms";
import { listLots } from "../services/lot/getLots";

import buffalo from "../assets/svg/buffalo.svg";
import cow from "../assets/svg/cow.svg";
import goat from "../assets/svg/goat.svg";
import sheep from "../assets/svg/sheep.svg";

import { format, getUnixTime } from "date-fns";
import big_buffalo from "../assets/svg/big-buffalo.svg";
import big_cow from "../assets/svg/big-cow.svg";
import big_goat from "../assets/svg/big-goat.svg";
import big_sheep from "../assets/svg/big-sheep.svg";

const cow_race = [
  { label: "Gir" },
  { label: "Holandês" },
  { label: "Girolando" },
  { label: "Jersey" },
  { label: "Mestiça" },
  { label: "Pardo" },
  { label: "Suíço" },
];
const cow_category = [{ label: "Vaca" }, { label: "Novilha" }];

const buffalo_race = [
  { label: "Mediterrânea" },
  { label: "Murrah" },
  { label: "Jafarabadi" },
];
const buffalo_category = [{ label: "Búfala" }, { label: "Novilha" }];

const goat_race = [
  { label: "Saanen" },
  { label: "Alpina (Alpina parda)" },
  { label: "Toggenburg" },
  { label: "Murciana" },
  { label: "Alpina Americana" },
  { label: "Anglo Nubiana" },
  { label: "Alpina Britânica" },
];
const goat_category = [{ label: "Cabra" }, { label: "Cabrita" }];

const sheep_race = [
  { label: "Lacaune" },
  { label: "East friesian" },
  { label: "Assaf" },
  { label: "Awassi" },
  { label: "Bergamácia" },
];
const sheep_category = [{ label: "Ovelha" }, { label: "Cordeiro" }];

const animalDict = {
  Vacas: {
    image: cow,
    bigImage: big_cow,
    races: cow_race,
    categories: cow_category,
  },
  Ovelhas: {
    image: sheep,
    bigImage: big_sheep,
    races: sheep_race,
    categories: sheep_category,
  },
  Búfalas: {
    image: buffalo,
    bigImage: big_buffalo,
    races: buffalo_race,
    categories: buffalo_category,
  },
  Cabras: {
    image: goat,
    bigImage: big_goat,
    races: goat_race,
    categories: goat_category,
  },
};

const apiSpecie = {
  cow: "Vacas",
  buffalo: "Búfalas",
  sheep: "Ovelhas",
  goat: "Cabras",
};

export const FarmContext = createContext({});

export function FarmContextProvider({ children }) {
  const { userID } = useUserContext();

  const [loadingFarms, setLoadingFarms] = useState(true);
  const [farmList, setFarmList] = useState([]);
  const [farmSelected, setFarmSelected] = useState();
  const [error, setError] = useState(false);

  const [animals, setAnimals] = useState([]);
  const [animalsId, setAnimalsId] = useState([]);
  const [animalsSelected, setAnimalsSelected] = useState();
  const [lots, setLots] = useState([]);

  const [reload, setReload] = useState(false);

  const getAnimals = useCallback(async (farm_id) => {
    try {
      const animals_response = await listAnimals(farm_id);
      setFarmSelected((farm) => {
        return { ...farm, animals_number: animals_response.animals.length };
      });
      setAnimalsIdArray(animals_response.animals);

      const animals_aux = animals_response.animals.map((animal) => {
        return {
          ...animal,
          Identificação: animal.identification,
          Nascimento: animal.birthday
            ? format(new Date(animal.birthday.slice(0, -1)), "dd/MM/yyyy")
            : undefined,
          Categoria: animal.category,
          Raça: animal.breed,
          Lote: animal.lot?.code,
          arquivado: !!animal.archivedAt,
        };
      });
      setAnimals(animals_aux);
    } catch (err) {
      console.log("Error fetching animals");
    }
  }, []);

  const getFarmList = useCallback(async () => {
    try {
      const farms_response = await listFarms(userID);

      const farm_aux = farms_response.farms.map((farm) => {
        return {
          farm_id: farm.id,
          user_id: farm.userId,
          city: farm.city,
          state: farm.state,
          deleted: false,
          name: farm.name,
          specie: apiSpecie[farm.specie.specie],
          apiSpecie: farm.specie.specie,
        };
      });

      setError(false);
      setFarmList(farm_aux);

      const farmId = localStorage.getItem("milk-farm");
      if (farmId) {
        farm_aux.forEach(
          (farm) => farm.farm_id === farmId && setFarmSelected(farm)
        );
        getAnimals(farmId);
      }
      setLoadingFarms(false);
      return farm_aux;
    } catch (err) {
      setError(true);
      setLoadingFarms(false);
      console.log("Error fetching user farms");
    }
  }, [getAnimals, userID]);

  useEffect(() => {
    getFarmList();
  }, [reload, getFarmList, userID]);

  const getAnimalById = useCallback(
    (id) => {
      return animals.filter((animal) => animal.id === id);
    },
    [animals]
  );

  const selectAnimals = useCallback((animal) => {
    setAnimalsSelected(animal);
  }, []);

  useEffect(() => {
    if (animalsSelected && animalsSelected.length === 1) {
      selectAnimals(getAnimalById(animalsSelected[0].id));
    }
  }, [animals, selectAnimals, getAnimalById]);

  async function selectFarm(uuid) {
    if (uuid) {
      localStorage.setItem("milk-farm", uuid);
      farmList.forEach(
        (farm) => farm.farm_id === uuid && setFarmSelected(farm)
      );
      getAnimals(uuid);
      setLots([]);
    } else {
      setFarmSelected();
    }
  }

  function haveFarmSelected() {
    return !!farmSelected;
  }

  const getCorrectEventAnimals = useCallback(
    async (eventName, typeEvent) => {
      // if (["Parto"].includes(eventName)) {
      //   // Que tem evento de reprodução
      //   return animals.filter(
      //     (animal) => animal.reproducaoFlag && !animal.arquivado
      //   );
      // } else
      // if (
      //   [
      //     "Secagem",
      //     // "Produção de Leite",
      //     "Teste CMT",
      //     "CCS Individual",
      //     "Cultura Microbiológica",
      //   ].includes(eventName)
      // ) {
      //   // Que tem evento de parto
      //   return animals.filter(
      //     (animal) =>
      //       animal.partoFlag && !animal.secagemFlag && !animal.arquivado
      //   );
      // } else if (["Lote"].includes(eventName)) {
      //   // Que tem evento de produção de leite
      //   return animals.filter(
      //     (animal) =>
      //       animal.leiteFlag &&
      //       animal.partoFlag &&
      //       !animal.secagemFlag &&
      //       !animal.arquivado
      //   );
      // } else {
      //   return animals.filter((animal) => !animal.arquivado);
      // }
      if (eventName === "Mastite Clínica" && typeEvent === "tratamento") {
        setLoadingFarms(true);
        const treatmentAnimals = await getAnimalsInTreatment(
          farmSelected.farm_id
        );
        const aux = treatmentAnimals.mastitis.map((item) => {
          const i = animals.find((animal) => animal.id === item.animalId);
          if (i) {
            var date = "-";
            if (item.treatmentDate) {
              date = format(
                new Date(item.treatmentDate.slice(0, -1)),
                "dd/MM/yyyy"
              );
            }
            return {
              ...i,
              data_diagnostico: getUnixTime(
                new Date(item.eventDate.slice(0, -1))
              ),
              data_tratamento: date,
              Data_do_Tratamento: date,
              event_id: item.id,
              Tipo_Tratamento: item.treatmentType,
              medicine: item.medicine,
            };
          }
        });
        setLoadingFarms(false);
        return aux;
      } else if (!["Parto", "Reprodução", "Lote"].includes(eventName)) {
        return animals.filter(
          (animal) =>
            !["Novilha", "Cabrita", "Cordeiro"].includes(animal.Categoria) &&
            !animal.arquivado
        );
      }
      return animals.filter((animal) => !animal.arquivado);
    },
    [animals]
  );

  const getCorrectEventAnimalsSpreadsheet = useCallback(() => {
    return animals.filter((animal) => !animal.arquivado);
  }, [animals]);

  function setAnimalsIdArray(animalsParamter) {
    const ids_aux = animalsParamter.map((item) => item.id);
    setAnimalsId(ids_aux);
  }

  function animalImg() {
    if (farmSelected) {
      return animalDict[farmSelected.specie].image;
    }
  }

  function animalBigImg() {
    if (farmSelected) {
      return animalDict[farmSelected.specie].bigImage;
    }
  }

  function getRaces() {
    return animalDict[farmSelected.specie].races;
  }

  function getCategories() {
    return animalDict[farmSelected.specie].categories;
  }

  const getFarmLots = useCallback(async () => {
    try {
      const lots_response = await listLots(farmSelected.farm_id);
      const aux = lots_response.lots.map((item) => ({
        codLote: item.code,
        id: item.id,
        fazenda_id: item.farmId,
      }));
      setLots(aux);
    } catch (err) {
      console.log("Error fetching lots");
    }
  }, [farmSelected]);

  function toggleReload() {
    setReload(!reload);
  }

  return (
    <FarmContext.Provider
      value={{
        farmList,
        farmSelected,
        error,
        animals,
        animalsId,
        animalsSelected,
        lots,
        loadingFarms,
        selectFarm,
        getFarmList,
        haveFarmSelected,
        getAnimals,
        getAnimalById,
        getCorrectEventAnimals,
        getCorrectEventAnimalsSpreadsheet,
        selectAnimals,
        animalImg,
        animalBigImg,
        getRaces,
        getCategories,
        getFarmLots,
        toggleReload,
      }}
    >
      {children}
    </FarmContext.Provider>
  );
}

export const useFarmContext = () => {
  return useContext(FarmContext);
};
