import PageTitle from "../../components/PageTitle/PageTitle"
// import Switch from '../../components/switch/switch'

import FormFarm from "./components/FormFarm/formFarm";
import FormAccount from "./components/FormAccount/formAccount";

import './propertyConfig.css'

function PropertyConfig() {
    return (
        <div className="property-config-body">
            <PageTitle
                path="Configurações"
                backButton
            />

            <div className="form-wrapper">
                <div className="farm-config">
                    <h2 className="config-title">Configurações da Fazenda</h2>
                    <FormFarm />
                </div>
                <div className="account-config">
                    <h2 className="config-title">Configurações da Conta</h2>
                    <FormAccount />
                </div>
                {/* <div className="alert-notifications">
                    <h2 className="config-title">Notificações do Alerta</h2>
                    <div className="notify-alerts">
                        <p>Silenciar Alertas</p>
                        <div className="notify-button">
                            <Switch onClick={() => {}} name="notify-alert" id="notify-alert" />
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default PropertyConfig;