import { newApi } from "../api";

async function resendConfirmationCode(data) {
  try {
    const response = await newApi.post("/user/resend", data);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data.err.code);
  }
}

export default resendConfirmationCode;
