import Lottie from "react-lottie-player";
import { useHistory } from "react-router-dom";

import { useFarmContext } from "../../contexts/farmContext";

import Button from "../../components/button/button";
import PropertyCard from "./components/propertyCard";

import loading_gif from "../../assets/gifs/loading_2.json";
import plus_icon from "../../assets/svg/plus.svg";
import bucket from "../../assets/svg/your-properties/bucket.svg";
import village from "../../assets/svg/your-properties/village.svg";

import { useEffect } from "react";
import "./yourProperties.css";

function YourPropertiesPage() {
  const history = useHistory();

  const {
    loadingFarms,
    farmList,
    error,
    selectFarm,
    toggleReload,
    getFarmList,
  } = useFarmContext();

  function onSelectFarm(uuid) {
    selectFarm(uuid).then(() => {
      history.push("/property-painel");
    });
  }

  useEffect(() => {
    if (!localStorage.getItem("milk-farm")) {
      getFarmList();
    }
  }, []);

  return (
    <div className="your-properties-body">
      <div className="header">
        <div className="text">
          <h1 className="title">Suas Propriedades</h1>
          <h3 className="subtitle">
            Clique na propriedade desejada para ver mais informações
          </h3>
        </div>

        <div className="buttons">
          <div className="button-wrapper"></div>
          <div className="button-wrapper">
            <Button
              className="add-farm-button"
              color="blue"
              onClick={() => history.push("/property-register")}
            >
              Cadastrar fazenda
              <img src={plus_icon} alt="Cadastrar" />
            </Button>
          </div>
        </div>
      </div>

      {farmList.length > 0 ? (
        <div className="properties-wrapper">
          {farmList.map((farm) => {
            return (
              <PropertyCard
                key={farm.farm_id}
                name={farm.name}
                location={farm.city + " - " + farm.state}
                onClick={() => onSelectFarm(farm.farm_id)}
              />
            );
          })}
        </div>
      ) : error ? (
        <div className="no-property">
          <img src={bucket} alt="Algum erro aconteceu" />
          <p className="message">Desculpe, algo aconteceu. Volte mais tarde.</p>
          <div className="button-wrapper">
            <Button
              className="message-button"
              color="blue"
              onClick={() => toggleReload()}
            >
              Tentar novamente
            </Button>
          </div>
        </div>
      ) : loadingFarms ? (
        <div className="no-property">
          <Lottie loop play animationData={loading_gif} />
        </div>
      ) : (
        <div className="no-property">
          <img src={village} alt="Não tem fazendas" />
          <p className="message">
            Nenhuma fazenda por aqui ainda. Comece cadastrando uma fazenda.
          </p>
          <div className="button-wrapper">
            <Button
              className="message-button"
              color="blue"
              onClick={() => history.push("/property-register")}
            >
              Cadastrar fazenda
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default YourPropertiesPage;
