import "./modalDownload.css";

import Button from "../../../../components/button/button";
import Modal from "../../../../components/modal/modal";

import { useFarmContext } from "../../../../contexts/farmContext";
import useSpreadsheet from "../../../../hooks/useSpreadsheet";

export default function ModalDownload({ onClickCancel, showModal }) {
  const {
    farmSelected,
    animalsId,
    animals: farmAnimals,
    getRaces,
    getCategories,
  } = useFarmContext();

  const races = getRaces()
    .map((race) => race.label)
    .join(", ");
  const categories = getCategories()
    .map((category) => category.label)
    .join(", ");

  const { handleCreateSpreadsheet } = useSpreadsheet(
    "Cadastro",
    farmSelected,
    animalsId,
    farmAnimals,
    [],
    races,
    categories
  );

  return (
    <Modal showModal={showModal} close={true}>
      <div className="download-modal-content">
        <p className="title">Baixar planilha para o cadastro de animal?</p>

        <p className="text">
          Ao baixar a planilha você consegue trabalhar offline. Leve a planilha
          com você e preencha-a com atenção. Quando conseguir acesso à internet,
          importe a sua planilha com os dados preenchidos e os animais serão
          cadastrados automaticamente.
        </p>

        <div className="modal-div-buttons">
          <div />
          <Button
            color="white"
            className="modal-button"
            onClick={onClickCancel}
          >
            Cancelar
          </Button>
          <Button
            color="blue"
            className="modal-button"
            onClick={handleCreateSpreadsheet}
          >
            Baixar planilha
          </Button>
        </div>
      </div>
    </Modal>
  );
}
