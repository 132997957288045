import { useEffect, useState } from "react";

import { useEventContext } from "../../../../contexts/eventContext";
import { useFarmContext } from "../../../../contexts/farmContext";

import useForm from "../../../../hooks/useForm";

import {
  lastCCS,
  registerCCS,
} from "../../../../services/events/individualCCS";

import Input from "../../../../components/input/input";
import EventContent from "../../components/eventContent/eventContent";

import alertIcon from "../../../../assets/svg/alerts/alert1.svg";
import arrow_white from "../../../../assets/svg/your-properties/arrow-white.svg";

import { dateToApiDate } from "../../../../utils/dateToApiDate";
import { isDateValid, isPastDate } from "../../../../utils/isDateValid";
import { isNumber } from "../../../../utils/isNumber";
import { separar } from "../../../../utils/separarArray";

import "./individualCCS.css";
import RegisterRow from "./registerRow";

const SortButton = ({ sortColumn, sortDesc, onClick, column }) => {
  return (
    <div className="sort-button" onClick={onClick}>
      <img
        src={arrow_white}
        alt="Arrow"
        className={
          "arrow " + (sortColumn === column && sortDesc ? "down" : "up")
        }
      />
    </div>
  );
};

export default function IndividualCCS() {
  const {
    values,
    errors,
    handleSelectChange,
    handleSubmit,
    handleSetErrors,
    reset,
  } = useForm();
  const { selectedAnimals, handleModalShow, handleModalProps, isCCSHigh } =
    useEventContext();
  const { farmSelected } = useFarmContext();

  const [isLoading, setIsLoading] = useState(false);
  const [infoValue, setInfoValue] = useState("-");

  const [sortDesc, setSortDesc] = useState(false);
  const [sortColumn, setSortColumn] = useState("");

  const [animalsToShow, setAnimalsToShow] = useState(selectedAnimals);

  useEffect(() => {
    async function fetchLastRegister() {
      try {
        const register_response = await lastCCS(
          selectedAnimals[0].id,
          selectedAnimals[0].farmId
        );
        const ccs_value = register_response.ccs.ccs + " cel/ml";
        setInfoValue(ccs_value);
      } catch (err) {}
    }
    if (selectedAnimals.length === 1) {
      fetchLastRegister();
    }
  }, [selectedAnimals]);

  // useEffect(() => {
  //   if (selectedAnimals.length > 0) {
  //     selectedAnimals.forEach(async (animal) => {
  //       try {
  //         const value = await getPartoById(animal.parto_id);
  //         setLastBirts((arr) => [...arr, value.data_parto]);
  //       } catch (err) {}
  //     });
  //   }
  // }, [selectedAnimals]);

  async function checkValues() {
    const error = {};

    Object.keys(values).forEach((key) => {
      if (!values[key].event_date) {
        error[key] = {
          ...error[key],
          event_date: "Este campo é obrigatório",
        };
      } else if (!isDateValid(values[key].event_date)) {
        error[key] = { ...error[key], event_date: "Insira uma data válida" };
      } else if (!isPastDate(values[key].event_date)) {
        error[key] = {
          ...error[key],
          event_date: "Insira uma data no passado ou o dia corrente",
        };
      } else if (errors[key]?.event_date) {
        delete error[key].event_date;
      }

      if (!values[key].ccs) {
        error[key] = { ...error[key], ccs: "Este campo é obrigatório" };
      } else if (!isNumber(parseInt(values[key].ccs))) {
        error[key] = { ...error[key], ccs: "Preencha só com números" };
      } else if (parseInt(values[key].ccs) === 0) {
        error[key] = {
          ...error[key],
          ccs: "Preencha um valor maior que zero",
        };
      } else if (errors[key]?.ccs) {
        delete error[key].ccs;
      }

      if (!values[key].ccs && !values[key].event_date) {
        delete error[key];
      }
    });

    return error;
  }

  async function valuesAreCorrect() {
    try {
      setIsLoading(true);
      const data = Object.keys(values).map((key) => {
        return {
          animalId: key,
          farmId: farmSelected.farm_id,
          eventDate: dateToApiDate(values[key].event_date),
          specieName: farmSelected.apiSpecie,
          ccs: parseInt(values[key].ccs),
        };
      });

      await Promise.all(
        separar(data, 400).map(async (arr) => {
          await registerCCS(arr);
        })
      );

      setIsLoading(false);
      reset();
      handleModalProps({
        boxValue: data.length === 1 ? data[0].ccs : undefined,
        hightValueCCS: data.some((item) => isCCSHigh(item.ccs)),
      });
      handleModalShow(true);
    } catch (e) {
      setIsLoading(false);
      handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
    }
  }

  function handleSort(column) {
    if (column === sortColumn) {
      setSortDesc(!sortDesc);
    } else {
      setSortDesc(true);
      setSortColumn(column);
    }
  }

  function sortedAnimals(animalsAux, column, desc) {
    if (!column) return animalsAux;
    return animalsAux.sort((a, b) => {
      if ((a[column] || "") > (b[column] || "")) {
        return desc ? -1 : 1;
      } else if ((b[column] || "") > (a[column] || "")) {
        return desc ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  function handleSearch(event) {
    if (!event.currentTarget.value) {
      setAnimalsToShow(selectedAnimals);
      return;
    }
    setAnimalsToShow(
      selectedAnimals.filter((item) =>
        item.identification.includes(event.currentTarget.value)
      )
    );
  }

  return (
    <EventContent
      animals={selectedAnimals}
      className="individualCCS"
      eventInfoValue={infoValue}
      onClickSave={handleSubmit(checkValues, valuesAreCorrect, isLoading)}
      isLoading={isLoading}
      apiError={errors.api}
    >
      <div className="search_input">
        <Input
          id="search"
          type="text"
          onChange={handleSearch}
          label="Filtrar por Identificação"
          placeholder="Insira a identificação"
        />
      </div>

      <div className="event_ccs_body">
        <div>
          <div>
            <div className="event_ccs_header">
              <div>
                <p>Identificação</p>
                <SortButton
                  column="identification"
                  sortColumn={sortColumn}
                  sortDesc={sortDesc}
                  onClick={() => handleSort("identification")}
                />
              </div>
              <div>
                <p>Lote</p>
                <SortButton
                  column="lot"
                  sortColumn={sortColumn}
                  sortDesc={sortDesc}
                  onClick={() => handleSort("lot")}
                />
              </div>
              <div>
                <p>Data do Registro*</p>
              </div>
              <div>
                <p>Resultado* (cel/ml)</p>
              </div>
            </div>

            <div className="event_ccs_animals_rows">
              {sortedAnimals(animalsToShow, sortColumn, sortDesc).map(
                (animal) => {
                  return (
                    <RegisterRow
                      key={animal.id}
                      definedValues={{
                        identification: animal.Identificação,
                        lot: animal.Lote,
                      }}
                      isCCSHigh={isCCSHigh}
                      errors={errors[animal.id]}
                      handleAnimalValues={(value) => {
                        if (!Object.keys(value).length) return;
                        handleSelectChange(animal.id, value);
                      }}
                    />
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>

      {Object.values(values).some((item) => isCCSHigh(item.ccs)) && (
        <div className="hight-ccs-alert">
          <img src={alertIcon} alt="Ícone de alerta" className="alert-icon" />
          <p>Valor de CCS muito alto</p>
        </div>
      )}
    </EventContent>
  );
}
