import { createRef, useEffect } from 'react';
import * as d3 from 'd3';
import drawAxis from './axis';
import drawTooltip from './tooltip';

import './baseChart.css';

const BaseChart = (drawChart, extraProps) => {
  function Chart(props) {
    const svgRef = createRef();
    const tooltipRef = createRef();
    const { axisProps, data, data2, svgProps, fixAxis, tooltipClass, scaleBandPadding, noDrawtooltipContent, ...restProps } = props;
    const {useScaleBands, findHoverData, paddingInner, isAreaChart } = extraProps;

    const { margin, width, height } = svgProps;
    var yMaxValue = d3.max(data, (d) => Number(d.value));
    var xMinValue = d3.min(data, (d) => d.label);
    var xMaxValue = d3.max(data, (d) => d.label);

    if(data2){
      const yMaxValue1 = d3.max(data, (d) => Number(d.value));
      const xMinValue1 = d3.min(data, (d) => d.label);
      const xMaxValue1 = d3.max(data, (d) => d.label);
      const yMaxValue2 = d3.max(data2, (d) => Number(d.value));
      const xMinValue2 = d3.min(data2, (d) => d.label);
      const xMaxValue2 = d3.max(data2, (d) => d.label);
      yMaxValue = yMaxValue1 > yMaxValue2 ?  yMaxValue1 : yMaxValue2
      xMinValue = xMinValue1 > xMinValue2 ?  xMinValue1 : xMinValue2
      xMaxValue = xMaxValue1 > xMaxValue2 ?  xMaxValue1 : xMaxValue2
    }

    // X SCALE
    
    let xScale = d3
      .scaleLinear()
      .domain([xMinValue, xMaxValue])
      .range([0, width]);
    
    // Axis X fixo
    if (fixAxis){
      xScale = d3
      .scaleLinear()
      .domain([fixAxis[0], fixAxis[1]])
      .range([0, width]);
    }
    
    if (useScaleBands.x) {
      xScale = d3.scaleBand()
        .range([0, width])
        .domain(data.map((d) => d.label))
        .paddingInner(paddingInner);
        // .padding(scaleBandPadding);
    }

    // Y SCALE
    let yScale = d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, yMaxValue]);
    if (useScaleBands.y) {
      yScale = d3.scaleBand()
        .range([height, 0])
        .domain(data.map((d) => d.value))
        .padding(scaleBandPadding);
    }

    // axis Y fixo
    if (fixAxis){
      yScale = d3
      .scaleLinear()
      .range([height, 0])
      .domain([fixAxis[0], fixAxis[1]])
    }
    // **************************


    useEffect(() => {
      flushChart();
      draw();
    });

    function flushChart() {
      d3.select(svgRef.current).selectAll('*').remove();
    }

    function draw() {
      // const svg
      d3
        .select(svgRef.current)
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      drawAxis({
        ...axisProps,
        ...svgProps,
        ...extraProps,
        data,
        svgRef,
        xScale,
        yScale,
      });

      drawChart({
        svgRef,
        data,
        data2,
        xScale,
        yScale,
        ...svgProps,
        ...restProps,
      });

      if (useScaleBands.x && !noDrawtooltipContent){
        drawTooltip({
          useScaleBands,
          svgRef,
          tooltipRef,
          data,
          data2,
          xScale,
          yScale,
          isAreaChart,
          findHoverData,
          ...svgProps,
          ...restProps,
        });
      }
    }
    return (
      data.find(elemento => elemento.value !== 0) || (data2 && data2.find(elemento => elemento.value !== 0) )?
      <div className="base__container">
        <svg
          ref={svgRef}
          className={'base__svg-container'}
        />
        <div className={'base__tooltip'} ref={tooltipRef}></div>
      </div>
      :
      <div className='noData'>
        <p>Não há dados na data selecionada</p>
      </div>
      
    )
  }

  Chart.defaultProps = {
    scaleBandPadding: 0.05,
  }

  return Chart;
}
export default BaseChart;
