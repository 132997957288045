import { format, fromUnixTime, isSameMonth, startOfMonth } from "date-fns";
import { useEffect, useState } from "react";
// import DatePicker from "react-datepicker";
import { useFarmContext } from "../../../../contexts/farmContext";
import { getDryingInfo } from "../../../../services/report/getDryingInfo";
import { apiDateToFormatedDate } from "../../../../utils/dateToApiDate";
import { timestampToDate } from "../../../../utils/timestampToDate";
import { Box, Row, SectionHeader, TextInfo } from "../bases/bases";
import "./dryCowTherapy.css";

export function DryCowTherapy(props) {
  const { farmSelected } = useFarmContext();

  // const [expectedDrying, setExpectedDrying] = useState([]);
  const [numberExpectedDrying, setNumberExpectedDrying] = useState("-");
  const [expectedAnimals, setExpectedAnimals] = useState([]);
  // const [allExpectedDrying, setAllExpectedDrying] = useState([]);

  // const [performedDrying, setPerformedDrying] = useState([]);
  const [numberPerformedDrying, setNumberPerformedDrying] = useState("-");
  const [performedAnimals, setPerformedAnimals] = useState([]);
  // const [allPerformedDrying, setAllPerformedDrying] = useState([]);

  function getExpectedDryingInTheMonth(data) {
    const dryingInTheMonth = data
      // .filter((item) => isSameMonth(date, fromUnixTime(item.data_prevista)))
      .map((item, index) => {
        return {
          id: "d" + index,
          Identificação: item.identification,
          Data_Prevista: apiDateToFormatedDate(item.expectedDate),
          Lote: item.lot,
        };
      });
    return dryingInTheMonth;
  }

  function getPerformedDryingInTheMonth(data, date) {
    const dryingInTheMonth = data
      .filter((item) =>
        isSameMonth(date, new Date(item.eventDate.slice(0, -1)))
      )
      .map((item, index) => {
        return {
          id: "d" + index,
          Identificação: item.identification,
          Data_Realizada: apiDateToFormatedDate(item.eventDate),
          Lote: item.lot,
        };
      });
    return dryingInTheMonth;
  }

  // function setNewDate(date) {
  //   const aux_animaisPrevistos = getExpectedDryingInTheMonth(
  //     allExpectedDrying,
  //     date
  //   );
  //   setNumberExpectedDrying(aux_animaisPrevistos.length);
  //   setExpectedAnimals(aux_animaisPrevistos);

  //   const aux_animaisPerformed = getPerformedDryingInTheMonth(
  //     allPerformedDrying,
  //     date
  //   );
  //   setNumberPerformedDrying(aux_animaisPerformed.length);
  //   setPerformedAnimals(aux_animaisPerformed);
  // }

  useEffect(() => {
    async function fetchData() {
      try {
        if (farmSelected) {
          const aux_data = await getDryingInfo(
            farmSelected.farm_id,
            props.date
          );

          // Previstos

          // setAllExpectedDrying(aux_data.animals_prevista);
          // var aux_expectedDrying = aux_data.secagensPrevistas.map((item) => {
          //   return new Date(item * 1000);
          // });
          // setExpectedDrying(aux_expectedDrying);

          const aux_animaisPrevistos = getExpectedDryingInTheMonth(
            aux_data.animals_expectedDryings
          );
          setNumberExpectedDrying(aux_animaisPrevistos.length);
          setExpectedAnimals(aux_animaisPrevistos);

          // Realizados

          // setAllPerformedDrying(aux_data.animals_realizada);
          // var aux_performedDrying = aux_data.secagensRealizadas.map((item) => {
          //   return new Date(item * 1000);
          // });
          // setPerformedDrying(aux_performedDrying);

          const aux_animaisRealizados = getPerformedDryingInTheMonth(
            aux_data.animals_performedDryings,
            new Date(props.date * 1000)
          );
          setNumberPerformedDrying(aux_animaisRealizados.length);
          setPerformedAnimals(aux_animaisRealizados);
        }
      } catch (e) {
        console.log("Error fetching data - Drying: " + e);
      }
    }
    fetchData();
  }, [farmSelected, props.date]);

  // const highlightWithRangesDryCowTherapy = [
  //   {
  //     "react-datepicker__day--highlighted-custom-1": performedDrying,
  //   },
  //   {
  //     "react-datepicker__day--highlighted-custom-2": expectedDrying,
  //   },
  // ];

  return (
    <>
      <SectionHeader title="Terapia da vaca seca" />

      <Row className="calendar-row">
        {/* <Box className="medium-calendar">
          <DatePicker
            // selected={startDate}
            // onChange={(date) => setStartDate(date)}
            onMonthChange={(date) => setNewDate(date)}
            inline
            disabledKeyboardNavigation
            highlightDates={highlightWithRangesDryCowTherapy}
            locale="pt"
          >
            <div className="wrapper-calendar-label">
              <div> Realizadas</div>
              <div> Previstas</div>
            </div>
          </DatePicker>
        </Box> */}
        {/* {expectedDrying && ( */}
        {/* <Column className="calendar-column"> */}
        <Box
          className="small"
          viewMore={expectedAnimals.length > 0}
          viewMoreTitle="Número de secagens previstas"
          animals={expectedAnimals}
          columns={["Identificação", "Lote", "Data_Prevista"]}
        >
          <TextInfo
            className="warning"
            number={numberExpectedDrying}
            latter={
              (numberExpectedDrying === 1
                ? "secagem prevista"
                : "secagens previstas") +
              ` a partir de ${timestampToDate(props.date)}.`
            }
          />
        </Box>
        <Box
          className="small"
          viewMore={performedAnimals.length > 0}
          viewMoreTitle="Número de secagens realizadas"
          animals={performedAnimals}
          columns={["Identificação", "Lote", "Data_Realizada"]}
        >
          <TextInfo
            className="warning"
            number={numberPerformedDrying}
            latter={
              (numberPerformedDrying === 1
                ? "secagem realizada"
                : "secagens realizadas") +
              ` no período de ${format(
                startOfMonth(fromUnixTime(props.date)),
                "dd/MM/yyyy"
              )} a ${timestampToDate(props.date)}.`
            }
          />
        </Box>
        {/* </Column> */}
        {/* )} */}
      </Row>
    </>
  );
}
