import "./barChart.css";
import * as d3 from "d3";
import { useRef, useEffect, useState, useCallback } from "react";
import { getMinMaxByProperty } from "../../../utils/getMinMaxByProperty";
import expand_icon from "../../../assets/svg/property-painel/expand.svg";

function BarChart({ data_, reverseValues, expandItens, grayBorder, unit, min, max }) {
  const barRef = useRef(null);

  const [expand, setExpand] = useState(false);
  const [showExpand, setShowExpand] = useState(false);

  function handleOptionClick() {
    setExpand(!expand);
  }

  const draw = useCallback(() => {
    const { min_, max_ } = getMinMaxByProperty(data_, "value");
    if (!min){
      min = min_
    }
    if (!max){
      max = max_
    }

    function styleFontWeight(d) {
      if (parseFloat(d.value) <= min || parseFloat(d.value) >= max) {
        return "bold";
      } else return "none";
    }

    const ContainerLabel = d3
      .select(barRef.current)
      .insert("div")
      .classed("countainer-label", true);

    // AnimalLabel (y Values)
    ContainerLabel.selectAll(".animal-label")
      .data(data_)
      .enter()
      .append("div")
      .classed("animal-label", true)
      .style("color", function (d) {
        if (
          (!reverseValues && parseFloat(d.value) < min) ||
          (reverseValues && parseFloat(d.value) > max)
        ) {
          return "#FF8D2B";
        }
        if (
          (!reverseValues && parseFloat(d.value) > max) ||
          (reverseValues && parseFloat(d.value) < min)
        ) {
          return "#165578";
        } else return "#A3B1B";
      })
      .style("font-weight", (d) => styleFontWeight(d))
      .text((dta) => dta.label);

    const CountainerData = d3
      .select(barRef.current)
      .insert("div")
      .classed("countainer-data", true);

    // Bar Wrapper
    CountainerData.selectAll(".line-border")
      .data(data_)
      .enter()
      .append("div")
      .classed("line-border", true)
      .classed("line-border-gray", grayBorder);

    // Bars
    CountainerData.selectAll(".line-border")
      .append("div")
      .classed("bar", true)
      .style("height", "15px")
      .data(data_)
      .transition()
      .duration(1000)
      .style("width", (data) => (data.value / max) * 90 + "%")
      .style("background", function (d) {
        if (
          (!reverseValues && parseFloat(d.value) < min) ||
          (reverseValues && parseFloat(d.value) > max)
        ) {
          return "linear-gradient(90deg, rgba(222,222,222,1) 0%, rgba(255,141,43,0.7) 70%)";
        } else if (
          (!reverseValues && parseFloat(d.value) > max) ||
          (reverseValues && parseFloat(d.value) < min)
        ) {
          return "linear-gradient(90deg, rgba(5,166,252,0.8) 0%, rgba(22,85,120,0.8) 70%)";
        } else {
          return "linear-gradient(90deg, rgba(222,222,222,1) 0%, rgba(5,166,252,0.7) 60%)";
        }
      });

    // Label
    CountainerData.selectAll(".line-border")
      .data(data_)
      .append("div")
      .classed("p-text", true)
      .style("white-space", "nowrap")
      .style("text-overflow", "ellipsis")
      .style("font-weight", (d) => styleFontWeight(d))
      .text((dta) => dta.value + " " + unit);
  }, [data_, grayBorder, reverseValues, unit]);

  useEffect(() => {
    draw();
    if (barRef.current) {
      if (barRef.current.offsetHeight > 270) {
        setShowExpand(true);
      }
    }
  }, [draw]);

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!barRef.current.contains(event.target)) {
        setExpand(false);
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return (
    <>
      <div className={"graph-wrapper expander-" + expand} ref={barRef}></div>
      {expandItens === true && showExpand && (
        <div
          className={"expander display-" + expand}
          onClick={() => handleOptionClick()}
        >
          {<img id="expand_icon" src={expand_icon} alt="Filtro" />}
        </div>
      )}
    </>
  );
}

export default BarChart;
