import { newApi } from "../api";

export async function registerCulture(data) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.post("/culture", data, config);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}

export async function lastCulture(animal_id, farm_id) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.get(
      `culture/last/${farm_id}/${animal_id}`,
      config
    );
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}
