import { useState } from "react";

import { useAlertContext } from "../../../../../contexts/alertContext";
import { useFarmContext } from "../../../../../contexts/farmContext";

import Button from "../../../../../components/button/button";
import Input from "../../../../../components/input/input";
import Modal from "../../../../../components/modal/modal";

import { dateToTimestamp } from "../../../../../utils/dateToTimestamp";
import { isDateValid } from "../../../../../utils/isDateValid";

import calendar from "../../../../../assets/svg/alerts/calendar.svg";

import "./ModalNewDate.css";

// modalName = newDate
export default function ModalNewDate() {
  const { animalImg } = useFarmContext();
  const {
    modalProps,
    handleModalShow,
    modalUnderstand,
    handleModalProps,
    handleModals,
  } = useAlertContext();
  const { animals, type, allAnimals } = modalProps;

  const [inputError, setInputError] = useState({});
  const [inputValue, setInputValue] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  function handleCancel() {
    handleModals({ modal_name: "birth", animals: allAnimals });
  }

  function handleInputNewDate(event) {
    setInputValue({ ...inputValue, [event.target.name]: event.target.value });
    setInputError({ ...inputError, [event.target.name]: undefined });
  }

  async function handleSaveNewDate() {
    const erros = {};

    for (var animal in inputValue) {
      if (!isDateValid(inputValue[animal])) {
        erros[animal] = "Insira uma data válida";
      }
    }

    for (animal in animals) {
      if (!inputValue[animals[animal].id]) {
        erros[animals[animal].id] = "Insira uma nova data";
      }
    }

    setInputError(erros);

    if (Object.keys(erros).length === 0) {
      setIsLoading(true);
      try {
        const data = [];
        for (animal in animals) {
          animals[animal].Data_prevista = inputValue[animals[animal].id];
          data.push({
            data: dateToTimestamp(inputValue[animals[animal].id]),
            id: animals[animal].alert_id,
            animal_id: animals[animal].id,
          });
        }

        // Salva as novas datas no backend
        handleModalProps({ animals: animals, type: type });
        if (type === "secagem") {
          await modalUnderstand("updateDateDrying", "", data);
        } else if (type === "parto") {
          await modalUnderstand("updateDateBirth", "", data);
        } else if (type === "mastite") {
          await modalUnderstand("endTreatment", "", data);
        }
      } catch (e) {
        setIsLoading(false);
        console.log("Error");
      }
    }
  }

  return (
    <Modal showModal={handleModalShow} close={true}>
      <div className="newdate-modal-content">
        <p className="title">{"Nova data de " + type}</p>
        <div className="newDate">
          {animals.map((animal, index) => {
            return (
              <div key={index + animal} className="newdate-wrapper">
                <div className="name-icon">
                  <img
                    src={animalImg()}
                    alt="Ícone de Animal"
                    className="animal-icon"
                  />
                  <p>{animal.Identificação}</p>
                </div>

                <div className="date-wrapper">
                  <Input
                    label={"Nova data de " + type}
                    id={animal.id}
                    className="date-input"
                    type="text"
                    placeholder={
                      type === "mastite" ? "dd/mm/aaaa" : animal.Data_prevista
                    }
                    mask="99/99/9999"
                    value={inputValue[animal.id]}
                    error={inputError[animal.id]}
                    onChange={(event) => handleInputNewDate(event)}
                    errorAlignColumn
                  />
                  <div className="date-icon">
                    <img
                      src={calendar}
                      alt="Ícone de Calendário"
                      className="img-date"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="modal-div-buttons">
          <div />
          <Button color="white" className="modal-button" onClick={handleCancel}>
            Cancelar
          </Button>
          <Button
            color="blue"
            className="modal-button"
            isLoading={isLoading}
            onClick={handleSaveNewDate}
          >
            Confirmar nova data
          </Button>
        </div>
      </div>
    </Modal>
  );
}
