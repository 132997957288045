import * as d3 from "d3";
import { useRef, useEffect, useCallback } from "react";
import { getOthersAndTop5 } from "../../../utils/getOthersAndTop5";

import "./progressLine.css";

function colorDefChart(d, index) {
  if (index === 0) {
    return "#2287BF";
  } else if (index === 1) {
    return "#05A6FC";
  } else if (index === 2) {
    return "#165578";
  } else if (index === 3) {
    return "#1F7AAB";
  } else if (index === 4) {
    return "#09202D";
  } else if (index === 5) {
    return "#A3B1B9";
  }
}

export default function ProgressLine({ data_, unit, default_text}) {
  const lineRef = useRef(null);
  const top5 = getOthersAndTop5(data_);

  const draw_graph = useCallback(() => {
    const CountainerData = d3
      .select(lineRef.current)
      .insert("div")
      .classed("countainer-data", true);

    const barWrapper = CountainerData.insert("div").classed(
      "line-border",
      true
    );

    // Bar
    barWrapper
      .selectAll(".bar-proguess")
      .data(top5)
      .enter()
      .append("div")
      .classed("bar-proguess", true)
      .transition()
      .duration(500)
      .style("width", (data) => data.value + "%")
      .style("background", colorDefChart)
      .style("box-shadow", function (d, index) {
        return "0 0 0 10px " + colorDefChart(d, index);
      });

    const showText = window.screen.width;
    if (showText > 900) {
      // Bar Text
      CountainerData.selectAll(".bar-proguess")
        .data(top5)
        .append("div")
        .classed("p-text", true)
        .text((dta) => dta.value + unit);
    }

    const CountainerLabel = d3
      .select(lineRef.current)
      .insert("div")
      .classed("countainer-label", true);

    const wrapperLabels = CountainerLabel.selectAll(".wrapper-labels")
      .data(top5)
      .enter()
      .append("div")
      .classed("wrapper-labels", true);

    const wrapperLabelFirst = wrapperLabels
      .append("div")
      .classed("wrapper-label-first", true);

    // Label Circle
    wrapperLabelFirst
      .append("div")
      .classed("label-circle", true)
      .style("background", colorDefChart);

    // Label Percentage
    wrapperLabelFirst
      .append("div")
      .classed("label-proguess", true)
      .text((dta) => dta.value + unit)
      .style("color", colorDefChart);

    // Label Text
    wrapperLabels
      .append("div")
      .classed("wrapper-label-second", true)
      .style("color", colorDefChart)
      .html(function (d) {
        return d.name;
      });
  }, [top5, unit]);

  useEffect(() => {
    if (data_.length)
    draw_graph();
  }, [draw_graph]);

  return (
    <>
    {data_.length ?
      <div className={"progressLine"} ref={lineRef}></div>
      :
      <div className="progressLine default_text">
        {default_text}
      </div>
    }
    </>
  );
}
