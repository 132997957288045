import { useState } from "react";

import { useEventContext } from "../../../../contexts/eventContext";
import useForm from "../../../../hooks/useForm";

import "./mastitis.css";

import Input from "../../../../components/input/input";
import EventContent from "../../components/eventContent/eventContent";

import { isBefore, parse } from "date-fns";
import { updateCarenciaMastitis } from "../../../../services/alerts/updateCarenciaMastitis";
import { dateToApiDate } from "../../../../utils/dateToApiDate";
import { isDateValid, isPastDate } from "../../../../utils/isDateValid";
import { timestampToDate } from "../../../../utils/timestampToDate";
import AddTreatment from "./addTreatment";

function TreatementMastitis() {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleSelectChange,
    reset,
  } = useForm();
  const { selectedAnimals, handleModalShow, handleModalProps } =
    useEventContext();

  const [shortageTime, setShortageTime] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  async function checkValues() {
    const error = {};

    selectedAnimals.forEach((animal) => {
      if (animal.data_tratamento === "-") {
        if (!values.date_treatment) {
          error.date_treatment = "Este campo é obrigatório";
        } else if (!isDateValid(values.date_treatment)) {
          error.date_treatment = "Insira uma data válida";
        } else if (
          isBefore(
            parse(values.date_treatment, "dd/MM/yyyy", new Date()),
            parse(
              timestampToDate(animal.data_diagnostico),
              "dd/MM/yyyy",
              new Date()
            )
          )
        ) {
          error.date_treatment =
            "Insira uma data posterior a data do diagnóstico - " +
            timestampToDate(animal.data_diagnostico);
        } else if (errors.date_treatment) {
          delete error.date_treatment;
        }

        if (!values.treatmentType) {
          error.treatmentType = "Este campo é obrigatório";
        } else if (errors.treatmentType) {
          delete error.treatmentType;
        }

        if (
          !values.drug &&
          !values.drug_intramamario &&
          !values.drug_sistemico
        ) {
          error.drug = "Este campo é obrigatório";
        } else if (errors.drug) {
          delete error.drug;
        }
      } else {
        if (!values[animal.id]) {
          error[animal.id] = "Este campo é obrigatório";
        } else if (!isDateValid(values[animal.id])) {
          error[animal.id] = "Insira uma data válida";
        } else if (!isPastDate(values[animal.id])) {
          error[animal.id] = "Insira uma data no passado ou dia corrente";
        } else if (
          isBefore(
            parse(values[animal.id], "dd/MM/yyyy", new Date()),
            parse(animal.data_tratamento, "dd/MM/yyyy", new Date())
          )
        ) {
          error.date_treatment =
            "Insira uma data posterior a data do tratamento";
        } else if (errors[animal.id]) {
          delete error[animal.id];
        }
      }
    });

    return error;
  }

  async function valuesAreCorrect() {
    let boxValue = "";
    if (selectedAnimals.length > 1) {
      boxValue = undefined;
    } else {
      for (let value in values) {
        boxValue = values[value];
      }
    }

    try {
      setIsLoading(true);
      const data = [];
      // for (let value in values) {
      data.push({
        // tratamento
        ...(values.date_treatment && {
          treatmentDate: dateToApiDate(values.date_treatment),
          treatmentType: values.treatmentType,
          medicine:
            values.treatmentType === "Intramamário e Sistêmico"
              ? values.drug_intramamario + " e " + values.drug_sistemico
              : values.drug,
          lack: shortageTime,
        }),
        // data final tratamento
        ...(values[selectedAnimals[0].id] && {
          endTreatmentDate: dateToApiDate(values[selectedAnimals[0].id]),
          // flagEndTreatment: true,
        }),
        mastitisId: selectedAnimals[0].event_id,
        animalId: selectedAnimals[0].id,
        farmId: selectedAnimals[0].farmId,
      });
      // }
      await updateCarenciaMastitis(data);

      setIsLoading(false);
      reset();
      handleModalProps({ boxValue: boxValue });
      handleModalShow(true);
    } catch (e) {
      setIsLoading(false);
      console.log("Error");
    }
  }

  return (
    <EventContent
      animals={selectedAnimals}
      className="mastitis"
      eventInfoValue={
        selectedAnimals.length > 1 ? "-" : selectedAnimals[0]?.medicamento
      }
      onClickSave={handleSubmit(checkValues, valuesAreCorrect, isLoading)}
      isLoading={isLoading}
      apiError={errors.api}
    >
      {selectedAnimals.map((animal) => {
        if (animal.data_tratamento === "-") {
          return (
            <div key={animal.id} className="input-wrapper">
              {selectedAnimals.length > 1 && (
                <div className="animal-name">
                  <p id="reproduction-animal-name">
                    <b>{animal.Identificação}</b>
                  </p>
                </div>
              )}
              <AddTreatment
                shortageTime={shortageTime}
                handleShortageTime={setShortageTime}
                errors={errors}
                handleChange={handleChange}
                handleSelectChange={handleSelectChange}
                values={values}
                open
              />
            </div>
          );
        } else {
          return (
            <div key={animal.id} className="input-wrapper">
              {selectedAnimals.length > 1 && (
                <div className="animal-name">
                  <p id="reproduction-animal-name">
                    <b>{animal.Identificação}</b>
                    {" (" + animal.medicamento + ")"}
                  </p>
                </div>
              )}
              <Input
                id={animal.id}
                label="Data final do tratamento"
                errorAlignColumn
                error={errors[animal.id]}
                value={values[animal.id]}
                type="text"
                onChange={handleChange}
                mask="99/99/9999"
                placeholder="dd/mm/aaaa"
              />
            </div>
          );
        }
      })}
    </EventContent>
  );
}

export default TreatementMastitis;
