import { newApi } from "../api";

async function newPassword(data) {
  try {
    const response = await newApi.post("/user/password", data);
    return response.data;
  } catch (err) {
    throw new Error(err.response);
  }
}

export default newPassword;
