import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useFarmContext } from "../../../../contexts/farmContext";
import useForm from "../../../../hooks/useForm";

import { deleteFarm } from "../../../../services/farm/deleteFarm";
import updateFarm from "../../../../services/farm/updateFarm";

import Button from "../../../../components/button/button";
import Input from "../../../../components/input/input";
import Select from "../../../../components/select/select";

import ModalFarm from "../ModalFarm/modalFarm";

import buffalo_icon from "../../../../assets/svg/property-register/buffalo.svg";
import cow_icon from "../../../../assets/svg/property-register/cow.svg";
import goat_icon from "../../../../assets/svg/property-register/goat.svg";
import sheep_icon from "../../../../assets/svg/property-register/sheep.svg";
import trash_icon from "../../../../assets/svg/trash.svg";
import { useUserContext } from "../../../../contexts/userContext";

const options = [
  { icon: cow_icon, label: "Vacas" },
  { icon: buffalo_icon, label: "Búfalas" },
  { icon: sheep_icon, label: "Ovelhas" },
  { icon: goat_icon, label: "Cabras" },
];

const apiSpecie = {
  Vacas: "cow",
  Búfulas: "buffalo",
  Ovelhas: "sheep",
  Cabras: "goat",
};

export default function FormFarm() {
  const history = useHistory();

  const { userID } = useUserContext();
  const { farmSelected, getFarmList, selectFarm } = useFarmContext();
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleSelectChange,
    handleSelectStateChange,
    cleanErrors,
    handleSetErrors,
    updateValues,
  } = useForm();

  const [showModalFarm, setShowModalFarm] = useState(false);
  const [isEditFarmEnabled, setIsEditFarmEnabled] = useState(true);
  const [states, setStates] = useState([]);
  const [rawStates, setRawStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [saveAvailable, setSaveAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleStateChange = useCallback(
    (option) => {
      rawStates.forEach((state) => {
        if (state.nome === option.label) {
          getCity(state.id);
          handleSelectStateChange(state.nome);
        }
      });
    },
    [handleSelectStateChange, rawStates]
  );

  useEffect(() => {
    rawStates.length > 0 &&
      farmSelected.state &&
      handleStateChange({ label: farmSelected.state });
  }, [rawStates, farmSelected, handleStateChange]);

  useEffect(() => {
    const url = "https://servicodados.ibge.gov.br/api/v1/localidades/estados";
    axios.get(url).then((res) => {
      const auxStates = res.data.map((state) => {
        return { label: state.nome };
      });
      setRawStates(res.data);
      setStates(auxStates);
    });
  }, []);

  function getCity(value) {
    const url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`;
    axios.get(url).then((res) => {
      const auxCities = res.data.map((city) => {
        return { label: city.nome };
      });
      setCities(auxCities);
    });
  }

  function handleCancelEditFarm() {
    setIsEditFarmEnabled(true);
    setSaveAvailable(false);
    cleanErrors();
    updateValues({});
  }

  async function checkFarmValues() {
    const error = {};

    if (!saveAvailable) {
      error.invisible = true;
      return error;
    }

    if (!values.farm_name) {
      error.farm_name = "Este campo é obrigatório";
    } else if (errors.farm_name) {
      delete error.farm_name;
    }

    if (!values.state) {
      error.state = "Este campo é obrigatório";
    } else if (errors.state) {
      delete error.state;
    }

    if (!values.city) {
      error.city = "Este campo é obrigatório";
    } else if (errors.city) {
      delete error.city;
    }

    if (!values.specie) error.specie = "Este campo é obrigatório";
    else if (errors.specie) {
      delete error.specie;
    }

    if (errors.invisible) {
      delete error.invisible;
    }

    return error;
  }

  async function farmValuesAreCorrect() {
    try {
      setIsLoading(true);
      const data = {
        id: farmSelected.farm_id,
        userId: userID,
        name: values.farm_name,
        city: values.city,
        specie: apiSpecie[values.specie],
        state: values.state,
        complement: values.complement ?? null,
      };
      await updateFarm(data);
      getFarmList();
      setIsEditFarmEnabled(true);
    } catch (err) {
      setIsLoading(false);
      handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
    }
  }

  function handleClickEditFarm() {
    updateValues({
      farm_name: farmSelected?.name,
      city: farmSelected?.city,
      specie: farmSelected?.specie,
      state: farmSelected?.state,
    });
    setIsEditFarmEnabled(false);
  }

  async function handleDeleteFarm() {
    try {
      setIsLoading(true);
      localStorage.removeItem("milk-farm");
      await deleteFarm({ farmId: farmSelected.farm_id, userId: userID });
      // selectFarm();
      // getFarmList();
      history.push("/your-properties");
    } catch (err) {
      setIsLoading(false);
      handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(
          checkFarmValues,
          farmValuesAreCorrect,
          isLoading
        )}
      >
        <Input
          id="farm_name"
          label="Nome da Propriedade"
          className="px400-input"
          errorAlignColumn
          error={errors.farm_name}
          value={values.farm_name}
          type="text"
          placeholder={
            isEditFarmEnabled
              ? farmSelected?.name
              : "Insira o nome da propriedade"
          }
          disabled={isEditFarmEnabled}
          onChange={handleChange}
        />
        <p className="form-localization">Localização da Propriedade:</p>
        <Input
          id="complement"
          label="Complemento"
          errorAlignColumn
          className="px400-input"
          error={errors.complement}
          value={values.complement}
          type="text"
          placeholder={isEditFarmEnabled ? "" : "Insira o complemento"}
          disabled={isEditFarmEnabled}
          onChange={handleChange}
        />
        <div className="form-row">
          <Select
            search
            id="state"
            label="Estado"
            options={states}
            error={errors.state}
            className="px400-input"
            disabled={isEditFarmEnabled}
            inputPlaceholder="Pesquisar estado"
            onChange={(index) => handleStateChange(index)}
            value={values.state === "-1" ? undefined : values.state}
            placeholder={
              isEditFarmEnabled ? farmSelected?.state : "Insira o estado"
            }
          />
          <Select
            search
            id="city"
            label="Cidade"
            options={cities}
            error={errors.city}
            className="px400-input"
            disabled={isEditFarmEnabled}
            inputPlaceholder="Pesquisar estado"
            value={values.city === "-1" ? undefined : values.city}
            onChange={(value) => handleSelectChange("city", value.label)}
            placeholder={
              isEditFarmEnabled ? farmSelected?.city : "Insira a cidade"
            }
          />
        </div>
        <div className="form-row">
          <Select
            id="species-config"
            className="px400-input"
            errorAlignColumn={true}
            label="Espécies"
            placeholder={
              isEditFarmEnabled ? farmSelected?.specie : "Selecione a espécie"
            }
            error={errors.specie}
            value={values.specie}
            disabled={isEditFarmEnabled}
            onChange={(value) => handleSelectChange("specie", value.label)}
            options={options}
          />
        </div>

        <div className="form-row-end">
          <div className="buttons-wrapper">
            {isEditFarmEnabled ? (
              <Button
                className="button-type"
                color="white"
                type="button"
                onClick={handleClickEditFarm}
              >
                Editar
              </Button>
            ) : (
              <Button
                className="button-type"
                color="blue"
                type="submit"
                onClick={() => setSaveAvailable(true)}
                isLoading={isLoading}
              >
                Salvar
              </Button>
            )}
            {isEditFarmEnabled ? (
              <Button
                className="button-type"
                color="white"
                type="button"
                onClick={() => setShowModalFarm(true)}
              >
                Excluir
                <img
                  src={trash_icon}
                  alt="Lixeira ícone"
                  className="img_trash"
                />
              </Button>
            ) : (
              <Button
                className="button-type"
                color="white"
                type="button"
                onClick={handleCancelEditFarm}
              >
                Cancelar
              </Button>
            )}
          </div>
        </div>
      </form>

      {showModalFarm && (
        <ModalFarm
          onClickCancel={() => setShowModalFarm(false)}
          onClickSubmit={handleDeleteFarm}
          isLoading={isLoading}
        />
      )}
    </>
  );
}
