import { useEffect, useState } from "react";

import { useEventContext } from "../../../../contexts/eventContext";
import { useFarmContext } from "../../../../contexts/farmContext";
import useForm from "../../../../hooks/useForm";

import { updateAnimalLot } from "../../../../services/events/lot";
import { registerLot } from "../../../../services/lot/registerLot";

import Input from "../../../../components/input/input";
import Select from "../../../../components/select/select";
import EventContent from "../../components/eventContent/eventContent";

import { separar } from "../../../../utils/separarArray";
import "./lot.css";

export default function Lot() {
  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    handleSelectChange,
    handleSetErrors,
    reset,
  } = useForm();
  const { selectedAnimals, handleModalShow, handleModalProps } =
    useEventContext();
  const { lots, getFarmLots, getAnimals, farmSelected } = useFarmContext();

  const [lotOptions, setLotOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [infoValue, setInfoValue] = useState("-");

  // useEffect(() => {
  //   async function fetchLastRegister() {
  //     try {
  //       const register_response = await lastLotUpdate(selectedAnimals[0].id);
  //       if (register_response !== "") {
  //         const date = fromUnixTime(register_response);
  //         const value = format(date, "dd/MM/yyyy");
  //         setInfoValue(value);
  //       }
  //     } catch (err) {}
  //   }
  //   if (selectedAnimals.length === 1) {
  //     fetchLastRegister();
  //   }
  // }, [selectedAnimals]);

  useEffect(() => {
    if (lots.length === 0) getFarmLots();
  }, [getFarmLots, lots.length]);

  useEffect(() => {
    if (lots.length > 0) {
      const lot_options_aux = lots.map((item) => {
        return { label: item.codLote, value: item.id };
      });
      setLotOptions(lot_options_aux);
    }
  }, [lots]);

  async function checkValues() {
    const error = {};

    if (
      !values.name &&
      //  || !values.number || !values.letter
      !values.lot_option
    ) {
      error.lot = "Informar um lote é obrigatório";
    } else if (errors.lot) {
      delete error.lot;
    }

    return error;
  }

  async function valuesAreCorrect() {
    try {
      setIsLoading(true);
      var lot_response;
      if (
        values.name
        // && values.number && values.letter
      ) {
        // const lot_data = {
        //   codLote: values.name + "-" + values.number + "-" + values.letter,
        //   fazenda_id: farmSelected.farm_id,
        // };
        // lot_response = await registerLot(lot_data);

        const lotName = values.name; // + "-" + values.number + "-" + values.letter;
        const response = await registerLot([lotName], farmSelected.farm_id);
        if (response.lots.length > 0) lot_response = response.lots[0];
      }

      const animal_data = selectedAnimals.map((item) => {
        return {
          farmId: farmSelected.farm_id,
          animalId: item.id,
          lotId: lot_response
            ? lot_response.id
            : lotOptions.filter((lot) => lot.label === values.lot_option)[0]
                .value,
          lotCode: lot_response
            ? values.name //+ "-" + values.number + "-" + values.letter
            : values.lot_option,
        };
      });
      await Promise.all(
        separar(animal_data, 400).map(async (arr) => {
          await updateAnimalLot(arr);
        })
      );

      getAnimals(farmSelected.farm_id);
      getFarmLots();

      setIsLoading(false);
      reset();
      const boxValue = lot_response
        ? values.name //+ "-" + values.number + "-" + values.letter
        : values.lot_option;
      handleModalProps({ boxValue: boxValue });
      handleModalShow(true);
    } catch (err) {
      setIsLoading(false);
      handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
    }
  }

  return (
    <EventContent
      animals={selectedAnimals}
      className="lot"
      eventInfoValue={infoValue}
      onClickSave={handleSubmit(checkValues, valuesAreCorrect, isLoading)}
      isLoading={isLoading}
      apiError={errors.api}
    >
      <p className="info-lot">Selecione ou crie um novo lote</p>
      {errors.lot && <p className="lot-error">{errors.lot}</p>}
      <div className="px350-input">
        <Select
          id="lot_option"
          label="Lotes"
          placeholder="Selecione um lote"
          className="px400-input"
          error={errors.lot_option}
          value={values.lot_option}
          options={lotOptions}
          onChange={(index) => handleSelectChange("lot_option", index)}
        />
      </div>
      <div className="px350-input">
        <Input
          id="name"
          label="Novo Lote"
          error={errors.name}
          value={values.name}
          type="text"
          placeholder="Lote"
          onChange={handleChange}
        />
      </div>
      {/* <div className="px350-input">
        <Input
          id="number"
          value={values.number}
          type="text"
          placeholder="Números"
          onChange={handleChange}
        />
      </div>
      <div className="px350-input">
        <Input
          id="letter"
          value={values.letter}
          type="text"
          placeholder="Letras"
          onChange={handleChange}
        />
      </div> */}
    </EventContent>
  );
}
