import { newApi } from "../api";

export async function listAnimals(farm_id) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.get(`/animal/farm/${farm_id}`, config);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}

export async function getAnimalData(animalId, farmId) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.get(
      `/report/animal/${farmId}/${animalId}`,
      config
    );
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}
