import { format, isBefore, parse } from "date-fns";
import { useEffect, useState } from "react";

import { useEventContext } from "../../../../contexts/eventContext";
import { useFarmContext } from "../../../../contexts/farmContext";
import useForm from "../../../../hooks/useForm";

import "./mastitis.css";

import Input from "../../../../components/input/input";
// import Select from "../../../../components/select/select";
import CheckBoxTeto from "../../components/checkBoxTeto/checkBoxTeto";
import EventContent from "../../components/eventContent/eventContent";

import { isDateValid, isPastDate } from "../../../../utils/isDateValid";

import {
  lastMastitis,
  registerMastitis,
} from "../../../../services/events/mastitis";
import { dateToApiDate } from "../../../../utils/dateToApiDate";
import AddTreatment from "./addTreatment";

// const optionsPeriod = [
//   { label: "Manhã" },
//   { label: "Tarde" },
//   { label: "Noite" },
// ];

function Mastitis() {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleSelectChange,
    handleSetErrors,
    reset,
  } = useForm();
  const { selectedAnimals, handleModalShow, handleModalProps } =
    useEventContext();
  const { farmSelected } = useFarmContext();

  const [includeTreatment, setIncludeTreatment] = useState(false);
  const [shortageTime, setShortageTime] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [infoValue, setInfoValue] = useState("-");

  useEffect(() => {
    async function fetchLastRegister() {
      try {
        const register_response = await lastMastitis(
          selectedAnimals[0].id,
          selectedAnimals[0].farmId
        );
        const date = new Date(
          register_response.mastitis.eventDate.slice(0, -1)
        );
        const value = format(date, "dd/MM/yyyy");
        setInfoValue(value);
      } catch (err) {}
    }
    if (selectedAnimals.length === 1) {
      fetchLastRegister();
    }
  }, [selectedAnimals]);

  useEffect(() => {
    handleSelectChange("drug", null);
  }, [values.treatmentType, handleSelectChange]);

  async function checkValues() {
    const error = {};

    if (!values.diagnosisDate) {
      error.diagnosisDate = "Este campo é obrigatório";
    } else if (!isDateValid(values.diagnosisDate)) {
      error.diagnosisDate = "Insira uma data válida";
    } else if (!isPastDate(values.diagnosisDate)) {
      error.diagnosisDate = "Insira uma data no passado ou dia corrente";
    } else if (errors.diagnosisDate) {
      delete error.diagnosisDate;
    }

    // if (!values.period) {
    //   error.period = "Este campo é obrigatório";
    // } else if (errors.period) {
    //   delete error.period;
    // }

    var count = 0;
    for (var teto in values.tetos) {
      if (values.tetos[teto]) count++;
      else delete values.tetos[teto];
    }

    if (!values.tetos || count === 0) {
      error.tetos = "Selecione um teto ou mais";
    } else if (errors.tetos) {
      delete error.tetos;
    }

    if (includeTreatment) {
      if (!values.date_treatment) {
        error.date_treatment = "Este campo é obrigatório";
      } else if (!isDateValid(values.date_treatment)) {
        error.date_treatment = "Insira uma data válida";
      } else if (
        isBefore(
          parse(values.date_treatment, "dd/MM/yyyy", new Date()),
          parse(values.diagnosisDate, "dd/MM/yyyy", new Date())
        )
      ) {
        error.date_treatment =
          "Insira uma data posterior a data do diagnóstico";
      } else if (errors.date_treatment) {
        delete error.date_treatment;
      }

      if (!values.treatmentType) {
        error.treatmentType = "Este campo é obrigatório";
      } else if (errors.treatmentType) {
        delete error.treatmentType;
      }

      if (!values.drug && !values.drug_intramamario && !values.drug_sistemico) {
        error.drug = "Este campo é obrigatório";
      } else if (errors.drug) {
        delete error.drug;
      }
    }

    return error;
  }

  async function valuesAreCorrect() {
    var boxValue = values.diagnosisDate;

    try {
      setIsLoading(true);
      const data = {
        animalId: selectedAnimals[0].id,
        farmId: farmSelected.farm_id,
        // tipo: "Mastite clínica",
        tetos: values.tetos,
      };

      data["eventDate"] = dateToApiDate(values.diagnosisDate);
      // data["periodo_ordenha"] = "Manhã"; //values.period;

      if (includeTreatment) {
        data["treatmentDate"] = dateToApiDate(values.date_treatment);
        data["treatmentType"] = values.treatmentType;
        data["medicine"] =
          values.treatmentType === "Intramamário e Sistêmico"
            ? values.drug_intramamario + " e " + values.drug_sistemico
            : values.drug;
        data["lack"] = shortageTime;
      }

      await registerMastitis([data]);

      setIsLoading(false);
      reset();
      handleModalProps({ boxValue: boxValue });
      handleModalShow(true);
    } catch (err) {
      setIsLoading(false);
      handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
    }
  }

  return (
    <EventContent
      animals={selectedAnimals}
      className="mastitis"
      eventInfoValue={infoValue}
      onClickSave={handleSubmit(checkValues, valuesAreCorrect, isLoading)}
      isLoading={isLoading}
      apiError={errors.api}
    >
      <div className="mastitis-container">
        <div className="mastitis-content">
          <div className="clinic-inputs">
            <div className="px400-input no-margin">
              <Input
                id="diagnosisDate"
                label="Data do diagnóstico"
                errorAlignColumn
                error={errors.diagnosisDate}
                value={values.diagnosisDate}
                type="text"
                placeholder="dd/mm/aaaa"
                mask="99/99/9999"
                onChange={handleChange}
              />
            </div>
            {/* <div className="px400-input no-margin">
              <Select
                id="period"
                errorAlignColumn
                label="Turno da ordenha do diagnóstico"
                placeholder="Selecione o período"
                error={errors.period}
                value={values.period}
                onChange={(value) => handleSelectChange("period", value.label)}
                options={optionsPeriod}
              />
            </div> */}
          </div>
        </div>

        <AddTreatment
          includeTreatment={includeTreatment}
          handleIncludeTreatment={setIncludeTreatment}
          shortageTime={shortageTime}
          handleShortageTime={setShortageTime}
          errors={errors}
          handleChange={handleChange}
          handleSelectChange={handleSelectChange}
          values={values}
        />
      </div>

      <div className="checkbox-teto-wrapper">
        <CheckBoxTeto
          id="tetos"
          cow
          errorAlignColumn
          label="Selecione o(s) teto(s)"
          error={errors.tetos}
          onChange={(values) => handleSelectChange("tetos", values)}
          cmt={values.type === "Mastite subclínica"}
        />
      </div>
    </EventContent>
  );
}

export default Mastitis;
