import { useAlertContext } from "../../../../../contexts/alertContext";

import "./ModalNewAlertGenerated.css";

import Button from "../../../../../components/button/button";
import Modal from "../../../../../components/modal/modal";

export default function ModalNewAlertGenerated(){
    const { modalProps, handleModalShow, modalUnderstand, handleModalProps } = useAlertContext();
    const { animals, animalsMastitis } = modalProps;

    function printNameAnimals(animals){
        if (animals.length === 1){
            return <b>{animals[0].Identificação}</b>
        } else {
            return (animals.map((animal, index) => { 
                const stringtext = ", "
                const last = " e da "
                if (index < animals.length - 2) {
                    return [<b key={animal.Identificação}>{animal.Identificação}</b> , stringtext]
                } else if (index < animals.length - 1){
                    return <b key={animal.Identificação}>{animal.Identificação}</b>
                } else {
                    return [last , <b key={animal.Identificação}>{animal.Identificação}</b>]
                }
            }))
        }
    }

    function onClickBack() {
        if (animalsMastitis.length){
            handleModalProps({ animals: animalsMastitis })
            modalUnderstand('mastitis')
        } else {
            modalUnderstand()
        }
    }
    
    return(
        <Modal showModal={handleModalShow} close={true}>
            <div className="newalert-modal-content">
                <p className="title">Alerta mastite subclínica</p>
                <p className="text">
                    {"Você finalizou o tratamento de mastite da "}
                    {printNameAnimals(animals)}
                    {". Um novo alerta foi gerado para lembrar você do final da carência dos medicamentos usados."}      
                </p>

                <div className="modal-div-buttons">
                    <div />
                    <Button color="white" className="modal-button" onClick={onClickBack}>Voltar</Button>
                    <Button color="blue" className="modal-button" onClick={modalUnderstand}>Apagar alerta</Button>
                </div>
            </div>
        </Modal>
    );  
}