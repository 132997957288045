import { format, fromUnixTime, startOfMonth } from "date-fns";
import { useEffect, useState } from "react";
import { useFarmContext } from "../../../../contexts/farmContext";
import { getClinicMastitis } from "../../../../services/report/getClinicMastitis";
import { apiDateToFormatedDate } from "../../../../utils/dateToApiDate";
import { timestampToDate } from "../../../../utils/timestampToDate";
import { Box, Row, SectionHeader, TextInfo } from "../bases/bases";

import "./mastitisClinical.css";

export function MastitisClinical(props) {
  const { farmSelected } = useFarmContext();

  const [numberInDiagnosis, setNumberInDiagnosis] = useState("-");
  const [animalsInDiagnosis, setAnimalsInDiagnosis] = useState([]);

  const [numberInTreatment, setNumberInTreatment] = useState("-");
  const [animalsInTreatment, setAnimalsInTreatment] = useState([]);

  const [discardedValue, setDiscardedValue] = useState(0);
  const [discardedVolume, setDiscardedVolume] = useState(0);

  const [successTreatment, setSuccessTreatment] = useState(0);
  const [animalsSuccess, setAnimalsSuccess] = useState([]);

  const [numberCarencia, setNumberCarencia] = useState(0);
  const [animalsCarencia, setAnimalsCarencia] = useState([]);

  const [numberReleased, setNumberReleased] = useState(0);
  const [animalsReleased, setAnimalsReleased] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const aux_data = await getClinicMastitis(
          farmSelected.farm_id,
          props.date
        );

        setNumberInDiagnosis(aux_data["diagnosedAnimal"]);
        const aux_animaisEmDiagnostico = aux_data.animals_diagnosedAnimal.map(
          (item, index) => {
            var date = "-";
            if (item.eventDate) {
              date = apiDateToFormatedDate(item.eventDate);
            }
            return {
              id: "d" + index,
              Identificação: item.identification,
              Data_do_Diagnostico: date,
              Lote: item.lot,
            };
          }
        );
        setAnimalsInDiagnosis(aux_animaisEmDiagnostico);

        setNumberInTreatment(aux_data["treatmentAnimal"]);
        const aux_animaisEmTratamento = aux_data.animals_treatmentAnimal.map(
          (item, index) => {
            var date = "-";
            if (item.treatmentDate) {
              date = apiDateToFormatedDate(item.treatmentDate);
            }
            return {
              id: "d" + index,
              Identificação: item.identification,
              Data_do_Tratamento: date,
              Lote: item.lot,
            };
          }
        );
        setAnimalsInTreatment(aux_animaisEmTratamento);

        setDiscardedValue(aux_data["discardedValue"]);
        setDiscardedVolume(aux_data["discardedVolume"]);

        setSuccessTreatment(aux_data["treatmentSuccess"]);
        const aux_animaisSucesso = aux_data.animals_treatmentSuccess.map(
          (item, index) => {
            var date_diagnostico = "-";
            if (item.eventDate) {
              date_diagnostico = apiDateToFormatedDate(item.eventDate);
            }

            var date_fim = "-";
            if (item.endTreatmentDate) {
              date_fim = apiDateToFormatedDate(item.endTreatmentDate);
            }
            return {
              id: "d" + index,
              Identificação: item.identification,
              Lote: item.lot,
              Data_do_Diagnóstico: date_diagnostico,
              Fim_do_Tratamento: date_fim,
            };
          }
        );
        setAnimalsSuccess(aux_animaisSucesso);

        setNumberCarencia(aux_data["lackAnimals"]);
        const aux_animaisCarencia = aux_data.animals_lackAnimals.map(
          (item, index) => {
            var date = "-";
            if (item.endLack) {
              date = apiDateToFormatedDate(item.endLack);
            }
            return {
              id: "d" + index,
              Identificação: item.identification,
              Fim_da_Carência: date,
              Lote: item.lot,
            };
          }
        );
        setAnimalsCarencia(aux_animaisCarencia);

        setNumberReleased(aux_data["releasedMilkAnimals"]);
        const aux_animaisReleased = aux_data.animals_releasedMilkAnimals.map(
          (item, index) => {
            var date_diagnostico = "-";
            if (item.eventDate) {
              date_diagnostico = apiDateToFormatedDate(item.eventDate);
            }

            var date_fim = "-";
            if (item.endTreatmentDate) {
              date_fim = apiDateToFormatedDate(item.endTreatmentDate);
            }
            return {
              id: "d" + index,
              Identificação: item.identification,
              Lote: item.lot,
              Data_do_Diagnóstico: date_diagnostico,
              Fim_do_Tratamento: date_fim,
            };
          }
        );
        setAnimalsReleased(aux_animaisReleased);
      } catch (e) {
        console.log("Error fetching data Subclinic Mastitis: " + e);
      }
    }
    fetchData();
  }, [farmSelected, props.date]);

  return (
    <div className="mastitis-wrapper">
      <SectionHeader title="Mastite Clínica" />
      <Row className="mastitis first">
        <Box className="small spaceBetween">
          <TextInfo
            number={discardedVolume + " litros"}
            latter={`Volume médio de leite descartado no período de ${format(
              startOfMonth(fromUnixTime(props.date)),
              "dd/MM/yyyy"
            )} a ${timestampToDate(props.date)}.`}
          />
          <div className="expense-discard">
            <p>
              {" "}
              Esse descarte equivale a{" "}
              <span>
                {" "}
                {discardedValue.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}{" "}
              </span>{" "}
              aproximadamente.{" "}
            </p>
          </div>
        </Box>
      </Row>
      <Row className="mastitis second">
        <Box
          className="small-medium"
          viewMore={animalsInDiagnosis.length > 0}
          viewMoreTitle="Mastite diagnosticada"
          animals={animalsInDiagnosis}
          columns={["Identificação", "Lote", "Data_do_Diagnostico"]}
        >
          <TextInfo
            number={numberInDiagnosis}
            latter={"Mastite diagnosticada"}
          />
        </Box>
        <Box
          className="small-medium"
          viewMore={animalsInTreatment.length > 0}
          viewMoreTitle="Tratamento de mastite"
          animals={animalsInTreatment}
          columns={["Identificação", "Lote", "Data_do_Tratamento"]}
        >
          <TextInfo
            number={numberInTreatment}
            latter={"Tratamento de mastite"}
          />
        </Box>
        <Box
          className="small-medium"
          viewMore={animalsCarencia.length > 0}
          viewMoreTitle="Carência medicamentosa"
          animals={animalsCarencia}
          columns={["Identificação", "Lote", "Fim_da_Carência"]}
        >
          <TextInfo number={numberCarencia} latter={"Carência medicamentosa"} />
        </Box>
        <Box
          className="small-medium"
          viewMore={animalsReleased.length > 0}
          viewMoreTitle="Leite liberado"
          animals={animalsReleased}
          columns={[
            "Identificação",
            "Lote",
            "Data_do_Diagnóstico",
            "Fim_do_Tratamento",
          ]}
        >
          <TextInfo number={numberReleased} latter={"Leite liberado"} />
        </Box>
        <Box
          className="small-medium"
          viewMore={animalsSuccess.length > 0}
          viewMoreTitle="Tratados com sucesso"
          animals={animalsSuccess}
          columns={[
            "Identificação",
            "Lote",
            "Data_do_Diagnóstico",
            "Fim_do_Tratamento",
          ]}
        >
          <TextInfo number={successTreatment} latter={"Tratados com sucesso"} />
          {/* <CircularChart
            label="Tratadas com sucesso"
            width={120}
            progressPercentage={successTreatment}
          /> */}
        </Box>
      </Row>
    </div>
  );
}
