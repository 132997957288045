import { timestampToApiDate } from "../../utils/dateToApiDate";
import { newApi } from "../api";

export async function getClinicMastitis(farm_id, date) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.get(
      `report/farm/${farm_id}/clinicmastitis/${timestampToApiDate(date)}`,
      config
    );
    return response.data.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}
