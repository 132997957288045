import { newApi } from "../api";

export async function registerLot(codes, farmId) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.post(`/lot`, { codes, farmId }, config);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}
