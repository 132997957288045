import Button from "../../../../components/button/button";
import Modal from "../../../../components/modal/modal";

import './modalFarm.css';

export default function ModalFarm({ isLoading, onClickCancel, onClickSubmit }) {
  return (
    <Modal showModal={onClickCancel} close={true}>
      <div className="content-farm">
        <p className="title">Você quer excluir essa propriedade?</p>
        <p className="text">Caso exclua essa propriedade, você não poderá mais acessá-la depois.</p>

        <div className="modal-buttons-wrapper">
          <div />
          <Button color="white" onClick={onClickCancel}>Não excluir</Button>
          <Button color="blue" onClick={onClickSubmit} isLoading={isLoading}>Excluir fazenda</Button>
        </div>
      </div>
    </Modal>
  );
}