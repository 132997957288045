import * as d3 from "d3";
import { scaleBandInvert } from "../../utils/invertScale";

function drawTooltip(config) {
  const {
    useScaleBands,
    margin,
    width,
    height,
    data,
    data2,
    svgRef,
    tooltipRef,
    xScale,
    yScale,
    isAreaChart
  } = config;

  const svg = d3.select(svgRef.current).select("g");
  const tooltip = d3.select(tooltipRef.current);

  const focus = svg.append("g").attr("class", "focus").style("display", "none");

  focus.append("circle").attr("r", 5).attr("class", "area-chart__circle");
  
  // Manter o tooltip ao passar o mouse sobre o próprio
  tooltip.on("mouseover", () => {
    focus.style("opacity", 100);
    tooltip.transition().duration(100).style("opacity", 0.9);
  })

  svg
    .append("rect")
    .attr("class", "overlay")
    .attr("width", width)
    .attr("height", height)
    .style("opacity", 0)
    .on("mouseover", () => {
      focus.style("display", null);
    })
    .on("mouseout", () => {
      focus.style("opacity", 0);
      tooltip.transition().duration(300).style("opacity", 0);
    })
    .on("mousemove", function (event) {
      const xPos = d3.pointer(event)[0];

      const invertedPoint = useScaleBands
        ? scaleBandInvert(xScale, xPos, isAreaChart)
        : xScale.invert(xPos);

      var d0 = data.find((d) => d.label === invertedPoint);
      if (d0){
        // Quando o gráfico tem dois valores, o tooltip vai ir na localização do maior valor
        if (data2){

          const d2 = data2.find((d) => d.label === invertedPoint);
          d0.value = Math.max(d0.value, d2.value)
        }

        focus.style("opacity", 1);

        focus.attr(
          "transform",
          `translate(${xScale(d0.label) + xScale.bandwidth() / 2},${yScale(
            d0.value
          )})`
        );

        tooltip.transition().duration(100).style("opacity", 0.9);

        tooltip
          .html(d0.tooltipContent || d0.label)
          .style("transform", "translate(-50%,-80%)")
          .style(
            "left",
            `${xScale(d0.label) + margin.left + xScale.bandwidth() / 2}px`
          )
          .style("top", `${yScale(d0.value) + margin.top - 10}px`);
      }

    });
}

export default drawTooltip;
