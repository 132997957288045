import { useState } from "react";

import Button from "../../../../components/button/button";
import Input from "../../../../components/input/input";
import Modal from "../../../../components/modal/modal";

import "./archiveModal.css";

export default function ArchiveModal({
  animal,
  error,
  isLoading,
  showModal,
  onClickCancel,
  onClickArchive,
}) {
  const [answer, setAnswer] = useState();

  return (
    <Modal showModal={showModal} close={true}>
      <div className="content">
        <p className="title">
          {!animal[0].arquivado ? "Arquivar animal" : "Desarquivar animal"}
        </p>

        {!animal[0].arquivado ? (
          <Input
            id="answer"
            label="Motivo para arquivar esse animal"
            className="input-modal"
            type="text"
            placeholder="Insira o motivo aqui"
            value={answer}
            error={error}
            onChange={(event) => setAnswer(event.target.value)}
          />
        ) : (
          <p className="unarchive">Deseja desarquivar esse animal?</p>
        )}

        <div className="modal-button-wrapper">
          <div />
          <Button color="white" onClick={onClickCancel}>
            Cancelar
          </Button>
          <Button
            color="blue"
            isLoading={isLoading}
            onClick={() => onClickArchive(answer)}
          >
            {!animal[0].arquivado ? "Arquivar animal" : "Desarquivar animal"}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
