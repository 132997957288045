import { isValid, fromUnixTime, differenceInDays, parse } from "date-fns";
import { ptBR } from 'date-fns/locale';

export function isDateValid(date) {
  const parsedDate = parse(date, 'P', new Date(), { locale: ptBR });
  const isValidDate = isValid(parsedDate);
  return isValidDate
}

export function isPastDate(pastDate, date) {
  // if you don't get the date to compare, compare with the current day
  const parsedDate = parse(pastDate, 'P', new Date(), { locale: ptBR });
  if (date) {
    const aux = new Date(date * 1000);
    return parsedDate < aux
  } else {
    var now = new Date();
    return (parsedDate < now);
  }
}

export function maxDateinArray(values) {
  return values.reduce(function (p, v) {
    return ( new Date(p * 1000) > new Date(v * 1000) ? p : v );
  });
}

export function isLessThan30Days(timestamp) {
  const date = fromUnixTime(timestamp);
  const today = new Date();
  if (differenceInDays(date, today) > 30) {
    return false;
  }
  return true;
}

export function isMoreThan7Days(timestamp) {
  const date = fromUnixTime(timestamp);
  const today = new Date();
  if (differenceInDays(date, today) < 7) {
    return false;
  }
  return true;
}

export function nDays(n_mes){
  // parm 99/9999
  var 
      numMes = parseInt(n_mes.split('/')[0]),
      numAno = parseInt(n_mes.split('/')[1]),
      numDias = new Date(numAno, numMes, 0).getDate();

  return numDias;
}
