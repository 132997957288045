import { newApi } from "../api";

async function getAllPlans(data) {
  try {
    const response = await newApi.get("/plan", data);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data.err.code);
  }
}

export default getAllPlans;
