import { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie-player";
import loading_gif from "../../../../assets/gifs/loading_2.json";
import { CircularChart } from "../../../../components/baseChart/circularChart/circularChart";
import ScatterPlot from "../../../../components/baseChart/scatterPlot/scatterPlot";
import YBarChart from "../../../../components/baseChart/yBarChart/yBarChart";
import { useFarmContext } from "../../../../contexts/farmContext";
import { getSubclinicMastitisInfo } from "../../../../services/report/getSubclinicMastitisInfo";
import { apiDateToFormatedDate } from "../../../../utils/dateToApiDate";
import AnualBarChart from "../bases/AnualBarChart/AnualBarChart";
import { Box, Row, SectionHeader, TextInfo } from "../bases/bases";
import "./mastitisSubClinical.css";

// const meses = [
//   "Jan.",
//   "Fev.",
//   "Mar.",
//   "Abr.",
//   "Mai.",
//   "Jun.",
//   "Jul.",
//   "Ago.",
//   "Set.",
//   "Out.",
//   "Nov",
//   "Dez.",
// ];

const intervals = [
  "1-30",
  "31-60",
  "61-90",
  "91-120",
  "121-150",
  "151-180",
  "181-270",
  "271+",
];

export function Mastitis(props) {
  const date = new Date(props.date * 1000);
  const [maxYear, setMaxYear] = useState(date.getFullYear());

  useEffect(() => {
    setMaxYear(props.date);
  }, [props.date]);

  const [isLoading, setIsLoading] = useState(true);
  const { farmSelected } = useFarmContext();

  // const mastitisEvolutionRef = useRef(null);
  const mastitisInfectionRef = useRef(null);
  const newInfectionsRef = useRef(null);

  // animais com mastite subclínca/mês
  const [numberWithMastitis, setNumberWithMastitis] = useState("-");
  const [animalsWithMastitis, setAnimalsWithMastits] = useState([]);

  // Guarda os dados de todos os anos da Evolução da prevalência da mastite subclínica
  const [allEvolutionData, setAllEvolutionData] = useState({});

  // Infection Dynamics
  const [infectionData, setInfectionData] = useState([]);
  const [sizeInfection, setSizeInfection] = useState({});

  // Chronic Prevalence
  const [chronicPrevalenceRate, setChronicPrevalenceRate] = useState(0);
  const [chronicPrevalence, setChronicPrevalence] = useState();
  const [animalsChronicPrevalence, setAnimalsChronicPrevalence] = useState([]);

  // New Infections
  const [newInfections, setNewInfections] = useState(0);
  const [animalsNewInfections, setAnimalsNewInfections] = useState([]);

  // Variação de CCS por Mês em Lactação
  const [CCSMonthData, setCCSMonthData] = useState([]);

  const [sizeNewInfections, setSizeNewInfections] = useState({});

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const aux_data = await getSubclinicMastitisInfo(
          farmSelected.farm_id,
          props.date
        );

        // Animais com mastite subclínca/mês
        setNumberWithMastitis(aux_data["mastitisAnimals"]);
        const aux_animaisAnimalsWithMastitis =
          aux_data.animals_mastitisAnimals.map((item, index) => {
            var date = "-";
            if (item.eventDate) {
              date = apiDateToFormatedDate(item.eventDate);
            }
            return {
              id: "d" + index,
              Identificação: item.identification,
              Evento: item.eventType,
              CCS: item.ccs ?? "-",
              Data: date,
              Lote: item.lot,
            };
          });
        setAnimalsWithMastits(aux_animaisAnimalsWithMastitis);

        // Chronic Rate - (Taxa Crônica)
        setChronicPrevalenceRate(aux_data["chronicRate"].toFixed(0));

        // Prevalência Crônica
        setChronicPrevalence(aux_data["chronicPrevalence"]);
        const aux_animaisAnimalsChronicPrevalence =
          aux_data.animals_chronicPrevalence.map((item, index) => {
            var date = "-";
            if (item.eventDate) {
              date = apiDateToFormatedDate(item.eventDate);
            }

            var date_anterior = "-";
            if (item.previusEventDate) {
              date_anterior = apiDateToFormatedDate(item.previusEventDate);
            }
            return {
              id: "d" + index,
              Identificação: item.identification,
              Evento: item.eventType,
              Data: date,
              Evento_Anterior: item.previusEventType,
              Data_Anterior: date_anterior,
              Lote: item.lot,
            };
          });
        setAnimalsChronicPrevalence(aux_animaisAnimalsChronicPrevalence);

        // Evolution of the prevalence of mastitis (Dinâmica de infecção)
        setAllEvolutionData(aux_data["evolutionPrevalence"]);

        // Novas Infecções
        setNewInfections(aux_data["newInfections"].toFixed(0));
        const aux_animaisAnimalsNewInfections =
          aux_data.animals_newInfections.map((item, index) => {
            var date = "-";
            if (item.eventDate) {
              date = apiDateToFormatedDate(item.eventDate);
            }
            return {
              id: "d" + index,
              Identificação: item.identification,
              Evento: item.eventType,
              Data: date,
              Lote: item.lot,
            };
          });
        setAnimalsNewInfections(aux_animaisAnimalsNewInfections);

        // Variação CCS Mes em Lactação
        var chartData = [];
        aux_data["variantionCcsMonthLactation"].forEach((value, index) =>
          chartData.push({
            label: intervals[index],
            value,
            tooltipContent: `<b>${value}<b>`,
          })
        );
        setCCSMonthData(chartData);
        // ********************************

        // Dinâmica de infecção
        var chartData = [];
        aux_data["infectionDynamic"].forEach((valuee, index) => {
          const value = valuee[0];
          const valueY = valuee[1];
          chartData.push({
            label: valueY,
            value,
            tooltipContent: `<b>x: </b>${index}<br><b>y: </b>${value}`,
          });
        });
        setInfectionData(chartData);

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.log("Error fetching data Subclinic Mastitis: " + e);
      }
    }
    fetchData();
  }, [farmSelected, props.date]);

  // Width
  useEffect(() => {
    // var aux1 = {};
    // aux1.width = mastitisEvolutionRef.current.offsetWidth - 65;
    // aux1.height = 149;
    // setSizeEvolution(aux1);

    var aux2 = {};
    aux2.width = mastitisInfectionRef.current.offsetWidth - 65;
    aux2.height = 218;
    setSizeInfection(aux2);

    var aux3 = {};
    aux3.width = newInfectionsRef.current.offsetWidth - 65;
    aux3.height = 198;
    setSizeNewInfections(aux3);
  }, []);

  return (
    <div className="mastitis-sub-clinical-wrapper">
      <SectionHeader title="Mastite Subclínica" />
      <Row className="mastitis second">
        <Box
          className="medium"
          viewMore={animalsWithMastitis.length > 0}
          viewMoreTitle="Número de animais com mastite subclínica no mês selecionado"
          animals={animalsWithMastitis}
          columns={["Identificação", "Lote", "Evento", "CCS", "Data"]}
        >
          <TextInfo
            number={numberWithMastitis}
            latter=" animais com mastite subclínca no mês selecionado"
          />
        </Box>
        <Box
          className="medium"
          title="Evolução da prevalência da mastite subclínica"
        >
          <AnualBarChart
            allData={allEvolutionData}
            maxDate={maxYear}
            isLoading={isLoading}
            label="% de animais"
          />
        </Box>
      </Row>
      <Row className="mastitis third">
        <Box
          className="bigger"
          viewMore={animalsChronicPrevalence.length > 0}
          viewMoreTitle="Prevalência Crônica"
          animals={animalsChronicPrevalence}
          columns={[
            "Identificação",
            "Lote",
            "Evento",
            "Data",
            "Evento_Anterior",
            "Data_Anterior",
          ]}
        >
          <CircularChart
            label="taxa crônica"
            width={140}
            progressPercentage={chronicPrevalenceRate}
          />
          <TextInfo number={chronicPrevalence} latter="Prevalência Crônica" />
        </Box>
        <Box className="bigger" title="Dinâmica de infecção ">
          <div className="infection-graph-wrapper" ref={mastitisInfectionRef}>
            {isLoading ? (
              <div className="no-data">
                <Lottie loop play animationData={loading_gif} />
              </div>
            ) : (
              <ScatterPlot
                svgProps={{
                  margin: { top: 40, bottom: 50, left: 35, right: 30 },
                  width: sizeInfection.width,
                  height: sizeInfection.height,
                }}
                axisProps={{
                  xLabel: "CCS Atual",
                  yLabel: "CCS Anterior",
                }}
                data={infectionData}
                pointWidth={2.3}
                fixAxis={[0, 10]}
              />
            )}
          </div>
        </Box>
      </Row>
      <Row className="mastitis fourth">
        <Box
          className="bigger"
          viewMore={animalsNewInfections.length > 0}
          viewMoreTitle="Novas Infecções"
          animals={animalsNewInfections}
          columns={["Identificação", "Lote", "Evento", "Data"]}
        >
          <CircularChart
            label="novas infecções"
            width={140}
            progressPercentage={newInfections}
          />
        </Box>
        <Box className="bigger" title="Variação de CCS por Mês em Lactação">
          {/* <div className="lactation-average">
            <h2> {150.0 + " CSS/mL"}</h2>
            <p>média de lactação</p>
          </div> */}
          <div className="new-infections-graph-wrapper" ref={newInfectionsRef}>
            {isLoading ? (
              <div className="no-data">
                <Lottie loop play animationData={loading_gif} />
              </div>
            ) : (
              <YBarChart
                svgProps={{
                  margin: { top: 40, bottom: 50, left: 45, right: 10 },
                  width: sizeNewInfections.width,
                  height: sizeNewInfections.height,
                }}
                axisProps={{
                  xLabel: "dias",
                  yLabel: "CCS",
                  drawXGridlines: true,
                }}
                data={CCSMonthData}
                strokeWidth={3}
              />
            )}
          </div>
        </Box>
      </Row>
    </div>
  );
}
