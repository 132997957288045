import { format } from "date-fns";
import { useEffect, useState } from "react";

import { useEventContext } from "../../../../contexts/eventContext";
import { useFarmContext } from "../../../../contexts/farmContext";
import useForm from "../../../../hooks/useForm";

import {
  lastReproduction,
  registerReproduction,
} from "../../../../services/events/reproduction";

import Input from "../../../../components/input/input";
import EventContent from "../../components/eventContent/eventContent";

import "./reproduction.css";

import { calcAge } from "../../../../utils/calcAge";
import { dateToApiDate } from "../../../../utils/dateToApiDate";
import { isDateValid, isPastDate } from "../../../../utils/isDateValid";
import { separar } from "../../../../utils/separarArray";

export default function Reproduction() {
  const { errors, values, handleSubmit, handleChange, handleSetErrors, reset } =
    useForm();
  const {
    selectedAnimals,
    handleModalShow,
    handleModalProps,
    handlePredictedBirthData,
  } = useEventContext();
  const { farmSelected } = useFarmContext();

  const [isLoading, setIsLoading] = useState(false);
  const [infoValue, setInfoValue] = useState("-");

  useEffect(() => {
    async function fetchLastRegister() {
      try {
        const register_response = await lastReproduction(
          selectedAnimals[0].id,
          selectedAnimals[0].farmId
        );
        const date = new Date(
          register_response.reproduction.eventDate.slice(0, -1)
        );
        const value = format(date, "dd/MM/yyyy");
        setInfoValue(value);
      } catch (err) {}
    }
    if (selectedAnimals.length === 1) {
      fetchLastRegister();
    }
  }, [selectedAnimals]);

  async function checkValues() {
    const error = {};

    selectedAnimals.forEach((animal) => {
      if (!values[animal.id]) {
        error[animal.id] = "Este campo é obrigatório";
      } else if (!isDateValid(values[animal.id])) {
        error[animal.id] = "Insira uma data válida";
      } else if (!isPastDate(values[animal.id])) {
        error[animal.id] = "Insira uma data no passado ou o dia corrente";
      } else if (errors[animal.id]) {
        delete error[animal.id];
      }
    });

    return error;
  }

  async function valuesAreCorrect() {
    let boxValue = "";
    if (selectedAnimals.length > 1) {
      boxValue = undefined;
    } else {
      for (var value in values) {
        boxValue = values[value];
      }
    }

    try {
      setIsLoading(true);
      const data = [];
      for (value in values) {
        // const date_aux = handlePredictedBirthData(values[value]);
        data.push({
          animalId: value,
          farmId: farmSelected.farm_id,
          eventDate: dateToApiDate(values[value]),
          specieName: farmSelected.apiSpecie,
          // data_nascimento: dateToTimestamp(date_aux),
        });
      }
      await Promise.all(
        separar(data, 400).map(async (arr) => {
          await registerReproduction(arr);
        })
      );

      setIsLoading(false);
      reset();
      const boxSecondaryInfo = {
        label: "Data prevista do parto:",
        value: handlePredictedBirthData(values[value]),
      };
      handleModalProps({ boxValue: boxValue, boxSecondary: boxSecondaryInfo });
      handleModalShow(true);
    } catch (err) {
      setIsLoading(false);
      handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
    }
  }

  return (
    <EventContent
      animals={selectedAnimals}
      className="reproduction"
      eventInfoValue={infoValue}
      onClickSave={handleSubmit(checkValues, valuesAreCorrect, isLoading)}
      isLoading={isLoading}
      apiError={errors.api}
    >
      {selectedAnimals.map((animal) => {
        return (
          <div key={animal.id} className="line-animal">
            <div className="time-line">
              {selectedAnimals.length > 1 && (
                <div className="animal-name">
                  <p id="reproduction-animal-name">
                    <b>{animal.Identificação}</b>
                    {animal.Nascimento &&
                      `(${calcAge(animal.Nascimento)} anos)`}
                  </p>
                </div>
              )}
              <span className="circle" />
              {values[animal.id] && !values[animal.id].includes("_") && (
                <>
                  <span
                    className={
                      "line " + (selectedAnimals.length > 1 && "has-name")
                    }
                  />
                  <span className="circle" />
                </>
              )}
            </div>
            <div className="date-line">
              <div>
                <Input
                  id={animal.id}
                  label="Data da cobrição/inseminação"
                  errorAlignColumn
                  error={errors[animal.id]}
                  value={values[animal.id]}
                  type="text"
                  onChange={handleChange}
                  placeholder="dd/mm/aaaa"
                  mask="99/99/9999"
                />
              </div>
              {values[animal.id] && !values[animal.id].includes("_") && (
                <>
                  <div className="expected-birth-date">
                    <div className="expected-birth-label">
                      <label htmlFor="expected-date">
                        Data prevista para o parto:
                      </label>
                    </div>
                    <div className="expected-birth-text">
                      <p id="expected-date">
                        {handlePredictedBirthData(values[animal.id])}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        );
      })}
    </EventContent>
  );
}
