import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import getAllPlans from "../../services/userForm/getAllPlans";

import logo from "../../assets/images/logo-small-1.png";

import Button from "../../components/button/button";
import savePlan from "../../services/userForm/savePlan";

import "./selectPlan.css";

// const plans = {
//   consultor: [
//     { id: "1", description: "1 a 3 fazendas", value: 100 },
//     { id: "2", description: "1 a 3 fazendas", value: 100 },
//     { id: "3", description: "1 a 3 fazendas", value: 100 },
//     { id: "4", description: "1 a 3 fazendas", value: 100 },
//     { id: "5", description: "1 a 3 fazendas", value: 100 },
//     { id: "6", description: "1 a 3 fazendas", value: 100 },
//   ],
//   produtor: [
//     { id: "7", description: "1 a 100 animais", value: 100 },
//     { id: "8", description: "1 a 100 animais", value: 100 },
//     { id: "9", description: "1 a 100 animais", value: 100 },
//     { id: "10", description: "1 a 100 animais", value: 100 },
//     { id: "11", description: "1 a 100 animais", value: 100 },
//   ],
// };

function SelectPlanPage() {
  const history = useHistory();
  const { search } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const [selectedPlan, setSelectedPlan] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [plans, setPlans] = useState({ consultor: [], produtor: [] });

  useEffect(() => {
    handlePlans();
  }, []);

  if (!query.get("id")) {
    return history.push("/");
  }

  async function handlePlans() {
    const allPlans = await getAllPlans();
    const auxPlans = allPlans.plans.reduce(
      (acc, curr) => {
        if (curr.type === "consultor")
          acc.consultor.push({
            id: curr.id,
            description: curr.description,
            value: curr.value,
            limit_farms: curr.limit_farms,
          });
        else if (curr.type === "produtor")
          acc.produtor.push({
            id: curr.id,
            description: curr.description,
            value: curr.value,
            limit_animals: curr.limit_animals,
          });

        return acc;
      },
      { consultor: [], produtor: [] }
    );
    setPlans(auxPlans);
  }

  async function handleFinish() {
    if (!selectedPlan) {
      return;
    }
    setIsLoading(true);

    await savePlan({ userId: query.get("id"), planId: selectedPlan });

    // history.push("/payment?id=" + query.get("id"));
    history.push("/");
  }

  function handleSelectPlan(plan) {
    setSelectedPlan(plan);
  }

  return (
    <div className="select-plan">
      <div className="white-part">
        <div>
          <h1 className="title">Selecione o plano</h1>

          <div className="plans">
            <div className="col-consultor">
              <h4>Consultor</h4>
              {plans.consultor
                .sort((a, b) => a.limit_farms - b.limit_farms)
                .map((plan) => (
                  <div
                    key={plan.id}
                    className={
                      "plan " + (selectedPlan === plan.id ? "selected" : "")
                    }
                    onClick={() => handleSelectPlan(plan.id)}
                  >
                    <p>{plan.description}</p>
                    <p>
                      {plan.value.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </p>
                  </div>
                ))}
            </div>

            <div className="col-produtor">
              <h4>Produtor</h4>
              {plans.produtor
                .sort((a, b) => a.limit_animals - b.limit_animals)
                .map((plan) => (
                  <div
                    key={plan.id}
                    className={
                      "plan " + (selectedPlan === plan.id ? "selected" : "")
                    }
                    onClick={() => handleSelectPlan(plan.id)}
                  >
                    <p>{plan.description}</p>
                    <p>
                      {plan.value.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </p>
                  </div>
                ))}
            </div>
          </div>

          <div className="button-row">
            <div />
            <Button
              color="blue"
              onClick={handleFinish}
              disabled={!selectedPlan}
              isLoading={isLoading}
            >
              Concluir
            </Button>
          </div>
        </div>
      </div>

      <div className="blue-part">
        <div className="mask">
          <div>
            <img className="logo" src={logo} alt="logo" />
            <p className="minor-text">
              Organize e gerencie os eventos dos seus animais
            </p>
            <p className="minor-text">
              Ficou mais fácil organizar e gerenciar os eventos dos animais da
              fazenda
            </p>
            <p className="minor-text">
              Eficiência para organizar e gerenciar os eventos dos seus animais
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectPlanPage;
