import { newApi } from "../api";

export async function registerMastitis(data) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.post("/mastitis", data, config);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}

export async function lastMastitis(animal_id, farm_id) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.get(
      `mastitis/last/${farm_id}/${animal_id}`,
      config
    );
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}

export async function getAnimalsInTreatment(farm_id) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.get(`mastitis/treatment/${farm_id}`, config);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}
