import Button from "../../../components/button/button";
import Modal from "../../../components/modal/modal";

import "./loginModals.css";

export default function ModalVerifyNewEmail({ showModal, onClickNotReceive, onClickUnderstand }){
  return(
    <Modal showModal={showModal} close={true}>
      <div className="content verifyEmail">
        <p className="title">Verifique o novo e-mail informado</p>
        <p className="text">Seu novo e-mail para contato foi cadastrado. Enviamos para você os próximos passos para criar uma nova senha.</p>

        <div className="modal-button-wrapper">
          <div />
          <Button color="white" onClick={onClickNotReceive}>Não recebi</Button>
          <Button color="blue" onClick={onClickUnderstand}>Entendi</Button>
        </div>
      </div>
    </Modal>
  );
}
