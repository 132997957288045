import { newApi } from "../api";

export async function delTankInfo(register_id, farm_id, tank_id) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    await newApi.delete(`tank/register/delete`, {
      ...config,
      data: { farmId: farm_id, tankId: tank_id, registerId: register_id },
    });
  } catch (err) {
    throw new Error(err.response.data);
  }
}
