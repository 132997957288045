import { newApi } from "../api";

export async function updateAnimalLot(data) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.put("/animal/lot", data, config);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}

// export async function lastLotUpdate(animal_id) {
//   const config = {
//     headers: {
//       Authorization: localStorage.getItem("milk-token"),
//     },
//   };

//   try {
//     const response = await api.get(`animal/lastLot/${animal_id}`, config);
//     return response.data;
//   } catch (err) {
//     throw new Error(err.response.data);
//   }
// }
