import { newApi } from "../api";

async function registerFarm(data) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.post("/farm", data, config);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export default registerFarm;
