import { useEffect, useRef, useState } from "react";
import Input from "../../components/input/input";
import useForm from "../../hooks/useForm";
import "./select.css";

function Select(props) {
  const { values, handleChange } = useForm();

  const [options, setOptions] = useState(props.options);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  const selectRef = useRef(null);

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  function handleSetSelectOpen() {
    if (!isSelectOpen) setIsSelectOpen(true);
  }

  function handleInputChange(event) {
    const subStringAtual = event.currentTarget.value;
    if (
      subStringAtual &&
      values.stateSearch &&
      subStringAtual.length >= values.stateSearch.length
    ) {
      // Adding a letter only filter in current values
      setOptions(
        options.filter(
          (option) =>
            option.label.toLowerCase().indexOf(subStringAtual.toLowerCase()) !==
            -1
        )
      );
      handleChange(event);
    } else {
      // Remove a letter filter in all original values
      setOptions(
        props.options.filter(
          (option) =>
            option.label.toLowerCase().indexOf(subStringAtual.toLowerCase()) !==
            -1
        )
      );
      handleChange(event);
    }
  }

  const [optionSelected, setOptionSelected] = useState("");

  useEffect(() => {
    setOptionSelected(props.value || "");
  }, [props.value]);

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!selectRef.current.contains(event.target)) {
        setIsSelectOpen(false);
      }
    };
    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  function handleOptionClick(value) {
    setOptionSelected(value.label);
    setIsSelectOpen(false);
    props.onChange(value);
  }

  return (
    <div className={"custom-select-div " + props.className}>
      {(props.label || props.error) && (
        <div
          className={
            "div-label " + (props.errorAlignColumn ? "errorColumn" : "errorRow")
          }
        >
          {props.label && (
            <label className="select-label" htmlFor={props.id}>
              {props.label}:{props.required && "*"}{" "}
              {props.optional && <i>Campo opcional</i>}
            </label>
          )}
          {props.error && <p className="erro">{props.error}</p>}
        </div>
      )}

      <div
        id={props.id}
        ref={selectRef}
        className={"custom-select-wrapper " + (props.disabled && "disabled")}
        onClick={() => handleSetSelectOpen()}
      >
        <div className={"custom-select " + (isSelectOpen && "open")}>
          <div className="custom-select__trigger">
            {props.filter ? (
              <span>{props.placeholder}</span>
            ) : props.bacteria ? (
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    optionSelected && optionSelected !== ""
                      ? optionSelected
                      : props.placeholder,
                }}
              />
            ) : isSelectOpen && props.search ? (
              <Input
                autoFocus
                id="stateSearch"
                placeholder={props.inputPlaceholder}
                errorAlignColumn
                value={values["teste"]}
                type="text"
                onChange={handleInputChange}
              />
            ) : (
              <div className="custom-select__option">
                {optionSelected && optionSelected !== ""
                  ? optionSelected
                  : props.placeholder}
              </div>
            )}
            <div
              className="custom-select__option"
              onClick={() => setIsSelectOpen(false)}
            >
              {!props.filter && optionSelected && optionSelected !== "" && (
                <div className="close_x" onClick={() => handleOptionClick("")}>
                  &times;
                </div>
              )}
              <div className="arrow"></div>
            </div>
          </div>

          {isSelectOpen && (
            <div
              className={"options-background " + (props.fixed ? "fixed" : "")}
              onClick={() => setIsSelectOpen(false)}
            >
              <div className={"custom-options " + (props.fixed ? "fixed" : "")}>
                {options.map((option, index) => {
                  return (
                    <div
                      key={index}
                      className="option-wrapper"
                      onClick={() => handleOptionClick(option)}
                    >
                      <div className="border">
                        {props.bacteria ? (
                          <p
                            className="custom-option"
                            dangerouslySetInnerHTML={{ __html: option.label }}
                          />
                        ) : (
                          <>
                            {option.icon && (
                              <img
                                src={option.icon}
                                className="option-icon"
                                alt="ícone"
                              />
                            )}
                            {option.label && (
                              <p className="custom-option">{option.label}</p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Select;
