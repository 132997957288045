import { format } from "date-fns";
import { useEffect, useState } from "react";

import { useEventContext } from "../../../../contexts/eventContext";
import { useFarmContext } from "../../../../contexts/farmContext";
import useForm from "../../../../hooks/useForm";

import { calcAge } from "../../../../utils/calcAge";
import { isDateValid, isPastDate } from "../../../../utils/isDateValid";
import { separar } from "../../../../utils/separarArray";

import {
  lastMilkProduction,
  registerMilkProduction,
} from "../../../../services/events/milkproduction";

import Input from "../../../../components/input/input";
import EventContent from "../../components/eventContent/eventContent";

// import { getPartoById } from "../../../../services/events/birth";
import { dateToApiDate } from "../../../../utils/dateToApiDate";
import "./milkproduction.css";

// const USE_VOLUME_POR_ORDENHA = false;

export default function MilkProduction() {
  const { errors, values, handleSubmit, handleChange, handleSetErrors, reset } =
    useForm();
  const { selectedAnimals, handleModalShow, handleModalProps } =
    useEventContext();
  const { farmSelected } = useFarmContext();

  // const [milking, setMilking] = useState(["milking"]);
  // const [auxState, setAuxState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [infoValue, setInfoValue] = useState("-");

  // const [lastBirt, setLastBirt] = useState("");

  useEffect(() => {
    async function fetchLastRegister() {
      try {
        const register_response = await lastMilkProduction(
          selectedAnimals[0].id,
          selectedAnimals[0].farmId
        );
        const date = new Date(
          register_response.milkProduction.eventDate.slice(0, -1)
        );
        const value = format(date, "dd/MM/yyyy");
        setInfoValue(value);
      } catch (err) {}
    }
    if (selectedAnimals.length === 1) {
      fetchLastRegister();
    }
  }, [selectedAnimals]);

  // useEffect(() => {
  //   async function fetchLastRegister() {
  //     try {
  //       if (selectedAnimals[0].parto_id) {
  //         const register_response = await getPartoById(
  //           selectedAnimals[0].parto_id
  //         );
  //         setLastBirt(register_response.data_parto);
  //       }
  //     } catch (err) {}
  //   }
  //   if (selectedAnimals.length === 1) {
  //     fetchLastRegister();
  //   }
  // }, [selectedAnimals]);

  async function checkValues() {
    const error = {};

    if (!values.registerMonth) {
      error.registerMonth = "Este campo é obrigatório";
    } else if (!isDateValid(values.registerMonth)) {
      error.registerMonth = "Insira uma data válida";
    } else if (!isPastDate(values.registerMonth)) {
      error.registerMonth = "Insira um mês passado ou o atual";
      // } else if (
      //   isPastDate(
      //     nDays(values.registerMonth) + "/" + values.registerMonth,
      //     lastBirt
      //   )
      // ) {
      //   error.registerMonth =
      //     "Insira um mês posterior ao ultimo parto do animal: " +
      //     timestampToDate(lastBirt);
    } else if (errors.registerMonth) {
      delete error.registerMonth;
    }

    selectedAnimals.forEach((animal) => {
      if (!values[animal.id]) {
        error[animal.id] = "Este campo é obrigatório";
      } else if (parseFloat(values[animal.id]) === 0) {
        error[animal.id] = "Preencha um valor maior que zero";
      } else if (errors[animal.id]) {
        delete error[animal.id];
      }
    });

    // if (USE_VOLUME_POR_ORDENHA && !values.milking) {
    //   error.milking = "Este campo é obrigatório";
    // } else if (errors.milking) {
    //   delete error.milking;
    // }

    return error;
  }

  async function valuesAreCorrect() {
    try {
      setIsLoading(true);
      const data = [];
      var total = 0;
      for (let value in values) {
        if (value !== "registerMonth") {
          total = total + parseFloat(values[value]) * 30;
          data.push({
            animalId: value,
            farmId: farmSelected.farm_id,
            eventDate: dateToApiDate(values.registerMonth),
            dayVolume: Number(parseFloat(values[value]).toFixed(2)),
            animalLotId: selectedAnimals[0].lot?.id ?? undefined,
            // volume_mes: (parseFloat(values[value]) * 30).toFixed(2),
          });
        }
      }
      await Promise.all(
        separar(data, 400).map(async (arr) => {
          await registerMilkProduction(arr);
        })
      );

      setIsLoading(false);
      reset();
      // const value =
      //   (parseFloat(values.totalVolume) * 30).toFixed(2) + " litros";
      const value = total.toFixed(2) + " litros";
      handleModalProps({ boxValue: value });
      handleModalShow(true);
    } catch (err) {
      setIsLoading(false);
      handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
    }
  }

  // function handleAddNewMilking() {
  //   const aux = milking;
  //   const newMilking = "milking" + milking.length;
  //   aux.push(newMilking);

  //   setMilking(aux);
  //   setAuxState(!auxState);
  // }

  function handleBlur(animal_id) {
    const value = values[animal_id].replace("litros", "").replace(/\s/g, "");
    if (value.length === 3) {
      handleChange({
        currentTarget: { name: animal_id, value: value + "00" },
      });
    } else if (value.length === 4) {
      handleChange({
        currentTarget: { name: animal_id, value: value + "0" },
      });
    }
  }

  return (
    <EventContent
      animals={selectedAnimals}
      className="milk-production"
      eventInfoValue={infoValue}
      onClickSave={handleSubmit(checkValues, valuesAreCorrect, isLoading)}
      isLoading={isLoading}
      apiError={errors.api}
    >
      <div className="volume-line">
        <div className="px300-input">
          <Input
            id="registerMonth"
            label="Dia do registro"
            errorAlignColumn
            error={errors.registerMonth}
            value={values.registerMonth}
            type="text"
            placeholder="dd/mm/aaaa"
            mask="99/99/9999"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="lines-wrapper">
        {selectedAnimals.map((animal) => {
          return (
            <div className="volume-line">
              <div>
                {selectedAnimals.length > 1 && (
                  <div className="animal-name">
                    <p id="reproduction-animal-name">
                      <b>{animal.Identificação}</b>
                      {isNaN(calcAge(animal.Nascimento))
                        ? ""
                        : "(" + calcAge(animal.Nascimento) + " anos)"}
                    </p>
                  </div>
                )}
                <div className="px300-input">
                  <Input
                    id={animal.id} //"totalVolume"
                    label="Volume total no dia"
                    errorAlignColumn
                    error={errors[animal.id]}
                    value={values[animal.id]}
                    type="text"
                    placeholder="Insira o volume total em litros"
                    mask="99.99 litros"
                    maskChar=" "
                    onChange={handleChange}
                    onBlur={() => handleBlur(animal.id)}
                  />
                </div>
              </div>
              {values[animal.id] && !isNaN(parseFloat(values[animal.id])) && (
                <div className="volume-value-div">
                  <p className="volume-label">Volume total estimado no mês:</p>
                  <h2 id="total-volume">
                    {(parseFloat(values[animal.id]) * 30).toFixed(2) +
                      " litros"}
                  </h2>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {/* {USE_VOLUME_POR_ORDENHA && (
        <>
          <p className="volume-label">Volume por ordenha:</p>
          <div className="volume-line">
            {milking.map((value, index) => {
              return (
                <div className="px300-input" key={value + index}>
                  <Input
                    id={value}
                    label={index + 1 + "ª ordenha"}
                    optional={index > 0}
                    errorAlignColumn
                    value={values[value]}
                    error={errors[value]}
                    type="text"
                    placeholder="Insira o valor em litros"
                    mask="99 litros"
                    onChange={handleChange}
                  />
                </div>
              );
            })}
          </div>
          <div className="new-milking">
            <Button color="white" onClick={handleAddNewMilking}>
              Adicionar Ordenha
            </Button>
          </div>
        </>
      )} */}
    </EventContent>
  );
}
