import React, { useEffect } from "react";

import Input from "../../../../components/input/input";
import Select from "../../../../components/select/select";

import useForm from "../../../../hooks/useForm";

function RegisterRow({
  categories,
  races,
  lotOptions,
  handleAnimalValues,
  errors,
}) {
  const { values, handleChange, handleSelectChange } = useForm();

  useEffect(() => {
    handleAnimalValues(values);
  }, [values]);

  return (
    <div className="register_row">
      <Input
        id="animal_name"
        type="text"
        className={errors?.animal_name ? "error" : ""}
        value={values.animal_name}
        onChange={handleChange}
        required
      />
      <Input
        id="animal_birth"
        type="text"
        className={errors?.animal_birth ? "error" : ""}
        value={values.animal_birth}
        onChange={handleChange}
        mask="99/99/9999"
      />
      <Select
        id="category"
        options={categories}
        className={errors?.category ? "error" : ""}
        value={values.category}
        onChange={(index) => handleSelectChange("category", index)}
        required
        fixed
      />
      <Select
        id="race"
        options={races}
        className={errors?.race ? "error" : ""}
        value={values.race}
        onChange={(index) => handleSelectChange("race", index)}
        fixed
      />
      <Select
        id="lot_option"
        options={lotOptions}
        className={errors?.lot_option ? "error" : ""}
        value={values.lot_option}
        onChange={(index) => handleSelectChange("lot_option", index)}
        fixed
      />
      <Input
        id="lot_name"
        type="text"
        className={errors?.lot_name ? "error" : ""}
        value={values.lot_name}
        onChange={handleChange}
      />
    </div>
  );
}

export default RegisterRow;
