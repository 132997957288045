import { format } from "date-fns";
import { useEffect, useState } from "react";

import { useEventContext } from "../../../../contexts/eventContext";
import { useFarmContext } from "../../../../contexts/farmContext";
import useForm from "../../../../hooks/useForm";

import CheckBox from "../../../../components/checkBox/checkBox";
import Input from "../../../../components/input/input";
import Select from "../../../../components/select/select";
import CheckBoxTeto from "../../components/checkBoxTeto/checkBoxTeto";
import EventContent from "../../components/eventContent/eventContent";

import "./microbiologicalCulture.css";

import { isDateValid, isPastDate } from "../../../../utils/isDateValid";
import { separar } from "../../../../utils/separarArray";

// import { getPartoById } from "../../../../services/events/birth";
import {
  lastCulture,
  registerCulture,
} from "../../../../services/events/microbiologicalCulture";
import { dateToApiDate } from "../../../../utils/dateToApiDate";

const optionsType = [
  { label: "<i>Escherichia coli</i>" },
  { label: "<i>Klebsiella</i> spp" },
  { label: "<i>Enterobacter</i> spp" },
  { label: "<i>Pseudomonas</i> spp" },
  { label: "<i>Prototheca</i> spp" },
  { label: "Levedura" },
  { label: "<i>Streptococcus dysgalactiae</i>" },
  { label: "<i>Streptococcus agalactiae</i>" },
  { label: "<i>Streptococcus uberis</i>" },
  { label: "<i>Enterococcus</i> spp" },
  { label: "<i>Lactococcus</i> spp" },
  { label: "<i>Aerococcus viridans</i>" },
  { label: "<i>Staphylococcus haemoliyticus</i>" },
  { label: "<i>Staphylococcus aureus</i>" },
  {
    label:
      "<i>Staphylococcus</i> não <i>aureus</i> (SCN – <i>Staphylococcus</i> coagulase negativa)",
  },
  { label: "Outras Gram-positivas" },
];

export default function MicrobiologicalCulture() {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleSelectChange,
    handleSetErrors,
    reset,
  } = useForm();
  const { selectedAnimals, handleModalShow, handleModalProps } =
    useEventContext();
  const { farmSelected } = useFarmContext();

  const [selectIndividual, setSelectIndividual] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [infoValue, setInfoValue] = useState("-");
  // const [lastBirts, setLastBirts] = useState([]);

  useEffect(() => {
    async function fetchLastRegister() {
      try {
        const register_response = await lastCulture(
          selectedAnimals[0].id,
          selectedAnimals[0].farmId
        );
        const date = new Date(register_response.culture.eventDate.slice(0, -1));
        const value = format(date, "dd/MM/yyyy");
        setInfoValue(value);
      } catch (err) {}
    }
    if (selectedAnimals.length === 1) {
      fetchLastRegister();
    }
  }, [selectedAnimals]);

  // useEffect(() => {
  //   if (selectedAnimals.length > 0) {
  //     selectedAnimals.forEach(async (animal) => {
  //       try {
  //         const value = await getPartoById(animal.parto_id);
  //         setLastBirts((arr) => [...arr, value.data_parto]);
  //       } catch (err) {}
  //     });
  //   }
  // }, [selectedAnimals]);

  async function checkValues() {
    const error = {};

    if (!values.date) {
      error.date = "Este campo é obrigatório";
    } else if (!isDateValid(values.date)) {
      error.date = "Insira uma data válida";
    } else if (!isPastDate(values.date)) {
      error.date = "Insira uma data no passado ou dia corrente";
      // } else if (isPastDate(values.date, maxDateinArray(lastBirts))) {
      //   error.date =
      //     "Insira uma data posterior a " +
      //     format(maxDateinArray(lastBirts) * 1000, "dd/MM/yyyy");
    } else if (errors.date) {
      delete error.date;
    }

    if (!selectIndividual) {
      var count = 0;
      for (var teto in values.tetos) {
        if (values.tetos[teto]) count++;
        else delete values.tetos[teto];
      }

      if (count === 0) {
        error.tetos = "Selecione um teto ou mais";
      } else if (errors.tetos) {
        delete error.tetos;
      }
    } else {
      selectedAnimals.forEach((animal) => {
        var count = 0;
        for (var teto in values[animal.id]) {
          if (values[animal.id][teto]) count++;
          else delete values[animal.id][teto];
        }

        if (count === 0) {
          error[animal.id] = "Selecione um teto ou mais";
        } else if (errors[animal.id]) {
          delete error[animal.id];
        }
      });
    }

    return error;
  }
  async function valuesAreCorrect() {
    try {
      setIsLoading(true);
      const data = [];
      const date = dateToApiDate(values.date);
      if (selectIndividual && selectedAnimals.length > 1) {
        for (var value in values) {
          if (value !== "date" && value !== "type") {
            const aux_data = {
              animalId: value,
              farmId: farmSelected.farm_id,
              eventDate: date,
              tetos: values[value],
            };
            if (values.type) aux_data["bacterium"] = values.type;

            data.push(aux_data);
          }
        }
      } else if (!selectIndividual && selectedAnimals.length > 1) {
        selectedAnimals.forEach((animal) => {
          const aux_data = {
            animalId: animal.id,
            farmId: farmSelected.farm_id,
            eventDate: date,
            tetos: values.tetos,
          };
          if (values.type) aux_data["bacterium"] = values.type;

          data.push(aux_data);
        });
      } else {
        const aux_data = {
          animalId: selectedAnimals[0].id,
          farmId: farmSelected.farm_id,
          eventDate: date,
          tetos: values.tetos,
        };
        if (values.type) aux_data["bacterium"] = values.type;

        data.push(aux_data);
      }
      await Promise.all(
        separar(data, 400).map(async (arr) => {
          await registerCulture(arr);
        })
      );

      setIsLoading(false);
      reset();
      const boxValue = values.date;
      handleModalProps({ boxValue: boxValue });
      handleModalShow(true);
    } catch (err) {
      setIsLoading(false);
      handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
    }
  }

  return (
    <EventContent
      animals={selectedAnimals}
      className="microbiologicalCulture"
      eventInfoValue={infoValue}
      onClickSave={handleSubmit(checkValues, valuesAreCorrect, isLoading)}
      isLoading={isLoading}
      apiError={errors.api}
    >
      <div className="px300-input">
        <Input
          id="date"
          label="Data da realização do teste"
          errorAlignColumn
          error={errors.date}
          value={values.date}
          type="text"
          onChange={handleChange}
          mask="99/99/9999"
          placeholder="dd/mm/aaaa"
        />
      </div>
      <div className="px300-input">
        <Select
          id="type"
          optional
          label="Agente encontrado"
          placeholder="Clique e selecione a bacteria"
          value={values.type}
          onChange={(value) => handleSelectChange("type", value.label)}
          options={optionsType}
          bacteria
        />
      </div>
      {selectedAnimals.length > 1 && (
        <div className="input-line">
          <CheckBox
            name="individual"
            typecheck="check"
            checked={selectIndividual}
            onChange={(_, checked) => setSelectIndividual(checked)}
            language="Selecione os tetos de cada animal separadamente"
            value="individual"
          />
        </div>
      )}
      {selectIndividual && selectedAnimals.length > 1 ? (
        <div className="input-line-tetos">
          {selectedAnimals.map((item) => {
            return (
              <div key={item.id} className="input-tetos">
                <CheckBoxTeto
                  id={item.id}
                  errorAlignColumn
                  cow={false}
                  noDegree
                  animal={item}
                  label="Selecione o(s) teto(s)"
                  error={errors[item.id]}
                  onChange={(values) => handleSelectChange(item.id, values)}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <div className="input-line">
          <CheckBoxTeto
            id="tetos"
            cow
            noDegree
            label="Selecione o(s) teto(s)"
            error={errors.tetos}
            onChange={(values) => handleSelectChange("tetos", values)}
          />
        </div>
      )}
    </EventContent>
  );
}
