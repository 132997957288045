import { Box, TextInfo } from "../../bases/bases";

const columns = ["Identificação", "Lote", "Última_Produção"];

export default function LactatingHerd({ lactationNumber, lactatingAnimals }) {
  return (
    <Box
      className="small"
      viewMore={lactationNumber > 0}
      viewMoreTitle={"Situação de rebanho em lactação"}
      animals={lactatingAnimals}
      columns={columns}
    >
      <TextInfo
        first="Situação de rebanho"
        number={lactationNumber}
        latter="em lactação"
      />
    </Box>
  );
}
