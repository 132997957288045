import Button from "../../../components/button/button";
import Input from "../../../components/input/input";
import Modal from "../../../components/modal/modal";

import "./loginModals.css";

export default function ModalInformNewEmail({ showModal, onClickCancel, onClickRegister }) {
  return(
    <Modal showModal={showModal} close={true}>
      <div className="content inform-new-email">
        <p className="title">Agora, cadastre um novo e-mail</p>

        <Input id="email"
          label="E-mail"
          className="input-modal"
          type="text"
          placeholder="Insira um e-mail que você tenha acesso"
        />
        <Input id="confirm_email"
          label="Confirme o e-mail"
          className="input-modal"
          type="text"
          placeholder="Insira novamente o e-mail"
        />

        <div className="modal-button-wrapper">
          <div />
          <Button color="white" onClick={onClickCancel}>Cancelar</Button>
          <Button color="blue" onClick={onClickRegister}>Cadastrar Novo E-mail</Button>
        </div>
      </div>
    </Modal>
  );
}
