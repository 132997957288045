import { newApi } from "../api";

export async function getTanqueInfos(tanque_id, farm_id) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.get(`/tank/${farm_id}/${tanque_id}`, config);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}
