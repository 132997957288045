import { format } from "date-fns";
import xl from "excel4node";
import { useCallback, useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import loading_gif from "../../../../assets/gifs/loading_2.json";
import Button from "../../../../components/button/button";
import Modal from "../../../../components/modal/modal";
import Table from "../../../../components/table/table";
import { delTankInfo } from "../../../../services/milkQuality/delTankInfo";
import { getTanqueInfos } from "../../../../services/milkQuality/getTankInfo";
import DeleteEventModal from "./components/deleteEventModal/deleteEventModal";
import "./modalHistoryTank.css";

const columns = [
  "Mês_do_registro",
  "Valor_de_CCS",
  "Valor_de_CPP",
  "Valor_do_litro",
];

const onClickDowloadSpreadsheet = async (title, columns, animals) => {
  const wb = new xl.Workbook({
    defaultFont: {
      size: 10,
      name: "Roboto",
      color: "#09202d",
    },
    author: "Milk Care",
  });

  const styleHeading = wb.createStyle({
    alignment: {
      horizontal: "center",
      vertical: "center",
    },
    font: {
      size: 14,
      bold: true,
      name: "Nunito",
    },
    border: {
      bottom: {
        style: "medium",
        color: "#05a6fc",
      },
    },
    fill: {
      type: "pattern",
      patternType: "solid",
      bgColor: "#dedede",
      fgColor: "#dedede",
    },
  });

  const styleCell = wb.createStyle({
    alignment: {
      horizontal: "center",
      vertical: "center",
    },
  });

  const ws = wb.addWorksheet(title);

  var headingIndex = 1;
  columns.forEach((col) => {
    ws.column(headingIndex).setWidth(40);
    ws.cell(1, headingIndex++).string(col).style(styleHeading);
  });

  var rowIndex = 2;
  animals.forEach((record) => {
    var columnIndex = 1;
    columns.forEach((columnName) => {
      // const aux =
      //   typeof record[columnName] === "number"
      //     ? record[columnName].toString()
      //     : record[columnName];
      ws.cell(rowIndex, columnIndex++)
        .string(record[columnName]?.toString())
        .style(styleCell);
    });
    rowIndex++;
  });

  const uint8Array = await wb.writeToBuffer();
  const buffer = uint8Array.buffer.slice(
    uint8Array.byteOffset,
    uint8Array.byteLength + uint8Array.byteOffset
  );

  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = title;
  link.click();
};

export default function ModalHistoryTank({
  showModal,
  onClickCancel,
  tankSelected,
  farm_id,
}) {
  const [allTankInfos, setTankInfos] = useState([]);
  const [tankInfosSelected, setTankInfosSelected] = useState([]);
  const [showModalDeleteEvent, setShowModalDeleteEvent] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [isLoadingDel, setIsLoadingDel] = useState(false);

  async function fetchTankInfos() {
    try {
      const response = await getTanqueInfos(tankSelected?.id, farm_id);
      setTankInfos(mapToTable(response.tank));
      setIsLoading(false);
    } catch (err) {
      setTankInfos([]);
      setIsLoading(false);
      console.log("Erro ao buscar informaçẽos do tanque:" + tankSelected?.id);
    }
  }

  useEffect(() => {
    fetchTankInfos();
  }, [tankSelected]);

  function handleDeleteTankInfo() {
    async function deleteTankInfos() {
      setIsLoadingDel(true);
      try {
        await delTankInfo(tankInfosSelected[0].id, farm_id, tankSelected?.id);
        setShowModalDeleteEvent(false);
        setIsLoadingDel(false);
        fetchTankInfos();
      } catch (err) {
        setIsLoadingDel(false);
        setShowModalDeleteEvent(false);
        console.log(
          "Erro ao tentar deletar a entrada:" + tankInfosSelected[0].id
        );
      }
    }
    deleteTankInfos();
  }

  const wrapSelectEvents = useCallback((value) => {
    setTankInfosSelected(value);
  }, []);

  const onChangeEvent = useCallback(
    (value) => {
      wrapSelectEvents(value);
    },
    [wrapSelectEvents]
  );

  function mapToTable(tankInfo) {
    const aux_tankInfo = tankInfo.registers.map((item) => {
      const valor =
        item.priceCcs +
        item.priceCpp +
        item.priceFat +
        item.priceLiter +
        item.priceProtein;
      return {
        id: item.id,
        Mês_do_registro: format(new Date(item.month.slice(0, -1)), "MM/yyyy"),
        Valor_de_CCS: item.ccs,
        Valor_de_CPP: item.cpp,
        Valor_do_litro:
          valor > 0 ? "R$ " + valor.toFixed(2).replace(".", ",") : "-",
      };
    });
    return aux_tankInfo;
  }

  return (
    <Modal showModal={showModal} close fullsize>
      <div className="delete-tankinfos-modal">
        {isLoading ? (
          <div className="no-data">
            <Lottie loop play animationData={loading_gif} />
          </div>
        ) : allTankInfos.length ? (
          <>
            <div className="tanque_header_wrapper">
              <div>
                <p className="title">Registros do tanque</p>
                <p className="text">Selecione o registro que deseja excluir.</p>
              </div>
              <div className="title_wrapper_button">
                <Button
                  color="blue"
                  onClick={() =>
                    onClickDowloadSpreadsheet("Tanques", columns, allTankInfos)
                  }
                >
                  Baixar planilha
                </Button>
              </div>
            </div>
            <div className="table_wrapper">
              <div
                className="table_wrapper_container"
                style={{ minWidth: columns.length * 180 + "px" }}
              >
                <Table
                  animals={allTankInfos}
                  columns={columns}
                  check
                  // multi TODO, com ploblema
                  event
                  onChangeAnimal={onChangeEvent}
                />
              </div>
            </div>
          </>
        ) : (
          <p className="title">Sem registros</p>
        )}

        <div className="modal-div-buttons">
          <div />
          <Button color="white" onClick={onClickCancel}>
            Cancelar
          </Button>
          <Button
            isLoading={isLoading}
            // onClick={ () => handleDeleteTankInfo()}
            disabled={tankInfosSelected?.length === 0}
            onClick={() => setShowModalDeleteEvent(true)}
          >
            Excluir registro
          </Button>
        </div>
      </div>
      {showModalDeleteEvent && (
        <DeleteEventModal
          onClickCancel={() => setShowModalDeleteEvent(false)}
          onClickSubmit={() => handleDeleteTankInfo()}
          isLoadingDel={isLoadingDel}
          eventInfo={tankInfosSelected[0]}
        />
      )}
    </Modal>
  );
}
