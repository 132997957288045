import { format } from "date-fns";

export function getLastReproductions(data) {
  var stringDates = [];
  data.forEach(function (element) {
    return stringDates.push(new Date(element.eventDate.slice(0, -1)));
  });

  stringDates.sort(function (a, b) {
    // var auxA = new Date(a);
    // var auxB = new Date(b);
    return b.getTime() - a.getTime();
  });

  stringDates = stringDates.map(function (element) {
    return format(element, "dd/MM/yyyy");
  });

  var objDates = [];
  stringDates.forEach((element) => {
    objDates.push({ Data: element });
  });

  return objDates;
}

export function getLastReproduction(data) {
  var today = new Date();
  var stringDates = [];
  data.forEach(function (element) {
    return stringDates.push(new Date(element.eventDate.slice(0, -1)));
  });

  stringDates.sort(function (a, b) {
    // var auxA = new Date(a);
    // var auxB = new Date(b);
    return b.getTime() - a.getTime();
  });

  var date = null;
  var newDate = true;

  stringDates.forEach(function (element) {
    // if (element * 1000 < today.getTime() && newDate) {
    if (element < today && newDate) {
      date = format(element, "dd/MM/yyyy");
      newDate = false;
    }
  });
  if (date) return date;
  else return "Não existe";
}
