import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useFarmContext } from "../../contexts/farmContext";
import useForm from "../../hooks/useForm";

import { registerLot } from "../../services/lot/registerLot";

import Button from "../../components/button/button";
import PageTitle from "../../components/PageTitle/PageTitle";

import ModalDownload from "./components/ModalDownload/modalDownload";
import ModalImport from "./components/ModalImport/modalImport";
import ModalSelectOption from "./components/ModalSelectOption/modalSelectOption";
import RegisterRow from "./components/RegisterRow/registerRow";

import { useUserContext } from "../../contexts/userContext";
import { registerAnimal } from "../../services/animals/registerAnimal";
import { isDateValid, isPastDate } from "../../utils/isDateValid";
import { separar } from "../../utils/separarArray";
import "./animalsRegister.css";

function containsDuplicates(array) {
  const result = array.some((element) => {
    if (array.indexOf(element) !== array.lastIndexOf(element)) {
      return true;
    }

    return false;
  });

  return result;
}

function AnimalsRegister() {
  const history = useHistory();

  const { values, errors, handleSelectChange, handleSubmit, handleSetErrors } =
    useForm();
  const {
    animals,
    farmSelected,
    lots,
    getAnimals,
    getRaces,
    getCategories,
    getFarmLots,
  } = useFarmContext();
  const { userID } = useUserContext();

  const [isLoading, setIsLoading] = useState(false);
  const [lotOptions, setLotOptions] = useState([]);

  const [modalSelectOptionIsOpen, setModalSelectOptionIsOpen] = useState(false);
  const [modalDownloadIsOpen, setModalDownloadIsOpen] = useState(false);
  const [modalImportIsOpen, setModalImportIsOpen] = useState(false);

  const [numberRows, setNumberRows] = useState(10);

  useEffect(() => {
    if (lots.length === 0) getFarmLots();
  }, []);

  useEffect(() => {
    if (lots.length > 0) {
      const lot_options_aux = lots.map((item) => {
        return { label: item.codLote, value: item.id };
      });
      setLotOptions(lot_options_aux);
    }
  }, [lots]);

  async function checkValues() {
    const error = {};

    const animals_names = Object.keys(values).map(
      (key) => values[key].animal_name
    );

    Object.keys(values).forEach((key) => {
      if (Object.values(values[key]).some((item) => item)) {
        if (values[key].animal_birth) {
          if (!isDateValid(values[key].animal_birth)) {
            error[key] = {
              ...error[key],
              animal_birth: "Insira uma data válida",
            };
          } else if (!isPastDate(values[key].animal_birth)) {
            error[key] = {
              ...error[key],
              animal_birth: "Insira uma data no passado ou dia corrente",
            };
          } else if (errors[key]?.animal_birth) {
            delete error[key].animal_birth;
          }
        } else if (errors[key]?.animal_birth) {
          delete error[key].animal_birth;
        }

        if (!values[key].animal_name) {
          error[key] = {
            ...error[key],
            animal_name: "Este campo é obrigatório",
          };
        } else if (/-/.test(values[key].animal_name)) {
          error[key] = {
            ...error[key],
            animal_name: "Nome não pode conter '-'",
          };
        } else if (
          containsDuplicates(animals_names) ||
          animals.find(
            (animal) => animal.identification === values[key].animal_name
          )
        ) {
          error[key] = {
            ...error[key],
            animal_name: "Identificação duplicada",
          };
        } else if (errors[key]?.animal_name) {
          delete error[key]?.animal_name;
        }

        if (!values[key].category) {
          error[key] = { ...error[key], category: "Este campo é obrigatório" };
        } else if (errors[key]?.category) {
          delete error[key].category;
        }

        if (values[key].lot_name && !values[key].lot_option) {
          if (lotOptions.some((opt) => opt.label === values[key].lot_name)) {
            error[key] = {
              ...error[key],
              lot_name: "Este lote já foi cadastrado",
            };
          }
        } else if (errors[key]?.lot_name) {
          delete error[key].lot_name;
        }
      } else {
        delete values[key];
      }
    });

    return error;
  }

  async function valuesAreCorrect() {
    try {
      setIsLoading(true);

      const new_lots = [];
      Object.values(values).forEach((value) => {
        if (
          value.lot_name &&
          !value.lot_option &&
          !new_lots.includes(value.lot_name)
        )
          new_lots.push(value.lot_name);
      });

      var lot_response;
      if (new_lots.length) {
        const response = await registerLot(new_lots, farmSelected.farm_id);
        if (response.lots.length) lot_response = response.lots;
      }

      const data = Object.keys(values).map((key) => {
        const birthday_array = values[key].animal_birth
          ? values[key].animal_birth.split("/")
          : [];

        const dataAux = {
          category: values[key].category,
          farmId: farmSelected.farm_id,
          identification: values[key].animal_name,
          breed: values[key].race ?? null,
          birthday: values[key].animal_birth
            ? birthday_array[2] +
              "-" +
              birthday_array[1] +
              "-" +
              birthday_array[0] // yyyy-mm-dd
            : null,
        };
        const auxLot = lot_response?.find(
          (lot) => lot.code === values[key].lot_name
        );
        if (auxLot || values[key].lot_option) {
          dataAux.lotId = auxLot
            ? auxLot.id
            : lotOptions.find((lot) => lot.label === values[key].lot_option)
                ?.value;
          dataAux.lotCode = auxLot
            ? values[key].lot_name
            : values[key].lot_option;
        }

        return dataAux;
      });
      
      if (data.length) {
        await Promise.all(
          separar(data, 50).map(async (arr) => {
            await registerAnimal({ animals: arr, userId: userID });
          })
        );
      } 

      getAnimals(farmSelected.farm_id);
      getFarmLots();

      history.goBack();
    } catch (err) {
      setIsLoading(false);
      if (err.statusCode !== 500) {
        handleSetErrors({ api: err.message });
      } else {
        handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
      }
    }
  }

  return (
    <div className="animal-register-body">
      <PageTitle path="Adicionar animal" backButton>
        {/* <Button color="blue" onClick={() => setModalSelectOptionIsOpen(true)}>
          Adicionar por planilha
        </Button> */}
      </PageTitle>

      <div className="form-wrapper">
        <form onSubmit={handleSubmit(checkValues, valuesAreCorrect, isLoading)}>
          <p className="title">Adicionar Animal</p>

          <div className="inputs_register_body">
            <div>
              <div>
                <div className="register_header">
                  <div>Identificação*</div>
                  <div>Nascimento</div>
                  <div>Categoria*</div>
                  <div>Raça</div>
                  <div>Lote</div>
                  <div>Novo Lote</div>
                </div>

                <div className="register_animals_rows">
                  {new Array(numberRows).fill(0).map((_, index) => {
                    return (
                      <RegisterRow
                        categories={getCategories()}
                        races={getRaces()}
                        lotOptions={lotOptions}
                        errors={errors[index]}
                        handleAnimalValues={(value) => {
                          if (!Object.keys(value).length) return;

                          handleSelectChange(index, value);
                          if (index + 1 === numberRows)
                            setNumberRows((old) => old + 1);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="form-row-end">
            <div className="buttons-wrapper start">
              <Button
                className="button-type"
                color="white"
                type="button"
                onClick={() => setNumberRows((old) => old + 1)}
              >
                + Linhas
              </Button>
            </div>
            <div className="buttons-wrapper">
              <Button
                className="button-type"
                color="white"
                type="button"
                onClick={() => history.goBack()}
              >
                Cancelar
              </Button>
              <Button
                className="button-type"
                color="blue"
                type="submit"
                isLoading={isLoading}
                disabled={
                  !Object.keys(values).length ||
                  Object.values(errors).every(Boolean) ||
                  isLoading
                }
              >
                Adicionar animal
              </Button>
            </div>
          </div>
        </form>
      </div>

      {modalSelectOptionIsOpen && (
        <ModalSelectOption
          showModal={(boolean) => setModalSelectOptionIsOpen(boolean)}
          onClickCancel={() => setModalSelectOptionIsOpen(false)}
          onClickContinue={(value) => {
            if (value === "import") {
              setModalImportIsOpen(true);
            } else {
              setModalDownloadIsOpen(true);
            }
            setModalSelectOptionIsOpen(false);
          }}
        />
      )}

      {modalDownloadIsOpen && (
        <ModalDownload
          showModal={(boolean) => setModalDownloadIsOpen(boolean)}
          onClickCancel={() => setModalDownloadIsOpen(false)}
        />
      )}

      {modalImportIsOpen && (
        <ModalImport showModal={(boolean) => setModalImportIsOpen(boolean)} />
      )}
    </div>
  );
}

export default AnimalsRegister;
