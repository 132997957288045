
export function scaleBandInvert(scale, coord, isAreaChart) {

  const eachBand = scale.step();
  if (isAreaChart)
    coord = coord + (eachBand/2)
  const index = Math.floor((coord / eachBand));
  const val = scale.domain()[index];
  return val;
}
  