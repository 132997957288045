import { format } from "date-fns";

const MESES_CONST = [
  "Jan.",
  "Fev.",
  "Mar.",
  "Abr.",
  "Mai.",
  "Jun.",
  "Jul.",
  "Ago.",
  "Set.",
  "Out.",
  "Nov",
  "Dez.",
];

export function getMilkProductionData(data) {
  const objData = data.map((element) => {
    return {
      date: new Date(element.eventDate.slice(0, -1)),
      volume: element.dayVolume,
    };
  });

  objData.sort((a, b) => b.date.getTime() - a.date.getTime());

  return objData.map((element) => {
    return {
      Mês: format(element.date, "MM/yyyy"),
      Volume: element.volume + " litros",
    };
  });
}

// export function getMilkProductionData(data) {
//   var aux = {};
//   data
//     .sort(
//       (a, b) =>
//         new Date(a.eventDate.slice(0, -1)).getTime() -
//         new Date(b.eventDate.slice(0, -1)).getTime()
//     )
//     .map((element) => {
//       var date = new Date(element.eventDate.slice(0, -1));
//       var month = date.getMonth();
//       var year = date.getFullYear();

//       if (aux[year]) {
//         aux[year][month].value = element.dayVolume;
//       } else {
//         aux[year] = [];
//         for (let j = 0; j < 12; j++) {
//           aux[year].push({
//             label: MESES_CONST[j],
//             value: 0,
//             tooltipContent: `<b>${0}<b>`,
//           });
//         }
//         aux[year][month].value = element.dayVolume;
//         aux[year][month].tooltipContent = `<b>${element.dayVolume}<b>`;
//       }
//     });

//   const allData = [];
//   Object.keys(aux).forEach((teste) => {
//     var yearData = {
//       year: teste,
//       data: aux[teste],
//     };
//     allData.push(yearData);
//   });
//   allData.sort(function (a, b) {
//     return b.year - a.year;
//   });

//   return allData;
// }
