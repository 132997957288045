
import { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie-player";
import YBarChart from "../../../../../components/baseChart/yBarChart/yBarChart";
import loading_gif from "../../../../../assets/gifs/loading_2.json";
import { meses } from "../../../../../utils/meses";
import { YearSelector } from "../bases";

export default function AnualBarChart({allData, maxDate, isLoading, label} ){

  const [sizeYearChart, setSizeYearChart] = useState({});

  const chartRef = useRef(null);
  const [data, setData] = useState([]);

  const aux_date = new Date(maxDate * 1000);
  const [date, setDate] = useState(aux_date.getFullYear());
  const [maxYear, setMaxYear] = useState(aux_date.getFullYear());

  useEffect(() => {
    const date = new Date(maxDate * 1000);
    setMaxYear(date.getFullYear())
    setDate(date.getFullYear())
  }, [maxDate])

  useEffect(() => {
    var chartData = [];
    var yearData = allData[date];
    for (let i = 0; i < 12; i++) {
      var value = 0
      if (yearData && yearData[i]) {
        value = yearData[i].toFixed();
      };
      chartData.push({
        label: meses[i],
        value: value,
        tooltipContent: `<b>${value}<b>`,
      });
    }
    setData(chartData);
  }, [allData, date])

  useEffect(() => {
    var aux = {};
    aux.height = 180;

    aux.width = chartRef.current.offsetWidth - 80;
    setSizeYearChart(aux);
  }, []);

  return (
    <>
    <YearSelector
      year={date}
      onChange={(value) => {
        setDate(value);
      }}
      maxYear={maxYear}
    />
    <div className="monthly-graph-wrapper" ref={chartRef}>
      {isLoading ? (
        <div className="no-data">
          <Lottie loop play animationData={loading_gif} />
        </div>
      ) : (
        <YBarChart
          svgProps={{
            margin: { top: 53, bottom: 40, left: 40, right: 30 },
            width: sizeYearChart.width,
            height: sizeYearChart.height,
          }}
          axisProps={{
            xLabel: "",
            yLabel: label,
            drawXGridlines: true,
          }}
          data={data}
          strokeWidth={3}
          paddingInner={0.5}
        />
      )}
    </div>
    
    </>)
}