import { useEffect, useState } from "react";

import "./modalRegisterEvent.css";

import { useEventContext } from "../../../../contexts/eventContext";
import { useFarmContext } from "../../../../contexts/farmContext";

import Modal from "../../../../components/modal/modal";

import alertIcon from "../../../../assets/svg/alerts/alert1.svg";
import checkCircle from "../../../../assets/svg/events/checkcircle.svg";

export default function ModalRegisterEvent() {
  const { farmSelected, getAnimals } = useFarmContext();
  const { modalProps, handleModalShow, eventName } = useEventContext();
  const { boxValue, boxSecondary, hightValueCCS, drug, procedure } = modalProps;

  const [boxLabel, setBoxLabel] = useState();

  useEffect(() => {
    getAnimals(farmSelected.farm_id);
  }, [farmSelected.farm_id]);

  useEffect(() => {
    if (boxValue) {
      if (eventName === "Reprodução") {
        setBoxLabel("Data da inseminação");
      } else if (eventName === "Parto") {
        setBoxLabel("Data do parto");
      } else if (eventName === "Produção de Leite") {
        setBoxLabel("Volume total estimado");
      } else if (eventName === "Teste CMT") {
        setBoxLabel("Data do teste");
      } else if (eventName === "CCS Individual") {
        setBoxLabel("Resultado do exame");
      } else if (eventName === "Mastite Clínica") {
        setBoxLabel("Data do diagnóstico");
      } else if (eventName === "Secagem") {
        setBoxLabel("Data da secagem");
      } else if (eventName === "Cultura Microbiológica") {
        setBoxLabel("Data da cultura");
      } else if (eventName === "Lote") {
        setBoxLabel("Lote");
      }
    }
  }, [eventName, boxValue]);

  function handleCloseModal(boolean) {
    handleModalShow(boolean);
  }

  return (
    <Modal showModal={handleCloseModal} close={true}>
      <div className="event-register-content">
        <img src={checkCircle} alt="Check item" className="check-icon" />
        <h2 className="register-event-title">
          {"Que ótimo, o evento de " +
            eventName.toLowerCase() +
            " foi registrado."}
        </h2>
        {boxValue && (
          <div className="info-box">
            <p className="box-label">{boxLabel}</p>
            <p className="box-value">{boxValue}</p>

            {boxSecondary && (
              <>
                {drug && <p className="box-value-secondary">{drug}</p>}
                <p className="box-label-secondary">{boxSecondary.label}</p>
                <p className="box-value-secondary">{boxSecondary.value}</p>
                {procedure && (
                  <p className="box-value-procedure">{procedure}</p>
                )}
              </>
            )}
          </div>
        )}

        {hightValueCCS && (
          <div className="hight-ccs-alert" id="hight-ccs-alert-modal">
            <img src={alertIcon} alt="Ícone de alerta" className="alert-icon" />
            <p>Valor de CCS muito alto.</p>
          </div>
        )}
      </div>
    </Modal>
  );
}
