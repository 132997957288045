import { useState } from "react";
import { useHistory } from "react-router-dom";

import { useUserContext } from "../../contexts/userContext";
import useForm from "../../hooks/useForm";

import logo from "../../assets/images/logo-small-1.png";
import Button from "../../components/button/button";
import Input from "../../components/input/input";

import "./register.css";

import signUp from "../../services/userForm/signUp";

// const options = [
//   { label: "Qual é o nome da sua avó materna?" },
//   { label: "Em qual cidade você nasceu?" },
//   { label: "Qual é a data de nascimento do seu pai?" },
//   { label: "Qual é a data de nascimento da sua mãe?" },
// ];

function RegisterPage() {
  const history = useHistory();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    // handleSelectChange,
    handleSetErrors,
  } = useForm();
  const { handleUser } = useUserContext();

  const [isLoading, setIsLoading] = useState(false);

  async function checkValues() {
    const error = {};

    if (!values.name) {
      error.name = "Este campo é obrigatório";
    } else if (errors.name) {
      delete error.name;
    }

    if (!values.email) {
      error.email = "Este campo é obrigatório";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      error.email = "Endereço de e-mail invalido";
    } else if (errors.email) {
      delete error.email;
    }

    if (!values.confirm_email) {
      error.confirm_email = "Este campo é obrigatório";
    } else if (values.confirm_email !== values.email) {
      error.confirm_email = "Os e-mails devem ser iguais";
    } else if (errors.confirm_email) {
      delete error.confirm_email;
    }

    if (!values.password) {
      error.password = "Este campo é obrigatório";
    } else if (values.password.length < 8) {
      error.password = "A senha deve ter mais de 8 caracteres";
    } else if (!/[A-Z]/.test(values.password)) {
      error.password = "A senha deve ter letras maiúsculas";
    } else if (!/[0-9]/.test(values.password)) {
      error.password = "A senha deve ter numerais";
    } else if (errors.password) {
      delete error.password;
    }

    if (!values.confirm_password) {
      error.confirm_password = "Este campo é obrigatório";
    } else if (values.confirm_password !== values.password) {
      error.confirm_password = "As senhas devem ser iguais";
    } else if (errors.confirm_password) {
      delete error.confirm_password;
    }

    if (!values.document) {
      error.document = "Este campo é obrigatório";
    } else if (values.document.indexOf("_") > -1) {
      error.document = "Por favor digite um CPF válido";
    } else if (errors.document) {
      delete error.document;
    }

    // if (!values.password_recover) {
    //   error.password_recover = "Este campo é obrigatório";
    // } else if (errors.password_recover) {
    //   delete error.password_recover;
    // }

    // if (!values.answer) {
    //   error.answer = "Este campo é obrigatório";
    // } else if (errors.answer) {
    //   delete error.answer;
    // }

    return error;
  }

  async function valuesAreCorrect() {
    try {
      setIsLoading(true);
      const data = {
        name: values.name,
        cpf: values.document,
        email: values.email,
        password: values.password,
        role: "veterinary",
      };
      const user = await signUp(data);
      handleUser({
        id: user.user.id,
        email: values.email,
        name: values.name,
        document: values.document,
      });

      history.push("/verification");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (err.message === "UsernameExistsException") {
        handleSetErrors({ email: "Endereço de e-mail já utilizado" });
      } else {
        handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
      }
    }
  }

  return (
    <div className="register">
      <div className="white-part">
        <div>
          <h1 className="welcome-text">Bem Vindo(a),</h1>
          <form
            onSubmit={handleSubmit(checkValues, valuesAreCorrect, isLoading)}
          >
            <div className="input-row">
              <Input
                id="name"
                label="Nome"
                placeholder="Insira o seu nome"
                className="register-input"
                type="text"
                error={errors.name}
                value={values.name}
                onChange={handleChange}
                required
              />
              <Input
                id="document"
                label="CPF"
                placeholder="___.___.___-__"
                className="register-input"
                type="text"
                error={errors.document}
                value={values.document}
                onChange={handleChange}
                mask="999.999.999-99"
                required
              />
            </div>
            <div className="input-row">
              <Input
                id="email"
                label="E-mail para acesso"
                placeholder="Insira um nome de usuário"
                className="register-input"
                type="text"
                errorAlignColumn={false}
                error={errors.email}
                value={values.email}
                onChange={handleChange}
                required
              />
              <Input
                id="confirm_email"
                label="Confirmar o e-mail"
                placeholder="Insira o e-mail novamente"
                className="register-input"
                type="text"
                error={errors.confirm_email}
                value={values.confirm_email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-row password">
              <Input
                id="password"
                label="Senha"
                placeholder="Insira uma senha"
                className="register-input"
                type="password"
                error={errors.password}
                value={values.password}
                onChange={handleChange}
                required
              />
              <Input
                id="confirm_password"
                label="Confirmar senha"
                placeholder="Insira a senha escolhida novamente"
                className="register-input"
                type="password"
                error={errors.confirm_password}
                value={values.confirm_password}
                onChange={handleChange}
                required
              />
            </div>
            <p className="password-info">
              (sua senha deve ter pelo menos 8 caracteres, contendo números e
              letras maiúsculas e minúsculas)
            </p>
            {/* <div className="input-row">
              <Select
                id="password_recover"
                className="register-select"
                label="Pergunta para recuperação de senha"
                placeholder="Selecione uma pergunta"
                options={options}
                errorAlignColumn={true}
                error={errors.password_recover}
                onChange={(value) =>
                  handleSelectChange("password_recover", value)
                }
                required
              />
              <Input
                id="answer"
                label="Resposta para recuperação de senha"
                placeholder="Insira a resposta para a pergunta escolhida"
                type="text"
                errorAlignColumn={true}
                error={errors.answer}
                value={values.answer}
                onChange={handleChange}
                required
              />
            </div> */}

            {errors.api && <p className="api-error">{errors.api}</p>}

            <div className="button-row">
              <div />
              <Button color="white" onClick={() => history.push("/")}>
                Cancelar Cadastro
              </Button>
              <Button color="blue" type="submit" isLoading={isLoading}>
                Cadastrar
              </Button>
            </div>
          </form>
        </div>
      </div>

      <div className="blue-part">
        <div className="mask">
          <div>
            <img className="logo" src={logo} alt="logo" />
            {/* <p className="major-text">Juntos pela qualidade</p> */}
            <p className="minor-text">
              Ficou mais fácil organizar e gerenciar as informações sobre
              qualidade do leite da sua propriedade
            </p>
            <p className="minor-text">
              Tenha na palma da mão todo o histórico de mastite clínica,
              subclínica e tratamentos dos seus animais
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
