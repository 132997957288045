import { useCallback, useEffect, useState } from "react";
import Lottie from "react-lottie-player";

import { useEventContext } from "../../contexts/eventContext";
import { useFarmContext } from "../../contexts/farmContext";

import Button from "../../components/button/button";
import PageTitle from "../../components/PageTitle/PageTitle";
// import Select from "../../components/select/select";
import Input from "../../components/input/input";
import Table from "../../components/table/table";
// import Filter from "../../components/filter/filter";

import ModalDownload from "./components/ModalDownload/modalDownload";
import ModalImport from "./components/ModalImport/modalImport";
import ModalSelectOption from "./components/ModalSelectOption/modalSelectOption";

import loading_gif from "../../assets/gifs/loading_2.json";
// import filter_icon from "../../assets/svg/filter.svg";
import search_icon from "../../assets/svg/search-icon.svg";

import { isPast, isValid, parse } from "date-fns";
import Spinner from "../../components/spinner/spinner";
import { getAnimalsByBirthMonth } from "../../services/events/birth";
import { getAnimalsByMilkProductionMonth } from "../../services/events/milkproduction";
import "./createEvents.css";

// const options = [
//   {
//     label: "Animais arquivados",
//     value: "Animais arquivados",
//   },
//   {
//     label: "Próximos partos",
//     value: "Próximos partos",
//   },
//   {
//     label: "Próximas secagens",
//     value: "Próximas secagens",
//   },
//   {
//     label: "Animais com ccs alto",
//     value: "Animais com ccs alto",
//   },
//   {
//     label: "Animais com mastite subclínica",
//     value: "Animais com mastite subclínica",
//   },
//   {
//     label: "Animais com mastite clínica",
//     value: "Animais com mastite clínica",
//   },
//   {
//     label: "Animais em lactação",
//     value: "Animais em lactação",
//   },
//   {
//     label: "Rebanhos",
//     value: "Rebanhos",
//   },
//   {
//     label: "Lote",
//     value: "Lote",
//   },
// ];

const columns = ["Identificação", "Categoria", "Lote"];
const mastiteTreatmentColumns = [
  "Identificação",
  "Lote",
  "Data_do_Tratamento",
  "Tipo_Tratamento",
];

function CreateEventsPage() {
  const {
    getCorrectEventAnimals,
    animalBigImg,
    getFarmLots,
    loadingFarms,
    farmSelected,
  } = useFarmContext();
  const {
    eventName,
    selectedAnimals,
    isMultiAvailable,
    handleSelectedAnimals,
    handleNewEvent,
    typeEvent,
    handleTypeEvent,
  } = useEventContext();

  // const [selectedFilters, setSelectedFilters] = useState([]);
  const [animalsToShow, setAnimalsToShow] = useState([]);
  const [eventAnimals, setEventAnimals] = useState([]);
  const [reload, setReload] = useState(false);

  const [values_filter_month, setValuesFilterMonth] = useState();
  const [errors_filter_month, setErrorsFilterMonth] = useState();
  const [animalsToFilter, setAnimalsToFilter] = useState([]);

  const [isMonthFilterLoading, setIsMonthFilterLoading] = useState(false);

  const [modalSelectOptionIsOpen, setModalSelectOptionIsOpen] = useState(false);
  const [modalDownloadIsOpen, setModalDownloadIsOpen] = useState(false);
  const [modalImportIsOpen, setModalImportIsOpen] = useState(false);

  useEffect(() => {
    if (eventName !== "Mastite Clínica") handleTypeEvent();
  }, [eventName]);

  useEffect(() => {
    async function fetch() {
      const eventAnimalsAux = await getCorrectEventAnimals(
        eventName,
        typeEvent
      );
      setEventAnimals(eventAnimalsAux);
      setAnimalsToShow(eventAnimalsAux);

      if (eventName === "Lote") getFarmLots();
    }
    fetch();
  }, [eventName, getCorrectEventAnimals, getFarmLots, typeEvent]);

  useEffect(() => {
    if (
      !errors_filter_month &&
      values_filter_month &&
      values_filter_month !== "__/____"
    ) {
      const aux = eventAnimals.filter((item) =>
        animalsToFilter.includes(item.id)
      );
      setAnimalsToShow(aux);
    } else {
      setAnimalsToShow(eventAnimals);
    }
  }, [animalsToFilter]);

  // function addFilter(filter) {
  //   if (!selectedFilters.includes(filter)) {
  //     setSelectedFilters([...selectedFilters, filter]);

  //     var animalsToShowAux;
  //     selectedFilters.length === 0
  //       ? (animalsToShowAux = [])
  //       : (animalsToShowAux = animalsToShow);

  //     setAnimalsToShow(animalsToShowAux);
  //   }
  // }

  // function removeFilter(filter, index){
  //   if (selectedFilters.length === 1)
  //     setAnimalsToShow(animals)
  //   else {
  //     var animalsToShowAux = animalsToShow.filter(elem => elem.filter_by.includes(filter.value) ? false : true)
  //     setAnimalsToShow(animalsToShowAux)
  //   }

  //   var selectedFiltersAux = selectedFilters
  //   selectedFiltersAux.splice(index, 1)
  //   setSelectedFilters(selectedFiltersAux)
  // }

  // function handleSearchbarChange(event) {
  //   if (event.target.value === "") {
  //     setAnimalsToShow(eventAnimals);
  //   } else {
  //     const aux = eventAnimals.filter(
  //       (animal) =>
  //         animal["Identificação"].toLowerCase().indexOf(event.target.value) !==
  //           -1 ||
  //         animal["Categoria"].toLowerCase().indexOf(event.target.value) !==
  //           -1 ||
  //         (animal["Lote"] &&
  //           animal["Lote"].toLowerCase().indexOf(event.target.value) !== -1)
  //     );
  //     if (aux.length > 0) {
  //       setAnimalsToShow(aux);
  //     } else {
  //       setAnimalsToShow(eventAnimals);
  //     }
  //   }
  // }

  const handleInputFilterMonthChange = (event) => {
    setValuesFilterMonth(event.target.value);

    if (!event.target.value || event.target.value === "__/____") {
      setErrorsFilterMonth();
      setAnimalsToFilter([]);
      return;
    }

    const date = parse(event.target.value, "MM/yyyy", new Date());
    if (!isValid(date)) {
      setErrorsFilterMonth("Insira uma data válida");
      setAnimalsToFilter([]);
    } else if (!isPast(date)) {
      setErrorsFilterMonth("Insira uma data no passado");
      setAnimalsToFilter([]);
    } else {
      setErrorsFilterMonth();
    }
  };

  const handleFilterMonth = async () => {
    if (errors_filter_month || isMonthFilterLoading) return;

    try {
      setIsMonthFilterLoading(true);
      const monthAux = values_filter_month.split("/");
      const month = monthAux[1] + "-" + monthAux[0];

      if (eventName === "Teste CMT") {
        const animals = await getAnimalsByMilkProductionMonth(
          farmSelected.farm_id,
          month
        );
        setAnimalsToFilter(animals.animalsIds);
      }

      if (eventName === "Produção de Leite") {
        const animals = await getAnimalsByBirthMonth(
          farmSelected.farm_id,
          month
        );
        setAnimalsToFilter(animals.animalsIds);
      }

      setIsMonthFilterLoading(false);
    } catch (err) {
      setIsMonthFilterLoading(false);
    }
  };

  const onChangeAnimal = useCallback(
    (value) => {
      handleSelectedAnimals(value);
      setReload((r) => !r);
    },
    [handleSelectedAnimals]
  );

  return (
    <div className="create-events-body">
      <PageTitle path="Adicionar Evento" backButton />

      <div className="body-wrapper">
        {eventName === "Mastite Clínica" ? (
          <div className="mastitis_title_row">
            <p
              className={typeEvent === "mastite" && "selected"}
              onClick={() => handleTypeEvent("mastite")}
            >
              Adicionar Mastite Clínica
            </p>
            <p
              className={typeEvent === "tratamento" && "selected"}
              onClick={() => handleTypeEvent("tratamento")}
            >
              Mastite Diagnosticada
            </p>
          </div>
        ) : (
          <div className="second-title-row">
            <p className="second-title">{eventName}</p>
          </div>
        )}
        <div
          className={
            "first-text_row " + (eventName === "Mastite Clínica" && "mastitis")
          }
        >
          <p className="first-text">
            Selecione o(s) animal(is) que será(ão) registrado(s) no evento.
          </p>

          <a href="/" target="_blank">
            Acesse nosso blog para tirar suas dúvidas
          </a>
        </div>

        {["Teste CMT", "Produção de Leite"].includes(eventName) && (
          <div className="input-button-div">
            <div className="input-wrapper">
              <Input
                id="filter_month"
                label={
                  "Filtrar por mês " +
                  (eventName === "Teste CMT"
                    ? "de produção de leite"
                    : "pós parto")
                }
                className="search-word-input"
                type="text"
                placeholder="mm/aaaa"
                mask="99/9999"
                onChange={handleInputFilterMonthChange}
                onBlur={handleFilterMonth}
                value={values_filter_month}
                error={errors_filter_month}
                errorAlignColumn
              />
              <div
                className={
                  "lupa-icon " + (isMonthFilterLoading ? "spinner" : "icon")
                }
              >
                {isMonthFilterLoading ? (
                  <Spinner color="blue" />
                ) : (
                  <img
                    src={search_icon}
                    alt="Lupa"
                    onClick={handleFilterMonth}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <div className="select-button-wrapper">
          {/* <Select
              id="filter-select"
              className="filter-select"
              placeholder={
                <div className="filter-placeholder">
                  <img src={filter_icon} alt="Filtro" />
                  Filtrar
                </div>
              }
              onChange={addFilter}
              options={options}
              filter
            /> */}

          {eventAnimals.length > 0 && typeEvent !== "tratamento" && (
            <Button
              className="spreadsheet-button"
              color="white"
              onClick={() => setModalSelectOptionIsOpen(true)}
            >
              Evento por Planilha
            </Button>
          )}
        </div>

        {/* <div className="filters">
          { selectedFilters.map((filter, index) => { 
            return (
              <Filter key={filter.label + index} 
                label={filter.label} 
                onClickRemove={() => removeFilter(filter, index)} 
              />
            )}
          )}
        </div> */}

        {selectedAnimals.length ? (
          <p className="number-animals">
            Você selecionou:{" "}
            <strong>
              {selectedAnimals.length}{" "}
              {selectedAnimals.length > 1 ? "animais" : "animal"}
            </strong>
          </p>
        ) : (
          <div className="number-animals-empty"></div>
        )}

        {loadingFarms ? (
          <div className="no-animals">
            <Lottie loop play animationData={loading_gif} />
          </div>
        ) : animalsToShow.length > 0 ? (
          <div className="table_wrapper">
            <div
              className="table_wrapper_container"
              style={{ minWidth: columns.length * 180 + "px" }}
            >
              <Table
                multi={isMultiAvailable}
                animals={animalsToShow}
                columns={
                  eventName === "Mastite Clínica" && typeEvent === "tratamento"
                    ? mastiteTreatmentColumns
                    : columns
                }
                check
                event
                onChangeAnimal={onChangeAnimal}
              />
            </div>
          </div>
        ) : (
          <div className="no-animals">
            <img src={animalBigImg()} alt="Vaca" />
            <p className="text">
              Nenhum animal disponível para esse{" "}
              {values_filter_month && values_filter_month !== "__/____"
                ? "filtro"
                : "evento"}
              .
            </p>
          </div>
        )}

        <div className="continue-button">
          <Button
            disabled={selectedAnimals.length === 0}
            onClick={handleNewEvent}
          >
            Continuar
          </Button>
        </div>
      </div>

      {modalSelectOptionIsOpen && (
        <ModalSelectOption
          showModal={(boolean) => setModalSelectOptionIsOpen(boolean)}
          onClickCancel={() => setModalSelectOptionIsOpen(false)}
          onClickContinue={(value) => {
            if (value === "import") {
              setModalImportIsOpen(true);
            } else {
              setModalDownloadIsOpen(true);
            }
            setModalSelectOptionIsOpen(false);
          }}
          event={eventName}
        />
      )}

      {modalDownloadIsOpen && (
        <ModalDownload
          showModal={(boolean) => setModalDownloadIsOpen(boolean)}
          onClickCancel={() => setModalDownloadIsOpen(false)}
          event={eventName}
        />
      )}

      {modalImportIsOpen && (
        <ModalImport
          showModal={(boolean) => setModalImportIsOpen(boolean)}
          onClickCancel={() => setModalImportIsOpen(false)}
          event={eventName}
        />
      )}
    </div>
  );
}

export default CreateEventsPage;
