import Dropzone from "react-dropzone";

import './upload.css'

function UploadMessage(props){
  return (
    <p className={"UploadMessage " + props.className}>
      {props.children}
    </p>  
  )
}

function RenderDragMessage (props) {
  if (!props.isDragActive) {
  return <UploadMessage>
    Arraste o arquivo aqui, ou clique para selecionar
    </UploadMessage>;
  }
  if (props.isDragReject) {
  return <UploadMessage type="error" className="error">
    Arquivo não suportado
    </UploadMessage>;
  }
  return <UploadMessage type="success" className="success">
    Solte o arquivo aqui
    </UploadMessage>;
};

export default function Upload(props){

  const { onUpload } = props;

  return (
    <Dropzone accept="image/*" onDropAccepted={onUpload} onDropRejected={() => {console.log("rejeitou")}}>
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <div
          {...getRootProps()} 
          className={"DropContainer " + (isDragActive ? "dragActive" : null)}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <input {...getInputProps()}/>
          <RenderDragMessage
            isDragActive={isDragActive}
            isDragReject={isDragReject}>
            
          </RenderDragMessage>

        </div>
      )}
    </Dropzone>
  )
}
