import { useState } from "react";

import Button from "../../../components/button/button";
import Input from "../../../components/input/input";
import Modal from "../../../components/modal/modal";

import forgotPassword from "../../../services/userForm/forgotPassword";
import newPassword from "../../../services/userForm/newPassword";

import "./loginModals.css";

export default function ModalInformEmail({
  showModal,
  onClickForgot,
  onClickDone,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [stage, setStage] = useState(1);
  const [email, setEmail] = useState();
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  function handleChange(event) {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  async function onClickInform() {
    const error = {};

    if (!email) {
      error.email = "Este campo é obrigatório";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      error.email = "Endereço de e-mail invalido";
    } else if (errors.email) {
      delete error.email;
    }

    setErrors(error);
    if (Object.keys(error).length === 0) {
      try {
        setIsLoading(true);

        await forgotPassword(email);

        setIsLoading(false);
        setStage(2);
      } catch (err) {
        setIsLoading(false);
        setErrors({ api: "Ocorreu um erro, tente novamente" });
      }
    }
  }

  async function onClickChangePassword() {
    const error = {};

    if (!values.code) {
      error.code = "Este campo é obrigatório";
    } else if (errors.code) {
      delete error.code;
    }

    if (!values.password) {
      error.password = "Este campo é obrigatório";
    } else if (values.password.length < 8) {
      error.password = "A senha deve ter mais de 8 caracteres";
    } else if (!/[A-Z]/.test(values.password)) {
      error.password = "A senha deve ter letras maiúsculas";
    } else if (!/[0-9]/.test(values.password)) {
      error.password = "A senha deve ter numerais";
    } else if (errors.password) {
      delete error.password;
    }

    if (!values.confirm_password) {
      error.confirm_password = "Este campo é obrigatório";
    } else if (values.confirm_password !== values.password) {
      error.confirm_password = "As senhas devem ser iguais";
    } else if (errors.confirm_password) {
      delete error.confirm_password;
    }

    setErrors(error);
    if (Object.keys(error).length === 0) {
      try {
        setIsLoading(true);

        await newPassword({
          email: email,
          password: values.password,
          code: values.code,
        });

        onClickDone();
      } catch (err) {
        setIsLoading(false);
        setErrors({ api: "Ocorreu um erro, tente novamente" });
      }
    }
  }

  return (
    <Modal showModal={showModal} close={true}>
      {stage === 1 ? (
        <div className="content">
          <p className="title">Primeiro, informe o e-mail cadastrado</p>
          <p className="text">
            Receba no e-mail o código para poder criar uma nova senha.
          </p>
          {/* <p className="text">Receba no e-mail <strong>jul******@gmail.com</strong> os próximos passos para criar uma nova senha.</p> */}

          <Input
            id="email"
            className="input-modal"
            type="text"
            placeholder="Insira o e-mail cadastrado"
            error={errors.email}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />

          {errors.api && <p className="api-error">{errors.api}</p>}

          <div className="modal-button-wrapper">
            <div />
            <Button color="white" onClick={onClickForgot}>
              Esqueci o E-mail
            </Button>
            <Button color="blue" onClick={onClickInform} isLoading={isLoading}>
              Informar E-mail
            </Button>
          </div>
        </div>
      ) : (
        <div className="content">
          <p className="title">Cadastre sua nova senha</p>
          <p className="text">
            Insira o código que recebeu no email <strong>{email}</strong> e
            cadastre sua nova senha.
          </p>

          <Input
            id="code"
            label="Código"
            className="input-modal"
            type="text"
            error={errors.code}
            value={values.code}
            placeholder="Insira o código"
            onChange={handleChange}
            mask="999999"
          />
          <Input
            id="password"
            label="Senha"
            className="input-modal"
            type="password"
            error={errors.password}
            value={values.password}
            placeholder="Insira sua nova senha"
            onChange={handleChange}
          />
          <Input
            id="confirm_password"
            label="Confirmar senha"
            className="input-modal"
            type="password"
            error={errors.confirm_password}
            value={values.confirm_password}
            placeholder="Confirme sua senha"
            onChange={handleChange}
          />

          {errors.api && <p className="api-error">{errors.api}</p>}

          <div className="modal-button-wrapper">
            <div />
            <div />
            <Button
              color="blue"
              onClick={onClickChangePassword}
              isLoading={isLoading}
            >
              Mudar senha
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
}
