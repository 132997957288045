import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useFarmContext } from "../../contexts/farmContext";
import useForm from "../../hooks/useForm";

import { deleteAnimal } from "../../services/animals/deleteAnimal";
import { updateAnimal } from "../../services/animals/updateAnimal";
import { registerLot } from "../../services/lot/registerLot";

import trash_icon from "../../assets/svg/trash.svg";
import Button from "../../components/button/button";
import Input from "../../components/input/input";
import PageTitle from "../../components/PageTitle/PageTitle";
import Select from "../../components/select/select";
import DeleteAnimalModal from "./components/DeleteAnimalModal/deleteAnimalModal";

import { useUserContext } from "../../contexts/userContext";
import { isDateValid, isPastDate } from "../../utils/isDateValid";
import "./animalsEditor.css";

function AnimalsEditor() {
  const history = useHistory();

  const {
    values,
    errors,
    handleChange,
    handleSelectChange,
    handleSubmit,
    handleSetErrors,
    updateValues,
  } = useForm();

  const {
    farmSelected,
    lots,
    getAnimals,
    getRaces,
    getCategories,
    getFarmLots,
    animalsSelected,
  } = useFarmContext();
  const { userID } = useUserContext();

  const [isLoading, setIsLoading] = useState(false);
  const [lotOptions, setLotOptions] = useState([]);
  const [showModalDeleteAnimal, setShowModalDeleteAnimal] = useState(false);

  useEffect(() => {
    updateValues({
      // animal_number: animalsSelected[0].numero_animal.split("-")[1],
      animal_name: animalsSelected[0].Identificação, //.split("-")[0],
      animal_birth: animalsSelected[0].Nascimento,
      category: animalsSelected[0].Categoria,
      race: animalsSelected[0].Raça,
      lot_option: animalsSelected[0].Lote,
      id: animalsSelected[0].id,
    });
    if (lots.length === 0) getFarmLots();
  }, []);

  useEffect(() => {
    if (lots.length > 0) {
      const lot_options_aux = lots.map((item) => {
        return { label: item.codLote, value: item.id };
      });
      setLotOptions(lot_options_aux);
    }
  }, [lots]);

  async function checkValues() {
    const error = {};

    // if (!values.animal_number && !values.animal_name) {
    //   error.animal_number = "Número e/ou nome deve ser preenchido";
    // } else if (errors.animal_number) {
    //   delete error.animal_number;
    // }

    // if (/-/.test(values.animal_number)) {
    //   error.animal_number = "Este campo não pode conter '-'";
    // } else if (errors.animal_number) {
    //   delete error.animal_number;
    // }

    if (!values.animal_name) {
      error.animal_name = "Este campo é obrigatório";
    } else if (/-/.test(values.animal_name)) {
      error.animal_name = "Este campo não pode conter '-'";
    } else if (errors.animal_name) {
      delete error.animal_name;
    }

    // if (!values.animal_birth) {
    //   error.animal_birth = "Este campo é obrigatório";
    // } else
    if (values.animal_birth) {
      if (!isDateValid(values.animal_birth)) {
        error.animal_birth = "Insira uma data válida";
      } else if (!isPastDate(values.animal_birth)) {
        error.animal_birth = "Insira uma data no passado ou dia corrente";
      } else if (errors.animal_birth) {
        delete error.animal_birth;
      }
    } else if (errors.animal_birth) {
      delete error.animal_birth;
    }

    if (!values.category) {
      error.category = "Este campo é obrigatório";
    } else if (errors.category) {
      delete error.category;
    }

    return error;
  }

  async function valuesAreCorrect() {
    try {
      setIsLoading(true);
      var lot_response;
      if (values.lot_name && !values.lot_option) {
        // const lot_data = {
        //   codLote:
        //     values.lot_name,
        //   fazenda_id: farmSelected.farm_id,
        // };
        // lot_response = await registerLot(lot_data);
        const response = await registerLot(
          [values.lot_name],
          farmSelected.farm_id
        );
        if (response.lots.length > 0) lot_response = response.lots[0];
      }

      const birthday_array = values.animal_birth
        ? values.animal_birth.split("/")
        : [];

      const data = {
        farmId: farmSelected.farm_id,
        animalId: values.id,
        category: values.category,
        identification: values.animal_name,
        // values.animal_number && values.animal_name
        //   ? values.animal_name + "-" + values.animal_number
        //   : values.animal_number
        //   ? values.animal_number
        //   : values.animal_name,
        breed: values.race ?? null,
        birthday: values.animal_birth
          ? birthday_array[2] +
            "-" +
            birthday_array[1] +
            "-" +
            birthday_array[0] // yyyy-mm-dd
          : null,
      };
      if (lot_response || values.lot_option) {
        data.lotId = lot_response
          ? lot_response.id
          : lotOptions.filter((lot) => lot.label === values.lot_option)[0]
              .value;
        data.lotCode = lot_response ? values.lot_name : values.lot_option;
      }

      await updateAnimal(data);
      getAnimals(farmSelected.farm_id);

      history.goBack();
    } catch (err) {
      setIsLoading(false);
      handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
    }
  }

  async function handleDeleteAnimal() {
    try {
      setIsLoading(true);
      await deleteAnimal(animalsSelected[0].id, farmSelected.farm_id, userID);
      getAnimals(farmSelected.farm_id);
      history.push("/animals");
    } catch (err) {
      setIsLoading(false);
      handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
    }
  }

  return (
    <div className="animal-edit-body">
      <PageTitle path="Editar animal" backButton></PageTitle>

      <div className="form-wrapper">
        <form onSubmit={handleSubmit(checkValues, valuesAreCorrect, isLoading)}>
          <div className="title-wrapper">
            <p className="title">Editar Animal</p>
            <div className="button-wrapper">
              <Button
                className="button-type"
                color="white"
                type="button"
                onClick={() => setShowModalDeleteAnimal(true)}
              >
                Excluir animal
                <img
                  src={trash_icon}
                  alt="Lixeira ícone"
                  className="img_trash"
                />
              </Button>
            </div>
          </div>

          <div className="form-row">
            {/* <Input
              id="animal_number"
              label="Número do animal"
              className="px400-input"
              type="text"
              placeholder={"Insira apenas números"}
              errorAlignColumn
              error={errors.animal_number}
              value={values.animal_number}
              onChange={handleChange}
              required
            /> */}
            <Input
              id="animal_name"
              label="Nome do animal"
              className="px400-input"
              type="text"
              placeholder="Insira o nome do animal"
              error={errors.animal_name}
              value={values.animal_name}
              onChange={handleChange}
            />
          </div>
          <div className="form-row">
            <Input
              id="animal_birth"
              label="Data de nascimento"
              className="px400-input"
              type="text"
              placeholder="Insira a data de nascimento do animal"
              error={errors.animal_birth}
              value={values.animal_birth}
              onChange={handleChange}
              mask="99/99/9999"
            />
          </div>
          <div className="form-row">
            <Select
              id="category"
              label="Categoria"
              placeholder="Selecione a categoria"
              className="px400-input"
              options={getCategories()}
              error={errors.category}
              value={values.category}
              onChange={(index) => handleSelectChange("category", index)}
              required
            />
            <Select
              id="race"
              label="Raça"
              placeholder="Selecione a raça"
              className="px400-input"
              error={errors.race}
              value={values.race}
              options={getRaces()}
              onChange={(index) => handleSelectChange("race", index)}
            />
          </div>
          {lotOptions.length > 0 && (
            <>
              <p className="info-lot">Selecione ou crie um novo lote</p>
              {errors.lot && <p className="lot-error">{errors.lot}</p>}
              <Select
                id="lot_option"
                label="Lotes"
                placeholder="Selecione um lote"
                className="px400-input"
                error={errors.lot_option}
                value={values.lot_option}
                options={lotOptions}
                onChange={(index) => handleSelectChange("lot_option", index)}
              />
            </>
          )}
          {values.lot_option ? null : (
            <div className="form-row lot">
              <Input
                label={lots.length > 0 ? "Novo lote" : "Lote"}
                id="lot_name"
                className="px200-input"
                type="text"
                placeholder="Nome / Número"
                error={errors.lot_name}
                value={values.lot_name}
                onChange={handleChange}
              />
            </div>
          )}

          {errors.api && <p className="api-error">{errors.api}</p>}

          <div className="form-row-end">
            <div className="buttons-wrapper">
              <Button
                className="button-type"
                color="white"
                type="button"
                onClick={() => history.goBack()}
              >
                Cancelar
              </Button>
              <Button
                className="button-type"
                color="blue"
                type="submit"
                isLoading={isLoading}
              >
                Editar animal
              </Button>
            </div>
          </div>
        </form>
      </div>
      {showModalDeleteAnimal && (
        <DeleteAnimalModal
          onClickCancel={() => setShowModalDeleteAnimal(false)}
          onClickSubmit={handleDeleteAnimal}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export default AnimalsEditor;
