import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import EventCard from "./components/eventCard";
import PageTitle from "../../components/PageTitle/PageTitle";
import Button from "../../components/button/button";

import { useEventContext } from "../../contexts/eventContext";

import plus_icon from "../../assets/svg/plus.svg";

import "./addEvent.css";

function AddEventPage() {
  const history = useHistory();

  const { handleSelectedAnimals } = useEventContext();

  useEffect(() => {
    handleSelectedAnimals([]);
  }, [handleSelectedAnimals]);

  return (
    <div className="add-events-body">
      <PageTitle path="Adicionar Evento" backButton>
        <Button color="blue" onClick={() => history.push("animal-register")}>
          Adicionar animal
          <img src={plus_icon} alt="Cadastrar" />
        </Button>
      </PageTitle>

      <div className="body-wrapper">
        <p className="second-title">Adicionar Evento</p>
        <p className="first-text">Escolha o evento que você quer adicionar.</p>

        <div className="events-wrapper">
          <EventCard name="Reprodução" />
          <EventCard name="Parto" />
          <EventCard name="Produção de Leite" />
          <EventCard name="Teste CMT" />
          <EventCard name="CCS Individual" />
          <EventCard name="Mastite Clínica" />
          <EventCard name="Secagem" />
          <EventCard name="Cultura Microbiológica" />
          <EventCard name="Lote" />
        </div>
      </div>
    </div>
  );
}

export default AddEventPage;
