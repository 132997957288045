import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import "./eventContent.css";

import { useEventContext } from "../../../../contexts/eventContext";

import AnimalCard from "../../../../components/animalCard/animalCard";
import Button from "../../../../components/button/button";
import PageTitle from "../../../../components/PageTitle/PageTitle";

function EventContent(props) {
  const history = useHistory();
  const { eventName, typeEvent } = useEventContext();

  const [subtitle, setSubtitle] = useState();
  const [eventInfoLabel, setEventInfoLabel] = useState();

  useEffect(() => {
    if (eventName === "Reprodução") {
      setEventInfoLabel("Última cobrição/inseminação");
    } else if (eventName === "Parto") {
      setEventInfoLabel("Último parto");
    } else if (eventName === "Produção de Leite") {
      setEventInfoLabel("Último registro");
    } else if (eventName === "Teste CMT") {
      setEventInfoLabel("Último teste");
      setSubtitle("(California Mastists Test)");
    } else if (eventName === "CCS Individual") {
      setEventInfoLabel("Último resultado");
    } else if (eventName === "Mastite Clínica") {
      setEventInfoLabel("Última mastite");
    } else if (eventName === "Secagem") {
      setEventInfoLabel("Última secagem");
    } else if (eventName === "Cultura Microbiológica") {
      setEventInfoLabel("Última cultura");
    } else if (eventName === "Lote") {
      setEventInfoLabel("Último registro");
    }
  }, [eventName]);

  if (props.animals.length === 0) {
    history.push("/create-events");
  }

  return (
    <div className={"newEvent-body " + props.className}>
      <PageTitle path={eventName} backButton />

      <div className="pag-wrapper">
        <div className="titles">
          <h2 className="event-title">
            {eventName}
            {eventName === "Mastite Clínica" &&
              typeEvent === "tratamento" &&
              " - Tratamento"}
          </h2>
          <h3 className="event-subtitle">{subtitle}</h3>
        </div>

        <form className="event-wrapper" onSubmit={props.onClickSave}>
          <div className="cards-wrapper">
            <AnimalCard animals={props.animals} />
            {props.animals.length === 1 && (
              <div className="date-card">
                <h3>{eventInfoLabel}</h3>
                <p>{props.eventInfoValue}</p>
              </div>
            )}
          </div>

          {props.children}

          {props.apiError && <p className="api-error">{props.apiError}</p>}
          <div className="events-buttons">
            <div className="event-button">
              <Button color="white" onClick={() => history.goBack()}>
                Cancelar
              </Button>
            </div>
            <div className="event-button">
              <Button type="submit" isLoading={props.isLoading}>
                Salvar
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EventContent;
