import { Route, Switch } from "react-router-dom";

import { AlertContextProvider } from "../contexts/alertContext";
import { EventContextProvider } from "../contexts/eventContext";

import AddEventPage from "../pages/addEvent/addEvent";
import Alerts from "../pages/alerts/alerts";
import CreateEventsPage from "../pages/createEvents/createEvents";
import PropertyConfig from "../pages/propertyConfig/propertyConfig";
import PropertyPainel from "../pages/propertyPainel/propertyPainel";
import PropertyRegister from "../pages/propertyRegister/propertyRegister";
import YourPropertiesPage from "../pages/yourProperties/yourProperties";
// import NewEvent from "../pages/newEvent/newEvent";
import AccountConfig from "../pages/accountConfig/accountConfig";
import AnimalReport from "../pages/animalReport/animalReport";
import AnimalsEditor from "../pages/animalsEditor/animalsEditor";
import AnimalsRegister from "../pages/animalsRegister/animalsRegister";
import FarmAnimals from "../pages/farmAnimals/farmAnimals";
import FarmReport from "../pages/farmReport/farmReport";
import MilkQuality from "../pages/milkQuality/milkQuality";
import Birth from "../pages/newEvent/events/birth/birth";
import Drying from "../pages/newEvent/events/drying/drying";
import IndividualCCS from "../pages/newEvent/events/individualCCS/individualCCS";
import Lot from "../pages/newEvent/events/lot/lot";
import Mastitis from "../pages/newEvent/events/mastitis/mastitis";
import TreatementMastitis from "../pages/newEvent/events/mastitis/treatmentMastitis";
import MicrobiologicalCulture from "../pages/newEvent/events/microbiologicalCulture/microbiologicalCulture";
import MilkProduction from "../pages/newEvent/events/milkproduction/milkproduction";
import Reproduction from "../pages/newEvent/events/reproduction/reproduction";
import TestCMT from "../pages/newEvent/events/testCMT/testCMT";

// Adicionar paginas que estarão dentro do PageContainer (Só necessitam do corpo)
const PageRoutes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/account-config" component={AccountConfig} />

        <Route exact path="/your-properties" component={YourPropertiesPage} />
        <Route exact path="/property-painel" component={PropertyPainel} />
        <Route exact path="/property-register" component={PropertyRegister} />
        <Route exact path="/property-config" component={PropertyConfig} />

        <Route exact path="/animal-register" component={AnimalsRegister} />
        <Route exact path="/animal-edit" component={AnimalsEditor} />

        <Route exact path="/animal-report" component={AnimalReport} />
        <Route exact path="/animals" component={FarmAnimals} />

        <Route exact path="/milk-quality" component={MilkQuality} />

        <Route exact path="/farm-report" component={FarmReport} />
      </Switch>

      <AlertContextProvider>
        <Switch>
          <Route exact path="/alerts" component={Alerts} />
        </Switch>
      </AlertContextProvider>

      <EventContextProvider>
        <Switch>
          <Route exact path="/add-event" component={AddEventPage} />
          <Route exact path="/create-events" component={CreateEventsPage} />
          <Route
            exact
            path="/new-event/reproduction"
            component={Reproduction}
          />
          <Route exact path="/new-event/birth" component={Birth} />
          <Route
            exact
            path="/new-event/milkproduction"
            component={MilkProduction}
          />
          <Route exact path="/new-event/testCMT" component={TestCMT} />
          <Route
            exact
            path="/new-event/individualCCS"
            component={IndividualCCS}
          />
          <Route exact path="/new-event/mastitis" component={Mastitis} />
          <Route
            exact
            path="/new-event/mastitis/treatment"
            component={TreatementMastitis}
          />
          <Route exact path="/new-event/drying" component={Drying} />
          <Route
            exact
            path="/new-event/microbiologicalculture"
            component={MicrobiologicalCulture}
          />
          <Route exact path="/new-event/lot" component={Lot} />
        </Switch>
      </EventContextProvider>
    </>
  );
};

export default PageRoutes;
