import BaseChart from "../baseChart";
import * as d3 from "d3";

import "./yBarChart.css";

function drawBarChart(props) {
  const { svgRef, data, xScale, yScale, height } = props;

  const svg = d3.select(svgRef.current).select("g");

  //  Gradient
  let svgDefs = svg.append("defs");
  let mainGradient = svgDefs
    .append("linearGradient")
    .attr("id", "mainGradient")
    .attr("gradientTransform", "rotate(90)");
  mainGradient.append("stop").attr("class", "stop-left").attr("offset", "0");

  mainGradient.append("stop").attr("class", "stop-right").attr("offset", "1");

  // Borda redonda
  svg
    .selectAll("bar")
    .data(data)
    .enter()
    .append("rect")
    .attr("rx", 6)
    .attr("ry", 6)
    .attr("class", "bar-chart__bar filled")
    .attr("x", (d) => xScale(d.label))
    .attr("width", xScale.bandwidth())
    .attr("y", (d) => yScale(d.value))
    .attr("height", (d) => height - yScale(d.value));

  svg
    .selectAll("bar")
    .data(data)
    .enter()
    .append("rect")
    .attr("class", "bar-chart__bar filled")
    .attr("x", (d) => xScale(d.label))
    .attr("width", xScale.bandwidth())
    .attr("y", (d) => yScale(d.value) + 6)
    .attr("height", (d) => height - yScale(d.value) - 6);
}

const extraProps = {
  useScaleBands: { x: true, y: false },
  paddingInner: 0.3,
};

export default BaseChart(drawBarChart, extraProps);
