import { useEffect } from "react";
import Lottie from "react-lottie-player";

import { useAlertContext } from "../../contexts/alertContext";

import "./alerts.css";

import PageTitle from "../../components/PageTitle/PageTitle";
import WarningBanner from "./components/warningBanner/warningBanner";
// import Input from "../../components/input/input";
import Select from "../../components/select/select";

import ShowFilters from "../../components/ShowFilters/showFilters";
import ShowAlerts from "./components/ShowAlerts/showAlerts";

import bucket from "../../assets/svg/alerts/bucket.svg";
import baby from "../../assets/svg/alerts/baby.svg";
import hourglass from "../../assets/svg/alerts/hourglass.svg";
import injection from "../../assets/svg/alerts/injection.svg";
import calendar1 from "../../assets/svg/alerts/calendar1.svg";
import hightccs from "../../assets/svg/alerts/hightccs.svg";
// import search from "../../assets/svg/alerts/search1.svg";
import filter_icon from "../../assets/svg/filter.svg";
import loading_gif from "../../assets/gifs/loading_2.json";

const filters = [
  { icon: bucket, label: "Leite liberado da carência", value: "milk" },
  { icon: hightccs, label: "Valor CCS alto", value: "high_css" },
  { icon: baby, label: "Parto previsto", value: "expected_birth" },
  { icon: injection, label: "Tratamento de Mastite", value: "mastitis" },
  { icon: hourglass, label: "Final da carência de medicamento", value: "lack" },
  {
    icon: calendar1,
    label: "Agendar CMT ou CCS individual",
    value: "individual",
  },
];

function Alerts() {
  const {
    alertsToShow,
    selectedFilters,
    addFilter,
    removeFilter,
    handleModals,
    getAlerts,
    loadingAlerts,
  } = useAlertContext();

  useEffect(() => {
    getAlerts();
  }, [getAlerts]);

  return (
    <div className="alerts-body">
      <PageTitle path="Alertas" backButton />

      <div className="pag-wrapper">
        <h2 className="config-title">Alertas</h2>
        <div className="search-filters">
          <div className="search-filter">
            {/* <div className="search-wrapper">
              <Input
                className="search-input"
                type="text"
                placeholder="Buscar por alerta, animal ou evento"
              />
              <div className="search-icon">
                <img src={search} alt="Animal Ícone" className="img-search" />
              </div>
            </div> */}
            <Select
              id="filters-select"
              className="filters-wrapper"
              placeholder={
                <div className="filter-placeholder">
                  <img src={filter_icon} alt="Filtro" />
                  Filtrar
                </div>
              }
              onChange={addFilter}
              options={filters}
              filter={true}
            />
          </div>
          <ShowFilters
            filters={selectedFilters}
            onClickRemoveFilter={removeFilter}
          />
        </div>
        {alertsToShow.length > 0 ? (
          <ShowAlerts alerts={alertsToShow} onClick={handleModals} />
        ) : loadingAlerts ? (
          <div className="no-alerts">
            <Lottie loop play animationData={loading_gif} />
          </div>
        ) : (
          <WarningBanner />
        )}
      </div>
    </div>
  );
}

export default Alerts;
