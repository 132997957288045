import Button from "../../../components/button/button";
import Input from "../../../components/input/input";
import Modal from "../../../components/modal/modal";
import Select from "../../../components/select/select";

import "./loginModals.css";

const options = [
  {
    label: "Qual é o nome da sua avó materna?",
    value: "Qual é o nome da sua avó materna?",
  },
  {
    label: "Em qual cidade você nasceu?",
    value: "Em qual cidade você nasceu?",
  },
  {
    label: "Qual é a data de nascimento do seu pai?",
    value: "Qual é a data de nascimento do seu pai?",
  },
  {
    label: "Qual é a data de nascimento da sua mãe?",
    value: "Qual é a data de nascimento da sua mãe?",
  },
];

export default function ModalAnswer({
  showModal,
  onClickCancel,
  onClickContinue,
}) {
  return (
    <Modal showModal={showModal} close={true}>
      <div className="content">
        <p className="title">
          Não se preocupe, temos uma outra forma de ajudar você
        </p>
        <p className="text">
          Responda a pergunta que você escolheu para recuperar a sua senha:
        </p>

        <Select
          id="question"
          label="Pergunta para recuperar senha"
          className="input-modal"
          placeholder="Selecione uma pergunta escolhida"
          onChange={() => {}}
          options={options}
        />
        <Input
          id="answer"
          label="Resposta para recuperar senha"
          className="input-modal"
          type="text"
          placeholder="Insira a resposta"
        />

        <div className="modal-button-wrapper">
          <div />
          <Button color="white" onClick={onClickCancel}>
            Cancelar
          </Button>
          <Button color="blue" onClick={onClickContinue}>
            Continuar
          </Button>
        </div>
      </div>
    </Modal>
  );
}
