import Button from "../../../../components/button/button";
import Modal from "../../../../components/modal/modal";

import './deleteEventModal.css';

export default function DeleteEventModal({ isLoading, onClickCancel, onClickSubmit, eventName }) {
  return (
    <Modal showModal={onClickCancel} close={true}>
      <div className="content-modal">
        <p className="title">Você quer excluir esse evento de {eventName}?</p>
        <p className="text">Ele será excluido permanentemente.</p>

        <div className="modal-buttons-wrapper">
          <div />
          <Button color="white" onClick={onClickCancel}>Cancelar</Button>
          <Button color="blue" onClick={() => onClickSubmit()} isLoading={isLoading}>Excluir evento</Button>
        </div>
      </div>
    </Modal>
  );
}