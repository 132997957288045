import xl from "excel4node";
import Button from "../../../../../components/button/button";
import Modal from "../../../../../components/modal/modal";
import Table from "../../../../../components/table/table";
import { TextInfo } from "../bases";
import "./animalModal.css";

const onClickDowloadSpreadsheet = async (title, columns, animals) => {
  const wb = new xl.Workbook({
    defaultFont: {
      size: 10,
      name: "Roboto",
      color: "#09202d",
    },
    author: "Milk Care",
  });

  const styleHeading = wb.createStyle({
    alignment: {
      horizontal: "center",
      vertical: "center",
    },
    font: {
      size: 14,
      bold: true,
      name: "Nunito",
    },
    border: {
      bottom: {
        style: "medium",
        color: "#05a6fc",
      },
    },
    fill: {
      type: "pattern",
      patternType: "solid",
      bgColor: "#dedede",
      fgColor: "#dedede",
    },
  });

  const styleCell = wb.createStyle({
    alignment: {
      horizontal: "center",
      vertical: "center",
    },
  });

  const ws = wb.addWorksheet(title);

  var headingIndex = 1;
  columns.forEach((col) => {
    ws.column(headingIndex).setWidth(40);
    ws.cell(1, headingIndex++).string(col).style(styleHeading);
  });

  var rowIndex = 2;
  animals.forEach((record) => {
    var columnIndex = 1;
    columns.forEach((columnName) => {
      ws.cell(rowIndex, columnIndex++)
        .string(record[columnName]?.toString())
        .style(styleCell);
    });
    rowIndex++;
  });

  const uint8Array = await wb.writeToBuffer();
  const buffer = uint8Array.buffer.slice(
    uint8Array.byteOffset,
    uint8Array.byteLength + uint8Array.byteOffset
  );

  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = title;
  link.click();
};

export default function AnimalModal({
  title,
  animals,
  columns,
  onClickCancel,
}) {
  return (
    <Modal showModal={onClickCancel} close={true} fullsize>
      <div className="title_wrapper">
        <TextInfo first={title} className="animal-modal-text" />
        <div className="title_wrapper_button">
          <Button
            color="blue"
            onClick={() => onClickDowloadSpreadsheet(title, columns, animals)}
          >
            Baixar planilha
          </Button>
        </div>
      </div>
      <div className="table_wrapper">
        <div
          className="table_wrapper_container"
          style={{ minWidth: columns.length * 180 + "px" }}
        >
          {animals && (
            <Table
              animals={animals}
              columns={columns}
              onChangeAnimal={() => {}}
            />
          )}
        </div>
      </div>
    </Modal>
  );
}
