import { fromUnixTime, isFuture } from "date-fns";
import jwt_decode from "jwt-decode";
import { createContext, useCallback, useContext, useState } from "react";

import { getUser } from "../services/userForm/getUser";

export const UserContext = createContext({});

export function UserContextProvider({ children }) {
  const [user, setUser] = useState();
  const [userID, setUserID] = useState();
  const [token, setToken] = useState(localStorage.getItem("milk-token"));

  function handleUser(value) {
    setUser({ ...user, ...value });
  }

  const getUserData = useCallback(async () => {
    if (!user) {
      try {
        const user_response = await getUser(userID);
        const auxUser = {
          name: user_response.profile.name,
          document: user_response.profile.cpf,
          email: user_response.profile.email,
        };
        setUser(auxUser);
      } catch (err) {}
    }
  }, [user, userID]);

  function isAuthenticated() {
    if (token) {
      const decode = jwt_decode(token);
      const date = fromUnixTime(decode.exp);
      setUserID(decode.sub);
      return isFuture(date);
    }
    return false;
  }

  function logOut() {
    setToken();
    setUserID();

    localStorage.removeItem("milk-token");
    localStorage.removeItem("milk-farm");
  }

  function handleToken(t) {
    localStorage.setItem("milk-token", t);
    setToken(t);
  }

  return (
    <UserContext.Provider
      value={{
        user,
        userID,
        handleUser,
        getUserData,
        handleToken,
        isAuthenticated,
        logOut,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useUserContext = () => {
  return useContext(UserContext);
};
