import { addDays, format, getUnixTime } from "date-fns";

export function dateToTimestamp(date) {

  if (date) {
    var date_aux = null
    if (date.length === 7){ // String de mes e ano ex.: 05/2000
      date_aux = new Date(date.substring(3), date.substring(0, 2));
    }
    else {
      const month = parseInt(date.substring(3, 5)) - 1;
      date_aux = new Date(date.substring(6), month, date.substring(0, 2));
    }
    return getUnixTime(date_aux);
  }
  return undefined;
}

export function excelDateToString(date) {
  var date_aux = date;
  if (typeof date_aux === "number") {
    const d = addDays(new Date(1899, 11, 31), date_aux - 1); // Entender erro!
    date_aux = format(d, "dd/MM/yyyy");
  }
  return date_aux;
}

export function rewriteDate(date) {
  var date_aux = excelDateToString(date);
  if (typeof date_aux === "string" && date_aux.length === 10) {
    date_aux = format(
      new Date(
        date_aux.substring(6),
        parseInt(date_aux.substring(3, 5)) - 1,
        date_aux.substring(0, 2)
      ),
      "dd/MM/yyyy"
    );
  }
  return date_aux;
}
