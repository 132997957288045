import { useHistory, useLocation } from "react-router-dom";

import { useFarmContext } from "../../contexts/farmContext";

import logo from "../../assets/images/logo-small-medium-blue.png";

import Button from "../button/button";

import back_arrow from "../../assets/svg/property-painel/back_arrow.svg";

import "./PageTitle.css";

function PageTitle(props) {
  const history = useHistory();
  const location = useLocation();

  const { farmSelected, animalImg } = useFarmContext();

  function handleClickGoBack() {
    if (location.pathname === "/add-event") {
      history.push("/property-painel");
    } else if (location.pathname === "/create-events") {
      history.push("/add-event");
    } else if (location.pathname === "/property-painel") {
      history.push("/your-properties");
    } else if (location.pathname === "/animals") {
      history.push("/property-painel");
    } else {
      history.goBack();
    }
  }

  return (
    <div className="head">
      <div>
        <div className="text">
          {!props.title && (
            <h3 className="path">
              Propriedades {" > "} {farmSelected?.name}{" "}
              {props.path && " > " + props.path}
            </h3>
          )}
          <h1 className="title">{props.title || farmSelected?.name}</h1>
          {!props.title && (
            <h3 className="subtitle">
              <img
                src={animalImg()}
                alt="Animal Ícone"
                className="img_subtitle"
              />
              {(farmSelected?.animals_number | "-") + " cadastrados"}
            </h3>
          )}
        </div>

        {(props.children || props.backButton) && (
          <div className="buttons">
            <div className="button-wrapper">
              {props.backButton && (
                <Button
                  className="back-farm-button"
                  color="white"
                  background="white"
                  onClick={handleClickGoBack}
                >
                  <img src={back_arrow} className="img-arrow" alt="Voltar" />
                </Button>
              )}
            </div>
            <img
              id="logo"
              src={logo}
              alt="Logo"
              onClick={() => history.push("/your-properties")}
            />
          </div>
        )}
      </div>

      <div className="children">{props.children}</div>
    </div>
  );
}

export default PageTitle;
