import { CircularChart } from '../../../baseChart/circularChart/circularChart'
import './fileList.css'

function FileList(props){

  return (
    <ul className="Container">
    {props.files.map(uploadedFile => (
      <li key={uploadedFile.id}>
        <div className="FileInfo">
          <div className="Preview" 
                style={{ backgroundImage: `url(${uploadedFile.preview})`,
            }}>
          </div>
          <div>
            <strong>{uploadedFile.name}</strong>
            <span>
              {uploadedFile.readableSize}{" "}
              {!!uploadedFile.url && (
                <button onClick={() => props.onDelete(uploadedFile.id)}>
                  Excluir
                </button>
              )}
            </span>
          </div>
        </div>

        <div>
          {!uploadedFile.uploaded &&
            !uploadedFile.error && (
              // <CircularProgressbar
              //   styles={{
              //     root: { width: 24 },
              //     path: { stroke: "#7159c1" }
              //   }}
              //   strokeWidth={10}
              //   percentage={uploadedFile.progress}
              // />
              <CircularChart
                width={24}
                arcWidth={2}
                notShowPercentage
                progressPercentage={uploadedFile.progress}
              />
              // <p>progress</p>
            )}

          {uploadedFile.url && (
            <a
              href={uploadedFile.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <MdLink style={{ marginRight: 8 }} size={24} color="#222" />  */} link
            </a>
          )}

          {/* {uploadedFile.uploaded && <MdCheckCircle size={24} color="#78e5d5" />} */}
          {/* {uploadedFile.error && <MdError size={24} color="#e57878" />} */}
        </div>
      </li>
    ))}
  </ul>
  )
}

export default FileList