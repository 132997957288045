import React, { useState } from 'react';

import './checkBox.css';

export default function CheckBox({ language, name, typecheck, id, checked, disabled, onClick, value, onChange }) {
  const [isChecked, setIsChecked] = useState(false);

  function handleOptionClick(value, checked){
    setIsChecked(name)
    onChange(value, checked)
  }

  return (
    <label id="checkbox" htmlFor={id} className={((disabled && !isChecked) ? "disable" : undefined) + typecheck}>{language}
      <input 
        onClick={onClick} 
        onChange={event => {handleOptionClick(event.target.value, event.target.checked)}} 
        type="checkbox" 
        name={name} 
        id={id} 
        checked={checked} 
        disabled={isChecked ? false : disabled} 
        value={value}
      />
      <span className={"checkmark " + typecheck}></span>
    </label>
  )
}