import { useState } from 'react';

import './modalSelectOption.css'

import Modal from "../../../../components/modal/modal";
import Button from '../../../../components/button/button';
import CheckBox from '../../../../components/checkBox/checkBox';

export default function ModalSelectOption({ event, onClickCancel, onClickContinue, showModal }) {
  const [optionSelected, setOptionSelected] = useState();

  function handleOptionChange(value, checked) {
    if (checked) {
      setOptionSelected(value)
    } else if (value === optionSelected) {
      setOptionSelected()
    }
  }

  return(
    <Modal showModal={showModal} close={true}>
      <div className="select-option-modal-content">
        <p className="title">Selecione uma opção</p>

        <CheckBox name="option"
          onChange={handleOptionChange}
          language={"Quero baixar a planilha para adicionar o evento de " + event + "." }
          value="download"
          checked={optionSelected === 'download'}
        />
        <CheckBox name="option"
          onChange={handleOptionChange}
          language="Tenho a planilha preenchida e quero importá-la para a MilkCare." 
          value="import"
          checked={optionSelected === 'import'}
        />

        <div className="modal-div-buttons">
          <div />
          <Button color="white" className="modal-button" onClick={onClickCancel}>Cancelar</Button>
          <Button disabled={!optionSelected} className="modal-button" onClick={() => onClickContinue(optionSelected)}>Continuar</Button>
        </div>
      </div>
    </Modal>
  );  
}