import { newApi } from "../api";

export async function delTanque(data) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.delete(`/tank/delete`, { ...config, data });
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}
