import Modal from "../../../../components/modal/modal";
import Table from "../../../../components/table/table";
import "./animalModal.css";

function TextInfo({ className, id, first }) {
  return (
    <div className={"textInfo " + className} id={id}>
      <div className="first-title">
        <p>{first}</p>
      </div>
    </div>
  );
}

export default function AnimalModal({
  title,
  animals,
  columns,
  onClickCancel,
}) {
  return (
    <Modal showModal={onClickCancel} close={true} fullsize>
      <div className="title_wrapper">
        <TextInfo first={title} className="animal-modal-text" />
      </div>
      <div className="table_wrapper">
        <div
          className="table_wrapper_container"
          style={{ minWidth: columns.length * 180 + "px" }}
        >
          {animals && (
            <Table
              animals={animals}
              columns={columns}
              onChangeAnimal={() => {}}
              search={false}
            />
          )}
        </div>
      </div>
    </Modal>
  );
}
