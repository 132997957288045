import { useState } from "react";

import { useAlertContext } from "../../../../../contexts/alertContext";

import "./ModalSimpleAlert.css";

import Button from "../../../../../components/button/button";
import Modal from "../../../../../components/modal/modal";

// modalName = simpleAlert
export default function ModalSimpleAlert(){
    const { modalProps, handleModalShow, modalRememberLater, modalUnderstand } = useAlertContext();
    const { title, text, animal_id } = modalProps;

    const [isLoading, setIsLoading] = useState(false);

    async function handleClickUnderstand() {
        setIsLoading(true);
        try {
            await modalUnderstand('simpleAlert', animal_id);
        } catch (e) {
            setIsLoading(false);
            console.log('Error')
        }
    }

    return (
        <Modal showModal={handleModalShow} close={true}>
            <div className="simple-alert-modal-content">
                <p className="title">{title}</p>
                <p className="text">{text}</p>

                <div className="modal-div-buttons">
                    <div />
                    <Button color="white" className="modal-button" onClick={modalRememberLater} >Lembrar-me Depois</Button>
                    <Button color="blue" className="modal-button" isLoading={isLoading} onClick={handleClickUnderstand} >Apagar alerta</Button>
                </div>
            </div>
        </Modal>
    ); 
}