import { useState } from "react";

import "./modalDeleteTank.css";

import Button from "../../../../components/button/button";
import Modal from "../../../../components/modal/modal";

import checkIcon from "../../../../assets/svg/alerts/check.svg";

import { delTanque } from "../../../../services/milkQuality/deleteTank";

export default function ModalDeleteTank({
  showModal,
  onClickCancel,
  onClickDelete,
  tanks,
  farm_id,
}) {
  const [tanksSelected, setTanksSelected] = useState([]);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleSelectTank(tank) {
    if (tanksSelected.includes(tank)) {
      tanksSelected.splice(tanksSelected.indexOf(tank), 1);
      setTanksSelected(tanksSelected);
      setReload(!reload);
    } else {
      setTanksSelected([...tanksSelected, tank]);
    }
  }

  function handleSelectAll() {
    if (tanksSelected.length === tanks.length) {
      setTanksSelected([]);
    } else {
      let tanksAux = [];
      tanks.forEach((tank) => tanksAux.push(tank));
      setTanksSelected(tanksAux);
    }
  }

  return (
    <Modal showModal={showModal} close={false}>
      <div className="delete-tank-modal">
        <p className="title">Excluir tanque(s)?</p>
        <p className="text">Selecione o(s) tanque(s) que deseja excluir.</p>

        <div className="tanks-list">
          <div className="top line">
            <div
              className={
                "check " + (tanksSelected.length === tanks.length && "icon")
              }
              onClick={handleSelectAll}
            >
              {tanksSelected.length === tanks.length && (
                <img src={checkIcon} alt="Check Icon" />
              )}
            </div>
            <p>Selecionar todos</p>
          </div>

          {tanks.map((tank) => {
            return (
              <div className="line" key={tank.name}>
                <div
                  className={
                    "check " + (tanksSelected.includes(tank) && "icon")
                  }
                  onClick={() => handleSelectTank(tank)}
                >
                  {tanksSelected.includes(tank) && (
                    <img src={checkIcon} alt="Check Icon" />
                  )}
                </div>
                <p>Tanque {tank.name}</p>
              </div>
            );
          })}
        </div>

        <div className="modal-div-buttons">
          <div />
          <Button color="white" onClick={onClickCancel}>
            Cancelar
          </Button>
          <Button
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              try {
                // await delTanque({
                //   tanques_id: tanksSelected
                //     .filter((tank) => tank.id)
                //     .map((tank) => tank.id),
                // });
                await delTanque(
                  tanksSelected.map((tank) => ({
                    farmId: farm_id,
                    tankId: tank.id,
                  }))
                );
                onClickDelete(tanksSelected);
              } catch (e) {
                console.log("Error deleting tanks");
                setIsLoading(false);
              }
            }}
          >
            Excluir tanque(s)
          </Button>
        </div>
      </div>
    </Modal>
  );
}
