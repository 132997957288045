import { newApi } from "../api";

export async function registerBirth(data) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.post("/birth", data, config);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}

// export async function lastBirth(animal_id) {
//   const config = {
//     headers: {
//       Authorization: localStorage.getItem('milk-token'),
//     }
//   }

//   try {
//     const response = await api.get(`/lastParto/${animal_id}`, config);
//     return response.data;
//   } catch (err) {
//     throw new Error(err.response.data);
//   }
// }

export async function dataPrevista(farmId, animalId) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.get(
      `birth/expected-date/${farmId}/${animalId}`,
      config
    );
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}

export async function getPartoById(farmId, animalId) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.get(
      `birth/last/${farmId}/${animalId}`,
      config
    );
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}

export async function getAnimalsByBirthMonth(farmId, month) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.get(`birth/month/${farmId}/${month}`, config);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}
