import { addDays, format } from "date-fns";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import ModalRegisterEvent from "../pages/newEvent/components/modalRegisterEvent/modalRegisterEvent";

import { useFarmContext } from "./farmContext";

export const EventContext = createContext({});

export function EventContextProvider({ children }) {
  const history = useHistory();

  const { farmSelected } = useFarmContext();

  const [selectedAnimals, setSelectedAnimals] = useState([]);
  const [eventName, setEventName] = useState();
  const [typeEvent, setTypeEvent] = useState("mastite");
  const [isMultiAvailable, setIsMultiAvailable] = useState(true);

  const [modalsControl, setModalsControl] = useState(false);
  const [modalProps, setModalProps] = useState({});

  useEffect(() => {
    const event = localStorage.getItem("milk-event");
    if (event) setEventName(event);
  }, []);

  useEffect(() => {
    if (typeEvent === "mastite") setIsMultiAvailable(false);
    else if (typeEvent === "tratamento") setIsMultiAvailable(false);
  }, [typeEvent]);

  const handleSelectedAnimals = useCallback((animals) => {
    setSelectedAnimals(animals);
  }, []);

  function handleModalShow(boolean) {
    setModalsControl(boolean);
    if (!boolean) {
      localStorage.removeItem("milk-event");
      history.replace("/create-events");
    }
  }

  function handleSetEventName(event) {
    setEventName(event);
    localStorage.setItem("milk-event", event);

    if (["Secagem", "Mastite Clínica"].includes(event)) {
      setIsMultiAvailable(false);
    } else setIsMultiAvailable(true);
  }

  function handleTypeEvent(type) {
    if (["Mastite Clínica"].includes(eventName)) setTypeEvent(type);
  }

  function handleModalProps(props) {
    setModalProps(props);
  }

  function handleNewEvent() {
    if (eventName === "Reprodução") {
      history.push("new-event/reproduction");
    } else if (eventName === "Parto") {
      history.push("new-event/birth");
    } else if (eventName === "Produção de Leite") {
      history.push("new-event/milkproduction");
    } else if (eventName === "Teste CMT") {
      history.push("new-event/testCMT");
    } else if (eventName === "CCS Individual") {
      history.push("new-event/individualCCS");
    } else if (eventName === "Mastite Clínica") {
      if (typeEvent === "mastite") history.push("new-event/mastitis");
      else if (typeEvent === "tratamento")
        history.push("new-event/mastitis/treatment");
    } else if (eventName === "Secagem") {
      history.push("new-event/drying");
    } else if (eventName === "Cultura Microbiológica") {
      history.push("new-event/microbiologicalculture");
    } else if (eventName === "Lote") {
      history.push("new-event/lot");
    }
  }

  function isCCSHigh(value) {
    const ccs_value = parseInt(value);
    if (["Vacas", "Búfalas"].includes(farmSelected.specie)) {
      if (ccs_value > 200) return true;
    } else if (["Ovelhas", "Cabras"].includes(farmSelected.specie)) {
      if (ccs_value > 1000) return true;
    }
    return false;
  }

  function handlePredictedBirthData(value) {
    if (!value.includes("_")) {
      const month = parseInt(value.substring(3, 5)) - 1;
      const date = new Date(value.substring(6), month, value.substring(0, 2));
      if (farmSelected.specie === "Vacas") {
        return format(addDays(date, 283), "dd/MM/yyyy");
      } else if (farmSelected.specie === "Ovelhas") {
        return format(addDays(date, 152), "dd/MM/yyyy");
      } else if (farmSelected.specie === "Búfalas") {
        return format(addDays(date, 310), "dd/MM/yyyy");
      } else if (farmSelected.specie === "Cabras") {
        return format(addDays(date, 150), "dd/MM/yyyy");
      }
    }
  }

  return (
    <EventContext.Provider
      value={{
        eventName,
        modalProps,
        selectedAnimals,
        isMultiAvailable,
        typeEvent,
        handleModalShow,
        handleModalProps,
        handleSelectedAnimals,
        handleSetEventName,
        handleNewEvent,
        isCCSHigh,
        handlePredictedBirthData,
        handleTypeEvent,
      }}
    >
      {children}

      {modalsControl && <ModalRegisterEvent />}
    </EventContext.Provider>
  );
}

export const useEventContext = () => {
  return useContext(EventContext);
};
