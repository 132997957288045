import { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import loading_gif from "../../../../assets/gifs/loading_2.json";
import BarChart from "../../../../components/baseChart/barChart/barChart";
import { getMilkQuality } from "../../../../services/report/getMilkQuality";
import { Box, Row, SectionHeader, TextInfo } from "../bases/bases";

import { useFarmContext } from "../../../../contexts/farmContext";
import { timestampToDate } from "../../../../utils/timestampToDate";
import AnualBarChart from "../bases/AnualBarChart/AnualBarChart";
import AnualAreaChart from "./components/AnualAreaChart/AnualAreaChart";
import "./milkQuality.css";

export function MilkQuality({ date }) {
  const [maxYear, setMaxYear] = useState(timestampToDate(date));

  useEffect(() => {
    setMaxYear(date);
  }, [date]);

  const [isLoading, setIsLoading] = useState(true);
  const { farmSelected } = useFarmContext();

  // CCS in Tank
  const [allCCSData, setAllCCSData] = useState([]);

  // CPP in Tank
  const [allCPPData, setAllCPPData] = useState([]);

  // CCS in tank Comparison
  // Usa allCCSData

  // Quality of milk in the tank
  const [qualityMilkTank, setQualityMilkTank] = useState("-");

  // CCS animals in tank
  const [CCSPerAnimal, setCCSPerAnimal] = useState([]);
  const [animalsContribuicaoCCS, setAnimalsContribuicaoCCS] = useState([]);

  // Value of the liter
  const [valueLiter, setValueLiter] = useState("-");
  // Quality values
  const [qualityValues, setQualityValues] = useState({});

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const aux_data = await getMilkQuality(farmSelected.farm_id, date);
        // CCS no tanque (todos os anos)
        setAllCCSData(aux_data["ccsTank"]);

        // CPP no tanque
        setAllCPPData(aux_data["cppTank"]);

        // Comparação de CCS no tanque
        // Usa allCCSData

        aux_data["milkQualityTank"] !== 0
          ? setQualityMilkTank(aux_data["milkQualityTank"].toFixed(2))
          : setQualityMilkTank("-");

        // Animais que mais contribuem para a CCS do tanque
        const aux_contribuicao = [];
        aux_data.ccsAnimalContribuition.forEach((item) => {
          const aux = aux_contribuicao.find(
            (i) => i.animalId === item.animalId
          );
          if (!aux) {
            aux_contribuicao.push(item);
          } else {
            aux.totalCcsIndividual += item.totalCcsIndividual;
            aux.percentage += item.percentage;
          }
        });
        const aux_CCSPerAnimal = aux_contribuicao.map((item, index) => {
          return {
            id: "d" + index,
            value: item.percentage.toFixed(2),
            label: item.identification ? item.identification : "sem lote",
          };
        });
        if (aux_CCSPerAnimal.length) {
          setCCSPerAnimal([]);
          var data_ = aux_CCSPerAnimal.sort((a, b) => {
            return b.value - a.value;
          });
          // if (data_.length > 10) {
          //   data_
          //     .slice(0, 10)
          //     .concat({
          //       label: "Outros",
          //       id: "outros",
          //       value: aux_CCSPerAnimal
          //         .slice(10, -2)
          //         .reduce(function (soma, i) {
          //           return soma + i.value;
          //         }, 0),
          //     })
          //     .concat(aux_CCSPerAnimal.slice(-1)[0]);
          // }
          setCCSPerAnimal(data_);
          const aux_animaisContribuicaoCCS =
            aux_data.ccsAnimalContribuition.map((item, index) => {
              return {
                id: "d" + index,
                Identificação: item.identification,
                CCS_Total: Math.floor(item.totalCcsIndividual),
                Porcentagem: parseFloat(item.percentage.toFixed(2)),
                Lote: item.lot,
              };
            });
          setAnimalsContribuicaoCCS(aux_animaisContribuicaoCCS);
        } else {
          setCCSPerAnimal([]);
        }

        // Valores de Qualidade de leite no tanque
        setValueLiter(aux_data["literValue"]);
        setQualityValues(aux_data["qualityValues"]);

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.log("Error fetching data Milk QUality: " + e);
      }
    }
    fetchData();
  }, [farmSelected, date]);

  function PositiveTriangle() {
    return (
      <svg
        width="9"
        height="7"
        viewBox="0 0 9 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.65509 1.01488L0.255287 5.38606C-0.255602 6.04292 0.212492 7 1.04464 7H7.84425C8.6764 7 9.14449 6.04292 8.6336 5.38606L5.2338 1.01488C4.83344 0.500136 4.05545 0.500134 3.65509 1.01488Z"
          fill="#09202D"
        />
      </svg>
    );
  }

  function NegativeTriangle() {
    return (
      <svg
        width="9"
        height="7"
        viewBox="0 0 9 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.34491 5.98512L8.74471 1.61394C9.2556 0.957082 8.78751 -1.05999e-07 7.95536 -1.78748e-07L1.15575 -7.73189e-07C0.323602 -8.45938e-07 -0.144492 0.957081 0.366398 1.61394L3.7662 5.98512C4.16656 6.49986 4.94455 6.49987 5.34491 5.98512Z"
          fill="#1F7AAB"
        />
      </svg>
    );
  }

  return (
    <div className="milk-quality-wrapper">
      <SectionHeader
        id="milk-quality fisrt"
        title="Qualidade do leite no tanque"
      />

      <Row className="milk-quality ">
        <Box id="box1" className="bigger" title="CCS no tanque">
          <AnualBarChart
            allData={allCCSData}
            maxDate={maxYear}
            isLoading={isLoading}
            label="CCS (mil cel/ml)"
          />
        </Box>
        <Box id="box2" className="bigger" title="CPP no tanque">
          <AnualBarChart
            allData={allCPPData}
            maxDate={maxYear}
            isLoading={isLoading}
            label="CPP (mil ucf/ml)"
          />
        </Box>
      </Row>

      <Row className="milk-quality second">
        <Box
          id="box3"
          title="Média de CCS no tanque no mês selecionado"
          className="bigger"
        >
          <TextInfo number={qualityMilkTank} latter="mil cel/ml de leite" />
        </Box>
        <Box id="box4" title="Comparação de CCS no tanque" className="bigger">
          <AnualAreaChart
            allData={allCCSData}
            maxDate={maxYear}
            isLoading={isLoading}
            label="CPP (mil ucf/ml)"
          />
        </Box>
      </Row>

      <Row className="milk-quality third">
        <Box
          id="box5"
          title="Animais que mais contribuem para a CCS do tanque no mês atual"
          className="bigger"
          viewMore={animalsContribuicaoCCS.length > 0}
          isViewMoreFull={animalsContribuicaoCCS.length > 11} // Se tiver mais que 12 animais aparece uma mensagem na tela
          viewMoreTitle={"Leite produzido por animal"}
          animals={animalsContribuicaoCCS}
          columns={["Identificação", "Lote", "CCS_Total", "Porcentagem"]}
        >
          {isLoading ? (
            <div className="no-data">
              <Lottie loop play animationData={loading_gif} />
            </div>
          ) : CCSPerAnimal.length > 0 ? (
            <BarChart
              data_={CCSPerAnimal}
              unit="%"
              reverseValues
              max={15}
              min={5}
            />
          ) : (
            <TextInfo
              first="Sem dados"
              latter="Cadastre um evento de produção de leite"
            />
          )}
        </Box>
        <Box
          id="box6"
          title="Valores de Qualidade de leite no tanque"
          className="bigger"
        >
          <TextInfo
            number={"R$ " + parseFloat(valueLiter).toFixed(2).replace(".", ",")}
            latter="por litro de leite"
          />
          <div className="milk-values-wrapper">
            <div className="milk-value">
              <div className="milk-value-first">
                {qualityValues.protein?.biggerThan ? (
                  <PositiveTriangle />
                ) : (
                  <NegativeTriangle />
                )}
                {qualityValues.protein?.value}
              </div>
              <div className="milk-value-second">proteina</div>
            </div>
            <div className="milk-value negative">
              <div className="milk-value-first">
                {qualityValues.fat?.biggerThan ? (
                  <PositiveTriangle />
                ) : (
                  <NegativeTriangle />
                )}
                {qualityValues.fat?.value}
              </div>
              <div className="milk-value-second">gordura</div>
            </div>
            <div className="milk-value">
              <div className="milk-value-first">
                {qualityValues.ccs?.biggerThan ? (
                  <PositiveTriangle />
                ) : (
                  <NegativeTriangle />
                )}
                {qualityValues.ccs?.value}
              </div>
              <div className="milk-value-second">CCS</div>
            </div>
            <div className="milk-value negative">
              <div className="milk-value-first">
                {qualityValues.cpp?.biggerThan ? (
                  <PositiveTriangle />
                ) : (
                  <NegativeTriangle />
                )}
                {qualityValues.cpp?.value}
              </div>
              <div className="milk-value-second">CPP</div>
            </div>
          </div>
        </Box>
      </Row>
    </div>
  );
}
