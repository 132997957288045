import { BrowserRouter, Route, Switch } from "react-router-dom";

import PageContainer from "../components/pageContainer/pageContainer";

import AdditionalRegisterPage from "../pages/additionalRegister/additionalRegister";
import EmailVerificationPage from "../pages/emailVerification/emailVerification";
import LoginPage from "../pages/login/login";
import PaymentPage from "../pages/payment/payment";
import RegisterPage from "../pages/register/register";
import SelectPlanPage from "../pages/selectPlan/selectPlan";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/register" component={RegisterPage} />
        <Route
          exact
          path="/register/additional"
          component={AdditionalRegisterPage}
        />
        <Route exact path="/verification" component={EmailVerificationPage} />
        <Route exact path="/plans" component={SelectPlanPage} />
        <Route exact path="/payment" component={PaymentPage} />

        <Route path="/" render={(props) => <PageContainer {...props} />} />
      </Switch>
    </BrowserRouter>
  );
}
