import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useFarmContext } from "../../contexts/farmContext";
import useForm from "../../hooks/useForm";

import registerFarm from "../../services/farm/registerFarm";

import Button from "../../components/button/button";
import Input from "../../components/input/input";
import PageTitle from "../../components/PageTitle/PageTitle";
import Select from "../../components/select/select";

import buffalo_icon from "../../assets/svg/property-register/buffalo.svg";
import cow_icon from "../../assets/svg/property-register/cow.svg";
import goat_icon from "../../assets/svg/property-register/goat.svg";
import sheep_icon from "../../assets/svg/property-register/sheep.svg";

import farm_icon from "../../assets/svg/farm-icon.svg";
// import localization_pin from '../../assets/svg/property-register/localization-pin.svg';

import { useUserContext } from "../../contexts/userContext";
import "./propertyRegister.css";

const options = [
  { icon: cow_icon, label: "Vacas" },
  { icon: buffalo_icon, label: "Búfalas" },
  { icon: sheep_icon, label: "Ovelhas" },
  { icon: goat_icon, label: "Cabras" },
];

const apiSpecie = {
  Vacas: "cow",
  Búfalas: "buffalo",
  Ovelhas: "sheep",
  Cabras: "goat",
};

function PropertyRegister() {
  const history = useHistory();

  const { userID } = useUserContext();
  const { getFarmList } = useFarmContext();
  const {
    values,
    errors,
    handleChange,
    handleSelectChange,
    handleSelectStateChange,
    handleSubmit,
    handleSetErrors,
  } = useForm();

  const [states, setStates] = useState([]);
  const [rawStates, setRawStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const url = "https://servicodados.ibge.gov.br/api/v1/localidades/estados";
    axios.get(url).then((res) => {
      const auxStates = res.data.map((state) => {
        return { label: state.nome };
      });
      auxStates.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setRawStates(res.data);
      setStates(auxStates);
    });
  }, []);

  function getCity(value) {
    const url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`;
    axios.get(url).then((res) => {
      const auxCities = res.data.map((city) => {
        return { label: city.nome };
      });
      setCities(auxCities);
    });
  }

  function handleStateChange(option) {
    var aux_index = -1;
    rawStates.forEach((state, index) => {
      if (state.nome === option.label) aux_index = index;
    });
    const index = aux_index;
    if (index !== -1) {
      states.forEach(
        (state) =>
          state.label === rawStates[index].nome && getCity(rawStates[index].id)
      );
      handleSelectStateChange(option.label);
    } else {
      handleSelectStateChange("-1");
    }
  }

  async function checkValues() {
    const error = {};

    if (!values.farm_name) {
      error.farm_name = "Este campo é obrigatório";
    } else if (errors.farm_name) {
      delete error.farm_name;
    }

    if (!values.state) {
      error.state = "Campo obrigatório";
    } else if (errors.state) {
      delete error.state;
    }

    if (!values.city || values.city === "-1") {
      error.city = "Campo obrigatório";
    } else if (errors.city) {
      delete error.city;
    }

    if (!values.species) {
      error.species = "Campo obrigatório";
    } else if (errors.species) {
      delete error.species;
    }

    return error;
  }

  async function valuesAreCorrect() {
    try {
      setIsLoading(true);
      const data = {
        name: values.farm_name,
        city: values.city,
        specie: apiSpecie[values.species],
        state: values.state,
        complement: values.complement ?? null,
        userId: userID,
      };
      await registerFarm(data);
      getFarmList();
      history.push("/your-properties");
    } catch (err) {
      setIsLoading(false);
      if (err.statusCode !== 500) {
        handleSetErrors({ api: err.message });
      } else {
        handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
      }
    }
  }

  function handleCancelButton() {
    history.push("/your-properties");
  }

  return (
    <div className="property-register-body">
      <PageTitle title="Cadastrar fazenda" />

      <div className="form-wrapper">
        <form
          onSubmit={handleSubmit(checkValues, valuesAreCorrect, isLoading)}
          autocomplete="off"
        >
          <div className="farm-icon-wrapper">
            <img src={farm_icon} alt="Farm icon" className="farm-icon1" />
          </div>

          <Input
            autoFocus
            type="text"
            id="farm_name"
            className="px400-input"
            error={errors.farm_name}
            value={values.farm_name}
            label="Nome da Propriedade"
            onChange={(event) => handleChange(event)}
            placeholder="Insira o nome da propriedade"
          />

          {/* <div className="px400-input">
            <div className="label-localization">
              <label for="localization" className="label-state">
                <img src={localization_pin} alt="Localização" className="localization-pin" />
                Localização da Propriedade:
              </label>
            </div>
            <Button color="whitebg" type="button">
              <img src={localization_pin} alt="Localização" className="localization-pin" />
              Localização
            </Button>
          </div> */}

          <div className="form-row">
            <Select
              id="state"
              search
              label="Estado"
              options={states}
              error={errors.state}
              className="px400-input"
              placeholder="Insira o Estado"
              inputPlaceholder="Pesquisar estado"
              onChange={(index) => handleStateChange(index)}
              value={values.state === "-1" ? undefined : values.state}
            />
            <Select
              id="city"
              search
              options={cities}
              label="Município"
              error={errors.city}
              className="px400-input"
              placeholder="Insira o Município"
              inputPlaceholder="Pesquisar município"
              value={values.city === "-1" ? undefined : values.city}
              onChange={(index) => handleSelectChange("city", index)}
            />
          </div>
          <div className="form-row">
            <Input
              label="Complemento"
              type="text"
              id="complement"
              className="px400-input"
              error={errors.complement}
              value={values.complement}
              onChange={(event) => handleChange(event)}
              placeholder="Se houver, insira o complemento do endereço"
            />
            <Select
              id="species"
              options={options}
              label="Espécies"
              error={errors.species}
              className="px400-input"
              value={values.species}
              placeholder="Selecione a espécie"
              onChange={(index) => handleSelectChange("species", index)}
            />
          </div>

          {errors.api && <p className="api-error">{errors.api}</p>}

          <div className="form-row-end">
            <div className="buttons-wrapper">
              <Button
                className="button-type"
                color="white"
                type="button"
                onClick={handleCancelButton}
              >
                Cancelar Cadastro
              </Button>
              <Button
                className="button-type"
                color="blue"
                type="submit"
                isLoading={isLoading}
              >
                Cadastrar Fazenda
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PropertyRegister;
