import { newApi } from "../api";

export async function registerMilkProduction(data) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.post("/milkproduction", data, config);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}

export async function lastMilkProduction(animal_id, farm_id) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.get(
      `milkproduction/last/${farm_id}/${animal_id}`,
      config
    );
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}

export async function getAnimalsByMilkProductionMonth(farmId, month) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };

  try {
    const response = await newApi.get(
      `milkproduction/month/${farmId}/${month}`,
      config
    );
    return response.data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}
