import React, { useEffect } from "react";

import Input from "../../../../components/input/input";

import alertIcon from "../../../../assets/svg/alerts/alert1.svg";
import useForm from "../../../../hooks/useForm";

function RegisterRow({ definedValues, handleAnimalValues, errors, isCCSHigh }) {
  const { values, handleChange } = useForm();

  useEffect(() => {
    handleAnimalValues(values);
  }, [values]);

  return (
    <div className="event_ccs_row">
      <Input
        id="identification"
        type="text"
        value={definedValues.identification}
        disabled
      />
      <Input id="lot" type="text" value={definedValues.lot ?? "-"} disabled />
      <Input
        id="event_date"
        type="text"
        className={errors?.event_date ? "error" : ""}
        value={values.event_date}
        onChange={handleChange}
        mask="99/99/9999"
        placeholder="dd/mm/aaaa"
      />
      <div className={"ccs_input " + (errors?.ccs ? "error" : "")}>
        <Input
          id="ccs"
          type="text"
          className={errors?.ccs ? "error" : ""}
          value={values.ccs}
          mask="9999 mil cel/ml"
          maskChar=" "
          onChange={handleChange}
          placeholder="Valor da CCS"
        />
        {values.ccs && isCCSHigh(values.ccs) && (
          <img src={alertIcon} alt="Ícone de alerta" className="alert-icon" />
        )}
      </div>
    </div>
  );
}

export default RegisterRow;
