import { newApi } from "../api";

export async function deleteEvent(eventName, id_event, id_animal, id_farm) {
  const config = {
    headers: {
      Authorization: localStorage.getItem("milk-token"),
    },
  };
  var event = "";
  var eventIdKey = "";

  if (eventName === "reprodução") {
    event = `/reproduction/delete`;
    eventIdKey = "reproductionId";
  } else if (eventName === "CCS individual") {
    event = `/ccs/delete`;
    eventIdKey = "ccsId";
  } else if (eventName === "teste CMT") {
    event = `/cmt/delete`;
    eventIdKey = "cmtId";
  } else if (eventName === "parto") {
    event = `/birth/delete`;
    eventIdKey = "birthId";
  } else if (eventName === "produção de leite") {
    event = `/milkproduction/delete`;
    eventIdKey = "milkProductionId";
  } else if (eventName === "secagem") {
    event = `/drying/delete`;
    eventIdKey = "dryingId";
  } else if (eventName === "cultura microbiológica") {
    event = `/culture/delete`;
    eventIdKey = "cultureId";
  } else if (eventName === "mastite") {
    event = `/mastitis/delete`;
    eventIdKey = "mastitisId";
  }

  // event = event + id_event + "/" + id_animal;
  // console.log(event)

  try {
    await newApi.delete(event, {
      ...config,
      data: { animalId: id_animal, farmId: id_farm, [eventIdKey]: id_event },
    });
  } catch (err) {
    throw new Error(err.response.data);
  }
}
