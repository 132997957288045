import "./button.css";

import Spinner from "../spinner/spinner";

function Button(props) {
  return (
    <div id="button-wrapper" className={props.className}>
      <button
        className={
          "button " +
          (props.color + "-button ") +
          (props.background + "-background ") +
          (props.disabled || props.isLoading ? "disabled" : undefined)
        }
        type={props.type ? props.type : "button"}
        onClick={props.onClick}
      >
        {props.isLoading ? <Spinner /> : props.children}
      </button>
    </div>
  );
}

export default Button;
