import { useState } from "react";

import { useAlertContext } from "../../../../../contexts/alertContext";

import "./ModalNumberMastitis.css";

import Button from "../../../../../components/button/button";
import Modal from "../../../../../components/modal/modal";

// modalName = animalsNumberMastitis
export default function ModalNumberMastitis(){
  const { modalProps, handleModalShow, modalRememberLater, modalUnderstand } = useAlertContext();
  const { number } = modalProps;

  const [isLoading, setIsLoading] = useState(false);

  async function handleClickUnderstand() {
    setIsLoading(true);
    try {
      await modalUnderstand('animalsNumberMastitis');
    } catch (e) {
      setIsLoading(false);
      console.log('Error')
    }
  }

  return(
    <Modal showModal={handleModalShow} close={true}>
      <div className="number-mastitis-modal-content">
        <p className="title">Alerta mastite subclínica</p>
        <p className="text">{number + " animais da fazenda estão com diagnóstico de mastite subclínica."}</p>
        
        <div className="modal-div-buttons">
          <div />
          <Button color="white" className="modal-button" onClick={modalRememberLater} >Lembrar-me Depois</Button>
          <Button color="blue" className="modal-button" isLoading={isLoading} onClick={handleClickUnderstand} >Apagar alerta</Button>
        </div>
      </div>
    </Modal>
  );  
}