import { differenceInCalendarDays, format } from "date-fns";

export function getDaysLactationData(secagem, parto) {
  var dataSecagem = [];
  var dataParto = [];
  secagem.forEach(function (element) {
    if (element.eventDate)
      return dataSecagem.push(new Date(element.eventDate.slice(0, -1)));
  });
  parto.forEach(function (element) {
    if (element.eventDate)
      return dataParto.push(new Date(element.eventDate.slice(0, -1)));
  });
  dataSecagem.sort(function (a, b) {
    return b.getTime() - a.getTime();
  });
  dataParto.sort(function (a, b) {
    return b.getTime() - a.getTime();
  });

  var data = [];

  dataSecagem.forEach(function (elem_secagem, index_secagem, array_secagem) {
    dataParto.every(function (elem_parto, index_parto, array_parto) {
      if (elem_parto < elem_secagem) {
        data.push({
          Término: format(elem_secagem, "dd/MM/yyyy"),
          Início: format(elem_parto, "dd/MM/yyyy"),
          Total: differenceInCalendarDays(elem_secagem, elem_parto),
        });
        array_secagem.splice(index_secagem, 1);
        array_parto.splice(index_parto, 1);
        return false;
      }
      return true;
    });
  });

  if (dataParto.length) {
    dataParto.every(function (elem_parto, index_parto, array_parto) {
      data.push({
        Término: "---",
        Início: format(elem_parto, "dd/MM/yyyy"),
        Total: "---",
      });
    });
  }

  return data;
}
