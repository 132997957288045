import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useUserContext } from "../../../../contexts/userContext";
import useForm from "../../../../hooks/useForm";

import { updateUserData } from "../../../../services/userForm/updateUser";
// import { deleteUser } from "../../../../services/userForm/delUser";

import Button from "../../../../components/button/button";
import Input from "../../../../components/input/input";

import ModalAccount from "../ModalAccount/modalAccount";

import trash_icon from "../../../../assets/svg/trash.svg";

export default function FormAccount() {
  const history = useHistory();

  const { user, userID, getUserData, handleUser } = useUserContext();
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    cleanErrors,
    handleSetErrors,
    updateValues,
  } = useForm();

  const [showModal, setShowModalAccount] = useState(false);
  const [isEditUserEnabled, setIsEditUserEnabled] = useState(true);
  const [saveAvailable, setSaveAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUserData();
  }, [userID, getUserData]);

  function handleCancelEditUser() {
    setIsEditUserEnabled(true);
    setSaveAvailable(false);
    cleanErrors();
    updateValues({});
  }

  async function checkUserValues() {
    const error = {};

    if (saveAvailable) {
      if (!values.name) {
        error.name = "Este campo é obrigatório";
      } else if (errors.name) {
        delete error.name;
      }

      if (!values.document) {
        error.document = "Este campo é obrigatório";
      } else if (values.document.length < 14) {
        error.document = "Digite um CPF válido";
      } else if (errors.document) {
        delete error.document;
      }

      if (!values.email) {
        error.email = "Este campo é obrigatório";
      } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        error.email = "Endereço de e-mail invalido";
      } else if (errors.email) {
        delete error.email;
      }

      if (errors.invisible) {
        delete error.invisible;
      }
    } else {
      error.invisible = true;
    }

    return error;
  }

  async function userValuesAreCorrect() {
    try {
      setIsLoading(true);
      const data = {
        id: userID,
        name: values.name,
        cpf: values.document,
      };
      const response = await updateUserData(data);
      handleUser({
        name: response.veterinary.name,
        document: response.veterinary.cpf,
        email: response.veterinary.email,
      });
      setIsEditUserEnabled(true);
    } catch (err) {
      setIsLoading(false);
      handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
    }
    setIsEditUserEnabled(true);
  }

  async function handleDeleteUser() {
    try {
      setIsLoading(true);
      // await deleteUser(userID);
      localStorage.removeItem("milk-token");
      history.push("/");
    } catch (err) {
      setIsLoading(false);
      handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
    }
  }

  function handleClickEditUser() {
    updateValues({
      name: user?.name,
      document: user?.document,
      email: user?.email,
    });
    setIsEditUserEnabled(false);
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(
          checkUserValues,
          userValuesAreCorrect,
          isLoading
        )}
      >
        <div className="name-cpf">
          <Input
            label="Nome"
            id="name"
            className="px400-input"
            error={errors.name}
            value={values.name}
            type="text"
            placeholder={isEditUserEnabled ? user?.name : "Insira o seu nome"}
            disabled={isEditUserEnabled}
            onChange={handleChange}
          />
          <Input
            label="CPF"
            id="document"
            className="px400-input"
            error={errors.document}
            value={values.document}
            type="text"
            placeholder={
              isEditUserEnabled ? user?.document : "Ex.: 499.123.252-00"
            }
            disabled={isEditUserEnabled}
            onChange={handleChange}
          />
        </div>
        <div className="email-row">
          <Input
            label="Email"
            id="email"
            className="px400-input"
            error={errors.email}
            value={values.email}
            type="text"
            placeholder={user?.email}
            disabled={true}
            onChange={handleChange}
          />
        </div>
        <div className="form-row-end">
          <div className="buttons-wrapper">
            {isEditUserEnabled ? (
              <Button
                className="button-type"
                color="white"
                type="button"
                onClick={handleClickEditUser}
              >
                Editar
              </Button>
            ) : (
              <Button
                className="button-type"
                color="blue"
                type="submit"
                onClick={() => setSaveAvailable(true)}
                isLoading={isLoading}
              >
                Salvar
              </Button>
            )}
            {isEditUserEnabled ? (
              <Button
                className="button-type"
                color="white"
                type="button"
                onClick={() => setShowModalAccount(true)}
              >
                Excluir
                <img
                  src={trash_icon}
                  alt="Lixeira Ícone"
                  className="img_trash"
                />
              </Button>
            ) : (
              <Button
                className="button-type"
                color="white"
                type="button"
                onClick={handleCancelEditUser}
              >
                Cancelar
              </Button>
            )}
          </div>
        </div>
      </form>

      {showModal && (
        <ModalAccount
          onClickCancel={() => setShowModalAccount(false)}
          onClickSubmit={handleDeleteUser}
          isLoading={isLoading}
        />
      )}
    </>
  );
}
