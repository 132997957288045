import { newApi } from "../api";

async function subscribeUser(data) {
  try {
    const response = await newApi.put("/user/subscribe", data);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data.err.code);
  }
}

export default subscribeUser;
