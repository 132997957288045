import { useEffect, useState } from "react";

import "./alertCard.css";

import arrow_white from "../../../../assets/svg/your-properties/arrow-white.svg";
import alert from "../../../../assets/svg/alerts/alert1.svg";
import bucket from "../../../../assets/svg/alerts/bucket.svg";
import baby from "../../../../assets/svg/alerts/baby.svg";
import hourglass from "../../../../assets/svg/alerts/hourglass.svg";
import milkcollector from "../../../../assets/svg/alerts/milkcollector.svg";
import injection from "../../../../assets/svg/alerts/injection.svg";
import calendar1 from "../../../../assets/svg/alerts/calendar1.svg";
import hightccs from "../../../../assets/svg/alerts/hightccs.svg";

function AlertCard({ name, animals_number, animal_id, isCritical, onClick }) {
  const [imagePath, setImagePath] = useState();
  const [label, setLabel] = useState();

  useEffect(() => {
    if (name === "Mastite") {
      setImagePath(injection);
      setLabel(
        animals_number + " animais da fazenda estão com mastite subclínica"
      );
    } else if (name === "Valor CCS alto") {
      setImagePath(hightccs);
      setLabel(
        animals_number + " animais da fazenda estão com valor de CCS altas"
      );
    } else if (name === "Secagem prevista") {
      setImagePath(milkcollector);
      setLabel(
        animals_number +
          ` ${
            parseInt(animals_number) > 1 ? "animais" : "animal"
          } com secagem prevista para os próximos dias`
      );
    } else if (name === "Parto previsto") {
      setImagePath(baby);
      setLabel(
        animals_number +
          ` ${
            parseInt(animals_number) > 1 ? "animais" : "animal"
          } com parto próximo`
      );
    } else if (name === "Tratamento de Mastite") {
      setImagePath(injection);
      setLabel(
        animals_number +
          ` ${
            parseInt(animals_number) > 1 ? "animais" : "animal"
          } em tratamento de mastite - descartar o leite`
      );
    } else if (name === "Final da carência de medicamento") {
      setImagePath(hourglass);
      setLabel(
        animals_number +
          ` ${
            parseInt(animals_number) > 1 ? "animais" : "animal"
          } da fazenda estão no final da carência do medicamento de secagem`
      );
    } else if (name === "Agendar CMT ou CCS individual") {
      setImagePath(calendar1);
      setLabel(
        "Vaca " +
          animal_id +
          " será seca hoje, lembre-se de incluir o CMT ou CCS individual"
      );
    } else if (name === "Leite liberado da carência") {
      setImagePath(bucket);
      setLabel(
        "O animal " + animal_id + " está com o leite liberado da carência"
      );
    } else if (name === "Parto próximo") {
      setImagePath(baby);
      setLabel(
        "Vaca " + animal_id + " vai entrar em trabalho de parto em 7 dias"
      );
    }

    if (isCritical) {
      setImagePath(alert);
    }
  }, [animals_number, animal_id, isCritical, name]);

  return (
    <div
      className={"alert-card " + (isCritical && "critical")}
      style={null}
      onClick={onClick}
    >
      <div className="alert-body">
        <div className="alert-info">
          <img src={imagePath} alt="Ícone" className="alert-icon" />
          <div className="point"></div>
          <p className="alert-text">{label}</p>
        </div>

        <div className="arrow-button">
          <div className="button">
            <img src={arrow_white} alt="Arrow" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertCard;
