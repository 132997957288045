import { format } from "date-fns";
import { useEffect, useState } from "react";

import { useEventContext } from "../../../../contexts/eventContext";
import { useFarmContext } from "../../../../contexts/farmContext";
import useForm from "../../../../hooks/useForm";

import Input from "../../../../components/input/input";
import EventContent from "../../components/eventContent/eventContent";

import arrow_white from "../../../../assets/svg/your-properties/arrow-white.svg";

import { dateToApiDate } from "../../../../utils/dateToApiDate";
import { isDateValid, isPastDate } from "../../../../utils/isDateValid";
import { separar } from "../../../../utils/separarArray";

import { lastCMT, registerCMT } from "../../../../services/events/testCMT";

import RegisterRow from "./registerRow";
import "./testCMT.css";

const SortButton = ({ sortColumn, sortDesc, onClick, column }) => {
  return (
    <div className="sort-button" onClick={onClick}>
      <img
        src={arrow_white}
        alt="Arrow"
        className={
          "arrow " + (sortColumn === column && sortDesc ? "down" : "up")
        }
      />
    </div>
  );
};

export default function TestCMT() {
  const {
    values,
    errors,
    handleSubmit,
    handleSelectChange,
    handleSetErrors,
    reset,
  } = useForm();
  const { selectedAnimals, handleModalShow, handleModalProps } =
    useEventContext();
  const { farmSelected } = useFarmContext();

  const [isLoading, setIsLoading] = useState(false);
  const [infoValue, setInfoValue] = useState("-");

  const [sortDesc, setSortDesc] = useState(false);
  const [sortColumn, setSortColumn] = useState("");

  const [animalsToShow, setAnimalsToShow] = useState(selectedAnimals);

  useEffect(() => {
    async function fetchLastRegister() {
      try {
        const register_response = await lastCMT(
          selectedAnimals[0].id,
          selectedAnimals[0].farmId
        );
        const date = new Date(register_response.cmt.eventDate.slice(0, -1));
        const value = format(date, "dd/MM/yyyy");
        setInfoValue(value);
      } catch (err) {}
    }
    if (selectedAnimals.length === 1) {
      fetchLastRegister();
    }
  }, [selectedAnimals]);

  // useEffect(() => {
  //   if (selectedAnimals.length > 0) {
  //     selectedAnimals.forEach(async (animal) => {
  //       try {
  //         const value = await getPartoById(animal.parto_id);
  //         setLastBirts((arr) => [...arr, value.data_parto]);
  //       } catch (err) {}
  //     });
  //   }
  // }, [selectedAnimals]);

  async function checkValues() {
    const error = {};

    Object.keys(values).forEach((key) => {
      if (Object.values(values[key]).some((item) => item)) {
        if (!values[key].event_date) {
          error[key] = {
            ...error[key],
            event_date: "Este campo é obrigatório",
          };
        } else if (!isDateValid(values[key].event_date)) {
          error[key] = { ...error[key], event_date: "Insira uma data válida" };
        } else if (!isPastDate(values[key].event_date)) {
          error[key] = {
            ...error[key],
            event_date: "Insira uma data no passado ou o dia corrente",
          };
        } else if (errors[key]?.event_date) {
          delete error[key].event_date;
        }

        // if (
        //   ![
        //     values[key].teto_fe,
        //     values[key].teto_fd,
        //     values[key].teto_te,
        //     values[key].teto_td,
        //   ].some(Boolean)
        // ) {
        //   error[key] = { ...error[key], tetos: "Selecione um teto ou mais" };
        // } else if (errors[key]?.tetos) {
        //   delete error[key].tetos;
        // }

        if (
          ![
            values[key].event_date,
            values[key].teto_fe,
            values[key].teto_fd,
            values[key].teto_te,
            values[key].teto_td,
          ].some(Boolean)
        ) {
          delete error[key];
        }
      } else {
        delete values[key];
      }
    });

    return error;
  }

  async function valuesAreCorrect() {
    try {
      setIsLoading(true);
      const data = Object.keys(values).map((key) => {
        return {
          animalId: key,
          farmId: farmSelected.farm_id,
          eventDate: dateToApiDate(values[key].event_date),
          tetos: {
            ...(values[key].teto_fd && { rightFront: values[key].teto_fd }),
            ...(values[key].teto_fe && { leftFront: values[key].teto_fe }),
            ...(values[key].teto_td && { rightBack: values[key].teto_td }),
            ...(values[key].teto_te && { leftBack: values[key].teto_te }),
          },
        };
      });

      await Promise.all(
        separar(data, 400).map(async (arr) => {
          await registerCMT(arr);
        })
      );

      setIsLoading(false);
      reset();
      handleModalProps({});
      handleModalShow(true);
    } catch (err) {
      setIsLoading(false);
      handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
    }
  }

  function handleSort(column) {
    if (column === sortColumn) {
      setSortDesc(!sortDesc);
    } else {
      setSortDesc(true);
      setSortColumn(column);
    }
  }

  function sortedAnimals(animalsAux, column, desc) {
    if (!column) return animalsAux;
    return animalsAux.sort((a, b) => {
      if ((a[column] || "") > (b[column] || "")) {
        return desc ? -1 : 1;
      } else if ((b[column] || "") > (a[column] || "")) {
        return desc ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  function handleSearch(event) {
    if (!event.currentTarget.value) {
      setAnimalsToShow(selectedAnimals);
      return;
    }
    setAnimalsToShow(
      selectedAnimals.filter((item) =>
        item.identification.includes(event.currentTarget.value)
      )
    );
  }

  return (
    <EventContent
      animals={selectedAnimals}
      className="testeCMT"
      eventInfoValue={infoValue}
      onClickSave={handleSubmit(checkValues, valuesAreCorrect, isLoading)}
      isLoading={isLoading}
      apiError={errors.api}
    >
      <div className="search_input">
        <Input
          id="search"
          type="text"
          onChange={handleSearch}
          label="Filtrar por Identificação"
          placeholder="Insira a identificação"
        />
      </div>

      <div className="event_cmt_body">
        <div>
          <div>
            <div className="event_cmt_header">
              <div>
                <p>Identificação</p>
                <SortButton
                  column="identification"
                  sortColumn={sortColumn}
                  sortDesc={sortDesc}
                  onClick={() => handleSort("identification")}
                />
              </div>
              <div>
                <p>Lote</p>
                <SortButton
                  column="lot"
                  sortColumn={sortColumn}
                  sortDesc={sortDesc}
                  onClick={() => handleSort("lot")}
                />
              </div>
              <div>
                <p>Data do Teste*</p>
              </div>
              <div>
                <p>Teto Frontal Esquerdo</p>
              </div>
              <div>
                <p>Teto Frontal Direito</p>
              </div>
              <div>
                <p>Teto Traseiro Esquerdo</p>
              </div>
              <div>
                <p>Teto Traseiro Direito</p>
              </div>
            </div>

            <div className="event_cmt_animals_rows">
              {sortedAnimals(animalsToShow, sortColumn, sortDesc).map(
                (animal) => {
                  return (
                    <RegisterRow
                      key={animal.id}
                      definedValues={{
                        identification: animal.identification,
                        lot: animal.Lote,
                      }}
                      errors={errors[animal.id]}
                      handleAnimalValues={(value) => {
                        if (!Object.keys(value).length) return;
                        handleSelectChange(animal.id, value);
                      }}
                    />
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </EventContent>
  );
}
