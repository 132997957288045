import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import "./animalReport.css";

import { useFarmContext } from "../../contexts/farmContext";

import {
  arquivarAnimal,
  desarquivarAnimal,
} from "../../services/animals/archiveAnimal";
import { getAnimalData } from "../../services/animals/getAnimals";
import { deleteEvent } from "../../services/events/deleteEvent";

import download from "../../assets/svg/animal-report/download.svg";
import trash_icon from "../../assets/svg/trash.svg";
import PageTitle from "../../components/PageTitle/PageTitle";
import AnimalCard from "../../components/animalCard/animalCard";
import Button from "../../components/button/button";
import Table from "../../components/table/table";
import AnimalModal from "./components/animalModal/animalModal";
import ArchiveModal from "./components/archiveModal/archiveModal";
import DeleteEventModal from "./components/deleteEventModal/deleteEventModal";

import { getAllEvents } from "./utils/getAllEvents";
import { getCulturaData } from "./utils/getCulturaData.js";
import { getDaysLactationData } from "./utils/getDaysLactationData.js";
import { getLastPartosRealizados } from "./utils/getLastPartosRealizados.js";
import { getLastReproductions } from "./utils/getLastReproductions.js";
import { getMastiteData } from "./utils/getMastiteData.js";
import { getMilkProductionData } from "./utils/getMilkProductionData.js";
import { getNextPartos } from "./utils/getNextPartos.js";
import { getSecagemData } from "./utils/getSecagemData.js";

const columns = ["Categoria", "Data", "Valor"];

const dataMap = {
  reproduction: {
    viewMoreTitle: "REPRODUÇÕES",
    columns: ["Data"],
  },
  expectedBith: {
    viewMoreTitle: "PARTOS PREVISTOS",
    columns: ["Data"],
  },
  birth: {
    viewMoreTitle: "PARTOS REALIZADOS",
    columns: ["Data"],
  },
  lactation: {
    viewMoreTitle: "DIAS EM LACTAÇÃO",
    columns: ["Total", "Início", "Término"],
  },
  milkProduction: {
    viewMoreTitle: "PRODUÇÃO DE LEITE",
    columns: ["Mês", "Volume"],
  },
  mastitis: {
    viewMoreTitle: "MASTITE CLÍNICA",
    columns: [
      "Diagnóstico",
      "Tipo Tratamento",
      "Início Tratamento",
      "Fim Tratamento",
      "Fim Carência",
      "Medicamento",
    ],
  },
  drying: {
    viewMoreTitle: "SECAGEM",
    columns: ["Ordenha", "Medicamento", "Aplicação", "Carência", "CMT", "CCS"],
  },
  culture: {
    viewMoreTitle: "CULTURA",
    columns: [
      "Diagnóstico",
      "Teto Frontal Esquerdo",
      "Teto Frontal Direito",
      "Teto Traseiro Esquerdo",
      "Teto Traseiro Direito",
    ],
  },
};

export default function AnimalReport() {
  const history = useHistory();

  const { animalsSelected } = useFarmContext();

  // Última reprodução
  // const [lastReproduction, setLastReproduction] = useState();
  // Inseminacoes passadas e futuras
  const [datasInseminacoes, setDatasInseminacoes] = useState([]);
  // Partos realizados
  const [datasPartosRealizados, setDatasPartosRealizados] = useState([]);
  // Próximos partos
  const [datasNextPartos, setDatasNextPartos] = useState([]);
  // Dias em lactação
  const [daysLactationData, setDaysLactationData] = useState([]);
  // Produção de Leite
  const [milkProductionData, setMilkProductionData] = useState([]);
  // Mastite
  const [mastiteData, setMastiteData] = useState([]);
  // Secagem
  const [secagemData, setSecagemData] = useState([]);
  // Cultura
  const [culturaData, setCulturaData] = useState([]);

  const [allEvents, setAllEvents] = useState([]);

  // Modals
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [showModalDeleteEvent, setShowModalDeleteEvent] = useState(false);

  // Delete events
  const [showEditEvents, setShowEditEvents] = useState(false);
  const [selectEvents, setSelectEvents] = useState([]);

  const [deleteEventName, setDeleteEventName] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [showModalAnimal, setShowModalAnimal] = useState();
  const [animalsModal, setAnimalsModal] = useState([]);

  useEffect(() => {
    if (!animalsSelected[0]) {
      history.push("/animals");
    } else {
      getAnimalData(animalsSelected[0].id, animalsSelected[0].farmId).then(
        (data) => {
          // setLastReproduction(getLastReproduction(data.reproduction));
          setDatasInseminacoes(getLastReproductions(data.reproduction));
          setDatasPartosRealizados(getLastPartosRealizados(data.birth));
          setDatasNextPartos(getNextPartos(data.reproduction));

          setDaysLactationData(getDaysLactationData(data.drying, data.birth));
          setMilkProductionData(getMilkProductionData(data.milkProduction));

          setSecagemData(getSecagemData(data.drying));
          setCulturaData(getCulturaData(data.culture));

          setMastiteData(getMastiteData(data.mastitis));

          setAllEvents(getAllEvents(data));
        }
      );
    }
  }, []);

  const wrapSelectEvents = useCallback((value) => {
    setSelectEvents(value);
    if (value.length) setDeleteEventName(value[0]["Categoria"]);
  }, []);

  const onChangeEvent = useCallback(
    (value) => {
      wrapSelectEvents(value);
    },
    [wrapSelectEvents]
  );

  async function handleDeleteEvent() {
    try {
      setIsLoading(true);
      await deleteEvent(
        selectEvents[0]["Categoria"],
        selectEvents[0]["id"],
        animalsSelected[0].id,
        animalsSelected[0].farmId
      );
      setIsLoading(false);
      setShowModalDeleteEvent(false);
      getAnimalData(animalsSelected[0].id, animalsSelected[0].farmId).then(
        (data) => {
          setAllEvents(getAllEvents(data));
        }
      );
    } catch (err) {
      setIsLoading(false);
    }
  }

  async function handleArchive(answer) {
    if (!animalsSelected[0].arquivado) {
      if (!answer) {
        setError("Este campo é obrigatório");
        return;
      }

      try {
        setIsLoading(true);
        await arquivarAnimal({
          animalId: animalsSelected[0].id,
          farmId: animalsSelected[0].farmId,
          archivedReason: answer,
        });
        animalsSelected[0].arquivado = true;
        setIsArchiveModalOpen(false);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setError("Ocorreu um erro. Tente novamente!");
      }
    } else {
      try {
        setIsLoading(true);
        await desarquivarAnimal({
          animalId: animalsSelected[0].id,
          farmId: animalsSelected[0].farmId,
        });
        animalsSelected[0].arquivado = false;
        setIsArchiveModalOpen(false);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setError("Ocorreu um erro. Tente novamente!");
      }
    }
  }

  // function DatasInseminacoes() {
  //   return (
  //     <>
  //       <p className="section-title">Datas das cobrições/inseminações</p>
  //       <div className="section-row timeline">
  //         <LastInfoCard
  //           info="Evento mais recente"
  //           date={lastReproduction}
  //           description="Inseminação sucedida"
  //         />
  //         <TimeLineInfo points={datasInseminacoes} event="dataCobrições" />
  //       </div>
  //     </>
  //   );
  // }

  // function PartosRealizados() {
  //   return (
  //     <>
  //       <p className="section-title">Partos realizados</p>
  //       <div className="section-row timeline">
  //         <LastInfoCard
  //           info="Último parto"
  //           date={datasPartosRealizados[0].date}
  //         />
  //         <TimeLineInfo
  //           points={datasPartosRealizados}
  //           event="partosRealizados"
  //         />
  //       </div>
  //     </>
  //   );
  // }

  // function PartosProximos() {
  //   return (
  //     <>
  //       <p className="section-title">Próximo parto</p>
  //       <div className="section-row">
  //         <LastInfoCard info="Próximo parto" date={datasNextPartos[0].date} />
  //         <TimeLineInfo points={datasNextPartos} event="partosProximos" />
  //       </div>
  //     </>
  //   );
  // }

  // function DiasLactacao() {
  //   return (
  //     <>
  //       <p className="section-title">Dias em lactação</p>
  //       <div className="section-row timeline">
  //         <LastInfoCard
  //           info="Previsão de término"
  //           date={daysLactationData[0].date}
  //           description={"Início: " + daysLactationData[0].startDate}
  //         />
  //         <TimeLineInfo points={daysLactationData} event="diasLactação" />
  //       </div>
  //     </>
  //   );
  // }

  // function ProducaoLeite() {
  //   return (
  //     <div className="avoid_break_inside">
  //       <p className="section-title">Produção de leite</p>
  //       <div className="section-row overflow ">
  //         <AnnualMilkProduction yearData={milkProductionData} />
  //       </div>
  //     </div>
  //   );
  // }

  // function Mastite() {
  //   return (
  //     <div className="avoid_break_inside">
  //       <p className="section-title">Mastite</p>
  //       <div className="section-row overflow">
  //         {mastiteData.map((elem, index) => {
  //           return (
  //             <MastiteEventCard
  //               date={elem.data_inicio}
  //               leftBack={elem.leftBack}
  //               rightBack={elem.rightBack}
  //               leftFront={elem.leftFront}
  //               rightFront={elem.rightFront}
  //               key={index}
  //             />
  //           );
  //         })}
  //       </div>
  //     </div>
  //   );
  // }

  // function Cultura() {
  //   return (
  //     <div className="avoid_break_inside">
  //       <p className="section-title">Cultura</p>
  //       <div className="section-row overflow">
  //         {culturaData.map((elem, index) => {
  //           return (
  //             <CulturaEventCard
  //               date={elem.date}
  //               leftBack={elem.leftBack}
  //               rightBack={elem.rightBack}
  //               leftFront={elem.leftFront}
  //               rightFront={elem.rightFront}
  //               key={index}
  //             />
  //           );
  //         })}
  //       </div>
  //     </div>
  //   );
  // }

  // function Secagem() {
  //   return (
  //     <div className="avoid_break_inside">
  //       <p className="section-title">Secagem</p>
  //       <div className="section-row overflow">
  //         {secagemData.map((elem, index) => {
  //           return (
  //             <SecagemEventCard
  //               drug={elem.medicine}
  //               dates={[
  //                 apiDateToFormatedDate(elem.eventDate),
  //                 apiDateToFormatedDate(elem.expectedDate),
  //                 apiDateToFormatedDate(elem.medicineDate),
  //               ]}
  //               cmt={elem.tetos ? true : false}
  //               cmtPositivo={
  //                 elem.tetos && Object.values(elem.tetos).some((t) => t !== "0")
  //               }
  //               ccs={elem.ccs}
  //               key={index}
  //             >
  //               {/* <div className="button-del-wrapper">
  //                 <Button
  //                   className="button-type"
  //                   color="none"
  //                   type="button"
  //                   onClick={() => {setShowModalDeleteEvent(true); setDeleteEventName("secagem"); setDeleteEventId(elem.id)}}>
  //                 <img
  //                   src={trash_icon}
  //                   alt="Lixeira ícone"
  //                   className="img_trash"
  //                 />
  //                 </Button>
  //               </div> */}
  //             </SecagemEventCard>
  //           );
  //         })}
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="animal-report-page">
      <PageTitle path="Ficha" backButton>
        <Button onClick={() => history.push("animal-edit")}>
          Editar dados
        </Button>
        <Button
          color="white"
          background="white"
          onClick={() => setIsArchiveModalOpen(true)}
        >
          {!animalsSelected[0].arquivado
            ? "Arquivar animal"
            : "Desarquivar animal"}
        </Button>
      </PageTitle>

      <div className="tabs-wrapper">
        <div
          className={"tab " + (showEditEvents ? "" : "selected")}
          onClick={() => setShowEditEvents(!showEditEvents)}
        >
          <p>Ficha do animal</p>
        </div>
        <div
          className={"tab " + (showEditEvents ? "selected" : "")}
          onClick={() => setShowEditEvents(!showEditEvents)}
        >
          <p>Todos os eventos</p>
        </div>
      </div>

      {showEditEvents ? (
        <div className="body-wrapper">
          <div className="table_wrapper">
            <div
              className="table_wrapper_container"
              style={{ minWidth: columns.length * 180 + "px" }}
            >
              <Table
                animals={allEvents}
                columns={columns}
                check
                // multi TODO, com ploblema
                event
                onChangeAnimal={onChangeEvent}
              />
            </div>
          </div>
          {selectEvents?.length !== 0 ? (
            <div className="continue-button">
              <Button
                className="button-type"
                color="white"
                type="button"
                disabled={selectEvents?.length === 0}
                onClick={() => setShowModalDeleteEvent(true)}
              >
                {selectEvents?.length > 1
                  ? "Excluir eventos"
                  : "Excluir evento"}
                <img
                  src={trash_icon}
                  alt="Lixeira ícone"
                  className="img_trash"
                />
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="body-wrapper">
          <div className="animal-card-wrapper">
            <AnimalCard animals={animalsSelected} />
            <div className="fields">
              {/* <div className="input-wrapper">
                <Input
                  id="search_event"
                  className="search-event-input"
                  type="text"
                  placeholder="Evento"
                  onChange={() => {}}
                />
                <img src={search_icon} alt="Lupa" />
              </div> */}
              <Button
                color="white"
                onClick={() => {
                  window.print();
                }}
              >
                Baixar
                <img src={download} alt="Baixar" />
              </Button>
            </div>
          </div>

          <div className="tables_row_wrapper">
            <div
              className="tables_row"
              style={{ minWidth: 3 * 230 + 2 * 16 + "px" }}
            >
              {datasInseminacoes.length > 0 && (
                <div className="tables_column">
                  <p className="event_title">
                    {dataMap.reproduction.viewMoreTitle}
                  </p>
                  <Table
                    animals={datasInseminacoes}
                    columns={dataMap.reproduction.columns}
                    onChangeAnimal={() => {}}
                    search={false}
                    top={5}
                  />
                  <div className="all_list">
                    <p
                      onClick={() => {
                        setShowModalAnimal("reproduction");
                        setAnimalsModal(datasInseminacoes);
                      }}
                    >
                      Lista completa
                    </p>
                  </div>
                </div>
              )}

              {datasNextPartos.length > 0 && (
                <div className="tables_column">
                  <p className="event_title">
                    {dataMap.expectedBith.viewMoreTitle}
                  </p>
                  <Table
                    animals={datasNextPartos}
                    columns={dataMap.expectedBith.columns}
                    onChangeAnimal={() => {}}
                    search={false}
                    top={5}
                  />
                  <div className="all_list">
                    <p
                      onClick={() => {
                        setShowModalAnimal("expectedBith");
                        setAnimalsModal(datasNextPartos);
                      }}
                    >
                      Lista completa
                    </p>
                  </div>
                </div>
              )}

              {datasPartosRealizados.length > 0 && (
                <div className="tables_column">
                  <p className="event_title">{dataMap.birth.viewMoreTitle}</p>
                  <Table
                    animals={datasPartosRealizados}
                    columns={dataMap.birth.columns}
                    onChangeAnimal={() => {}}
                    search={false}
                    top={5}
                  />
                  <div className="all_list">
                    <p
                      onClick={() => {
                        setShowModalAnimal("birth");
                        setAnimalsModal(datasPartosRealizados);
                      }}
                    >
                      Lista completa
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="tables_row_wrapper">
            <div className="tables_row" style={{ minWidth: 3 * 180 + "px" }}>
              {daysLactationData.length > 0 && (
                <div className="tables_column">
                  <p className="event_title">
                    {dataMap.lactation.viewMoreTitle}
                  </p>
                  <Table
                    animals={daysLactationData}
                    columns={dataMap.lactation.columns}
                    onChangeAnimal={() => {}}
                    search={false}
                    top={5}
                  />
                  <div className="all_list">
                    <p
                      onClick={() => {
                        setShowModalAnimal("lactation");
                        setAnimalsModal(daysLactationData);
                      }}
                    >
                      Lista completa
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="tables_row_wrapper">
            <div className="tables_row" style={{ minWidth: 2 * 180 + "px" }}>
              {milkProductionData.length > 0 && (
                <div className="tables_column">
                  <p className="event_title">
                    {dataMap.milkProduction.viewMoreTitle}
                  </p>
                  <Table
                    animals={milkProductionData}
                    columns={dataMap.milkProduction.columns}
                    onChangeAnimal={() => {}}
                    search={false}
                    top={5}
                  />
                  <div className="all_list">
                    <p
                      onClick={() => {
                        setShowModalAnimal("milkProduction");
                        setAnimalsModal(milkProductionData);
                      }}
                    >
                      Lista completa
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="tables_row_wrapper">
            <div className="tables_row" style={{ minWidth: 6 * 180 + "px" }}>
              {mastiteData.length > 0 && (
                <div className="tables_column">
                  <p className="event_title">
                    {dataMap.mastitis.viewMoreTitle}
                  </p>
                  <Table
                    animals={mastiteData}
                    columns={dataMap.mastitis.columns}
                    onChangeAnimal={() => {}}
                    search={false}
                    top={5}
                  />
                  <div className="all_list">
                    <p
                      onClick={() => {
                        setShowModalAnimal("mastitis");
                        setAnimalsModal(mastiteData);
                      }}
                    >
                      Lista completa
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="tables_row_wrapper">
            <div className="tables_row" style={{ minWidth: 6 * 180 + "px" }}>
              {secagemData.length > 0 && (
                <div className="tables_column">
                  <p className="event_title">{dataMap.drying.viewMoreTitle}</p>
                  <Table
                    animals={secagemData}
                    columns={dataMap.drying.columns}
                    onChangeAnimal={() => {}}
                    search={false}
                    top={5}
                  />
                  <div className="all_list">
                    <p
                      onClick={() => {
                        setShowModalAnimal("drying");
                        setAnimalsModal(secagemData);
                      }}
                    >
                      Lista completa
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="tables_row_wrapper">
            <div className="tables_row" style={{ minWidth: 5 * 180 + "px" }}>
              {culturaData.length > 0 && (
                <div className="tables_column">
                  <p className="event_title">{dataMap.culture.viewMoreTitle}</p>
                  <Table
                    animals={culturaData}
                    columns={dataMap.culture.columns}
                    onChangeAnimal={() => {}}
                    search={false}
                    top={5}
                  />
                  <div className="all_list">
                    <p
                      onClick={() => {
                        setShowModalAnimal("drying");
                        setAnimalsModal(secagemData);
                      }}
                    >
                      Lista completa
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* {datasInseminacoes.length > 0 && <DatasInseminacoes />}
          {datasPartosRealizados.length > 0 && <PartosRealizados />}
          {datasNextPartos.length > 0 && <PartosProximos />}
          {daysLactationData.length > 0 && <DiasLactacao />}
          {milkProductionData.length > 0 && <ProducaoLeite />}
          {mastiteData.length > 0 && <Mastite />}
          {culturaData.length > 0 && <Cultura />}
          {secagemData.length > 0 && <Secagem />} */}
        </div>
      )}

      {isArchiveModalOpen && (
        <ArchiveModal
          showModal={(boolean) => setIsArchiveModalOpen(boolean)}
          onClickCancel={() => setIsArchiveModalOpen(false)}
          onClickArchive={(answer) => handleArchive(answer)}
          animal={animalsSelected}
          error={error}
          isLoading={isLoading}
        />
      )}

      {showModalDeleteEvent && (
        <DeleteEventModal
          onClickCancel={() => setShowModalDeleteEvent(false)}
          onClickSubmit={() => handleDeleteEvent()}
          eventName={deleteEventName}
          isLoading={isLoading}
        />
      )}

      {showModalAnimal && (
        <AnimalModal
          title={dataMap[showModalAnimal].viewMoreTitle}
          onClickCancel={() => setShowModalAnimal()}
          animals={animalsModal}
          columns={dataMap[showModalAnimal].columns}
        />
      )}
    </div>
  );
}
