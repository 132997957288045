import { useState } from "react";

import { useAlertContext } from "../../../../../contexts/alertContext";

import Button from "../../../../../components/button/button";
import Modal from "../../../../../components/modal/modal";
import Table from "../../../../../components/table/table";

import "./ModalMastitis.css";

const columns = ["Identificação", "Lote"];

export default function ModalMastitis() {
  const {
    modalProps,
    handleModalShow,
    modalRememberLater,
    modalUnderstand,
    // handleModalProps,
  } = useAlertContext();
  const { animals } = modalProps;

  // const [checkOn, setCheckOn] = useState(false);
  // const [selectedAnimals, setSelectedAnimals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function handleClickUnderstand() {
    setIsLoading(true);
    try {
      await modalUnderstand();
    } catch (e) {
      setIsLoading(false);
      console.log("Error");
    }
  }

  // function handleEndTreatment() {
  //   if (selectedAnimals.length > 0) {
  //     setIsLoading(true);
  //     try {
  //       const animalsNewAlertAux = [];
  //       const animalsEndTreatment = [];
  //       const data = [];
  //       animals.forEach((animal) => {
  //         if (selectedAnimals.includes(animal)) {
  //           animalsNewAlertAux.push(animal);
  //           data.push({ id: animal.alert_id, animal_id: animal.id });
  //         } else {
  //           animalsEndTreatment.push(animal);
  //         }
  //       });

  //       setSelectedAnimals([]);
  //       // setCheckOn(false)
  //       handleModalProps({
  //         animals: animalsNewAlertAux,
  //         animalsMastitis: animalsEndTreatment,
  //       });
  //       modalUnderstand("endTreatment", "", data);
  //     } catch (e) {
  //       setIsLoading(false);
  //       console.log("Error");
  //     }
  //   }
  // }

  // function handleNewDate() {
  //   if (selectedAnimals.length > 0) {
  //     handleModalProps({
  //       animals: selectedAnimals,
  //       type: "mastite",
  //       allAnimals: animals,
  //     });
  //     modalUnderstand("newDate");
  //   }
  // }

  // const onChangeAnimal = useCallback((value) => {
  //   setSelectedAnimals(value);
  // }, []);

  return (
    <Modal showModal={handleModalShow} close={true}>
      <div className="mastitis-modal-content">
        <p className="title">Vaca em tratamento de mastite</p>
        <p className="text">
          {animals.length} animais estão em tratamento de mastite, por isso,
          descarte o leite produzido desses animais.
        </p>

        <div className="table_wrapper">
          <div
            className="table_wrapper_container"
            style={{ minWidth: columns.length * 180 + "px" }}
          >
            <Table
              multi
              animals={animals}
              columns={columns}
              alert
              check={false} //checkOn}
              onChangeAnimal={() => {}}
              // onChangeAnimal={onChangeAnimal}
            />
          </div>
        </div>

        {/* {checkOn ? (
          <div className="modal-div-buttons checkOn">
            <div />
            <Button
              color="white"
              className="modal-button"
              onClick={() => setCheckOn(false)}
            >
              Cancelar
            </Button>
            <Button
              color="blue"
              className="modal-button"
              isLoading={isLoading}
              onClick={handleNewDate}
            >
              Finalizar Tratamento
            </Button>
          </div>
        ) : ( */}
        <div className="modal-div-buttons checkOff">
          <div />
          <Button
            color="white"
            className="modal-button"
            onClick={modalRememberLater}
          >
            Lembrar-me Depois
          </Button>
          {/* <Button
              color="blue"
              className="modal-button"
              onClick={() => setCheckOn(true)}
            >
              Finalizar Tratamento
            </Button> */}
          <Button
            color="blue"
            className="modal-button"
            isLoading={isLoading}
            onClick={handleClickUnderstand}
          >
            Fechar alerta
          </Button>
        </div>
        {/* )} */}
      </div>
    </Modal>
  );
}
