import { format, fromUnixTime } from "date-fns";
import { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie-player";
import loading_gif from "../../../../assets/gifs/loading_2.json";
import BarChart from "../../../../components/baseChart/barChart/barChart";
import YBarChart from "../../../../components/baseChart/yBarChart/yBarChart";
import { useFarmContext } from "../../../../contexts/farmContext";
import { getMilkProductionInfo } from "../../../../services/report/getMilkProductionInfo";
import { apiDateToFormatedDate } from "../../../../utils/dateToApiDate";
import { Box, Column, Row, SectionHeader, TextInfo } from "../bases/bases";
import GestationHerd from "./gestationHerd/gestationHerd";
import LactatingHerd from "./lactatingHerd/lactatingHerd";
import MilkPerAnimal from "./milkPerAnimal/milkPerAnimal";
import "./milkProduction.css";

const intervals = [
  "1-30",
  "31-60",
  "61-90",
  "91-120",
  "121-150",
  "151-180",
  "181-270",
  "271+",
];
const columnsMeanPerLot = ["Lote", "Volume_diário"];
const columnsLactation = ["Identificação", "Lote", "Dias"];

function get_limit(data, others) {
  data.sort((a, b) => b.value - a.value);
  if (data.length > 11) {
    if (others) {
      let othersValue = data
        .slice(11)
        .reduce((sum, item) => sum + parseFloat(item.value), 0);
      data = data.slice(0, 11);
      data.push({
        label: "Outros",
        id: "outros",
        value: parseFloat(othersValue).toFixed(2),
      });
    } else {
      data = data.slice(0, 11);
    }
  }
  return data;
}

export function MilkProduction(props) {
  const { farmSelected } = useFarmContext();
  // em lactação
  const [lactationNumber, setLactationNumber] = useState("-");
  const [lactatingAnimals, setLactatingAnimals] = useState([]);
  // em gestação
  const [gestationNumber, setGestationNumber] = useState("-");
  const [gestationAnimals, setGestationAnimals] = useState([]);
  // Média por lote
  const [meanPerLot, setMeanPerLot] = useState([]);
  const [animalsMeanPerLot, setAnimalsMeanPerLot] = useState([]);
  // Animais em lactação por dias em lactação
  const [lactationAnimalsPerDay, setLactationAnimalsPerDay] = useState([]);
  const [animalslactationAnimalsPerDay, setAnimalslactationAnimalsPerDay] =
    useState([]);
  // Leite produzido por animal
  const [milkPerAnimal, setMilkPerAnimal] = useState([]);
  const [animalsMiklPerAnimal, setAnimalsMiklPerAnimal] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        if (farmSelected) {
          const aux_data = await getMilkProductionInfo(
            farmSelected.farm_id,
            props.date
          );

          // em lactação
          setLactationNumber(aux_data.lactatingAnimals);
          const aux_animaisLactantes = aux_data.animals_lactating.map(
            (item, index) => {
              var date = "-";
              if (item.eventDate) {
                date = apiDateToFormatedDate(item.eventDate);
              }
              return {
                id: "d" + index,
                Identificação: item.identification,
                Última_Produção: date,
                Lote: item.lot,
              };
            }
          );
          setLactatingAnimals(aux_animaisLactantes);

          // em gestação
          setGestationNumber(aux_data.pregnantAnimals);
          const aux_animals_gestantes = aux_data.animals_pregnant.map(
            (item, index) => {
              var date_previsto = "-";
              if (item.expectedBirthDate) {
                date_previsto = apiDateToFormatedDate(item.expectedBirthDate);
              }

              var date_inseminacao = "-";
              if (item.eventDate) {
                date_inseminacao = apiDateToFormatedDate(item.eventDate);
              }
              return {
                id: "d" + index,
                Identificação: item.identification,
                Inseminação: date_inseminacao,
                Parto_Previsto: date_previsto,
                Lote: item.lot,
              };
            }
          );
          setGestationAnimals(aux_animals_gestantes);

          // Média por lote
          const aux_meanPerLot = aux_data.milkPerLot.map((item, index) => {
            return {
              id: "d" + index,
              value: item.value.toFixed(2),
              label: item.label ? item.label : "sem lote",
            };
          });
          if (aux_meanPerLot.length) {
            setMeanPerLot([]);
            // Juntar o que passar de 10 em "outros" - caso de muitos animais
            const data_ = get_limit(aux_meanPerLot, true);
            // console.log(data_)
            setMeanPerLot(data_);
          } else {
            setMeanPerLot([]);
          }
          // console.log(aux_data)
          // Média por lote, detelhamento
          const aux_animalsMeanPerLot = aux_data.milkPerLot.map(
            (item, index) => {
              return {
                id: "d" + index,
                Volume_diário: parseFloat(item.value.toFixed(2)),
                Lote: item.label ? item.label : "sem lote",
              };
            }
          );
          setAnimalsMeanPerLot(aux_animalsMeanPerLot);

          // Animais em lactação por dias em lactação
          const chartData = [];
          aux_data["animalsByDayLactation"].forEach((value, index) =>
            chartData.push({
              label: intervals[index],
              value,
              tooltipContent: `<b>${value}<b>`,
            })
          );
          setLactationAnimalsPerDay(chartData);
          const animals_lactationDays =
            aux_data.animals_animalsByDayLactation.map((item) => {
              return {
                Identificação: item.identification,
                Lote: item.lot,
                Dias: item.days,
              };
            });
          setAnimalslactationAnimalsPerDay(animals_lactationDays);

          // Leite produzido por animal
          const aux_milkPerAnimal = aux_data.milkPerAnimal.map(
            (item, index) => {
              return {
                id: "d" + index,
                value: Number(item.value).toFixed(2),
                label: item.label ? item.label : "sem nome",
              };
            }
          );
          if (aux_milkPerAnimal.length) {
            setMilkPerAnimal([]);
            // Juntar o que passar de 10 em "outros" - caso de muitos animais
            const data_ = get_limit(aux_milkPerAnimal, false);
            setMilkPerAnimal(data_);
          } else {
            setMilkPerAnimal([]);
          }

          // Leite produzido por animal, detelhamento
          const aux_animalsMiklPerAnimal = aux_data.animals_milkPerAnimal.map(
            (item, index) => {
              return {
                id: "d" + index,
                Identificação: item.identification,
                Volume_diário: parseFloat(item.dayVolume.toFixed(2)),
                Lote: item.lot,
              };
            }
          );
          setAnimalsMiklPerAnimal(aux_animalsMiklPerAnimal);
        }
        setIsLoading(false);
      } catch (e) {
        console.log("Error fetching data - Milk Production: " + e);
        setIsLoading(false);
      }
    }
    fetchData();
  }, [farmSelected, props.date]);

  const lactationsDaysRef = useRef(null);
  const [size, setSize] = useState({}); // Size (width, height) - Animais em lactação por dias em lactação

  useEffect(() => {
    var aux = { width: "", height: "" };
    aux.height = 190;

    if (lactationsDaysRef.current)
      aux.width = lactationsDaysRef.current.offsetWidth - 65;

    setSize(aux);
  }, []); // auxState

  return (
    <div className="milk-production-wrapper">
      <SectionHeader title="Leite Produzido" />

      <Row className="milk-production first">
        <Column>
          <LactatingHerd
            lactationNumber={lactationNumber}
            lactatingAnimals={lactatingAnimals}
          />
          <GestationHerd
            gestationNumber={gestationNumber}
            gestationAnimals={gestationAnimals}
          />
        </Column>
        <Box
          className="bigger"
          title="Média diária por lote"
          titleDescription={
            "Valores médio considerando os eventos feitos até o dia " +
            format(fromUnixTime(props.date), "dd/MM/yyyy")
          }
          viewMore={meanPerLot.length > 0}
          isViewMoreFull={meanPerLot.length >= 11}
          viewMoreTitle={"Média diária por lote"}
          animals={animalsMeanPerLot}
          columns={columnsMeanPerLot}
        >
          {meanPerLot.length ? (
            <BarChart
              data_={meanPerLot} // meanPerLot
              unit="litros"
              min={10}
              max={30}
            />
          ) : (
            isLoading && (
              <div className="no-data">
                <Lottie loop play animationData={loading_gif} />
              </div>
            )
          )}
        </Box>
      </Row>

      <Row className="milk-production second">
        <Box
          id="box4"
          title="Animais em lactação por dias em lactação"
          className="bigger"
          viewMore={lactationAnimalsPerDay.length > 0}
          viewMoreTitle="Animais em lactação por dias em lactação"
          animals={animalslactationAnimalsPerDay}
          columns={columnsLactation}
        >
          <div
            className="animals-lactation-graph-wrapper"
            ref={lactationsDaysRef}
          >
            {isLoading ? (
              <div className="no-data">
                <Lottie loop play animationData={loading_gif} />
              </div>
            ) : lactationAnimalsPerDay.length > 0 ? (
              <YBarChart
                svgProps={{
                  margin: { top: 40, bottom: 60, left: 35, right: 30 },
                  width: size.width,
                  height: size.height,
                }}
                axisProps={{
                  xLabel: "Dias em lactação",
                  yLabel: "Nº de animais",
                  drawXGridlines: true,
                }}
                data={lactationAnimalsPerDay} // data
                strokeWidth={3}
                paddingInner={0.3}
              />
            ) : (
              <TextInfo first="Sem dados" latter="Cadastre um evento parto" />
            )}
          </div>
        </Box>
        <MilkPerAnimal
          isLoading={isLoading}
          milkPerAnimal={milkPerAnimal}
          animalsMiklPerAnimal={animalsMiklPerAnimal}
          titleDescription={
            "Valores médio considerando os eventos feitos até o dia " +
            format(fromUnixTime(props.date), "dd/MM/yyyy")
          }
        />
      </Row>
    </div>
  );
}
