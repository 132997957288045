import { format } from "date-fns";

export function getCulturaData(DATA) {
  var culturaData = [];

  let group = DATA.reduce((r, a) => {
    r[a.eventDate] = [...(r[a.eventDate] || []), a];
    return r;
  }, {});

  for (var x in group) {
    var dataDay = group[x];

    var culturaObj = {};

    for (var inst in dataDay) {
      culturaObj.date = new Date(dataDay[inst].eventDate.slice(0, -1));

      if (dataDay[inst].tetos.leftFront) {
        culturaObj.leftFront = {
          bacteria: dataDay[inst].bacterium,
        };
      }
      if (dataDay[inst].tetos.rightFront) {
        culturaObj.rightFront = {
          bacteria: dataDay[inst].bacterium,
        };
      }
      if (dataDay[inst].tetos.leftBack) {
        culturaObj.leftBack = {
          bacteria: dataDay[inst].bacterium,
        };
      }
      if (dataDay[inst].tetos.rightBack) {
        culturaObj.rightBack = {
          bacteria: dataDay[inst].bacterium,
        };
      }
    }
    culturaData.push(culturaObj);
  }

  culturaData.sort(function (a, b) {
    return b.date.getTime() - a.date.getTime();
  });

  return culturaData.map(function (element) {
    return {
      Diagnóstico: format(element.date, "dd/MM/yyyy"),
      "Teto Frontal Esquerdo": element.leftFront
        ? element.leftFront.bacteria ?? "Positivo"
        : "",
      "Teto Frontal Direito": element.rightFront
        ? element.rightFront.bacteria ?? "Positivo"
        : "",
      "Teto Traseiro Esquerdo": element.leftBack
        ? element.leftBack.bacteria ?? "Positivo"
        : "",
      "Teto Traseiro Direito": element.rightBack
        ? element.rightBack.bacteria ?? "Positivo"
        : "",
    };
  });
}
