import { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useForm from "../../hooks/useForm";

import logo from "../../assets/images/logo-small-1.png";
import Button from "../../components/button/button";
import Input from "../../components/input/input";

import "./additionalRegister.css";

import addtionalSignUp from "../../services/userForm/addtionalSignUp";
import { dateToApiDate } from "../../utils/dateToApiDate";
import { isDateValid, isPastDate } from "../../utils/isDateValid";

function AditionalRegisterPage() {
  const history = useHistory();
  const { search } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const { values, errors, handleChange, handleSubmit, handleSetErrors } =
    useForm();

  const [isLoading, setIsLoading] = useState(false);

  if (!query.get("id")) {
    return history.push("/");
  }

  async function checkValues() {
    const error = {};

    if (!values.birthdate) {
      error.birthdate = "Este campo é obrigatório";
    } else if (
      !isDateValid(values.birthdate) ||
      !isPastDate(values.birthdate)
    ) {
      error.birthdate = "Insira uma data válida";
    } else if (errors.birthdate) {
      delete error.birthdate;
    }

    if (!values.cellphone) {
      error.cellphone = "Este campo é obrigatório";
    } else if (!/\((\d{2})\)\s(\d{5})-(\d{4})/.test(values.cellphone)) {
      error.cellphone = "Telefone inválido";
    } else if (errors.cellphone) {
      delete error.cellphone;
    }

    if (!values.cep) {
      error.cep = "Este campo é obrigatório";
    } else if (!/(\d{5})-(\d{3})/.test(values.cep)) {
      error.cep = "CEP inválido";
    } else if (errors.cep) {
      delete error.cep;
    }

    if (!values.street) {
      error.street = "Este campo é obrigatório";
    } else if (errors.street) {
      delete error.street;
    }

    if (!values.street_number) {
      error.street_number = "Este campo é obrigatório";
    } else if (errors.street_number) {
      delete error.street_number;
    }

    if (!values.locality) {
      error.locality = "Este campo é obrigatório";
    } else if (errors.locality) {
      delete error.locality;
    }

    if (!values.city) {
      error.city = "Este campo é obrigatório";
    } else if (errors.city) {
      delete error.city;
    }

    if (!values.state) {
      error.state = "Este campo é obrigatório";
    } else if (values.state.length !== 2) {
      error.state = "Insira a sigla do estado";
    } else if (errors.state) {
      delete error.state;
    }

    return error;
  }

  async function valuesAreCorrect() {
    try {
      setIsLoading(true);
      const data = {
        id: query.get("id"),
        address: {
          street: values.street,
          number: values.street_number,
          locality: values.locality,
          city: values.city,
          state: values.state.toUpperCase(),
          postalCode: values.cep.replace(/\D/g, ""),
        },
        birthdate: dateToApiDate(values.birthdate),
        cellphone: values.cellphone.replace(/\D/g, ""),
      };
      await addtionalSignUp(data);

      history.push("/plans?id=" + query.get("id"));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (err.message === "UsernameExistsException") {
        handleSetErrors({ email: "Endereço de e-mail já utilizado" });
      } else {
        handleSetErrors({ api: "Ocorreu um erro, tente novamente" });
      }
    }
  }

  return (
    <div className="additional-register">
      <div className="white-part">
        <div>
          <h1 className="welcome-text">Seu acesso já está quase pronto!</h1>
          <form
            onSubmit={handleSubmit(checkValues, valuesAreCorrect, isLoading)}
          >
            <div className="input-row">
              <Input
                id="birthdate"
                label="Data de nascimento"
                placeholder="__/__/____"
                mask="99/99/9999"
                className="register-input"
                type="text"
                error={errors.birthdate}
                value={values.birthdate}
                onChange={handleChange}
                required
              />
              <Input
                id="cellphone"
                label="Telefone"
                placeholder="(__) ____-____"
                className="register-input"
                type="text"
                error={errors.cellphone}
                value={values.cellphone}
                onChange={handleChange}
                mask="(99) 99999-9999"
                required
              />
            </div>
            <div className="input-row">
              <Input
                id="cep"
                label="CEP"
                placeholder="Insira seu CEP"
                className="register-input"
                type="text"
                errorAlignColumn={false}
                error={errors.cep}
                value={values.cep}
                onChange={handleChange}
                mask="99999-999"
                required
              />
              <Input
                id="street"
                label="Rua"
                placeholder="Insira sua rua"
                className="register-input"
                type="text"
                error={errors.street}
                value={values.street}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-row">
              <Input
                id="street_number"
                label="Número"
                placeholder="Insira o número"
                className="register-input"
                type="text"
                error={errors.street_number}
                value={values.street_number}
                onChange={handleChange}
                required
              />
              <Input
                id="locality"
                label="Bairro"
                placeholder="Insira seu bairro"
                className="register-input"
                type="text"
                error={errors.locality}
                value={values.locality}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-row">
              <Input
                id="city"
                label="Cidade"
                placeholder="Insira sua cidade"
                className="register-input"
                type="text"
                error={errors.city}
                value={values.city}
                onChange={handleChange}
                required
              />
              <Input
                id="state"
                label="Estado (sigla)"
                placeholder="Insira a sigla do seu estado"
                className="register-input"
                type="text"
                error={errors.state}
                value={values.state}
                onChange={handleChange}
                required
              />
            </div>

            <p className="termos-uso">
              Ao clicar em concluir o cadastro você concorda com o{" "}
              <a
                target="_blank"
                href="https://drive.google.com/file/d/1KklJrk-SqtSsDS6tN6QGAR1oVhjYirf2/view"
                rel="noreferrer"
              >
                termos de uso
              </a>{" "}
              do sistema
            </p>

            {errors.api && <p className="api-error">{errors.api}</p>}

            <div className="button-row">
              <div />
              <Button color="white" onClick={() => history.push("/")}>
                Cancelar Cadastro
              </Button>
              <Button color="blue" type="submit" isLoading={isLoading}>
                Cadastrar
              </Button>
            </div>
          </form>
        </div>
      </div>

      <div className="blue-part">
        <div className="mask">
          <div>
            <img className="logo" src={logo} alt="logo" />
            <p className="minor-text">
              Ficou mais fácil organizar e gerenciar as informações sobre
              qualidade do leite da sua propriedade
            </p>
            <p className="minor-text">
              Tenha na palma da mão todo o histórico de mastite clínica,
              subclínica e tratamentos dos seus animais
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AditionalRegisterPage;
