// import Dropzone from "react-dropzone";
import { uniqueId } from "lodash";
import filesize from "filesize";
// import { Container, Content } from "./styles";
import Upload from "./components/Upload/upload";
import FileList from "./components/FileList/fileList";
import { useState } from "react";
// import FileList from "./components/FileList";
// import api from "./services/api";

import './uploadFile.css'

function UploadFile(){

  const [uploadedFiles, setUploadedFiles] = useState([]);

  function handleUpload(files){
    const aux_uploadedFiles = files.map(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null
    }));

    setUploadedFiles(uploadedFiles.concat(aux_uploadedFiles))

    uploadedFiles.forEach(processUpload)
  }

  function processUpload(uploadedFile){
    const data = new FormData()

    data.append('file', uploadedFile.file, uploadedFile.name)

  }

  return (
    <div className="UploadFile">
        <Upload onUpload={handleUpload} />
        {!!uploadedFiles.length && (
          <FileList files={uploadedFiles} onDelete={null} />
        )}
    </div>
  )
}

// class App extends Component {
//   state = {
//     uploadedFiles: []
//   };

//   async componentDidMount() {
//     const response = await api.get("posts");

//     this.setState({
//       uploadedFiles: response.data.map(file => ({
//         id: file._id,
//         name: file.name,
//         readableSize: filesize(file.size),
//         preview: file.url,
//         uploaded: true,
//         url: file.url
//       }))
//     });
//   }

//   handleUpload = files => {
//     const uploadedFiles = files.map(file => ({
//       file,
//       id: uniqueId(),
//       name: file.name,
//       readableSize: filesize(file.size),
//       preview: URL.createObjectURL(file),
//       progress: 0,
//       uploaded: false,
//       error: false,
//       url: null
//     }));

//     this.setState({
//       uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
//     });

//     uploadedFiles.forEach(this.processUpload);
//   };

//   updateFile = (id, data) => {
//     this.setState({
//       uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
//         return id === uploadedFile.id
//           ? { ...uploadedFile, ...data }
//           : uploadedFile;
//       })
//     });
//   };

//   processUpload = uploadedFile => {
//     const data = new FormData();

//     data.append("file", uploadedFile.file, uploadedFile.name);

//     api
//       .post("posts", data, {
//         onUploadProgress: e => {
//           const progress = parseInt(Math.round((e.loaded * 100) / e.total));

//           this.updateFile(uploadedFile.id, {
//             progress
//           });
//         }
//       })
//       .then(response => {
//         this.updateFile(uploadedFile.id, {
//           uploaded: true,
//           id: response.data._id,
//           url: response.data.url
//         });
//       })
//       .catch(() => {
//         this.updateFile(uploadedFile.id, {
//           error: true
//         });
//       });
//   };

//   handleDelete = async id => {
//     await api.delete(`posts/${id}`);

//     this.setState({
//       uploadedFiles: this.state.uploadedFiles.filter(file => file.id !== id)
//     });
//   };

//   componentWillUnmount() {
//     this.state.uploadedFiles.forEach(file => URL.revokeObjectURL(file.preview));
//   }





export default UploadFile;