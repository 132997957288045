import { useEffect, useState } from "react";

import "./mastitis.css";

import Input from "../../../../components/input/input";
import Select from "../../../../components/select/select";
import Switch from "../../../../components/switch/switch";

const treatmentOptions = [
  { label: "Intramamário" },
  { label: "Sistêmico" },
  { label: "Intramamário e Sistêmico" },
];

const intramamario_drugs = [
  { label: "AGROMASTIT", period: 4 },
  { label: "BIOMAST", period: 3.5 },
  { label: "BOVIGAN L", period: 3 },
  { label: "CEFAVET", period: 3.5 },
  { label: "CIPROLAC", period: 2 },
  { label: "COBACTAN VL", period: 2.5 },
  { label: "GENTATEC", period: 4 },
  { label: "INTRAMAST", period: 3 },
  { label: "MASTCLIN", period: 2 },
  { label: "MASTIBEST L", period: 4 },
  { label: "MASTICAL", period: 3 },
  { label: "MASTICEL", period: 3.5 },
  { label: "MASTICINE L", period: 4 },
  { label: "MASTICLOX L", period: 4 },
  { label: "MASTIFIN", period: 4 },
  { label: "MASTIJET FORTE", period: 9 },
  { label: "MASTIPLAN LC", period: 5 },
  { label: "MASTIPLUS", period: 9 },
  { label: "MASTITE CLINICA VL", period: 2.5 },
  { label: "MASTIZONE", period: 3.5 },
  { label: "MASTIZONE PLUS LACTAÇÃO", period: 4 },
  { label: "NEWMAST", period: 6 },
  { label: "PROMASTIC", period: 3 },
  { label: "QUALLYXINE", period: 3.5 },
  { label: "RILEXINE", period: 4 },
  { label: "SILMAST", period: 3 },
  { label: "SPECTRAMAST LC", period: 3 },
  { label: "SUPRONAL", period: 1.5 },
  { label: "TOPMAST", period: 4 },
  { label: "UBERLAC", period: 3.5 },
  { label: "UBRECILIN", period: 3 },
  { label: "UBROLEXIN", period: 5 },
  { label: "VASECLOX MA", period: 4.5 },
  { label: "VETIMAST", period: 4 },
];

const sistemico_drugs = [
  { label: "AGROVET PLUS", period: 3 },
  { label: "BORGAL", period: 2 },
  { label: "CALBIÓTICO", period: 5 },
  { label: "CLAMOXYL", period: 4 },
  { label: "CLAVACILIN", period: 1 },
  { label: "DIASTIN", period: 7 },
  { label: "DIAZIL", period: 13 },
  { label: "ENRO FLEX INJETÁVEL", period: 3 },
  { label: "ENROFLOXACINA 10%", period: 3 },
  { label: "ESTREPTOMAX", period: 1 },
  { label: "ESTREPTOMICINA BIOFARM", period: 3 },
  { label: "FLOBIOTIC", period: 3 },
  { label: "FLOXICLIN", period: 3 },
  { label: "FLUMAST", period: 4 },
  { label: "FORCYL", period: 2 },
  { label: "FORTECILIN PLUS", period: 5 },
  { label: "FORTGAL PLUS", period: 1 },
  { label: "GENTAMOX", period: 3 },
  { label: "GENTATEC", period: 4 },
  { label: "GENTOMICIN", period: 4 },
  { label: "IBATRIM", period: 7 },
  { label: "KINETOMAX", period: 5 },
  { label: "MEGACILIN PPU PLUS", period: 5 },
  { label: "MEGACILIN SUPER PLUS", period: 5 },
  { label: "MOGIPEN", period: 5 },
  { label: "NORODINE 24", period: 4 },
  { label: "OUROTETRA PLUS LA", period: 7 },
  { label: "PANGRAM 10%", period: 1.5 },
  { label: "PENCIMAX", period: 5 },
  { label: "PENFORT PPU", period: 18 },
  { label: "PRONTO PEN", period: 3 },
  { label: "PROPEN", period: 3 },
  { label: "PULMODAZIN PLUS", period: 3 },
  { label: "PULMODAZIN REFORÇADO", period: 3 },
  { label: "SEPTIPEN", period: 5 },
  { label: "SULFATRIM", period: 3 },
  { label: "SUPRA PEN", period: 4 },
  { label: "TERRAMAX 20 LA", period: 4 },
  { label: "TERRAMICINA LA", period: 4 },
  { label: "TETRABAC LA", period: 8 },
  { label: "TRIAZOCLIN", period: 3 },
  { label: "TRIBRISSEN", period: 3 },
  { label: "TRISSULFIN", period: 5 },
  { label: "TRISSULMAX", period: 11 },
  { label: "TYLADEN", period: 4 },
  { label: "TYLAN 200", period: 3 },
  { label: "VETSULFA", period: 5 },
  { label: "ZELOTRIL 10%", period: 3 },
  { label: "ZELOTRIL PLUS", period: 3 },
];

function AddTreatment({
  includeTreatment,
  shortageTime,
  handleIncludeTreatment,
  handleShortageTime,
  handleChange,
  values,
  errors,
  handleSelectChange,
  open = false,
}) {
  const [shortages, setShortages] = useState({});

  useEffect(() => {
    handleSelectChange("drug", null);
  }, [values.treatmentType, handleSelectChange]);

  function handleDrugsChange(value, field) {
    if (values.treatmentType === "Intramamário e Sistêmico") {
      handleSelectChange(field, value.label);
      setShortages((old) => {
        old[field] = value.period;
        handleShortageTime(
          Object.values(old).reduce((acc, cur) => acc + cur, 0)
        );
        return old;
      });
    } else {
      handleSelectChange(field, value.label);
      handleShortageTime(value.period);
    }
  }

  return (
    <div className="mastitis-treatment">
      <div className={"box-include-treatment " + (open ? "open" : "")}>
        <div className="title">
          <h2>Incluir Tratamento</h2>
          {!open && (
            <Switch onClick={() => handleIncludeTreatment(!includeTreatment)} />
          )}
        </div>
        {(includeTreatment || open) && (
          <>
            <div className="select-type first">
              <Input
                id="date_treatment"
                label="Data do início do tratamento"
                errorAlignColumn
                error={errors.date_treatment}
                value={values.date_treatment}
                type="text"
                placeholder="dd/mm/aaaa"
                mask="99/99/9999"
                onChange={handleChange}
              />
            </div>
            <div className="select-type">
              <Select
                id="treatmentType"
                errorAlignColumn
                label="Tipo de tratamento"
                placeholder="Selecione uma opção"
                error={errors.treatmentType}
                value={values.treatmentType}
                onChange={(value) => {
                  handleSelectChange("treatmentType", value.label);
                  handleShortageTime(0);
                }}
                options={treatmentOptions}
              />
            </div>
            {values.treatmentType === "Intramamário e Sistêmico" ? (
              <>
                <div className="select-type">
                  <Select
                    id="drug_intramamario"
                    errorAlignColumn
                    label="Medicamento Intramamário"
                    placeholder="Selecione o medicamento"
                    error={errors.drug}
                    value={values.drug_intramamario}
                    onChange={(value) =>
                      handleDrugsChange(value, "drug_intramamario")
                    }
                    disabled={!values.treatmentType}
                    options={intramamario_drugs}
                  />
                </div>
                <div className="select-type">
                  <Select
                    id="drug_sistemico"
                    errorAlignColumn
                    label="Medicamento Sistêmico"
                    placeholder="Selecione o medicamento"
                    error={errors.drug}
                    value={values.drug_sistemico}
                    onChange={(value) =>
                      handleDrugsChange(value, "drug_sistemico")
                    }
                    disabled={!values.treatmentType}
                    options={sistemico_drugs}
                  />
                </div>
              </>
            ) : (
              <div className="select-type">
                <Select
                  id="drug"
                  errorAlignColumn
                  label="Medicamento"
                  placeholder="Selecione o medicamento"
                  error={errors.drug}
                  value={values.drug}
                  onChange={(value) => handleDrugsChange(value, "drug")}
                  disabled={!values.treatmentType}
                  options={
                    values.treatmentType === "Intramamário"
                      ? intramamario_drugs
                      : sistemico_drugs
                  }
                />
              </div>
            )}
            {(values.drug ||
              (values.drug_intramamario && values.drug_sistemico)) && (
              <div className="box-description">
                <h2>
                  Tempo de carência após a ultima aplicação do medicamento:
                </h2>
                <p>{shortageTime} dias (de acordo com o fabricante)</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default AddTreatment;
