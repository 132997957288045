import './actionCard.css';

import arrow_white from '../../../../assets/svg/your-properties/arrow-white.svg';

function ActionCard({ name, gridarea, image_path, image_alt, onClick }) {
    return (
        <div className="action-card" style={{ gridArea: gridarea }} onClick={onClick}>

            <div className="action-body">
                <div className="arrow-button">
                    <div className="button">
                        <img src={arrow_white} alt="Arrow" />
                    </div>
                </div>

                <div className="action-info">
                    <img src={image_path} alt={image_alt} className="action-icon" />
                    <p className="action-name">{name}</p>
                </div>
            </div>

        </div>
    )
}

export default ActionCard;