import React, { useEffect } from "react";

import Input from "../../../../components/input/input";

import Select from "../../../../components/select/select";
import useForm from "../../../../hooks/useForm";

const optionsCMT = [
  { label: "1" },
  { label: "2" },
  { label: "3" },
  { label: "4" },
  { label: "x" },
  { label: "m" },
];

function RegisterRow({ definedValues, handleAnimalValues, errors }) {
  const { values, handleChange, handleSelectChange } = useForm();

  useEffect(() => {
    handleAnimalValues(values);
  }, [values]);

  const hasTits = Object.keys(values).some(
    (key) => key !== "event_date" && values[key]
  );

  return (
    <div className="event_cmt_row">
      <Input
        id="identification"
        type="text"
        value={definedValues.identification}
        disabled
      />
      <Input id="lot" type="text" value={definedValues.lot ?? "-"} disabled />
      <Input
        id="event_date"
        type="text"
        className={errors?.event_date ? "error" : ""}
        value={values.event_date}
        onChange={handleChange}
        mask="99/99/9999"
        placeholder="dd/mm/aaaa"
      />
      <Select
        id="teto_fe"
        options={optionsCMT}
        className={
          (hasTits ? "" : "no-tits ") + (errors?.tetos ? " error" : "")
        }
        value={values.teto_fe}
        onChange={(index) => handleSelectChange("teto_fe", index)}
        fixed
      />
      <Select
        id="teto_fd"
        options={optionsCMT}
        className={
          (hasTits ? "" : "no-tits ") + (errors?.tetos ? " error" : "")
        }
        value={values.teto_fd}
        onChange={(index) => handleSelectChange("teto_fd", index)}
        fixed
      />
      <Select
        id="teto_te"
        options={optionsCMT}
        className={
          (hasTits ? "" : "no-tits ") + (errors?.tetos ? " error" : "")
        }
        value={values.teto_te}
        onChange={(index) => handleSelectChange("teto_te", index)}
        fixed
      />
      <Select
        id="teto_td"
        options={optionsCMT}
        className={
          (hasTits ? "" : "no-tits ") + (errors?.tetos ? " error" : "")
        }
        value={values.teto_td}
        onChange={(index) => handleSelectChange("teto_td", index)}
        fixed
      />
    </div>
  );
}

export default RegisterRow;
