import { useEffect, useRef, useState } from "react";

import "./animalCard.css";

import { useFarmContext } from "../../contexts/farmContext";
import { calcAge } from "../../utils/calcAge";

export default function AnimalCard(props) {
  const { animalImg } = useFarmContext();

  const seeMoreRef = useRef(null);

  const [seeMore, setSeeMore] = useState(false)
  const [showSeeMore, setShowSeeMore] = useState(false)

  useEffect(() => {
    if (seeMoreRef.current) {
      var paragraphHeight = seeMoreRef.current.clientHeight
      if (paragraphHeight > 73){
        setShowSeeMore(true)
      }
    }
  }, []);

  return (
    <div className="animal-card">
      <img src={animalImg()} alt="Animal Ícone" className="img-wrapper" />
      <div className={"card-body  " + (seeMore ? "seeMore" : "noSeeMore")}>
        { props.animals.length === 1 &&
          <div className="card-column"> 
            <h3>Identificação</h3>
            <p><b>{props.animals[0]["Identificação"]}</b>{props.animals[0]["Nascimento"] && "(" + calcAge(props.animals[0]["Nascimento"]) + " anos)"}</p>
          </div>
        }
        { props.animals.length > 1 &&
          <div className="card-column number-animals one-animal"> 
            <h3>Identificação</h3>
            <p><b>{props.animals.length}</b> animais selecionados</p>
          </div>
        }
        { props.animals.length === 1 &&
          <>
            <div className="card-column one-animal"> 
              <h3>Raça</h3> 
              <p>{props.animals[0]["Raça"] || "-"}</p>
            </div>
            <div className="card-column one-animal"> 
              <h3>Nascimento</h3> 
              <p>{props.animals[0]["Nascimento"] || "-"}</p>  
            </div>
            <div className="card-column one-animal"> 
              <h3>Lote</h3>
              <p>{props.animals[0]["Lote"] || "-"}</p> 
            </div>
          </>
        }
        { props.animals.length > 1 &&
            <div className={"card-column name-animals " + (seeMore ? "seeMore" : "noSeeMore")}> 
              <p id="name-animals-p" ref={seeMoreRef}>
                { props.animals.map((item, index, arr) => { 
                    if (index < arr.length - 1)
                      return (item["Identificação"] + ", ")
                    else
                      return (item["Identificação"])
                  })
                }
              </p>
              { showSeeMore &&
                  <div className={"show-more " + (seeMore ? "seeMore" : "noSeeMore")} onClick={()=> setSeeMore(!seeMore)}>
                    <p className="ellipsis"> 
                      {seeMore ? null : "..."} 
                    </p>
                    <p>
                      {seeMore ? "Ocultar" : "Saiba mais"}
                    </p>
                  </div>
              }
            </div>
        }
      </div>
    </div>
  )
}